import React, { Component } from "react";
import * as Constant from "../../CustomUI/Constant";

export default class StepSix extends Component {
  render() {

      
    return (
      <div>
        {/*<h1>Registration Completed</h1>*/}
        <div className="bodyContent">
          <div className="success">
            <div className="center-block">
              <i
                className="fa fa-check-circle greenText "
                aria-hidden="true"
              />
            </div>
            {/*<h2 className="success greenText"><i className="material-icons">done</i> You have completed registration successfully!!!!</h2> */}
            <p className="ml35 mt10 font35">
              Please{" "}
              <a
                href={
                  Constant.FRONTEND_SERVER_URL + Constant.FRONTEND_CONTEXT_PATH
                }
              >
                <strong>Login</strong>
              </a>{" "}
              to access your account.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
