import React, { Component } from "react";
import Input from "../../CustomUI/Input";
import * as Constant from "../../CustomUI/Constant";
import { TableAgent } from "../../PageComponent/Table/Table";
import ReactTooltip from "react-tooltip";

class Agent extends Component {
  render() {
    return (
      <div>
      <div className="">
        <div className="agentRole">
        <div className="row">
        <div className="tabSectionTitle clearfix mt20">
          <div className="col-xs-12 pl0">
            <h3 className="pull-left">Add Physician Details</h3>
            <span className="inline pt7 pl15">
              (Add all Prescribers for whom you will act as agent)
            </span>
            <div className="clearfix" />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
          <div className="input-field">
            <Input
              intid="npiAgent"
              intplaceholder={Constant.LABEL_NPI}
              intType="text"
              intClasssName={
                this.props.stepTwoValidate.npiValid
                  ? "validate invalid"
                  : "allownumericwithoutdecimal validate"
              }
              intName={Constant.INPUT_NPI_AGENT}
              change={this.props.Change}
              intStep={Constant.STEP_TWO_AGENT}
              maxlength="10"
              keyPress={this.props.associatedDataListner}
              keyEvent="Enter"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
          <div className="input-field">
            <Input
              intid="agentFNSearch"
              intplaceholder={Constant.LABEL_FIRST_NAME}
              intType="text"
              intClasssName={
                this.props.stepTwoValidate.agentFNSearchValid
                  ? "validate invalid"
                  : "validate"
              }
              intName={Constant.INPUT_AGENT_FNSEARCH}
              intStep={Constant.STEP_TWO_AGENT}
              keyPress={this.props.associatedDataListner}
              keyEvent="Enter"
              maxLength="30"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
          <div className="input-field">
            <Input
              intid="agentLNSearch"
              intplaceholder={Constant.LABEL_LAST_NAME}
              intType="text"
              intClasssName={
                this.props.stepTwoValidate.agentLNSearchValid
                  ? "validte invalid"
                  : "validate"
              }
              intName={Constant.INPUT_AGENT_LNSEARCH}
              intStep={Constant.STEP_TWO_AGENT}
              keyPress={this.props.associatedDataListner}
              keyEvent="Enter"
              maxLength="30"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-1">
            <span className="actionIconWrapper">
              <a href="#"name="agentAssociatePrescriberSearch" className="tooltipped" data-tip="Search" data-position="top" onClick={this.props.associatedDataListner} >
                <i className="themify-icons ti-search" name="agentAssociatePrescriberSearch"/>
              </a>
              <ReactTooltip />
            </span>
          </div>
        </div>
        <div className="row">
        <div className="col-sm-12 pl0 pr0 ">
          <div className="addPhysicians">
            <TableAgent
              data={this.props.prescriberJson}
              changePrescriberListner={this.props.changePrescriberListner}
              isSelected={false}
            />
          </div>
        </div>
      </div>
          <div className="tabSectionTitle clearfix">
            <div className="col-xs-12 pl0">
              <h3>Selected Physician Details</h3>
            </div>
          </div>
          <div className="selectPhysicians pl5 pr5">
            <TableAgent
              data={this.props.selectedPrescriberJson}
              changePrescriberListner={this.props.changePrescriberListner}
              isSelected={true}
            />
          </div>

        </div>

      </div>
      </div>
    );
  }
}

export default Agent;
