import React from 'react';
import CookieConsent from "react-cookie-consent";

let currentYear = new Date().getFullYear();

const footer = () => {
    return (
        <div>
            <footer className="page-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="footer-content">
                            <p>KloudScript, Inc. 17W755 Butterfield Road, Suite 102, Oakbrook Terrace, IL 60181</p>
                            <p><i className="fa fa-mobile" aria-hidden="true"></i>877-265-1231</p>
                            <p><i className="fa fa-envelope" aria-hidden="true"></i>support@theprontorx.com </p>
                            <div className="footer-right">
                                <div className="footer-privacy"><a target="_blank" href="https://kloudscript.com/privacy/">Privacy Policy</a></div>
                                <p className="copyright">Copyright <i className="fa fa-copyright" aria-hidden="true"></i> KloudScript, Inc. {currentYear}. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            <CookieConsent  buttonText="Accept"
                cookieName="acceptCookieConesnet"
                style={{ background: "#2e3192" }}
                buttonStyle={{ background: "#4eaf50", color: "white", fontSize: "13px" }}
                expires={150}>
                        We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.
            </CookieConsent>
        </footer>
    </div>
    );
};

export default footer;