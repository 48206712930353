import React, { Component } from 'react';
import {Input} from 'react-materialize';
import Button from '../../CustomUI/Button';
import CustomInput from '../../CustomUI/Input'
import * as Constant from '../../CustomUI/Constant';
//import { constants } from 'perf_hooks';
import axios from 'axios';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';

export default class StepFive extends Component {

	state = {
		questionList : []
	}


componentDidMount(){
	axios.get('/registration/user/securityQues')
		.then(response => {
			// console.log(response.data);
			 this.setState({questionList : response.data})
			//  console.log(this.state.questionList);
		})
		.catch(function (error) {
			console.log(error);
		});
}



   render (){
	$('.noSpace').keyup(function() {
		this.value = this.value.replace(/\s/g,'');
	   });

	let stepFiveValidate ={...this.props.stepFiveValidate}
	let questionList = this.state.questionList ? this.state.questionList:[]
	 const questionListData = questionList.map(question =>{
	 	return <option data-id={question.id} value={question.id} key={question.id} >{question.question}</option>
	 });

	return(

        <div>
            <h1>Login Information </h1>
					<div className="bodyContent" >
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<div className="input-field">
								<CustomInput intplaceholder={Constant.LABEL_USER_NAME+Constant.STAR} intid="userNamelogin" intType="text" intClasssName={(stepFiveValidate.userNameValid) || (stepFiveValidate.userNameValid? 'validate invalid  specialCharRestriction alphanumeric':'alphanumeric specialCharRestriction validate ')} intName={Constant.INPUT_USER_NAME} required  change={this.props.change} intStep={Constant.STEP_FIVE} />
									{/*<Label  lblhtmlFor="userNamelogin" lblclassName={(this.props.stepFiveData.userName||stepFiveValidate.userNameValid)? 'active': null}   lbldataError={stepFiveValidate.userNameValid ? Constant.USER_NAME:  null} lblname={Constant.LABEL_USER_NAME+Constant.STAR}/>*/}
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
								<div className="vertical-bottom">
									{this.props.isUserNameAvailable && this.props.stepFiveData.userName ?<p className="red1">{Constant.USER_NAME_DUPLICATE}</p>: null}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<div className="input-field">
									<CustomInput intplaceholder={Constant.LABEL_PASSWORD+Constant.STAR}	intid="passwordlogin" intName="password" intType="password" intClasssName={stepFiveValidate.passwordValid? 'validate invalid noSpace': 'validate noSpace'} required change={this.props.change} intStep={Constant.STEP_FIVE}/>
									{/*<Label lblhtmlFor="passwordlogin" lblclassName={(this.props.stepFiveData.password || stepFiveValidate.passwordValid)? 'active': null} lbldataError={stepFiveValidate.passwordValid?Constant.PASSWORD  : null} lblname={Constant.LABEL_PASSWORD+Constant.STAR}/>*/}
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<div className="vertical-bottom">
									<a data-tip={Constant.INVALID_PASSWORD_FORMATE}>
										<i className="small material-icons">info</i>
									</a>
									<ReactTooltip place="right" html={true}/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<div className="input-field">
									<CustomInput intplaceholder={Constant.LABEL_CONFIRM_PASSWORD+Constant.STAR} intid="confirmPasswordlogin" intType="password" intName={Constant.INPUT_CONFIRM_PASSWORD} intClasssName={stepFiveValidate.confirmPasswordValid? 'validate invalid noSpace': 'validate noSpace'} required change={this.props.change} intStep={Constant.STEP_FIVE}/>
									{/*<Label lblhtmlFor="confirmPasswordlogin" lblclassName={(stepFiveValidate.confirmPasswordValid || this.props.stepFiveData.confirmPassword)? 'active': null} lbldataError={stepFiveValidate.confirmPasswordValid ? Constant.CONFIRM_PASSWORD: null} lblname={Constant.LABEL_CONFIRM_PASSWORD+Constant.STAR}/>*/}
								</div>
							</div>

						</div>
						<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5 hide-arrow">
									<Input s={12} m={12} l={12} xl={12} disabled  onLoad={(event)=>this.props.change(event,Constant.STEP_FIVE)} id='resetpassword'  type='select'  label='Reset Password Method *' name='resetpassword'  >
									<option disabled value=""></option>
									<option defaultValue value="EMAIL">Email</option>
									</Input>
								</div>
						</div>
						<hr/>
						<div className="row">
							<div className="question-part">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<Input s={12} m={12} l={12} xl={12}  onChange={(event)=>this.props.change(event,Constant.STEP_FIVE)} id='securityQuestion1' className={stepFiveValidate.securityQuestion1Valid? 'validate invalid': 'validate'} type='select'  label='Security Question 1 *' name='securityQuestion1'  >
										<option value="" >--Select--</option>
										{questionListData}
								</Input>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
									<div className="input-field">
										<CustomInput intplaceholder={Constant.LABEL_ANSWER+Constant.STAR} intid="securityAnswer1" intName={Constant.INPUT_SECURITY_ANSWER_1} intType="text" required intClasssName={stepFiveValidate.securityAnswer1Valid? 'validate invalid noSpace': 'validate noSpace'} cintStep={Constant.STEP_FIVE}/>
										{/*<Label lblhtmlFor="securityAnswer1" lblclassName={this.props.stepFiveData.securityAnswer1 || stepFiveValidate.securityAnswer1Valid? 'active null':'null active' } lbldataError={stepFiveValidate.securityAnswer1Valid ?  Constant.SECURITY_ANSWER_1: null} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="question-part">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<Input s={12} m={12} l={12} xl={12} onChange={(event)=>this.props.change(event,Constant.STEP_FIVE)} id='securityQuestion2' className={stepFiveValidate.securityQuestion2Valid? 'validate invalid': 'validate'}  type='select'  label='Security Question 2 *' name='securityQuestion2' >
									<option value="" >--Select--</option>
									{questionListData}
								</Input>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
									<div className="input-field">
										<CustomInput intplaceholder={Constant.LABEL_ANSWER+Constant.STAR} intid="securityAnswer2" intName={Constant.INPUT_SECURITY_ANSWER_2} intType="text" required intClasssName={stepFiveValidate.securityAnswer2Valid? 'validate invalid noSpace': 'validate noSpace'}  intStep={Constant.STEP_FIVE}/>
										{/*<Label lblhtmlFor="securityAnswer2" lblclassName={this.props.stepFiveData.securityAnswer2 || stepFiveValidate.securityAnswer2Valid? 'active null': 'null active'} lbldataError={stepFiveValidate.securityAnswer2Valid ?  Constant.SECURITY_ANSWER_2: null} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>*/}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="question-part">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
								<Input s={12} m={12} l={12} xl={12} id='securityQuestion3' onChange={(event)=>this.props.change(event,Constant.STEP_FIVE)} type='select' className={stepFiveValidate.securityQuestion3Valid? 'validate invalid': 'validate'} label='Security Question 3 *' name='securityQuestion3'   >
								<option value="" >--Select--</option>
								{questionListData}
								</Input>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 pl5 pr5">
									<div className="input-field">

										<CustomInput intplaceholder={Constant.LABEL_ANSWER+Constant.STAR} intid="securityAnswer3" intName={Constant.INPUT_SECURITY_ANSWER_3} intType="text" required intClasssName={stepFiveValidate.securityAnswer3Valid? 'validate invalid noSpace': 'validate noSpace'}  intStep={Constant.STEP_FIVE}/>
										{/*<Label lblhtmlFor="securityAnswer3" lblclassName={this.props.stepFiveData.securityAnswer3 || stepFiveValidate.securityAnswer3Valid? 'active null': 'null active'} lbldataError={stepFiveValidate.securityAnswer3Valid ?  Constant.SECURITY_ANSWER_3: null} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>*/}

									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="wizard-footer">
						<div className="btn-toolbar pull-right">
							<Button btnName="Back" className="btn btn-primary wizard-next btn-dark scroll-top" click={this.props.click} secondParams={Constant.STEP_FOUR}/>
							<a href={Constant.FRONTEND_CONTEXT_PATH}>
								<Button btnName="Cancel" className="btn btn-primary wizard-next Cancel btn-white scroll-top"  secondParams="step3" />
							</a>
							<Button btnName="Submit" className="btn btn-primary wizard-next scroll-top" click={this.props.click} secondParams={Constant.STEP_FOUR}/>
						</div>
               	 </div>

		</div>



	);
   }
}

