import React, { Component } from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import PrescriberProfile from './prescriberProfile';
import axios from 'axios';
import $ from 'jquery';
import * as Constant from '../../../Component/CustomUI/Constant';
import UserProfile from '../../../UserProfile';
import Button from '../../../Component/CustomUI/Button';



let userData = []
let deaNumber = ''
let existingUserName=""

export default class MyProfilePrescriber extends Component {

    state = {
        resetPasswordMethod:'',
        npi : 1417012378,
        prescriberApiData:[],
        securityQuestionAnswerData:[],
        securityQuestionsData:[],
        prescriberProfileData :{},
        ProfileValidate :{
            userNameValid:false,
            firstNameValid : false,
            lastNameValid: false,
            addressLine1Valid: false,
            cityValid:false,
            isStateValid:false,
            zipValid:false,
            workphoneValid:false,
            emailValid:false,
            confEmailValid:false,
            isEmailAvailable:false
        },
        isSuccess :false,
        isLoading:false,
        roleName:'',
        documentTest:[]

    }
    // checkDuplicateEmail = (event) => {
    //    let  value=event.target.value;
    //    let userEmail=userData;
    //     if(value!=''&&!value.match(Constant.REGEX_SPACE)&&value.toLowerCase()!=userEmail[0].email.toLowerCase()){
    //         value=value.toLowerCase()
    //     axios.get('/registration/verifyEmail?email='+value)
    //     .then(response => {
    // 	this.isEmailDuplicate=response.data
    // 	 let isEmailAvailable = this.state
    //          if(this.isEmailDuplicate){
    //             isEmailAvailable = false;
    // 			  this.setState({isEmailAvailable:this.isEmailDuplicate})
    //             window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
    //         } else {
    //             isEmailAvailable = true;
    // 		  this.setState({isEmailAvailable:this.isEmailDuplicate})

    //          }
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
    // } else{
    //     this.setState({isEmailAvailable:false})}
    // }
    checkDuplicateEmail = (prescriberJson) => {
        let  value=$("#email").val()
        let userEmail=userData;
         if(value!=''&&!value.match(Constant.REGEX_SPACE) && value.toLowerCase()!=userEmail[0].email.toLowerCase() && value.match(Constant.REGEX_EMAIL) !== null){
             value=value.toLowerCase()
         axios.get('/registration/verifyEmail?email='+value)
         .then(response => {
             this.isEmailDuplicate=response.data
              if(this.isEmailDuplicate){
                   this.setState({isEmailAvailable:this.isEmailDuplicate})
                   window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
               } else if(value.match(Constant.REGEX_EMAIL) !== null && value){
                 this.setState({isEmailAvailable:this.isEmailDuplicate})
                 this.updatePrescriber(prescriberJson)
              }
         })
         .catch(function (error) {
             console.log(error);
         });
     } else{
         this.setState({isEmailAvailable:false})}
     }

    componentDidMount(){
            this.setState({
                isLoading:true
            })

        this.getPrescriberProfileData()

    }

     removeConsentListener = (event) =>{

        let id = this.state.removeConsentId;
        axios.put('/prescriberProfile/deleteConsentDocument?id='+id)
            .then(response => {
                 this.setState({
                    isLoading:true
                })
                this.getPrescriberProfileData();
                $("#deleteConsentModalCancel").click();
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

     }

    deleteConsentModel(){
        return(
            <div id="deleteConsentModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center max550">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete Prescriber Consent Document</h4>
                       </div>
                       <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <p>Are you sure! You want to Delete Prescriber Consent Document ?</p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="deleteConsentDoc" btnName="Ok" className="btn btn-primary wizard-next btn-purpale" click={this.removeConsentListener.bind(this)} secondParams={null} />
                       <button type="button"  id="deleteConsentModalCancel" className="btn btn-primary wizard-next  btn-dark" data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

    setRemoveConsentDocument = (event,row) =>{
        this.setState({removeConsentId:row.id});
     }

     getPrescriberProfile=(username)=>{

        let queryString = '';
        let isProfile = true
        queryString += '?username='+encodeURIComponent(username)
        queryString += '&isProfile='+isProfile

        axios.get('/prescriberProfile/getPrescriberProfileData'+queryString)
            .then(response => {
                // console.log(response.data.securityQuestionAnswer)
                if(response.data.validateMessage){
                     window.Materialize.toast(response.data.validateMessage, 3000, 'red rounded')
                }

                if(response.data.user === null){
                    this.setState({isLoading:false})
                    window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"Pagenofound";
                }else{
                    this.setState({securityQuestionAnswerData:response.data.securityQuestionAnswer})
                    this.setState({prescriberApiData:[JSON.parse(response.data.npiApiData)]})
                    this.setState({documentTest:response.data.user.prescriber.document})
                    userData.push(response.data.user)
                    existingUserName=response.data.user.username
                    this.setState({resetPasswordMethod:response.data.user.resetPasswordMethod})
                    this.setState({isLoading:false})
                }
				
                }).catch(function (error) {
                console.log(error);
        });
     }
     getUserNameFromUserID=(userID)=>{

        axios.get('/user/getUserNameFromUserID?userid='+userID)
         .then(response => {
           this.getPrescriberProfile(response.data)
        }).catch(function (error) {
            console.log(error);
        });
    }

    getPrescriberProfileData = () =>{

        let username;
        if(this.param) {
            this.getUserNameFromUserID(this.param)
        } else {
            username = UserProfile.getUserName();
            this.getPrescriberProfile(username)
    }
}

    savePrescriberProfileListner = () => {

        let userNameValid = false;
        let firstNameValid = false;
        let lastNameValid = false;
        let addressLine1Valid = false;
        let emailValid = false;
        let isValid = true;

        let password = $("#password").val();
        let userId  =  $("#userId").val();
        let prescriberId = $("#prescriberId").val();
        let username = $("#username").val();
        let prefix = $("#prefix").val();
        let fname = $("#fname").val();
        let mname = $("#mname").val();
        let lname = $("#lname").val();
        let address1 = $("#address1").val();
        let address2 = $("#address2").val();
        let city = $("#city").val();
        let state = $("#state").val();
        let zip = $("#zip").val();
        let npi = $("#npi").val();
        let tempPassword = $("#displayTempPassword").val();
        let userKey = $("#userKey").val();
        let npiStatus = $("#npiStatus").val();
        // if(faxOne.match(Constant.PHONE_NUMBER_REGX) || !faxOne ){
        //     isValid = true
        // }else {
        //     isValid = false
        //     $("#faxOne").val("");
        //     window.Materialize.toast("Please Enter proper faxNumber", 5000,  'red rounded')
        // }

         //     if(faxTwo.match(Constant.PHONE_NUMBER_REGX) || !faxTwo){
        //         isValid = true
        //     }else {
        //         isValid = false
        //         faxTwo = $("#faxTwo").val("");
        //         window.Materialize.toast("Please Enter proper faxNumber", 5000,  'red rounded')

        //     }




        let passwordUpdateDate = userData[0].passwordUpdateDate
        let ipAddress =  userData[0].ipAddress
        let neverLogin = userData[0].neverLogin
        let createdDate = userData[0].createdDate
        let email = $("#email").val();
        let roleTitleId = $("#roleTitleId").val();
        let roles = []
        roles.push( $("#roleId").val());
        let resetPasswordMethod=$("#resetpasswordmethod").val();
        let status = $("#userStatus").val();
        let approvalStatus = $("#userApprovalStatus").val();
        // if(this.state.isEmailAvailable&&email) {
        //     isValid = false;
        //     window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
        // }

        if(username.match(Constant.USER_ALPHABET_SPACE)==null){
            isValid = false
            if(username){
                window.Materialize.toast(Constant.USER_NAME_VALID, 3000,  'red rounded')
            }
        }
        if(!username){
            userNameValid = true
            isValid = false
            window.Materialize.toast(Constant.USER_NAME, 3000,  'red rounded')
        }
        if(!fname||fname.trim().length==0){
            firstNameValid = true
            isValid = false
            window.Materialize.toast(Constant.FIRST_NAME_VALLID, 3000,  'red rounded')
        }
        if(!lname||lname.trim().length==0){
            firstNameValid = true
            isValid = false
            window.Materialize.toast(Constant.LAST_NAME_VALID, 3000,  'red rounded')
        }
        if(mname&&mname.trim().length==0){
            isValid = false
            window.Materialize.toast(Constant.MIDDLE_NAME_VALID, 3000,  'red rounded')
        }
        // if(basic.middle_name=="" && mname.match(Constant.SPECIALCHAR1) === null && mname){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid middlename", 5000,  'red rounded')
        // }
        // if(basic.last_name==""  && lname.match(Constant.SPECIALCHAR1) === null && lname){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid lastName", 5000,  'red rounded')
        // }
        // if(basic.first_name=="" && fname.match(Constant.SPECIALCHAR1) === null && fname){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid firstName", 5000,  'red rounded')
        // }

        if(!address1){
            addressLine1Valid = true
            window.Materialize.toast(Constant.ADDRESS_LINE_1, 3000,  'red rounded')
        }
        if(email && email.trim().length==0){
            emailValid = true
            isValid = false
            window.Materialize.toast(Constant.EMAIL, 3000,  'red rounded')
        }
        // if(username.match(Constant.REGEX_ALPHABET) === null && username){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid userName", 5000,  'red rounded')
        // }
        // if(lname.match(Constant.REGEX_ALPHABET) === null && lname){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid lastName", 5000,  'red rounded')
        // }
        // if(fname.match(Constant.REGEX_ALPHABET) === null && fname){
        //     isValid = false
        //     window.Materialize.toast("Please Enter Valid firstName", 5000,  'red rounded')
        // }
        if(email  && email.trim().length!==0 && email.match(Constant.REGEX_EMAIL) === null ){
            isValid = false
            window.Materialize.toast(Constant.EMAIL_INVALID, 3000,  'red rounded')
        }


        this.setState({
            ProfileValidate :{
            userNameValid : userNameValid,firstNameValid:firstNameValid,lastNameValid:lastNameValid,addressLine1Valid:addressLine1Valid,emailValid:emailValid
        }
        })



        let taxonomiesData = userData[0].prescriber.taxonomies
        let taxonomies = []
        for(let i =0; i< taxonomiesData.length; i++){
            let amacode = $("#amacode_"+taxonomiesData[i].id).val();
            let innerJson = {"id":taxonomiesData[i].id,"amacode":amacode,"state":taxonomiesData[i].state,"code":taxonomiesData[i].code,"type":taxonomiesData[i].type,"license":taxonomiesData[i].license,"desc":taxonomiesData[i].desc}
            taxonomies.push(innerJson)
        }

        let addressData = userData[0].prescriber.address
        let address = [];
        address.push({id:addressData[0].id,address1:address1,address2:address2,state:state,city:city,zip:zip})

        let contactData = userData[0].prescriber.contact
        let contact = [];



        for(let i =0; i< contactData.length; i++){
                // if(contactData[i].contactType === "PHONE"){
                //     contact.push({id:contactData[i].id,contactType:"PHONE",contactValue:workPhone})
                // }
                if(contactData[i].contactType === "EMAIL"){
                    contact.push({id:contactData[i].id,contactType:"EMAIL",contactValue:email})
                }
                // if(contactData[i].contactType === "FAX" && i == 2){
                //     contact.push({id:contactData[i].id,contactType:"FAX",contactValue:faxOneApiData})
                // }
                // if(contactData[i].contactType === "FAX" && i == 3){
                //     contact.push({id:contactData[i].id,contactType:"FAX",contactValue:faxTwo})
                // }
        }

        // if(!faxOneApiData){
        //     contact.push({contactType:"FAX",contactValue:faxOne})
        // }if(!faxTwoApiData){
        //     contact.push({contactType:"FAX",contactValue:faxTwo})
        // }

        let license = userData[0].prescriber.license

            for(var i =0;i<license.length;i++){
                if(license[i]){

                    if(license[i].licenseType === "DEA"){
                        this.licenseType = "DEA"
                        deaNumber = license[i].licenseValue
                        license[i].licenseValue = $("#deaNumber").val()
                    }
                }
            }



        if(!deaNumber && $("#deaNumber").val() !== "" && this.licenseType !== "DEA"){
            let innerJson = []
            // console.log("--------deaNumber blank---------"+deaNumber)
            innerJson = {licenseType:"DEA",licenseValue:$("#deaNumber").val(),description:"",state:null};
            license.push(innerJson)
        }

        let prescriberJson = {
                            id:userId,npi:npi,password:password,ipAddress:ipAddress,createdDate:createdDate,
                            prefix:prefix,middleName:mname,firstName:fname,userKey:userKey,neverLogin:neverLogin,
                            lastName:lname,email:email,roleTitleId:roleTitleId,roleIds:roles,status:status,passwordUpdateDate:passwordUpdateDate,
                            approvalStatus:approvalStatus,tempPassword:tempPassword,
                            prescriber:{
                                id:prescriberId,prefix:prefix,middleName:mname,firstName:fname,
                                lastName:lname,npi:npi,npiStatus:npiStatus,contact:contact,address:address,taxonomies:taxonomies,license:license
                            },username:username,securityQuestion:userData[0].securityQuestion,
                    resetPasswordMethod:resetPasswordMethod,
            }
        // if(isValid){

        //     axios.post('/prescriberProfile/updatePrescriber',prescriberJson)
        //     .then(response => {
        //         if(response.data){
        //             // console.log(prescriberJson)
        //             window.Materialize.toast("Prescriber updated successfully", 4000, 'green rounded')
        //             this.getPrescriberProfileData()
        //             this.setState({isSuccess:true})

        //         }
        //     })
        //         .catch(function (error) {
        //             console.log(error);
        //     });
        //     ;
        // }}

        if(isValid && userData[0].email.trim() === email.trim()){
            if(existingUserName===$("#username").val()){
                this.updatePrescriber(prescriberJson)
            }else{
                this.checkDuplicateUserName(prescriberJson)
            }
        }else{
            this.checkDuplicateEmail(prescriberJson)
        }


        // let isEmailValid = true
        // let isUserValid = true

        // if(isValid){
        //     if(userData[0].username.trim() === username.trim() && userData[0].email.trim() === email.trim()){
        //          this.updatePrescriber(prescriberJson)
        //          isValid = true
        //     }
        //     if(userData[0].email.trim() !== email.trim()){
        //         isEmailValid = false
        //         isValid = false
        //     }

        //     if(userData[0].username.trim() !== username.trim()){
        //         isUserValid = false
        //         isValid = false
        //     }
        // }

        // if(!isValid && !isEmailValid){
        //     isValid = false
        //     this.checkDuplicateEmail(prescriberJson)
        // }
        // if(!isValid && !isUserValid){
        //     isValid = false
        //     this.checkDuplicateUserName(prescriberJson)
        // }

    }

    updatePrescriber=(prescriberJson)=>{
        this.setState({isSuccess:true})
        axios.post('/prescriberProfile/updatePrescriber',prescriberJson)
        .then(response => {
            if(response.data){
                if(this.state.isSuccess){
                    if(response.data != true && response.data != false){
                        UserProfile.setUserName(response.data);
                    }
                    window.Materialize.toast("Prescriber updated successfully", 7000, 'green rounded')
                    window.location.reload();
                }


            }
        }).catch(function (error) {
                console.log(error);
        });
    }

    //  checkDuplicateUserName(queryString) {

    //         axios.get('/registration/userName?userName=' +  $("#username").val())
    //         .then(response => {
    //             if(response.data){
    //                 this.updatePrescriber(queryString)
    //             }else{
    //                 window.Materialize.toast(Constant.USER_NAME_DUPLICATE, 3000, 'red rounded')
    //                 this.setState({ isSaveLoading : false,isSuccess:false })
    //             }

    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
    checkDuplicateUserName(prescriberJson) {
        let valueOfUsername=$("#username").val()
        let KSEmail=false
        let val=valueOfUsername.toLowerCase();
        if(val.includes("@")){
            if(val.split("@")[1]==="kloudscript.net" || val.split("@")[1]==="usewms.com" || val.split("@")[1]==="usekspa.com"){
                KSEmail=true
            }
          }
          if(!KSEmail){
        // if(!isUserValid){
             axios.get('/registration/userName?userName=' + valueOfUsername )
                .then(response => {
                    if(response.data){
                        // this.checkDuplicateEmail(queryString)
                        this.updatePrescriber(prescriberJson)
                    }else{
                        window.Materialize.toast(Constant.USER_NAME_DUPLICATE, 3000, 'red rounded')
                        this.setState({ isSaveLoading : false,isSuccess:false })
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        // }else{
        //      this.checkDuplicateEmail(queryString)
        // }
        }else{
            window.Materialize.toast("User Name Should Not Contain @kloudscript.net or @usewms.com or @usekspa.com", 3000,  'red rounded')
        }

}



     render() {
        UserProfile.setUrlName(window.location.href)

        let url_string = window.location.href;
        let url = new URL(url_string);
        this.param = url.searchParams.get("username");

        let title = "";

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            title = 'Prescriber Profile';
        }else{
            title = 'My Profile';
        }

        return (
            <div className="v2-back-inner">
                 <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={title}  />
                        <main>
                            <div className="inner-page-container">
                                <div className="row">
                                    <div className="col-sm-12">
                                         {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div>
                                    </div>:
                                    <div className="boxWithShadow boxWithSpace">
                                        <PrescriberProfile
                                        documentTest={this.state.documentTest}
                                        checkDuplicateEmail={this.checkDuplicateEmail}
                                        resetPasswordMethod={this.state.resetPasswordMethod}
                                        prescriberApiData = {this.state.prescriberApiData}
                                        userData = {userData}
                                        ProfileValidate = {this.state.ProfileValidate}
                                        setRemoveConsentDocument = {this.setRemoveConsentDocument}
                                        />
                                        <div className="clearfix"></div>
                                            <section className="buttonWrapper mb20">
                                                <button className="btn waves-effect waves-light btn-darkpurpale" disabled ={this.state.isSuccess} onClick={this.savePrescriberProfileListner}>Save</button>
                                                {/* <button className="btn">Reset</button> */}
                                                <a href={this.param?Constant.FRONTEND_CONTEXT_PATH+"manageuser":Constant.FRONTEND_CONTEXT_PATH+"workqueue"} className="btnn btn btn-primary waves-effect waves-light">Cancel</a>
                                            </section>
                                    </div> }
                                    {this.deleteConsentModel()}
                                 </div>
                               </div>
                            </div>
                        </main>
                   <Footer />
                 </div>
            </div>
        );
     }

}