import Modal from 'react-responsive-modal';
import React, { Component } from 'react'
import * as Constant from '../../CustomUI/Constant';

export default class CustomModal extends Component{

    state = {
        isOpen : this.props.isModalOpen?false:true
    }

    onCloseConfirmationModal = () =>{
        this.setState({isOpen:true})
    }

    addMyPhysician = () =>{
        window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"myphysician";
    }

    render () {
        // let styleClass = { width :'5%'}
        return(
            <div>
                <Modal open={this.state.isOpen} onClose={this.onCloseConfirmationModal} classNames={{modal:"max550"}} >
                    <div className="modal-header" >
                        <h4 className="modal-title">Associate Prescriber</h4>
                    </div>
                    <div className="modal-body">
                        <div className="agengtDetailsAdd inner-page-container">
                            <div className="col-sm-12">
                                <p><b>No active prescriber is associated with you. Please associate at least one active prescriber.</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <section className="buttonWrapper">
                                <button className="btn right btn-darkpurpale"  id="button" type="button" onClick = {this.addMyPhysician} value="Add Physician">Add Physician</button>
                        </section>
                    </div>
                </Modal>
            </div>
        );
    }
}
