import React, {Component} from 'react';
import * as Constant from '../../Component/CustomUI/Constant';
import Button from '../CustomUI/Button';
import CustomInput from '../CustomUI/Input';
import Label from '../CustomUI/Label';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export default class Forgotpasswordinputs extends Component {
    state = {
        value:120,
         count:this.props.count,
         display:false
        }


    disPlayQuestion =()=>{
                return (
                    <div className="input-field">
                     <span className="input-icon"><span className="fa fa-question-circle"></span></span>
                        <CustomInput intid="answer" intType="text" className="validate" intName="question" change={this.props.change} intStep={Constant.STEP_THREE} keyPress={this.props.clickHandler} keyEvent='Enter' />
                        <Label lblhtmlFor="ques2"  lblname={this.props.questionName}></Label>
                    </div>
                );

          }


    render() {

        return (
            <div className="inputs forgot-inputs clearfix" id="inputs">
                <div className="firstSectionDiv">
                    <div className="col-sm-12">
                        <div className="loginTitleDiv">
                            <h1 >
                        Forgot Password - Step 1/4
                        </h1></div>
                    </div>
                    <div className="forgot-box">
                    <div className="input-content">
                    <div className="col-sm-12">
                        <div className="input-field">
                        <span className="input-icon"><span className="fa fa-user"></span></span>
                            <CustomInput intid="userName" intType="text" intName={Constant.INPUT_USER_NAME} change={this.props.change} intStep={Constant.STEP_ONE} />
                            <Label  lblhtmlFor="userName"  lblname="Enter User Name"/>
                        </div>
                    </div>
                    </div>
                    <div className="clearfix">
                        <div className="btn-wrapper center-block text-center">
                            <a href={Constant.FRONTEND_CONTEXT_PATH}  className="btn btn-white" role="button">Cancel</a>
                            <Button btnName="Next" className="btn btn-white" click={this.props.clickHandler} disabled={!this.props.disable} secondParams="step1" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className="secondSectionDiv">
                    {/* <div className="back" id="back"> <span><i className="fa fa-arrow-circle-left" aria-hidden="true"  onClick={this.props.clickHandler}></i></span></div> */}
                    <div className="col-sm-12">
                        <div className="loginTitleDiv">
                        <h1 >
                        Forgot Password - Step 2/4
                        </h1>
                        </div>
                    </div>
                    <div className="forgot-box">

                        <div className="col-sm-12">
                            <div id="otpMethodDiv">
                                <div className="otpMethod center-block text-center">
                                <p className="whitetext font16">
                                {/* <button type="button" className="btn" id="emailBtn" data-tip='Send OTP' data-position='top' name="EMAIL" onClick={(event) => this.props.startTimer(event,this.state.value,"EMAIL")} disabled={!this.props.disable}>
                                <OverlayTrigger placement="top" overlay={<Tooltip >Send OTP</Tooltip>}><i className="material-icons" >mail</i></OverlayTrigger>
                                                </button> */}
                                    Check your email. You should get an email message with one-time password.</p>

                                    {/* <div>
                                            <button type="button" className="btn tooltipped" id="emailBtn" data-tooltip='Email' data-position='top' name="EMAIL" onClick={(event) => this.props.startTimer(event,this.state.value,"EMAIL")} disabled={!this.props.disable}><i className="material-icons">mail</i></button>
                                            <button type="button" className="btn tooltipped" id="smsBtn"  data-tooltip='Sms' data-position='top' name="SMS" onClick={(event) => this.props.startTimer(event,this.state.value,"SMS")} disabled={!this.props.disable}><i className="material-icons">chat_bubble_outline</i></button>
                                            <button type="button" className="btn tooltipped" id="callbackBtn"  data-tooltip='Callback' data-position='top' name="CALLBACK" onClick={(event) => this.props.startTimer(event,this.state.value,"CALLBACK")} disabled={!this.props.disable}><i className="material-icons">ring_volume</i></button>
                                    </div> */}
                                </div>

                                <div >
                                <div className="time pull-right ksPurpleText" >
                                <h2> {this.props.hideTimer?this.props.format(this.props.time):''}</h2>
                                </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="input-content">
                        <div className="col-sm-12">
                            <div className="input-field">
                                <span className="input-icon"><span className="fa fa-lock"></span></span>
                                <CustomInput intid="otpPassword" intType="password"  className="validate" intName="temporary_password"  change={this.props.change} intStep={Constant.STEP_TWO} />
                                <Label lblhtmlFor="password" lblname= "Please enter temporary password"/>
                            </div>
                        </div>
                        </div>
                        <div className="clearfix">
                            <div className="btn-wrapper center-block text-center">
                                <a href={Constant.FRONTEND_CONTEXT_PATH} className="btn btn-white cancel" role="button">Cancel</a>
                                <Button btnName="Next" className="btn btn-white" click={this.props.clickHandler}  secondParams="step2"/>
                            </div>
                        </div>
                    </div>
                 </div>

                <div className="thirdSectionDiv">
                    {/* <div className="back" id="back2"> <span><i className="fa fa-arrow-circle-left" aria-hidden="true"  onClick={this.props.clickHandler}></i></span></div> */}
                    <div className="col-sm-12">
                        <div className="loginTitleDiv"><h1>Forgot Password - Step 3/4</h1></div>
                    </div>
                    <div className="forgot-box">
                        <div className="input-content">
                            <div className="col-sm-12">
                        {this.disPlayQuestion()}
                        </div>
                    </div>

                        <div className="clearfix">
                            <div className="btn-wrapper">

                                <a href={Constant.FRONTEND_CONTEXT_PATH} className="btn btn-white cancel" role="button">Cancel</a>
                                <Button btnName="Next" className="btn btn-white" click={this.props.clickHandler} secondParams="step3"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fourthSectionDiv">
                    {/* <div className="back" id="back2"> <span><i className="fa fa-arrow-circle-left" aria-hidden="true"  onClick={this.props.clickHandler}></i></span></div> */}
                    <div className="col-sm-12">
                        <div className="loginTitleDiv"><h1>Forgot Password - Step 4/4</h1></div>
                    </div>
                    <div className="forgot-box">
                    <div className="input-content">
                    <div className="col-sm-12 pr0 pl0">
                        <div className="input-field">
                        <span className="input-icon"><span className="fa fa-lock"></span></span>
                            <CustomInput intid="newPassword" intName="Password" intType="password"   maxlength="16" change={this.props.change} intStep={Constant.STEP_FOUR} keyPress={this.props.clickHandler} keyEvent='Enter'/>
                            <Label lblhtmlFor="password"  lblname={"New Password"+Constant.STAR}/>
                            <div className="vertical-bottom-info">
                            <OverlayTrigger placement="right" overlay={<Tooltip >{Constant.INVALID_PASSWORD_FORMATE}</Tooltip>}><i className="small material-icons whitetext ">info</i></OverlayTrigger>

                        </div>
                        </div>

                    </div>

                    <div className="col-sm-12 pr0 pl0">
                        <div className="input-field">
                        <span className="input-icon"><span className="fa fa-lock"></span></span>
                            <CustomInput intid="reNewPassword"  intName="rePassword" intType="password"
                              maxlength="16" change={this.props.change} intStep={Constant.STEP_FOUR} keyPress={this.props.clickHandler} keyEvent='Enter'/>
                            <Label lblhtmlFor="password"  lblname={Constant.LABEL_CONFIRM_PASSWORD+Constant.STAR}/>
                        </div>
                    </div>
                        {/* <div className="col-sm-2 pr0">
                        <div className="vertical-bottom">
                            <a data-tip={'Password must be combination of upper and lower case letters. <br/> It must contain at least one special character or number.  <br/> It must be of nine to sixteen characters long.'}>
                                <i className="small material-icons">info</i>
                            </a>
                            <ReactTooltip place="right" html={true}/>
                        </div>
                    </div> */}
                    </div>
                        <div className="clearfix">
                            <div className="btn-wrapper">
                                <Button btnName="Finish" className="btn btn-white" click={this.props.clickHandler} secondParams="step4"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

       );
    }
}