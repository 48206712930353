import React from 'react';
import * as Constant from '../../../Component/CustomUI/Constant';
import CustomInput from '../../CustomUI/Input';
import Label from '../../CustomUI/Label';

const logininputs = (props) => {

    return (
        <div>

        <div className="input-part">
        <form method="post" action={Constant.SIGNIN_CONTEXT_PATH} id="loginForm" onSubmit={props.loginSubmit}>
        <div className="inputs clearfix">
            <div className="firstSectionDiv">
                <div className="col-sm-12 hidden">
                    <div className="loginTitleDiv">Sign In</div>
                </div>
                <div className="col-sm-12">
                    <div className="input-field">
                    <span className="input-icon"><span className="fa fa-user"></span></span>
                        <CustomInput intid="username" intType="text" intName="username" onChange={props.changeLoginDetail} defaultValue={props.username} intClasssName={props.isUserNameEmpty? 'validate invalid': 'validate'}/>
                        <Label lblhtmlFor="username" lblname={"Username"} lblclassName={props.isUserNameEmpty || props.username? 'active': null} lbldataError={props.isUserNameEmpty? Constant.USER_NAME:null}/>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="input-field">
                    <span className="input-icon"><span className="fa fa-lock"></span></span>
                        <CustomInput intid="password" intType="password" intName="password" intClasssName={props.isPasswordEmpty? 'validate invalid': 'validate'}/>
                        <Label lblhtmlFor="password" lblname={"Password"} lblclassName={props.isPasswordEmpty || props.password? 'active': null} lbldataError={props.isPasswordEmpty? Constant.PASSWORD:null}/>
                    </div>
                </div>
                <div className="col-sm-12">
                <div className="center-block text-center">
                <p className="forgetLink text-center white-text">
                    <a  href={Constant.FRONTEND_CONTEXT_PATH+"forgotPassword"} className="white-text" >
                        Forgot Password ?
                    </a>
                    </p>
                    </div>
                </div>

                <div className="clearfix text-center">
                    <div className="col-xs-12">
                    <div className="center-block text-center">
                        {/* <a href="#" className="btn login btn-lg">
                            Login
                        </a> */}
                        <button className="btn btn-sm btn-white " type="submit" >Login</button>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="center-block text-center border-bottom-login ">
                    <p className="forgetLink text-center white-text"><a  href={Constant.FRONTEND_CONTEXT_PATH+"register"} className="white-text">Register</a>
                        </p>
                    </div>
                </div>

                <div className="clearfix">
                </div>
            </div>
        </div>
        </form>
        </div>
        </div>
    );
}

export default logininputs;