import React from 'react';
import  * as Constant  from '../../CustomUI/Constant';
import UserProfile from '../../../UserProfile';
let href =''
const logintop = () => {
	if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
		href = '#'
	}else{
        href=Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH
	}
    return (
        <div>
            <section className="loginTopSection">
				<div className="container">
					<div className="col-sm-12">
						<div className="loginLogo center-block text-left ">
							<a href={href}>
								<img alt="ProntoRx" src="images/logo-white.png" className="img-responsive center-block"/>
							</a>
						</div>
					</div>
				</div>
			</section>
        </div>
    );
};

export default logintop;