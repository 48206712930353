import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import * as Constant from '../../Component/CustomUI/Constant';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

class UserTable extends Component {

    constructor(props) {
        super(props);

        this.options = {
          onPageChange: this.props.onPageChange,
          onSortChange: this.props.onSortChange,
          sizePerPage: this.props.sizePerPage,
          sizePerPageList: [ 100,50,25 ],
          page:this.props.page,
          paginationPosition: 'bottom',
          prePage: 'Prev', // Previous page button text
          nextPage: 'Next', // Next page button text
          firstPage: 'First', // First page button text
          lastPage: 'Last'
        };

        this.state = {
            data: [],
            isPaginationAllow:false
        }
      }
      test(){};

    tooltipFormatter(cell, row){
        return (<Tooltip placement="top" trigger={['hover']} overlay={<span>{cell}</span>}><span>{cell}</span></Tooltip>);
    };

    setStatus = (cell,row) => {
      let completeClass = 'badge complete status_'+row.id
      let incompleteClass = 'badge incomplete status_'+row.id
      if(row.status === 'ACTIVE'){
        return (
          <div>
              <Tooltip placement="top" trigger={['hover']} overlay={<span>Active</span>}>
                    <span className={completeClass}>Active</span>
             </Tooltip>
          </div>
          );
      } else {
        return (
            <div>
                <Tooltip placement="top" trigger={['hover']} overlay={<span>Inactive</span>}>
                    <span className={incompleteClass}>Inactive</span>
                </Tooltip>
            </div>
            );
        }
    }

    setAuthorization = (cell,row) => {
        let completeClass = 'badge complete approvalStatus_'+row.id
        let incompleteClass = 'badge incomplete approvalStatus_'+row.id
        let pendingClass = 'badge pending approvalStatus_'+row.id
        if(row.approvalStatus === 'APPROVED'){
        return (
            <div>
                <Tooltip placement="top" trigger={['hover']} overlay={<span>Approv</span>}>
                    <span data-tip="Approved" className={completeClass}> Approved</span>
                </Tooltip>
            </div>
                );
             } 
            if(row.approvalStatus === 'REJECTED'){
                return (
                    <div>
                        <Tooltip placement="top" trigger={['hover']} overlay={<span>Reject</span>}>
                            <span data-tip="Rejected" className={incompleteClass}>Rejected</span> 
                        </Tooltip>

                    </div>
                );
            } 
            if(row.approvalStatus === 'PENDING'){
                return (
                    <div>
                        <Tooltip placement="top" trigger={['hover']} overlay={<span>Pending</span>}>
                            <span data-tip="Pending Authorization" className={pendingClass}>Pending Authorization</span>
                        </Tooltip>
                    </div>
                );
            }
    }

    actionIcons = (cell,row) => {
        let checkedApproved = false
        let checkedRejected = false
        let checkedPending = false
       if(row.approvalStatus === "APPROVED"){
            checkedApproved = true
       }
       if(row.approvalStatus==="REJECTED"){
            checkedRejected = true
       }
       if(row.checkedPending === "PENDING"){
           checkedPending = true
       }

        return(
            <div>
                <div className='actions-list-div'>
                    <Tooltip placement="top" trigger={['hover']} overlay={<span>Edit</span>}>
                        <a href={row.role===Constant.PRESCRIBER?Constant.FRONTEND_CONTEXT_PATH+'myProfilePrescriber?username='+row.id:Constant.FRONTEND_CONTEXT_PATH+'myProfileAgent?username='+row.id}  target="_blank" className='tooltipped' data-tip='Edit' data-position='top'>
                            <i className="fa fa-edit"></i>
                        </a>
                    </Tooltip>
                    <Tooltip id={"userStatusCheckbox"+row.id} placement="top" trigger={['hover']} overlay={<span>{row.status==="ACTIVE" ? 'Make Inactive' : 'Make Active'}</span>}>
                        <a href='#' className='tooltipped' name="userStatusCheckbox" data-tip={row.status==="ACTIVE" ? 'Make Inactive' : 'Make Active'} data-position='top' onClick={(event)=>this.props.toggleStatus(event,row,"Status")}>
                            <i className={row.status==="ACTIVE" ? ('userStatusCheckboxIcon'+row.id+' fa fa-ban') : ('userStatusCheckboxIcon'+row.id+' fa fa-check-circle')}></i>
                        </a>
                    </Tooltip>
                    
                    <Tooltip placement="top" trigger={['hover']} overlay={<span>History</span>}>
                            <a href='#' className='tooltipped'  data-tip='History' data-position='top' onClick={(event)=>this.props.history(event,row)}>
                                <i className="fa fa-history"></i>
                            </a>
                    </Tooltip>
                  

                    {/*<span className="switch">
                        <label className='tooltipped' data-tip='Approve/Reject' data-position='top'>
                            <input id={"userAuthCheckbox"+row.id} name="userAuthCheckbox" checked ={row.status==="ACTIVE" ? true : false} type="checkbox" onClick={(event)=>this.props.toggleAuth(event,row,"Auth")} readOnly/>
                            <span className="lever"></span>
                        </label>
                    </span>*/}
                    {row.role===Constant.PRESCRIBER?<div className="radio-wrapper">
                                    <input type="radio" value="Pending" name={"userAuthCheckbox"+row.id} className="neutral clearfix" id={"userAuthCheckboxNA"+row.id} checked = {checkedPending} disabled = {checkedPending} onChange={this.test} />
                                    <label htmlFor={"userAuthCheckboxNA"+row.id} data-tip="Pending"></label>

                                     <input type="radio" value="Approve" name={"userAuthCheckbox"+row.id} className="no" id={"userAuthCheckboxYes"+row.id} checked = {checkedApproved} onClick={(event)=>this.props.toggleAuth(event,row,"Auth")} disabled = {checkedApproved} onChange={this.test} />
                                     <label htmlFor={"userAuthCheckboxYes"+row.id} data-tip="Approve"><Tooltip placement="top" trigger={['hover']} overlay={<span>Approve</span>}><i className="fa fa-check" aria-hidden="true"></i></Tooltip></label>

                                    <input type="radio" value="Reject" name={"userAuthCheckbox"+row.id} className="yes" id={"userAuthCheckboxNo"+row.id} checked = {checkedRejected} onClick={(event)=>this.props.toggleAuth(event,row,"Auth")}  disabled = {checkedRejected} onChange={this.test} />
                                    <label htmlFor={"userAuthCheckboxNo"+row.id} data-tip="Reject"><Tooltip placement="top" trigger={['hover']} overlay={<span>Reject</span>}><i className="fa fa-times" aria-hidden="true"></i></Tooltip></label>

                    </div>:""}
                </div>

            </div>
        )
    }


  render() {



    this.options = {
        onPageChange: this.props.onPageChange,
        onSortChange: this.props.onSortChange,
        sizePerPage: this.props.sizePerPage,
        sizePerPageList: [ 100,50,25 ],
        paginationPosition: 'bottom',
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last',
        page: this.props.page


    };

    var isSort = parseInt(this.props.dataCount) > 1 ? true : false;


    // $(".react-bs-table-sizePerPage-dropdown-customTotal-User").remove();
    // $(".react-bs-table-sizePerPage-dropdown").append("<span class='react-bs-table-sizePerPage-dropdown-customTotal-User'> of "+parseInt(this.props.dataCount)+"</span>");

    return (

      <div>

       <div className="row">
            {/* <div className="col-md-12"> */}
                <h4 className="count-number"><strong>Total:</strong> {parseInt(this.props.dataCount)}</h4>
            {/* </div> */}
        </div>
        <div className="responsive-table max-width-1366">
        <BootstrapTable   data={this.props.data?this.props.data:""} striped bordered={ true } pagination={this.props.isPaginationAllow}  options={this.options} remote={true} fetchInfo={{ dataTotalSize: this.props.dataCount }} >
            <TableHeaderColumn width="100px" dataSort={false} dataField='npi' dataFormat={this.tooltipFormatter} ><span data-tip="NPI" >NPI</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='firstName' dataFormat={this.tooltipFormatter}><span data-tip="First Name">First<br/>Name</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='lastName' dataFormat={this.tooltipFormatter}><span data-tip="Last Name" >Last<br/>Name</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='email' dataFormat={this.tooltipFormatter}><span data-tip="Email Address">Email<br/>Address</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='userTitle' dataFormat={this.tooltipFormatter}><span data-tip="User Title">User<br/>Title</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} isKey dataField='username' dataFormat={this.tooltipFormatter}><span data-tip="User Name">User<br/>Name</span> </TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='role' dataFormat={this.tooltipFormatter}><span>User<br/>Role</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={isSort} dataField='approvalStatus' dataFormat={this.setAuthorization}><span>Authorization</span></TableHeaderColumn>
            <TableHeaderColumn width="8%" dataSort={isSort} dataField='status' dataFormat={this.setStatus}><span>Status</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={isSort} dataField='createdDate' dataFormat={this.tooltipFormatter}><span data-tip="Submitted Date" >Submitted<br/>Date</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={false} dataField='ipaddress' dataFormat={this.tooltipFormatter}><span data-tip="IP Address" >IP Address</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataFormat={this.actionIcons}><span>Action</span></TableHeaderColumn>
        </BootstrapTable>
        </div>
      </div>
    );
  }
}

class UserHistoryTable extends Component {
    tooltipFormatter(cell, row){
        return (<Tooltip placement="top" trigger={['hover']} overlay={<span>{cell}</span>}><span>{cell}</span></Tooltip>);
    };

    render() {
        return (
          <div>
          <div className="table-height">
            <BootstrapTable data={this.props.historyData} striped bordered={ true } className="break-word">
                <TableHeaderColumn dataSort={parseInt(this.props.historyData.length) > 1 ? true : false} width={'45%'} isKey dataField='user' dataFormat={this.tooltipFormatter} >User</TableHeaderColumn>
                <TableHeaderColumn dataSort={parseInt(this.props.historyData.length) > 1 ? true : false} width={'60%'} dataField='description' dataFormat={this.tooltipFormatter}>Description</TableHeaderColumn>
                <TableHeaderColumn dataSort={parseInt(this.props.historyData.length) > 1 ? true : false} width={'40%'} dataField='date'>Date Updated</TableHeaderColumn>
            </BootstrapTable>
          </div>
          </div>
        );
      }
}

export {UserTable,UserHistoryTable};