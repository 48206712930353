import React, { Component } from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import AgentProfile from '../../Profile/MyProfile/AgentProfile';
import PendingUserTable from './PendingUserApproval';
import LoginInfo from './LoginInfo';


export default class User extends Component {
    render(){
        return(
            <div className="v2-back-inner">
                 <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={"User Profile"} />
                    <main>
                        <div className="inner-page-container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="tabsContainer">
                                        <ul className="tabs">
                                            <li className="tab col s2"><a className="active" href="#profile">Profile</a></li>
                                            <li className="tab col s2"><a href="#loginInfo">Login</a></li>
                                            <li className="tab col s2"><a href="#pendingregister">Pending Registration</a></li>
                                        </ul>
                                    </div>
                                    <div className="boxWithShadow boxWithOutSpace pt20 pb30">
                                        <div className="row" id="profile">
                                            <div className="col-sm-12">
                                                <AgentProfile />
                                                <section className="buttonWrapper">
                                                    <button className="btn">Search</button>
                                                    <button className="btn btn-white">Reset</button>
                                                    <button className="btn">Cancel</button>
                                                </section>                                                                                    
                                            </div>
                                        </div>
                                        <div className="row" id="loginInfo">
                                            <div className="col-sm-12">
                                                <LoginInfo /> 
                                                <div className="clearfix"></div>                                            
                                                <section className="buttonWrapper">
                                                    <button className="btn">Search</button>
                                                    <button className="btn btn-white">Reset</button>
                                                    <button className="btn">Cancel</button>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="row" id="pendingregister">
                                            <div className="col-sm-12">
                                                <PendingUserTable />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />    
                 </div>                
            </div>
        )
    }
}