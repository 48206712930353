import React, { Component } from 'react';
import {Input} from 'react-materialize';
import { Prescriber as Prescriber } from '../StepThree/Prescriber';
import Agent from '../StepThree/Agent';
import axios from 'axios';
import Button from '../../CustomUI/Button';
import * as Constant from '../../CustomUI/Constant';
import UserProfile from '../../../UserProfile';
import AgentFour from '../StepFour/AgentKsUser'
import PrescriberFour from '../StepFour/PrescriberKsUser';

let isKsUser=false
class stepTwoKsUser extends Component {

    state = {
        isHiddenMd:false,
        isHiddenAgent:false,
        roleList : [],
        roleTitleList : []
    }

    componentDidMount(){
        axios.get('/registration/renderRole')
        .then(response => {
            // console.log(response.data)
            this.setState({roleList : response.data})
        })
        .catch(function (error) {
            console.log(error);
        });
    }


     prescriberData() {
        if(this.props.isHiddenMd){
            const roleTitleListPrescriberData = this.props.roleTitleList.map( roleTitle =>{
                return <option  value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
            } );

            //  console.log(roleTitleListPrescriberData[0]?roleTitleListPrescriberData[0].props.value:'')
            
           
            
            return (
                <div>

                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5">
                        <Input s={12} m={12} xl={12} l={12} type='select' id="whoIsA" className={this.props.stepTwoValidate.whoIsAValid? 'validate invalid': 'validate'} name="whoIsA"  label="Who is a *" onChange={(event) => this.props.change(event,Constant.STEP_TWO_PRESCRIBER)} >
                                        <option value="">--Select--</option>
                                        {roleTitleListPrescriberData}
                        </Input>
                        </div>
                     <Prescriber
                       
                        addDocumentClicked ={this.props.addDocumentClicked}
                        data={this.props.data}
                        changeFile={this.change}
                        Change={this.props.change}
                        stepTwoValidate={this.props.stepTwoValidate}
                        getPrescriberApiData={this.props.getPrescriberApiData}
                        document={this.props.document}
                        removeDocument={this.props.removeDocument}
                        isLoading={this.props.isLoading}
                     />
                    <br/>
                    <br/>
                     <div >
                    
                     {this.props.stepTwoPrescriberData === "2" && this.props.isShowButton?
                     <div>
                      <div className="tabSectionTitle clearfix mt20">
                        <div className="col-xs-12 pl0">
                            <h3 className="pull-left">About You</h3>
                            <div className="alert alert-info right">
                            <h6 className="pull-right"><strong> <i className="small material-icons">info</i> Your NPI and contact information is provided by NPPES registry by CMS. To update your information, visit [<a href="https://npiregistry.cms.hhs.gov/" target="_blank">NPPES NPI Registry</a>]</strong></h6> 
      	                    </div>
                            <span className="inline pt7 pl15">
                            </span>
                            <div className="clearfix" />
                        </div>
                     </div>
                     <PrescriberFour change={this.props.change}
                        checkDuplicateEmail={this.props.checkDuplicateEmail}
                        checkDuplicateUserName={this.props.checkDuplicateUserName}
                        StepFourPrescriberData={this.props.StepFourPrescriberData}
                        stepFourPrescriberValidate={this.props.stepFourPrescriberValidate}
                        faxNumber={this.props.faxNumber	}
                        workPhone={this.props.workPhone}
                        isDisabled={this.props.isDisabled}
                        prescriberApiData={this.props.prescriberApiData}
                        changeFacilityListner={this.props.changeFacilityListner}
                        isLoading={this.props.isLoading}
                        click={this.props.click}
					
				  /></div>:" "}
                    </div>
                    
            </div>
            );
        }


      }

      agentData() {

        if(this.props.isHiddenAgent){

            const roleTitleListAgentData = this.props.roleTitleList.map(roleTitle =>{
                return <option value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
             });

            return (
                <div>
                <div className="zero-padding-xs max-height-drop">
                 <div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
                    <Input m={12} type='select' id="whoIsA"  name="I_Work_At_A" label="I work at a *" className={this.props.stepTwoValidate.whoIsAValid? 'validate invalid': 'validate'} onChange={(event) => this.props.change(event,Constant.STEP_TWO_AGENT)} >
                            <option value="" >--Select--</option>
                            {roleTitleListAgentData}
                    </Input>
                </div>
                <div className = "row clearfix">
                <div className="tabSectionTitle clearfix mt20">
                    <div className="col-xs-12 pl0">
                        <h3 className="pull-left">About You</h3>
                        <span className="inline pt7 pl15">
                        </span>
                        <div className="clearfix" />
                    </div>
                </div>
                    { this.props.stepTwoAgentData === "1"?
                 <AgentFour change={this.props.change}
                        checkDuplicateEmail={this.props.checkDuplicateEmail}
                        checkDuplicateUserName={this.props.checkDuplicateUserName}
                        StepFourAgentData={this.props.StepFourAgentData}
                        selectedPrescriberJson={this.props.selectedPrescriberJson}
                        stepFourAgentValidate = {this.props.stepFourAgentValidate}
                        click={this.props.click}
                        isLoading={this.props.isLoading} />:null}
                    
                </div>
                <Agent
                           isUpdatedData={this.props.isUpdatedData}
                           Change={this.props.change}
                           associatedDataListner={this.props.associatedDataListner}
                           changePrescriberListner={this.props.changePrescriberListner}
                           agentData={this.props.agentData}
                           selectedPrescriberJson={this.props.selectedPrescriberJson}
                           prescriberJson={this.props.prescriberJson}
                           stepTwoValidate={this.props.stepTwoValidate}

                    />
                </div>
                </div>


            );
      }
    }

    render(){

    

        let href = ''
        const roleListData = this.state.roleList.map(role =>{
               return <option data-id={role.value} key={role.id} value={role.id} >{role.name}</option>
        })

        if(UserProfile.getUrlName() ===  Constant.FRONTEND_SERVER_URL + Constant.FRONTEND_CONTEXT_PATH){
             UserProfile.setRole("")
        }

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            href = UserProfile.getUrlName()
            isKsUser=true
        }else{
            href = Constant.FRONTEND_CONTEXT_PATH
        }



     return (
            <div>

                <h1>{isKsUser?Constant.LABEL_KSROLE:Constant.LABEL_ROLE}</h1>
                <div>
                <div className="bodyContent">
                    <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5 ">
                                <Input s={12} m={12} xl={12} l={12}   type='select' id="ima" label={isKsUser?"I want to register a *":"I am a *"} name="ima" className={this.props.stepTwoValidate.imaValid? 'validate invalid': 'validate'} onChange={this.props.change}  >
                                         <option value="" >--Select--</option>
                                         {roleListData}
                                </Input>
                                </div>

                        {this.prescriberData()}
                        {this.agentData() }
                        </div>

                </div>
                <div className="wizard-footer">
                    <div className="btn-toolbar pull-right">
                    <a href={href}>
						<Button id="Step2Cancel" btnName="Cancel" className="btn btn-primary btn-white wizard-next scroll-top"  secondParams={Constant.STEP_TWO} />
					</a>
                    {this.props.isShowButton || this.props.isHiddenAgent? <Button id="Step2Next" btnName={Constant.REGISTER} className="btn btn-primary wizard-next scroll-top" click={this.props.click} secondParams={Constant.STEP_TWO} />:""}
                   
                   
                    </div>
                </div>
            </div>
            </div>
        );
    }

}

export default stepTwoKsUser;