import React from 'react';


export default (props) => {
    return (
        <label  htmlFor={props.lblhtmlFor} className={props.lblclassName} data-error={props.lbldataError} >
        {props.lblname}
        </label>    
    );
}

 