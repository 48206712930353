import React, { Component } from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import OfficeAdminPhysiciansAgents from './OfficeAdminPhysiciansAgents';
import axios from 'axios';
import UserProfile from '../../../UserProfile';
import Button from '../../../Component/CustomUI/Button';
import CustomInput from '../../../Component/CustomUI/Input';
import CustomInput1 from '../../../Component/CustomUI/CustomInput';
import Label from '../../../Component/CustomUI/Label';
import * as Constant from '../../../Component/CustomUI/Constant';
 import {Input} from 'react-materialize';
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import moment from 'moment';
import Select from 'react-select';


let userPrescriberList = []
let  prescriberData = []
let checkboxCountGloble=0

export default class OfficeAdminRepresentative extends Component {

    state = {
        userPrescribers : [],
        user : [],
        userPrivileges : [],
        selectedRow : {},
        prescriberId : '',
        associationType :[],
        fromEdit :false,

        prefixValid : true,
        firstNameValid : true,
        lastNameValid : true,
        emailValid:true,
        cemailValid:true,
        isEmailAvailable:false,
        usernameValid:true,
        prescriberNameValid:true,
        roleTitleValid:true,
        userPrivilegesMaster : '',
        status : '',
        date: moment().add(1, 'y'),
        roleTitleList : [],
        selectedOptionSearch: null,
        selectedOptionEdit:null,
        selectedOptionAdd:null,
        isSaveLoading : false,
         isUserNameAvailable:true,
         isLoading:true,
         isSuccess:false,
         checkboxCount:0,
         flag:true


    }


    checkDuplicateEmail = (event) => {
       let value=event.target.value
    
        if(value !=='' || !value.match(Constant.REGEX_SPACE)) {
           value=value.toLowerCase()
        axios.get('/registration/verifyEmail?email='+value)
        .then(response => {
        this.isEmailDuplicate=response.data
             if(this.isEmailDuplicate){
                  this.setState({isEmailAvailable:this.isEmailDuplicate})
                window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
            } else {
             //  window.Materialize.toast(Constant.EMAIL_DUPLICATE, 1000,  'red rounded')
              this.setState({isEmailAvailable:this.isEmailDuplicate})

             }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
        //   console.log("this.state.isEmailAvailable**********"+this.state.isEmailAvailable)

    }

    getMyPhysicianMyAgentData = () => {

        axios.get('/officeAdminProfile/getMyPhysicianMyAgentData?username='+encodeURIComponent(UserProfile.getUserName()))
           .then(response => {

                  if(response.data.prescriberUser.length == 0){
                      window.location.reload();
                  }

                    prescriberData = []
                    if(response.data.prescriberUser && response.data.prescriberUser.length !== 0){
                        let dataLength =  response.data.prescriberUser.length;
                    for(let i =0;i< dataLength;i++){
                            prescriberData.push(response.data.prescriberUser[i].prescriber);
                        }
                    }

                    this.setState({associationType : JSON.parse(response.data.associationType)})
                    this.setState({userPrivileges:response.data.userPrivileges})
                    this.setState({userPrescribers:response.data.prescriberUser})
                    this.setState({roleTitleList : response.data.roleTitleList})
                    this.setState({isLoading:false})

           })
           .catch(function (error) {

           });
    }

     handleChange = (selectedOptionSearch) => {
      this.setState({selectedOptionSearch})

      }

    handleChangeEdit = (selectedOptionEdit) => {
     this.setState({selectedOptionEdit})

    }

    handleChangeAdd = (selectedOptionAdd) => {
       this.setState({selectedOptionAdd})

    }

    editMyAgentListener = (event,row) =>{

        let userPrivileges = []
        for(let i=0;i<row.userPrivileges.length;i++){
            let privilegesJson = {"value":row.userPrivileges[i].id,"label":row.userPrivileges[i].value}
            userPrivileges.push(privilegesJson);
        }

        this.setState({selectedRow:row,fromEdit:true,selectedOptionEdit:userPrivileges})

    }

    addMyAgentListener = () =>{



         $("#myAgentPrefix").val("");
         $("#myAgentFname").val("");
         $("#myAgentMname").val("");
         $("#myAgentLname").val("");
         $("#myAgentEmail").val("");
         $("#myAgentCEmail").val("");
         $("#myAgentDactive").val("");
         $("#myAgentUname").val("");
         $("#whoIsA").val("");


        this.setState({selectedRow:'',fromEdit:false,selectedOptionAdd:null,
                prefixValid : true,
                firstNameValid : true,
                lastNameValid : true,
                emailValid:true,
                cemailValid:true,
                usernameValid:true,
                roleTitleValid:true
            })

    }

    saveMyAgentListener = (event) =>{

        let isAgentValid = true
        let isBlankValid = false

        let userPrivilegesIds = []

        let myAgentPrefix = $("#myAgentPrefix").val();
        let myAgentFname = $("#myAgentFname").val();
        let myAgentMname = $("#myAgentMname").val();
        let myAgentLname = $("#myAgentLname").val();
        let myAgentEmail = $("#myAgentEmail").val();
        let myAgentCEmail = $("#myAgentCEmail").val();
        let agentPrescriberId = $("#agentPrescriberId").val();
        let myAgentUname = $("#myAgentUname").val();
        let roleTitleIds = $("#whoIsA").val();

        let myAgentUserId = $("#myAgentUserId").val();
        let myAgentUserPrescribersId = $("#agentPrescriberId").val();

        let deactivateDate = $("#myAgentEditDeactiveDate").val();


        if(!this.state.fromEdit){

              if(this.state.selectedOptionAdd !== null){
                for(let i=0;i<this.state.selectedOptionAdd.length;i++){
                    userPrivilegesIds.push(this.state.selectedOptionAdd[i].value)
                }
             }

            deactivateDate = $("#myAgentAddDeactiveDate").val();
            if(this.state.isEmailAvailable) {
                isAgentValid = false;
                window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
            }
            if(!myAgentUname){
                this.setState({usernameValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }else if(myAgentUname.match(Constant.USER_ALPHABET_SPACE)==null){
                this.setState({usernameValid : false});
                isAgentValid = false;
                // isBlankValid = true;
                window.Materialize.toast(Constant.USER_NAME_VALID, 3000, 'red rounded')
            }

            if(!myAgentUserPrescribersId){

                this.setState({prescriberNameValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }
             if(!roleTitleIds){
                this.setState({roleTitleValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }

            // if(!myAgentPrefix){
            //     this.setState({prefixValid : false});
            //     isAgentValid = false;
            //     isBlankValid = true;
            // }

            if(!myAgentFname){
                this.setState({firstNameValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }
            if(!myAgentLname){
                this.setState({lastNameValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }
             let isValidEmail = true
            if(!myAgentEmail){
                this.setState({emailValid : false});
                isAgentValid = false;
                isBlankValid = true;

            }else if(myAgentEmail.match(Constant.REGEX_EMAIL) === null){
                isAgentValid = false;
                isValidEmail = false;
            }

            if(!myAgentCEmail){
                this.setState({cemailValid : false});
                isAgentValid = false;
                isBlankValid = true;
            }else if(myAgentCEmail.match(Constant.REGEX_EMAIL) === null){
                isAgentValid = false;
                isValidEmail = false;
            }
            if( isBlankValid){
                  window.Materialize.toast(Constant.MANDATORY_MESSAGE, 5000,  'red rounded')
            }
            if(!isValidEmail){
                window.Materialize.toast(Constant.EMAIL_INVALID, 5000,  'red rounded')
            }

            if(myAgentEmail && myAgentCEmail){
                let isEmailSame  = false
                isEmailSame = (myAgentEmail.trim()  === myAgentCEmail.trim())

                if(!isEmailSame){
                    isAgentValid = false
                    window.Materialize.toast("Email and Confirm Email must be same", 5000,  'red rounded')
                }
            }

        }else{
            for(let i =0;i<this.state.selectedOptionEdit.length;i++){
                userPrivilegesIds.push(this.state.selectedOptionEdit[i].value)
            }
        }

        // else if(deactivateDate === null || deactivateDate === undefined || deactivateDate === 'undefined' || deactivateDate === ''){
        //     isAgentValid = false
        //     window.Materialize.toast("Please Select Disassociate Agent on", 5000,  'red rounded')
        // }


        if(isAgentValid){
            let isOfficeAdmin = "isOfficeAdmin"
            this.setState({ isSaveLoading : true })

            let queryString = ''
            queryString += '?prefix='+myAgentPrefix
            queryString += '&middleName='+myAgentMname
            queryString += '&userId='+myAgentUserId
            queryString += '&firstName='+myAgentFname
            queryString += '&lastName='+myAgentLname
            queryString += '&email='+myAgentEmail
            queryString += '&deactivateDate='+deactivateDate
            queryString += '&userPrivilegesIds='+userPrivilegesIds
            queryString += '&fromEdit='+this.state.fromEdit
            queryString += '&prescriberId='+agentPrescriberId
            queryString += '&roleTitleId='+roleTitleIds
            queryString += '&username='+myAgentUname
            queryString += '&isOfficeAdmin='+isOfficeAdmin
            queryString += '&createdOrUpdatedBy='+UserProfile.getUserId() ? UserProfile.getUserId() : '';


            if(this.state.fromEdit){
                this.saveMyRepresentiveData(queryString)
            }else{
                this.checkDuplicateUserName(queryString);
            }

        }

    }

    checkDuplicateUserName(queryString) {

        let valueOfUsername=$("#myAgentUname").val()
        let KSEmail=false
        let val=valueOfUsername.toLowerCase();
        if(val.includes("@")){
            if(val.split("@")[1]==="kloudscript.net" || val.split("@")[1]==="usewms.com" || val.split("@")[1]==="usekspa.com"){
                KSEmail=true
            }
          }
          if(!KSEmail){
                    axios.get('/registration/userName?userName=' +valueOfUsername)
                    .then(response => {
                        // console.log("response.data",response.data);
                        if(response.data){
                            this.saveMyRepresentiveData(queryString);
                        }else{
                            window.Materialize.toast(Constant.USER_NAME_DUPLICATE, 3000, 'red rounded')
                            this.setState({ isSaveLoading : false })
                        }
            
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

          }else{
            this.setState({ isSaveLoading : false })
            window.Materialize.toast("User Name Should Not Contain @kloudscript.net or @usewms.com or @usekspa.com", 3000,  'red rounded')
          }
        
    }

    saveMyRepresentiveData = (queryString) =>{

        axios.post('/prescriberProfile/savePrescriberMyAgentData'+queryString)
        .then(response => {
            // console.log(response.data);
            this.getMyPhysicianMyAgentData();

            this.setState({ isSaveLoading : false })
            this.setState({isSuccess:true})

            if(!this.state.fromEdit){
                $("#myPhysicianMyAgentAddModelCancel").click();
            }else{
                $("#myPhysicianMyAgentEditModelCancel").click();
            }

        })
        .catch(function (error) {

        });

    }
   componentDidMount(){
      this.getMyPhysicianMyAgentData();
   }

    myAgentEditModel(){
          let buttonName = this.state.fromEdit?'Update':'Save'
        return(
             <div id="editMyAgentModal" className="modal fade" role="dialog" >
                <div className="modal-dialog max1280" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"> {this.state.fromEdit ? 'Edit':'Add'} My Agent</h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    {this.myAgentDetails()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <section className="buttonWrapper">
                                    <Button id="submitMyAgentAdd" click={this.saveMyAgentListener.bind(this)} secondParams={null} btnName={buttonName} className="btn btn-primary btn-darkpurpale wizard-next"  />
                                    <button type="button" id="myPhysicianMyAgentEditModelCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    myAgentAddModel(){
        let buttonName = this.state.fromEdit?'Update':'Save'
            return(
                <div id="addMyAgentModal" className="modal fade" role="dialog" >
                    <div className="modal-dialog max1280" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"> {this.state.fromEdit ? 'Edit':'Add'} My Agent</h4>
                            </div>
                            <div className="modal-body">
                                <div className="agengtDetailsAdd inner-page-container">
                                    <div className="col-sm-12">
                                        {this.myAgentDetails()}
                                    </div>
                                </div>
                            </div>
                           {this.state.isSaveLoading?<div className="overlay"><div className="loader v-center"></div></div>:
                             <div className="modal-footer">
                             <section className="buttonWrapper">
                                    <Button id="submitMyAgentAdd" click={this.saveMyAgentListener.bind(this)} secondParams={null} btnName={buttonName} className="btn-darkpurpale btn btn-primary wizard-next"  />
                                    <button type="button" id="myPhysicianMyAgentAddModelCancel" name = "myAgentModelAdd" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>

                                </section>
                            </div>
                          }
                        </div>
                    </div>
                </div>
            )
    }

    onChangeListner = () =>{

    }


    handleDateTimeSelect(date) {

        if(this.state.fromEdit){
            this.setState({selectedRow:{...this.state.selectedRow,endDate:date}})
        }else{
            this.setState({date:date})
        }
    }

    myAgentAddForm(){

        var yesterday = Datetime.moment().subtract( 0, 'day' );
        var valid = ( current ) =>{
            return current.isAfter( yesterday );
        };

        const roleTitleListAgentData = this.state.roleTitleList.map( roleTitle =>{
            return <option  value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
         } );


        let options = []

        this.state.userPrivileges.map(privileges =>{
          options.push({ value: privileges.id, label: privileges.value })
        })

         const {selectedOptionAdd} = this.state;

        return (

            <div className="agengtDetailsAdd">
                    <div className="row">
                    <input type="hidden" value={this.state.selectedRow.userId} id="myAgentUserId" name="userId"/>

                    <Input type='select' s={12} m={3} l={3} xl={3}  onChange={this.onChangeListner} id="agentPrescriberId" label={"Prescriber Name"+Constant.STAR} name={"Prescriber Name"} className={this.state.prescriberNameValid? 'validate': 'validate invalid'}>
                         <option value="" >--Select--</option>
                         {prescriberData ? prescriberData.map(prescriber =>{
                                    return <option value={prescriber.id} key={prescriber.id} >{prescriber.firstName +" "+prescriber.lastName}</option>
                                }) : <option value="" >--Select--</option>}
                    </Input>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                       <Label lblhtmlFor="myAgentUname" lblclassName = {this.state.usernameValid? null : 'active'}  lblname={Constant.LABEL_USER_NAME + Constant.STAR}/>
                       <CustomInput intid="myAgentUname"   intClasssName={this.state.usernameValid? 'noSpace specialCharRestriction alphanumeric validate': 'noSpace specialCharRestriction alphanumeric validate invalid'} intType="text"  required  intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" maxLength="30"/>

						</div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 pl0 pr0">
                         <Input s={12} m={12} xl={12} l={12} type='select'  onChange={this.onChangeListner} className={this.state.roleTitleValid? 'validate': 'validate invalid'} id="whoIsA"  name="whoIsA"  label="Who is a *"  >
                                        <option value="">--Select--</option>
                                        {roleTitleListAgentData}
						</Input>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 pl0 pr0">
                     <Input type='select' s={12} m={12} l={12} xl={12} onChange={(event) => this.changePrivileges(event)} className={this.state.prefixValid? 'validate': 'validate invalid'}  id="myAgentPrefix" label={Constant.LABEL_PREFIX} name={Constant.INPUT_PREFIX}>
                                <option value="" >--Select--</option>
                                <option value={Constant.PREFIX_VALUE_DR}>{Constant.PREFIX_NAME_DR}</option>
								<option value={Constant.PREFIX_VALUE_MISS}>{Constant.PREFIX_NAME_MISS}</option>
								<option value={Constant.PREFIX_VALUE_MR}>{Constant.PREFIX_NAME_MR}</option>
								<option value={Constant.PREFIX_VALUE_MRS}>{Constant.PREFIX_NAME_MRS}</option>
								<option value={Constant.PREFIX_VALUE_MS}>{Constant.PREFIX_NAME_MS}</option>
								<option value={Constant.PREFIX_VALUE_PROF}>{Constant.PREFIX_NAME_PROF}</option>
                    </Input>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                            <Label lblhtmlFor="fNameAboutAgent" lblclassName = {this.state.firstNameValid? null : 'active'}  lblname={Constant.LABEL_FIRST_NAME + Constant.STAR}/>
                            <CustomInput intid="myAgentFname" isDisabled ={this.state.fromEdit}  intType="text" intClasssName={this.state.firstNameValid? 'validate': 'validate invalid'} required  intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" maxlength="50"/>

						</div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <div className="input-field">
                            <Label lblhtmlFor="mNameAboutAgent" lblclassName = {this.state.selectedRow.mName? 'active' : null} lblname={Constant.LABEL_MIDDLE_NAME}/>
                            <CustomInput intid="myAgentMname" isDisabled ={this.state.fromEdit} intType="text"   intName={Constant.INPUT_MIDDLE_NAME}  maxlength="50"/>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <div className="input-field">
                            <Label lblhtmlFor="lNameAboutAgent" lblclassName={this.state.lastNameValid? null : 'active'}  lblname={Constant.LABEL_LAST_NAME + Constant.STAR}/>
                            <CustomInput intid="myAgentLname" intType="text" isDisabled ={this.state.fromEdit}  intClasssName={this.state.lastNameValid? 'validate': 'validate invalid'} required  intName={Constant.INPUT_LAST_NAME }  maxlength="50"/>

						</div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                         <div className="input-field">
                            <Label lblhtmlFor="emailAboutAgent" lblclassName={this.state.emailValid? null: 'active'}  lblname={Constant.LABEL_EMAIL + Constant.STAR}/>
                            <CustomInput intid="myAgentEmail" isDisabled ={this.state.fromEdit} intType="text"  blur={this.checkDuplicateEmail} intClasssName={this.state.emailValid? 'validate': 'validate invalid'} required  intName={Constant.INPUT_EMAIL } />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <div className="input-field">
                            <Label lblhtmlFor="confirmEmailAboutAgent" lblclassName={this.state.cemailValid?  null:'active'}  lblname={Constant.LABEL_CONFIRM_EMAIL + Constant.STAR}/>
                            <CustomInput intid="myAgentCEmail" isDisabled ={this.state.fromEdit} intType="text" intClasssName={this.state.cemailValid? 'validate': 'validate invalid'}  required  intName={Constant.INPUT_CONFIRM_EMAIL } />
                        </div>
                    </div>

                    {/*<div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                          <div className="input-field suffix">
                                    <CustomInput intid="myAgentDactive" intType="text" intName="deActiveData" intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter'/>
                                    <Label lblhtmlFor="icon_prefix" lblname={"DeActive Date"}/>
                                    <i className="icon-calendar simple-icons"></i>
                        </div>
                    </div>*/}


                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div className="input-field suffix">
                        <Label lblhtmlFor="icon_prefix" lblclassName={'active'} lblname= "Disassociate Agent on"/>
                        <Datetime
                            classsName="datepicker"
                            timeFormat={false}
                            keyEvent='Enter' closeOnSelect={false} strictParsing={true}
                            onChange={(_date) => this.handleDateTimeSelect(_date)}
                            isValidDate={ valid }
                            inputProps={{ id: 'myAgentAddDeactiveDate', name: 'Disassociate Agent on'}}
                            dateFormat='MM/DD/YYYY'
                            input = {true}
                              />
                            <i className="icon-calendar simple-icons" onClick={()=>$("#myAgentAddDeactiveDate").click()} ></i>
                        </div>

                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                           <div className="input-field suffix custom-prx-multiselect-dropdown">
                                <label className="active" >User Privileges </label>
                                <Select
                                    id = "addPrivileges"
                                    className="input-field"
                                    isMulti = {true}
                                    isSearchable={false}
                                    closeMenuOnSelect = {false}
                                    value={selectedOptionAdd}
                                    onChange={this.handleChangeAdd}
                                    options={options}
                                />
                            </div>
                       </div>

                    </div>
                </div>

         )
    }

   myAgentEditForm(){

        var yesterday = Datetime.moment().subtract( 1, 'day' );
        var valid = ( current ) =>{
            return current.isAfter( yesterday );
        };

        this.state.roleTitleList.map( roleTitle =>{
            return <option  value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
        } );

        let options = []

        this.state.userPrivileges.map(privileges =>{
          options.push({ value: privileges.id, label: privileges.value })
        })

        const {selectedOptionEdit} = this.state;


       return(


            <div className="agengtDetailsAdd">
                    <div className="row">
                    <input type="hidden" value={this.state.selectedRow.userId} id="myAgentUserId" name="userId"/>
                    <input type="hidden" value={this.state.selectedRow.prescriberId} id="agentPrescriberId" name="prescriberId"/>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                            <Label lblhtmlFor="uNameAboutAgent" lblclassName = {this.state.selectedRow.uName? 'active' : null} lblname={Constant.LABEL_USER_NAME + Constant.STAR}/>
                            <CustomInput intid="myAgentUname" isDisabled ={this.state.fromEdit} value={this.state.selectedRow.uName}  intType="text"  required  intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" maxLength="30"/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                            <Label lblhtmlFor="uNameAboutAgent" lblclassName = {this.state.selectedRow.roleTitleName? 'active' : null} lblname="Who is a"/>
                            <CustomInput1 intid="whoIsA" isDisabled ={this.state.fromEdit} value={this.state.selectedRow.roleTitleName}  intType="text"  required  intName="whoIsA" intPattern="^[a-zA-Z]+$" maxLength="30"/>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                            <Label lblhtmlFor="myAgentPrefix" lblclassName = {this.state.selectedRow.prefix? 'active' : null}  lblname={"Prefix"}/>
                            <CustomInput1 intid="myAgentPrefix" isDisabled ={this.state.fromEdit} value={this.state.selectedRow.prefix} intType="text"/>
						</div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                      <div className="input-field">
                            <Label lblhtmlFor="myAgentFname" lblclassName = {this.state.selectedRow.fName? 'active' : null}  lblname={Constant.LABEL_FIRST_NAME}/>
                            <CustomInput intid="myAgentFname" isDisabled ={this.state.fromEdit} value={this.state.selectedRow.fName} intType="text" />
						</div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                        <div className="input-field">
                            <Label lblhtmlFor="myAgentMname" lblclassName = {this.state.selectedRow.mName? 'active' : null} lblname={Constant.LABEL_MIDDLE_NAME}/>
                            <CustomInput intid="myAgentMname" isDisabled ={this.state.fromEdit} intType="text" value={this.state.selectedRow.mName}  intName={Constant.INPUT_MIDDLE_NAME}/>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <div className="input-field">
                        <Label lblhtmlFor="myAgentLname" lblclassName={this.state.selectedRow.lName? 'active': null}  lblname={Constant.LABEL_LAST_NAME}/>
						<CustomInput intid="myAgentLname" intType="text" isDisabled ={this.state.fromEdit} value={this.state.selectedRow.lName} intName={Constant.INPUT_LAST_NAME }/>

						</div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                         <div className="input-field">
                            <Label lblhtmlFor="myAgentEmail" lblclassName={'active'} lblname={Constant.LABEL_EMAIL}/>
                            <CustomInput intid="myAgentEmail" isDisabled ={this.state.fromEdit} intType="text" value={this.state.selectedRow.email}  intName={Constant.INPUT_EMAIL } />

						</div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">

                        <div className="input-field suffix">

                        <Label lblhtmlFor="icon_prefix" lblclassName={'active'} lblname= "Disassociate Agent on"/>
                        <Datetime
                            classsName="datepicker"
                            timeFormat={false}
                            value = {this.state.selectedRow.endDate}
                            keyEvent='Enter' closeOnSelect='false' strictParsing="true"
                            onChange={(_date) => this.handleDateTimeSelect(_date)}
                            isValidDate={ valid }
                            inputProps={{ id: 'myAgentEditDeactiveDate', name: 'Disassociate Agent on'}}
                            dateFormat='MM/DD/YYYY'
                            input = {true}
                              />
                            <i className="icon-calendar simple-icons"  onClick={()=>$(".rdt input").click()}></i>
                        </div>

                    </div>

                        <div className="col-xs-12 col-sm-4 col-md-5 col-lg-5">
                           <div className="input-field suffix custom-prx-multiselect-dropdown">
                                <label className="active" >User Privileges </label>
                                <Select
                                    id="editPrivileges"
                                    className="input-field"
                                    isMulti = {true}
                                    isSearchable={false}
                                    closeMenuOnSelect = {false}
                                    value={selectedOptionEdit}
                                    onChange={this.handleChangeEdit}
                                    options={options}
                                />
                            </div>
                        </div>


                    </div>
                </div>

       )
   }

    myAgentDetails(){

        return(
            <div>
              {this.state.fromEdit ? this.myAgentEditForm() : this.myAgentAddForm()}
            </div>
        )
    }

    changeMyAgentPrivilegesListener = () =>{
        checkboxCountGloble=0
        this.setState({checkboxCount:0})
         let userPrivilegesIds = []
         let deactivateDate = ''
         if( $("#status").val() === "ASSOCIATE"){
              deactivateDate = $("#myDeactiveDate").val()
              let selectedOptionlen = this.state.selectedOptionSearch?this.state.selectedOptionSearch.length:0
              for ( let i=0;i<selectedOptionlen;i++){
                   userPrivilegesIds.push(this.state.selectedOptionSearch[i].value)
              }
         }

         this.setState({flag:true})
        // $("#changeMyAgentPrivileges").prop("disabled",true);

        let queryString = {"status":$("#status").val(),"userPrescriberList":userPrescriberList,"userPrivilegesIds":userPrivilegesIds,"deactivateDate":deactivateDate}

        // console.log(queryString)

        axios.post('/officeAdminProfile/changeMyPhysicianAgentAssociationPrivileges',queryString)
        .then(response => {

            let message = "";
            if( this.state.status === "ASSOCIATE"){
                message = Constant.AGENT_ASSOCIATED_SUCCESS
            }else{
                message = Constant.AGENT_DISASSOCIATED_SUCCESS
            }
            window.Materialize.toast(message, 3000,  'green rounded')
            userPrescriberList = []
             $("#status").val("");
             this.setState({selectedOptionSearch:null,status:''});
            $("input:checkbox").prop('checked',false);
            this.getMyPhysicianMyAgentData();
            this.setState({isSuccess:true})

        })
        .catch(function (error) {

        });
    }

    selectedPrivilegesListener = (event,row) =>{

        if(event.target.checked){
            let jsonData = {"prescriberId":row.prescriberId,"userId":row.userId}
            userPrescriberList.push(jsonData)
            checkboxCountGloble=checkboxCountGloble+1
        }else{
            checkboxCountGloble=checkboxCountGloble-1
        }
        if(checkboxCountGloble===1||checkboxCountGloble===0){
            this.setState({checkboxCount:checkboxCountGloble,status:""})
        }

        if( (userPrescriberList.length > 0 && this.state.status === "ASSOCIATE") || (userPrescriberList.length > 0 &&this.state.status === "DISASSOCIATE") ){
            // $("#changeMyAgentPrivileges").prop("disabled",false)
            this.setState({flag:false})
        }else{
            // $("#changeMyAgentPrivileges").prop("disabled",true)
            this.setState({flag:true})
        }


    }

    changePrivileges = (event) =>{
       this.setState({userPrivilegesMaster:event.target.value})
    }

    changestatus = (event) =>{
        this.setState({status:event.target.value,selectedOptionSearch:null})
         if( (userPrescriberList.length > 0 && this.state.status === "ASSOCIATE") || (userPrescriberList.length > 0 &&this.state.status === "DISASSOCIATE") ){
            // $("#changeMyAgentPrivileges").prop("disabled",false)
            this.setState({flag:false})
       }else{
        //    $("#changeMyAgentPrivileges").prop("disabled",true)
        this.setState({flag:true})
       }
    }

    actionChangeData(){
        if(userPrescriberList.length === 0){
            $("#changeMyAgentPrivileges").prop("disabled",true);
            // this.setState({flag:true})
        }

        var yesterday = Datetime.moment().subtract( 0, 'day' );
        var valid = ( current ) =>{
            return current.isAfter( yesterday );
        };



        let options = []

        this.state.userPrivileges.map(privileges =>{
            options.push({ value: privileges.id, label: privileges.value })
        })


        const { selectedOptionSearch } = this.state;


        return(

            <div className="boxWithShadow boxWithSpace clearfix mb150">
            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pr0 pl0">
                    <Input  s={12} m={12} l={12} xl={12} type='select' defaultValue={this.state.status} onChange={(event) => this.changestatus(event)} id="status" label={"Status"} name={"status"}>
                         <option value="" >--Select--</option>
                         {this.state.associationType ? this.state.associationType.map(association =>{
                                    return <option value={association.name} key={association.name} >{association.value}</option>
                             }) : <option value="" >--Select--</option>}

                    </Input>
                    </div>

                     {this.state.status === "ASSOCIATE"?<div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                        <div className="input-field suffix custom-prx-multiselect-dropdown">
                                <label className="active" >User Privileges </label>
                                <Select
                                className="input-field"
                                isMulti = {true}
                                isSearchable={false}
                                closeMenuOnSelect = {false}
                                value={selectedOptionSearch}
                                onChange={this.handleChange}
                                options={options}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">

                        <div className="input-field suffix">
                            <Label lblhtmlFor="icon_prefix" lblclassName={'active'} lblname= "Disassociate Agent on"/>
                            <Datetime
                                classsName="datepicker" keyPress={this.props.getSearchHistoryData}
                                timeFormat={false}
                                // value = {this.state.date}
                                keyEvent='Enter' closeOnSelect='false' strictParsing="true"
                                onChange={(_date) => this.handleDateTimeSelect(_date)}
                                isValidDate={ valid }
                                inputProps={{ id: 'myDeactiveDate', name: 'dob'}}
                                dateFormat='MM/DD/YYYY'
                                input = {true}
                                />
                                <i className="icon-calendar simple-icons" onClick={()=>$("#myDeactiveDate").click()}></i>
                            </div>

                        </div> </div>:''}
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <Button id="changeMyAgentPrivileges" disabled={this.state.flag}  click={this.changeMyAgentPrivilegesListener.bind(this)} secondParams={null} btnName="Apply" className="btn btn-primary wizard-next margin-top-a"  />
                    </div>
                </div>

        )

    }

    render() {
        $(".specialCharRestriction").on('keypress keyup blur', function (event) {
            // var regex = new RegExp("^[#%^&{}[\\]]+$");
            var regex = new RegExp("^[#%^&{}[\\]]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                event.preventDefault();
                return false;
            }
         });
         //specific characters allowed ,alphabets allowed,digit allowed
         $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._']+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
        $('.noSpace').on("keypress keyup blur",function (event) {
            this.value = this.value.replace(/\s/g,'');
           });
        UserProfile.setUrlName(window.location.href)
        return(
            <div className="v2-back-inner">
                <div className="wrapper custom-prx-wrapper slide-nav-toggle">
                 <Header h2Tag={"My Physician’s Agents/Representatives"} isSuccess = {this.state.isSuccess} />
                    <main>
                        <div className="inner-page-container">

                        {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="boxWithShadow over-hidden boxWithSpace clearfix">

                                        <OfficeAdminPhysiciansAgents
                                            userPrescribers={this.state.userPrescribers}
                                            editMyAgentListener = {this.editMyAgentListener}
                                            prescriberId = {this.state.prescriberId}
                                            selectedPrivilegesListener = {this.selectedPrivilegesListener}
                                            addMyAgentListener = {this.addMyAgentListener}
                                            getMyPhysicianMyAgentData = {this.getMyPhysicianMyAgentData}
                                            prescriberData={prescriberData}
                                           />

                                    </div>
                                </div>
                            </div>
                            {
                                this.state.checkboxCount>0?
                                <div className="row">
                                    <div className="col-sm-12">
                                    <div className="boxWithShadow boxWithSpace clearfix" >
                                       {this.actionChangeData()}
                                    </div>
                                 </div>
                                </div>:null
                            }
                            {this.myAgentAddModel()}
                            {this.myAgentEditModel()}
                        </div>

                    </main>
                    <Footer />
                </div>
            </div>
        );
    }
}