import React, { Component } from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import axios from 'axios';
import $ from 'jquery';
import * as Constant from '../../../Component/CustomUI/Constant';
import UserProfile from '../../../UserProfile';
import AgentProfile from '../MyProfile/AgentProfile';
let userData = []
let stateMaster = null
let prescriber = []
let agentPracticeLocation = []
let formaattedPracticeLocation = []

export default class MyProfileAgent extends Component {
   
    state = {
        userData:[],
        ProfileValidate :{
            userNameValid:false,
            firstNameValid : false,
            lastNameValid: false,
            emailValid:false,
            isEmailAvailable:false
        },
        isSuccess :false,
        isLoading:false,
        isCancel:false
    }

    componentDidMount(){
        this.getAgentProfileData()  
    }

    getAgentProfile = (username) => {
        let queryString = '';
        let isProfile = true
        queryString += '?username='+encodeURIComponent(username)
        queryString += '&isProfile='+isProfile
        axios.get('/agentProfile/getAgentProfileData'+queryString)
        .then(response => {
            if(response.data.user === null){
                this.setState({isLoading:false})    
                window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"Pagenofound";
            }else{
                console.log("response.data::::::::::::::::::")
                console.log(response.data)
                formaattedPracticeLocation = []
                stateMaster = response.data.stateMaster;
                prescriber = response.data.prescriberInfo
                agentPracticeLocation =  response.data.agentPracticeLocation

                 for(let i =0;i<agentPracticeLocation.length;i++){
                    let innerJson = {"id":agentPracticeLocation[i].id,"prescriberFullName":agentPracticeLocation[i].prescriberFullName,"facility":agentPracticeLocation[i].facility,
                    "address":agentPracticeLocation[i].address_1+" "+(agentPracticeLocation[i].address_2 ?agentPracticeLocation[i].address_2 :'')+" "+agentPracticeLocation[i].city+", "+agentPracticeLocation[i].state+" "+agentPracticeLocation[i].postal_code+", "+agentPracticeLocation[i].country_name,
                    "contact":"PHONE : "+(agentPracticeLocation[i].phone_1 ? agentPracticeLocation[i].phone_1 :'')+" | "+"FAX : "+(agentPracticeLocation[i].fax_1 ? agentPracticeLocation[i].fax_1 : '')}

                    formaattedPracticeLocation.push(innerJson);
                  }

                this.setState({userData:[response.data.user]})
                userData.push(response.data.user)
                this.setState({
                    isLoading:false
                })
            }
            
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    getUserNameFromUserID=(userID)=>{
        
        axios.get('/user/getUserNameFromUserID?userid='+userID)
         .then(response => {
            this.getAgentProfile(response.data)           
        }).catch(function (error) {
            console.log(error);
        });
    }

    getAgentProfileData = () => {
        this.setState({
               isLoading:true
            })

        let username;
        if(this.param) {
            this.getUserNameFromUserID(this.param);
        } else {
            username = UserProfile.getUserName();
            this.getAgentProfile(username)
        }
        
                
    }

    
    // checkDuplicateEmail = (event) => {
    //     let  value=event.target.value;
    // //    let value=  $("#emailAboutAgent").val();       
    //    let userData=this.state.userData
       
    //     if(value!=''&&!value.match(Constant.REGEX_SPACE)&&value.toLowerCase()!=userData[0].email.toLowerCase()){ 
    //         value=value.toLowerCase()
    //     axios.get('/registration/verifyEmail?email='+value)
    //     .then(response => {            
    //     this.isEmailDuplicate=response.data
    //      let isEmailAvailable = this.state
    //          if(this.isEmailDuplicate){
    //             isEmailAvailable = false;
    //               this.setState({isEmailAvailable:this.isEmailDuplicate})
    //             window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
    //         } else {
    //             isEmailAvailable = true;           
    //           this.setState({isEmailAvailable:this.isEmailDuplicate})

    //          }
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });  
    // }else {
    //     this.setState({isEmailAvailable:false})
    //     }   
    // }
    checkDuplicateEmail = (agentJson) => {
        // let  value=event.target.value;
       let value=  $("#emailAboutAgent").val();       
        userData=this.state.userData
       
        if(value!=''&&!value.match(Constant.REGEX_SPACE)&&value.toLowerCase()!=userData[0].email.toLowerCase() && value.match(Constant.REGEX_EMAIL) !== null){ 
            value=value.toLowerCase()
        axios.get('/registration/verifyEmail?email='+value)
        .then(response => {            
        this.isEmailDuplicate=response.data
             if(this.isEmailDuplicate){
                  this.setState({isEmailAvailable:this.isEmailDuplicate})
                window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
            } else {
                if(value.match(Constant.REGEX_EMAIL) !== null && value){
                    this.updateAgent(agentJson)
                } 
              this.setState({isEmailAvailable:this.isEmailDuplicate})
             }
        })
        .catch(function (error) {
            console.log(error);
        });  
    }else {
        this.updateAgent(agentJson)
        this.setState({isEmailAvailable:false})
        }   
    }
    saveAgentProfileListner = ()=>{
        let firstNameValid = false;
        let lastNameValid = false;
        let emailValid = false;
        let userNameValid = false;
        let isValid = true;
        let blankFieldValid = true
		let uNameAboutAgent = $("#uNameAboutAgent").val();
		let fNameAboutAgent = $("#fNameAboutAgent").val();
		let lNameAboutAgent = $("#lNameAboutAgent").val();
		let emailAboutAgent = $("#emailAboutAgent").val();
		let mNameAboutAgent =  $("#mNameAboutAgent").val();
        let userKey =  $("#userKey").val();
		let prefix =  $("#prefix").val();
        let password =  $("#password").val();
        let temPassword = $("#displayTempPassword").val();
        let resetPasswordMethodAboutAgent = this.state.userData[0].resetPasswordMethod
        let userId = this.state.userData[0].id
        let ipAddress =  this.state.userData[0].ipAddress
        let createdDate = this.state.userData[0].createdDate
        let neverLogin = this.state.userData[0].neverLogin
        
        
        let passwordUpdateDate = this.state.userData[0].passwordUpdateDate
		console.log(this.state.userData[0])
		let roleId = []
		let roleTitleId = ''
		let status = $("#userStatus").val();
        let approvalStatus = $("#userApprovalStatus").val();
		let id = this.state.userData[0].role[0].id
		roleId.push(id.toString())

        roleTitleId = this.state.userData[0].roleTitle.id
        // if(this.state.isEmailAvailable) {
        //     isValid = false;
        //     // blankFieldValid = false;
        //      window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')            
        // }

        //username validation

        if(!uNameAboutAgent){
            userNameValid = true
            isValid = false
            window.Materialize.toast(Constant.USER_NAME, 3000,  'red rounded')
        }
        if(uNameAboutAgent.match(Constant.USER_ALPHABET_SPACE)==null){
            userNameValid = true
            isValid = false
            if(uNameAboutAgent){
                window.Materialize.toast(Constant.USER_NAME_VALID, 3000,  'red rounded')
            }
        }

        if(!fNameAboutAgent||fNameAboutAgent.trim().length==0){
            firstNameValid = true
            blankFieldValid = false;
            isValid = false
        }
        if(!lNameAboutAgent||lNameAboutAgent.trim().length==0){
            blankFieldValid = false;
            lastNameValid = true
            isValid = false
        }
        if(emailAboutAgent && emailAboutAgent.trim().length==0){
            emailValid = true
            blankFieldValid = false;
            isValid = false
        }
        // if(mNameAboutAgent&&mNameAboutAgent.trim().length==0){ 
        //     blankFieldValid=false           
        //     window.Materialize.toast("White space not allow as middelname", 5000,  'red rounded') 
        //     isValid = false
        // }
        if(!blankFieldValid){
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000,'red rounded') 
        }      
        if(emailAboutAgent && emailAboutAgent.trim().length !==0 && emailAboutAgent.match(Constant.REGEX_EMAIL) === null){
            emailValid = true
            isValid = false
            window.Materialize.toast(Constant.EMAIL_INVALID, 5000,'red rounded')
        }  
        if(lNameAboutAgent.trim().length==0 && lNameAboutAgent){
            isValid = false
            window.Materialize.toast("Please enter valid lastname", 5000,  'red rounded') 
        }
        if(mNameAboutAgent.trim().length==0 && mNameAboutAgent){
            isValid = false
            window.Materialize.toast("Please enter valid middlename", 5000,  'red rounded') 
        }
        if(fNameAboutAgent.trim().length==0 && fNameAboutAgent){         
            isValid = false
            window.Materialize.toast("Please enter valid firstname", 5000,  'red rounded')
        }       
        let agentJson ={
            id:userId,password:password,prefix:prefix,middleName:mNameAboutAgent.trim(),firstName:fNameAboutAgent,passwordUpdateDate:passwordUpdateDate,
            resetPasswordMethod:resetPasswordMethodAboutAgent,status:status,approvalStatus:approvalStatus,neverLogin:neverLogin,
            lastName:lNameAboutAgent,email:emailAboutAgent,roleIds:roleId,roleTitleId:roleTitleId,username:uNameAboutAgent,ipAddress:ipAddress,createdDate:createdDate,
            securityQuestion:this.state.userData[0].securityQuestion,tempPassword:temPassword,userKey:userKey
        }    
        this.setState({
            ProfileValidate :{
           firstNameValid:firstNameValid,lastNameValid:lastNameValid,emailValid:emailValid,userNameValid:userNameValid
           }
        })
    //     if(isValid){
    //                     axios.post('/agentProfile/updateAgent',agentJson)
    //                     .then(response => {
    //                         this.setState({
    //                             isLoading:false
    //                         })
    //                     })
    //                     .catch(function (error) {
    //                         console.log(error);
    //                     });
    //                     window.Materialize.toast("Agent updated successfully", 4000, 'green rounded')
    //     }
    //     this.setState({isSuccess:true})
    // }
       
        // if(isValid && userData[0].email===emailAboutAgent){
        //     this.updateAgent(agentJson)       
        // }else if(blankFieldValid){
        //     this.checkDuplicateEmail(agentJson)
        // }


        //username changes

       let isEmailValid = true
       let isUserValid = true

        if(isValid){
            if(userData[0].username.trim() === uNameAboutAgent.trim() && userData[0].email.trim() === emailAboutAgent.trim()){
                 this.updateAgent(agentJson)
            }
            if(userData[0].email.trim() !== emailAboutAgent.trim()){
                isEmailValid = false
                isValid = false
            }
        
            if(userData[0].username.trim() !== uNameAboutAgent.trim()){
                isUserValid = false
                isValid = false
            }
        }
        if(!isValid && (!isUserValid || !isEmailValid)){
            this.checkDuplicateUserName(agentJson,isUserValid)
        }
        //  if(!isValid && !isEmailValid){
        //     isValid = false
        //     this.checkDuplicateEmail(agentJson)
        // }
    }


     checkDuplicateUserName(queryString,isUserValid) {

        let valueOfUsername=$("#uNameAboutAgent").val()
        let KSEmail=false
        let val=valueOfUsername.toLowerCase();
        if(val.includes("@")){
            if(val.split("@")[1]==="kloudscript.net" || val.split("@")[1]==="usewms.com" || val.split("@")[1]==="usekspa.com"){
                KSEmail=true
            }
          }
          if(!KSEmail){
            if(!isUserValid){
                axios.get('/registration/userName?userName=' + valueOfUsername )
                   .then(response => {
                       if(response.data){
                           this.checkDuplicateEmail(queryString)
                           //this.updateAgent(queryString)
                       }else{
                           window.Materialize.toast(Constant.USER_NAME_DUPLICATE, 3000, 'red rounded')
                           this.setState({ isSaveLoading : false,isSuccess:false })
                       }

                   })
                   .catch(function (error) {
                       console.log(error);
                   });
           }else{
                this.checkDuplicateEmail(queryString)
           }
          }else{
            window.Materialize.toast("User Name Should Not Contain @kloudscript.net or @usewms.com or @usekspa.com", 3000,  'red rounded')
          }
            
           
    }


    updateAgent=(agentJson)=>{
        this.setState({isSuccess:true})
        axios.post('/agentProfile/updateAgent',agentJson)
        .then(response => {
            this.setState({
                isLoading:false
            })
            if(response.data){
                if(this.state.isSuccess){ 
                    if(response.data != true && response.data != false){
                        UserProfile.setUserName(response.data);
                    }
                    window.Materialize.toast("Agent updated successfully", 7000, 'green rounded') 
                    window.location.reload();
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
       
    }
    cancelPageListener = () =>{
         this.setState({isCancel:true})
    }
    render() {

        let url_string = window.location.href;
        let url = new URL(url_string);
        this.param = url.searchParams.get("username");        
        UserProfile.setUrlName(window.location.href);
        
        let title = "";

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            title = 'Agent Profile';
        }else{
            title = 'My Profile';
        }

        return (
            <div className="v2-back-inner">
                 <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={title}  />
                    <main>
                        <div className="inner-page-container">
                            <div className="row">
                                <div className="col-sm-12">
                                {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:
                                <div className="boxWithShadow boxWithSpace">
                                <AgentProfile 
                                    checkDuplicateEmail={this.checkDuplicateEmail}
                                    userData = {this.state.userData}
                                    ProfileValidate = {this.state.ProfileValidate}
                                    stateMaster = {stateMaster}
                                    prescriber = {prescriber}
                                    agentPracticeLocation = {formaattedPracticeLocation}
                                    getAgentProfileData = {this.getAgentProfileData}
                                  />
                                <div className="clearfix"></div>
                                <section className="buttonWrapper mb20">
                                    <button className="btn waves-effect waves-light btn-darkpurpale" onClick={this.saveAgentProfileListner} disabled={this.state.isSuccess}>Save</button>
                                    <a href={this.param?Constant.FRONTEND_CONTEXT_PATH+"manageuser":Constant.FRONTEND_CONTEXT_PATH+"workqueue"} className="btnn btn btn-primary waves-effect waves-light btn-dark">Cancel</a>
                                </section>
                                </div> }

                              </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                 </div>
            </div>
        );
     }

}

