export const BACKEND_SERVER_URL=process.env.REACT_APP_BACKEND_SERVER_URL
export const BACKEND_CONTEXT_PATH=process.env.REACT_APP_CONST_CONTEXT
export const STEP_ONE_DOCUMENT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/registration/viewDocument"
export const PRESCRIBER_PROFILE_DOCUMENT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/prescriberProfile/viewDocument"
export const SIGNIN_CONTEXT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/signin"
export const SIGNOUT_CONTEXT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/logout"
export const EXPORT_WORKQUEUE_URL=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/status/exportStatusData/status.xlsx"
export const EXPORT_Audit_URL=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/audit/exportAuditData/audit.csv"
export const DOWNLOAD_MD_FORMS=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/status/downloadPdf"
export const VIEW_MD_FORMS=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/status/viewPdf"
export const HISTORY_DOCUMENT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/history/viewDocument"
export const STATUS_DOCUMENT_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/status/viewDocument"
export const STATUS_VIDEO_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/registration/videos?id="
export const VIDEO_PATH=BACKEND_SERVER_URL+BACKEND_CONTEXT_PATH+"/videos/renderVideos?id="
export const USER_MANAGEMENT_URL="/userManagement"

export const FRONTEND_SERVER_URL=process.env.REACT_APP_FRONTEND_SERVER_URL
export const FRONTEND_CONTEXT_PATH="/"
export const PREFIX_VALUE_DR="DR.";
export const PREFIX_VALUE_MISS="MISS."
export const PREFIX_VALUE_MR="MR."
export const PREFIX_VALUE_MRS="MRS."
export const PREFIX_VALUE_MS="MS."
export const PREFIX_VALUE_PROF="PROF."
export const PREFIX_NAME_DR="Dr";
export const PREFIX_NAME_MISS="Miss"
export const PREFIX_NAME_MR="Mr"
export const PREFIX_NAME_MRS="Mrs"
export const PREFIX_NAME_MS="Ms"
export const PREFIX_NAME_PROF="Prof"
export const PRESCRIBER="Prescriber";
export const AGENT="Agent";
export const KS_TECH_ADMIN="KS_TECH_ADMIN";
export const STAR = " *";
export const ROLE_TYPE = "ima"
export const ROLE_AGENT = "1";
export const ROLE_PRESCRIBER = "2";
export const STEP_TWO_PRESCRIBER = "step2Prescriber";
export const STEP_TWO_AGENT = "step2Agent";
export const STEP_FOUR_PRESCRIBER = "step4Prescriber";
export const STEP_FOUR_AGENT = "step4Agent";
export const STEP_ONE = "step1";
export const STEP_TWO = "step2";
export const STEP_THREE = "step3";
export const STEP_FOUR = "step4";
export const STEP_FIVE = "step5";
export const STEP_SIX = "step6";
export const NEXT = "Next";
export const ACCEPT="Accept";
export const BACK="Back";
export const SUBMIT="Submit";
export const DEA="DEA";
export const RESET_PASSWORD_METHOD="Reset Password Method";
export const DISSOCIATED = "Disassociate"
export const ASSOCIATED = "Associate"
export const PENDING = "Pending"
export const APPROVED = "Approved"
export const APPROVED_STATUS = "APPROVED"
export const REJECTED = "Rejected"
export const ACTIVE = "Active"
export const INACTIVE = "Inactive"
export const REGISTER = "Register"

export const STATE_LICENSE ="STATE_LICENSE"
export const MANDATORY_MESSAGE = "Please fill out all the mandatory fields.";
export const NPI_NOT_FOUND = "Data not Found, Please Enter Valid NPI";
export const NPI_API_DOWN = "NPPES NPI Registry is down, Please try again later"
export const ROLE_REQUIRED = "Please Select I am a";
export const KSROLE_REQUIRED = "Please Select I want to register a";
export const NPI_VALIDATION = "Please Enter Only 10 digit NPI Number";
export const ANSWER_IS_DUPLICATE = "Please enter different answer.";
export const QUESTION_IS_DUPLICATE = "Please select different question.";
export const FIRST_NAME="Please Enter First Name";
export const LAST_NAME="Please Enter Last Name";
export const NPI="Please Enter NPI";
export const EMAIL="Please Enter Email Address";
export const EMAIL_DUPLICATE="Email Address Already Exists"
export const USER_NAME_DUPLICATE="Username Already Exists";
export const EMAIL_INVALID="Please Enter Valid Email Address";
export const CONFIRM_EMAIL="Please Enter Confirm Email Address";
export const CONFIRM_EMAIL_INVALID="Email Address and Confirm Email Address must be same";
export const STATE_LICENCE_ID="Please Enter Only Number in State License Id";
export const CITY="Please Enter City";
export const STATE="Please Enter State";
export const ZIP="Please Enter Zip";
export const WORK_PHONE="Please Enter Workphone";
export const ADDRESS_LINE_1="Please Enter AddressLine1";
export const TAXONOMY_CODE="Please Enter Taxonomy Code";
export const USER_NAME="Please Enter Username";
export const PASSWORD="Please Enter Password";
export const OLD_PASSWORD="Please Enter Old Password";
export const INVALID_PASSWORD_FORMATE = "Password must be combination of upper and lower case letters. It must contain at least one special character or number. It must be of nine to sixteen characters long."
//export const ENTERED_PASSWORD_INVALID="Password must be combination of upper and lower case letters. It must contain at least one special character and number. <br/> It must be of nine to sixteen characters long.";
export const CONFIRM_PASSWORD="Please Enter Confirm Password";
export const CONFIRM_PASSWORD_INVALID="Password and Confirm Password must be same.";
export const SECURITY_ANSWER_1="Please Enter Security Answer 1";
export const SECURITY_ANSWER_2="Please Enter Security Answer 2";
export const SECURITY_ANSWER_3="Please Enter Security Answer 3";
export const ASSOCIATE_ONE_PRESCRIBER = "Please associate atleast one Prescriber";
export const ASSOCIATE_PRESCRIBER = "Prescriber associated successfully.";
export const DOCUMENT_NAME = "Please Enter Document Name";
export const DOCUMENT_DESCRIPTION = "Please Enter Description";
export const CHARTNOTES_TITLE = "Please Enter Title";
export const SELECT_DOCUMENT = "Please select a document to upload";
export const SELECT_MAIN_TYPE = "Please select Type to create new category";
export const SELECT_MANDATORY_FIELDS = "Please select all mandatory fields";
export const SELECT_FILE_TYPE = "Please select a Pdf or Doc file only";
export const MAX_CATEGORY = "Category Name is too long(maximum length is 50 character)";
export const MAX_FILENAME = "File name is too long(maximum length is 100 character)";
export const MAX_FILESIZE = "File size can not greater than 100MB";
export const INVALID_CATEGORY = "Not allowing only Special Character as Category name";
export const UPLOADED_FILE = "Document uploaded successfully";
export const NOT_UPLOADED_FILE = "Something went wrong! Document not uploaded";
export const NEW_CATEGORY = "You have created new category";
export const FILE_REMOVE = "Document deleted successfully";
export const NOT_FILE_REMOVE = "Document not deleted successfully";
export const CATEGORY_REMOVE = "Category also removed";
export const CHANGE_PASSWORD_UPDATED_SUCESSFULLY = "Change password updated successfully.";
export const SECURITY_QUE_UPDATE ="Security questions updated successfully.";
export const FAILED_ERROR_MSG = "Something went wrong. Please try again later.";
export const PASSWORD_DOES_NOT_MATCH = "Old password does not match.";
export const PASSWORD_UPDATED = "Password updated successfully.";
export const SAME_OLD_NEW_PASSWORD="Old password and new password must be different.";
export const CONTACT_TYPE = "Please select a contact type";
export const CONTACT_NUMBER = "Please enter a contact number";
export const PASSWORD_ALREADY_EXITS = "New password is already in use, Please select diffrent password.";
export const SECURITY_QUE_UPDATE_AND_CHANGE_PASSWORD_UPDATE = "Security questions and password updated successfully."

export const LABEL_FIRST_NAME="First Name";
export const LABEL_PATIENT_FIRST_NAME="Patient First Name";
export const LABEL_CONFIRM_EMAIL="Confirm Email Address";
export const LABEL_LAST_NAME="Last Name";
export const LABEL_PATIENT_LAST_NAME="Patient Last Name";
export const LABEL_EMAIL="Email Address";
export const LABEL_MIDDLE_NAME="Middle Name";
export const LABEL_STATE_LICESE_ID="State License Id ";
export const LABEL_NPI="NPI";
export const LABEL_DOCUMENT = "Select File";
export const LABEL_FACILITY_NAME="Facility Name";
export const LABEL_ADDRESS_LINE_2="Address Line 2";
export const LABEL_ADDRESS_LINE_1="Address Line 1";
export const LABEL_CITY="City";
export const LABEL_STATE="State";
export const LABEL_ZIP="Zip";
export const LABEL_FAX_1="Fax";
export const LABEL_WORK_PHONE="Work Phone";
export const LABEL_FAX_2="Fax #2";
export const LABEL_SPECIALTY="Specialty";
export const LABEL_TAXONOMY="Taxonomy Code";
export const LABEL_USER_NAME="Username";
export const LABEL_OLDPASSWORD="Old Password";
export const LABEL_PASSWORD="New Password";
export const LABEL_CONFIRM_PASSWORD="Confirm Password";
export const LABEL_ANSWER="Answer";
export const LABEL_PREFIX="Prefix";
export const LABEL_QUESTION1="In what city or town did your mother and father met?";
export const LABEL_QUESTION2="What was the name of your first stuffed animal?";
export const LABEL_DOB="Date Of Birth";
export const LABEL_DRUG_NAME="Drug Name";
export const LABEL_CASE_ID="Case Id";
export const LABEL_SUBMISSION_DATE_FROM = "Submission Date From";
export const LABEL_SUBMISSION_DATE_TO = "Submission Date To";
export const LABEL_CREATE_AND_SHOW_PASSWORD = "Create & Show Password";
export const LABEL_KSROLE = "Register"
export const LABEL_ROLE = "Select Role"


export const INPUT_NPI_AGENT="npiAgent";
export const INPUT_AGENT_FNSEARCH="agentFNSearch";
export const INPUT_AGENT_LNSEARCH="agentLNSearch";
export const INPUT_NPI="npi";
export const INPUT_STATE_LICENSE_ID="statelicenseId";
export const INPUT_FILE="document";
export const INPUT_PREFIX="prefix";
export const INPUT_FIRST_NAME="firstName";
export const INPUT_LAST_NAME="lastName";
export const INPUT_MIDDLE_NAME="middleName";
export const INPUT_EMAIL="email";
export const INPUT_CONFIRM_EMAIL="confirmEmail";
export const INPUT_FACILITY_NAME="facilityName";
export const INPUT_ADDRESSLINE_1="addressLine1";
export const INPUT_ADDRESSLINE_2="addressLine2";
export const INPUT_CITY="city";
export const INPUT_STATE="state";
export const INPUT_STATE_FULL_NAME="state_full_name";
export const INPUT_ZIP="zip";
export const INPUT_WORK_PHONE="workphone";
export const INPUT_FAX_1="faxOne";
export const INPUT_FAX_2="faxTwo";
export const INPUT_SPECIALITY="Specialty";
export const INPUT_TAXONOMY_CODE="taxonomyCode";
export const INPUT_USER_NAME="userName";
export const INPUT_PASSWORD="password";
export const INPUT_CONFIRM_PASSWORD="confirmPassword";
export const INPUT_SECURITY_ANSWER_1="securityAnswer1";
export const INPUT_SECURITY_ANSWER_2="securityAnswer2";
export const INPUT_SECURITY_ANSWER_3="securityAnswer3";
export const USER_VERIFY_SUCCESS = "success";
export const AGENT_ASSOCIATED_SUCCESS = "Agent associated successfully.";
export const AGENT_DISASSOCIATED_SUCCESS = "Agent disassociated successfully.";
export const PRACTICE_LOCATION_ADD = "Practice location add successfully."
export const PRACTICE_LOCATION_EDIT = "Practice location edit successfully."
// export const Datetime=/(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/
// export const Datetime=/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2} [AP]M$/
export const Datetime=/^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4} ((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AP][M]))$/
//export const REGEX_PASSWORD="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,16}$";
export const WRONG_PASSWORD_FORMATE = "^(?=((?=.*[\\d\\W]{1,})(?=.*\\w).{9,}))(?=.*[a-z])(?=.*[A-Z]).*$"
export const REGEX_DIGIT=/^[0-9\b]+$/;
export const REGEX_ONLY_ALPHABET=/^[a-zA-Z\b]+$/;
export const REGEX_DIGIT_MASK=/^[-0-9\b]+$/;
export const REGEX_DIGIT_ALPHABET_SPACE=/^[0-9a-zA-Z\s\b]+$/;
export const REGEX_ANY=/./;
//  export co   -z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
export const REGEX_EMAIL=/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
// export const REGEX_NUMBER_MASK=[/[1-9]/, /\d/, /\d/, '-',  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const REGEX_NUMBER_MASK=[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const REGEX_NUMBER_MASK2=[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,/\d/];
export const REGEX_NUMBER_MASK1=[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, 'x', /\d/, /\d/, /\d/, /\d/];
export const REGEX_ALPHABET=/^[a-zA-Z\s]+$/
export const REGEX_SPACE=/^[\s]+$/
export const TEN = 10;
export const SPECIALCHAR=/^[!@#$%^&*(),.?":{}|<>]$/;
export const SPECIALCHAR1= /^[a-zA-Z']+$/
export const ALPHABET_SPACE= /^[a-zA-Z'\s]+$/
export const USER_ALPHABET_SPACE= /^[a-zA-Z]+|^[a-zA-Z]+[0-9]+^\S*$/
export const  PHONE_NUMBER_REGX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
export const  PHONE_NUMBER_REGX1 = /^[0-9]{3}-[0-9]{3}-[0-9]{4}x[0-9]{4}$/
export const  REGEX_CREATE_CATEGORY = /^[\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|_|-]+/  

export const SELECT_WHO_IS_A_AGENT = "Please select I work at a";
export const SELECT_WHO_IS_A_PRESCRIBER = "Please select Who is a";
export const SELECT_ANY_SEARCH_FIELD = "Please select one of the fields to search";
export const NPI_ALREADY_IN_SYSTEM = "NPI is already in system, <br/> Please choose different NPI for further registration."
export const MAX_DOCUMENTS_SELECTED = "You can upload upto 10 documents only";
export const FIRST_NAME_VALLID="Please Enter First Name"
export const LAST_NAME_VALID="Please Enter Last Name"
export const MIDDLE_NAME_VALID="Please Enter Valid Middle Name"
export const USER_NAME_VALID="Please Enter Valid User Name"
export const LABEL_USER_FIRST_NAME="User First Name";
export const LABEL_USER_LAST_NAME="User Last Name";
export const ZIP_VALID="Please Enter Zip Code";
export const NEW_PASSWORD_VALID="Please Enter NewPassword"
export const OLD_PASSWORD_VALID="Please Enter OldPassword"

export const PLEASE_SEARCH_CRITERIA = "Please select at least one search criteria";

export const PLEASE_SELECT_PRESCRIBER = "Please select at least one Prescriber for agent Association";

export const LOGGED_IN_ROLE_PRESCRIBER="ROLE_PRESCRIBER";
export const LOGGED_IN_ROLE_AGENT="ROLE_AGENT";
export const LOGGED_IN_ROLE_KS_TECH_ADMIN="ROLE_KS_TECHNICAL_ADMIN";
export const LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER="ROLE_KS_BUSINESS_SUPER_USER";
export const PERMIT_ALL = "*";

export const LOGIN_FAILED_USER_INACTIVE_TECHADMIN = "User is inactivated by Technical Admin";
export const LOGIN_FAILED_USER_MAX_FAILED_ATTEMPTS = "You cannot log in as your account has been inactivated because you have reached the maximum number of invalid login attempts";
export const LOGIN_FAILED_USER_INACTIVE = "User is not active. Please contact administrator to reset the password";
export const LOGIN_FAILED_USER_APPROVAL_STATUS_PENDING = "User approval status is Pending";
export const LOGIN_FAILED_USER_APPROVAL_STATUS__REJECTED = "User approval status is Rejected";

export const UPLOAD_DOCUMENTATION = "UPLOAD_DOCUMENTATION";
export const UPLOADED_FILE_NAME = "document";

export const LABEL_PH_RX_ID = "PH Rx ID";

export const MD_NOT_FOUND = "This physician is not registered with ProntoRX. In order for an agent to register, a Physician should be registered first."
export const AUDIT_SEARCH_VALIDATIONE = "Please select Audit Search From and To Date."
export const AUDIT_SEARCH_DATE_VALIDATIONE = "Please enter valid date & time."
export const AUDIT_SEARCH_AUDIT_USERNAME = "Please enter valid User Name."
export const AUDIT_SEARCH_AUDIT_SERVER_URL = "Please enter valid Server URL."
export const AUDIT_SEARCH_AUDIT_LOCALIPADDRESS = "Please enter valid Local IP Address."


export const EXPIRE_SESSION_URL = "This session has been expired (possibly due to multiple concurrent logins being attempted as the same user)."
export const COPIED_PRESCRIBER_MESSAGE = "Prescriber practice locations has been copied."
export const MANDATORY_LOCATION_MESSAGE = "Please Select at least one location"