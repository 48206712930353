import EncryptDecrypt from './EncryptDecrypt';
var UserProfile = (function() {
    var full_name = "";
    var user_id = "";
    var username = "";
    var role = "";
    var NPIList = [];
    var status = "";
    var approvalStatus = "";
    var urlName = "";
    var excelExportPassword = "";

    var clearAll = function(){
      localStorage.removeItem("full_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("username");
      localStorage.removeItem("role");
      localStorage.removeItem("NPIList");
      localStorage.removeItem("isOfficeAdmin");
      localStorage.removeItem("status");
      localStorage.removeItem("approvalStatus");
      localStorage.removeItem("inactiveDueToIncorrectPass");
      localStorage.removeItem("showChangePassword");
      localStorage.removeItem("isInactiveDueToIncorrectSecQue");
      localStorage.removeItem("isPasswordExpire");
      localStorage.removeItem("urlName")
      localStorage.removeItem("isNeverLogin")
      localStorage.removeItem("excelExportPassword")
    }
  
    var getName = function() {
      full_name = EncryptDecrypt.decrypt(localStorage.getItem("full_name"));
      return full_name;    
    };
  
    var setName = function(full_name) {
      localStorage.setItem("full_name",EncryptDecrypt.encrypt(full_name));
    };

    var getUrlName = function() {
      urlName = EncryptDecrypt.decrypt(localStorage.getItem("urlName"));
      return urlName;    
    };
  
    var setUrlName = function(urlName) {
      localStorage.setItem("urlName",EncryptDecrypt.encrypt(urlName));
    };

    var getUserId = function() {
      return user_id;    
    };
  
    var setUserId = function(id) {
      user_id = id;     
    };

    var getExcelExportPassword = function() {
      return excelExportPassword;    
    };
  
    var setExcelExportPassword = function(pass) {
      excelExportPassword = pass;     
    };

    var getUserName = function() {
      username = EncryptDecrypt.decrypt(localStorage.getItem("username"));
      return username;    
    };
  
    var setUserName = function(user_name) {
      localStorage.setItem("username",EncryptDecrypt.encrypt(user_name));    
    };

    var getRole = function() {
      role = EncryptDecrypt.decrypt(localStorage.getItem("role"));
      return role;    
    };
  
    var setRole= function(role) {
      localStorage.setItem("role",EncryptDecrypt.encrypt(role)); 
    };

    var getNPIList = function() {
      return NPIList;    
    };
  
    var setNPIList = function(npi_list) {
      NPIList = npi_list;     
    };

    var getOfficeAdmin = function() {
      var isOfficeAdminEnc = EncryptDecrypt.decrypt(localStorage.getItem("isOfficeAdmin"));
      if(isOfficeAdminEnc === "true"){
        return true;
      }

      return false;  
    };
  
    var setOfficeAdmin = function(is_office_admin) {
      localStorage.setItem("isOfficeAdmin",EncryptDecrypt.encrypt(is_office_admin.toString()));
    };

    var getStatus = function() {
      status = EncryptDecrypt.decrypt(localStorage.getItem("status"));
      return status;    
    };
  
    var setStatus = function(user_status) {
      localStorage.setItem("status",EncryptDecrypt.encrypt(user_status)); 
    };

    var getApprovalStatus = function() {
      approvalStatus = EncryptDecrypt.decrypt(localStorage.getItem("approvalStatus"));
      return approvalStatus;    
    };
  
    var setApprovalStatus = function(approval_status) {
      localStorage.setItem("approvalStatus",EncryptDecrypt.encrypt(approval_status)); 
    };

    var getInactiveDueToIncorrectPass = function() {
      var inactiveDueToIncorrectPassEnc = EncryptDecrypt.decrypt(localStorage.getItem("inactiveDueToIncorrectPass"));

      if(inactiveDueToIncorrectPassEnc === "true"){
          return true;
      }
      return false;      
    };
  
    var setInactiveDueToIncorrectPass = function(inactive_due_to_incorrect_pass) {
      localStorage.setItem("inactiveDueToIncorrectPass",EncryptDecrypt.encrypt(inactive_due_to_incorrect_pass.toString())); 
    };

    var getShowChangePassword = function() {
      var showChangePasswordEnc = EncryptDecrypt.decrypt(localStorage.getItem("ShowChangePassword"));
      if(showChangePasswordEnc === "true"){
          return true;
      }
      return false;      
    };
  
    var setShowChangePassword = function(show_change_password) {
      localStorage.setItem("ShowChangePassword",EncryptDecrypt.encrypt(show_change_password.toString())); 
    };


    var getIsInactiveDueToIncorrectSecQue = function() {
      var isInactiveDueToIncorrectSecQueEnc = EncryptDecrypt.decrypt(localStorage.getItem("isInactiveDueToIncorrectSecQue"));
      if(isInactiveDueToIncorrectSecQueEnc === "true"){
          return true;
      }
      return false;      
    };
  
    var setIsInactiveDueToIncorrectSecQue = function(is_inactive_due_to_incorrect_secQue) {
      localStorage.setItem("isInactiveDueToIncorrectSecQue",EncryptDecrypt.encrypt(is_inactive_due_to_incorrect_secQue.toString())); 
    };
    
    var getIsPasswordExpire = function() {
      var isPasswordExpire = EncryptDecrypt.decrypt(localStorage.getItem("isPasswordExpire"));
      if(isPasswordExpire === "true"){
          return true;
      }
      return false;      
    };
  
    var setIsPasswordExpire = function(is_password_expire) {
      localStorage.setItem("isPasswordExpire",EncryptDecrypt.encrypt(is_password_expire.toString())); 
    };

    var getIsNeverLogin = function() {
      var isNeverLogin = EncryptDecrypt.decrypt(localStorage.getItem("isNeverLogin"));
      if(isNeverLogin === "true"){
          return true;
      }
      return false;      
    };
  
    var setIsNeverLogin = function(is_never_login) {
      localStorage.setItem("isNeverLogin",EncryptDecrypt.encrypt(is_never_login.toString())); 
    };



   
  
    return {
      getName: getName,
      setName: setName,
      getUserId: getUserId,
      setUserId: setUserId,
      getUserName: getUserName,
      setUserName: setUserName,
      getNPIList: getNPIList,
      setNPIList: setNPIList,
      getOfficeAdmin: getOfficeAdmin,
      setOfficeAdmin: setOfficeAdmin,
      getRole:getRole,
      setRole:setRole,
      getStatus:getStatus,
      setStatus:setStatus,
      getApprovalStatus:getApprovalStatus,
      setApprovalStatus:setApprovalStatus,
      getInactiveDueToIncorrectPass:getInactiveDueToIncorrectPass,
      setInactiveDueToIncorrectPass:setInactiveDueToIncorrectPass,
      getShowChangePassword:getShowChangePassword,
      setShowChangePassword:setShowChangePassword,
      getIsInactiveDueToIncorrectSecQue:getIsInactiveDueToIncorrectSecQue,
      setIsInactiveDueToIncorrectSecQue:setIsInactiveDueToIncorrectSecQue,
      setIsPasswordExpire:setIsPasswordExpire,
      getIsPasswordExpire:getIsPasswordExpire,
      getUrlName:getUrlName,
      setUrlName:setUrlName,
      getIsNeverLogin:getIsNeverLogin,
      setIsNeverLogin:setIsNeverLogin,
      getExcelExportPassword:getExcelExportPassword,
      setExcelExportPassword:setExcelExportPassword,
      clearAll:clearAll
    }
  
  })();
  
  export default UserProfile;