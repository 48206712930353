import React, {Component} from 'react';
import Header from './../PageComponent/Header/Header';
import Footer from './../PageComponent/Footer/Footer';
import  * as Constant  from '../CustomUI/Constant';

class Forms extends Component {
     
    DownloadDocument = (event,filename) =>{
       console.log(filename)
       window.location = Constant.DOWNLOAD_MD_FORMS+'?fileName='+filename;

    }

    render () {

        
        return (

            <div>
            <div className="wrapper slide-nav-toggle">
              <Header h2Tag={"Forms & Consents"}/>
              <main>
                  <div >
                <div className="row">
               
                   <div className="col-xs-12 col-sm-4 col-md-3 col-lg-4"  >
                    <div className="formSectionTitle clearfix mt20">
                     <h3>Consent Froms and Information</h3></div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectioncontent">
                    <p><b>Prescriber Consent Form</b> is intended to be used by the healthcare provider with Prescribing rights. Consent will be valid for 1 year from the date of signature.
                    The signed consent form can be faxed to a designated number or uploaded from the portal whent reqursted for a patient.
                    </p>
                    <p><b>Patient Consent Form</b> is intended to be used by the patient to grant the KloudScript team consent to conduct Benefit Investigation and Financial Case Management on Behalf of th patient. KloudScript team will enroll qualifying patients for patient assistance programs and attempt to reduce their out of pocket cost when applicable.The signed patu
                    consent form will allow for expedited Benefit Investigation to take place and not delay patient care. </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectionLink clearfix mt20">
                        <span>Prescriber Consent Form <a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a> </span> 
                        <span>Patient Consent Form<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a></span>
                        <span>Prescriber Information Brochure<a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                        <span>Patient Information Brochure<a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a></span>
                    </div>
                   </div>
                   <div className="col-xs-12 col-sm-4 col-md-3 col-lg-4">
                   <div className="formSectionTitle clearfix mt20">  <h3>Referral Forms</h3></div>
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectioncontent">
                   <p>
                     Our referral forms make it easy for you to send prescription to KloudSCript's network of pharmacy partners. Simply download th form. fill in the appropriate Information and fax to the designated number on the form or upload from the portal when requested for a patient! Contact KloudSCript for more information on the referral forms.
                     </p>
                   </div>
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectionLink clearfix mt20">
                      <span>Asthma <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a></span>
                      <span>Atopic Dermatitis <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a>  </span> 
                      <span>Behavioral Health <a href="#"onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                      <span>Endometriosis  <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                      <span>Hepatitis C Virus <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a>  </span>
                      <span>Human immunodeficiency Virus  <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a>  </span>
                      <span>hypercholesterolemia <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a></span>
                      <span>inflammatory bowel disease <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a></span>
                      <span>migraine   <a href="#" onClick={(event) => this.DownloadDocument(event ,"test.pdf")}><i className="fa fa-download right" aria-hidden="true"></i></a></span>
            
                   </div>
                   </div>
                   <div className="col-xs-12 col-sm-4 col-md-3 col-lg-4">
                   <div className="formSectionTitle clearfix mt20"><h3>Prior Authorization Guidelines</h3></div>
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectioncontent ">
                   <p>
                     Sends us your referral with ease and confidence! PA Guidelines will help guide you through the Prescribing process by outlining the necessary Statement of Medical Necessity requirements needed for an authorizaiton.
                     </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 formSectionLink clearfix mt20">
                    <span>Atopic Dermatitis PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>Asthma PA Guidelines <a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>Behavioral Health PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>Hepatitis C Virus PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>migraine PA Guidelines <a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>inflammatory bowel disease PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>hypercholesterolemia<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>Psoriasis PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    <span>rheumatology PA Guidelines<a href="#"  onClick={(event) => this.DownloadDocument(event ,"test.pdf")}> <i className="fa fa-download right" aria-hidden="true"></i></a> </span>
                    
                   </div>
                   </div>
                </div>
            </div>
              </main>
              <Footer />
            </div>
          </div>
        );
    }
}

export default Forms;