import React from 'react';

export default (props) => {
    return (
        <button id={props.id} disabled={props.disabled}name={props.btnName} className={props.className} onClick={(event) => props.click(event,props.secondParams)}>
             {props.btnName}
        </button>
    );
}

 