import React , { Component } from 'react';
import * as Constant from '../../CustomUI/Constant';
import UserProfile from '../../../UserProfile';

export default class fixsidebar extends Component {

    getRoleBasedRights(){
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
            return(
                <div>
                     <ul className="list-group-item-text">
                        <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myProfilePrescriber"}> My Profile</a></li>
                        <li><a href={Constant.FRONTEND_CONTEXT_PATH+"mylocations"}> My Practice Locations</a></li>
                        <li><a href={Constant.FRONTEND_CONTEXT_PATH+"representative"}> My Agents/ Representatives</a></li>
                    </ul>
                </div>
            );
        }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            //Office Admin
            if(UserProfile.getOfficeAdmin() == true){
                return(
                    <div>
                       <ul className="list-group-item-text">
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myProfileAgent"}> My Profile</a></li>
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myPhysicianLocations"}> My Physician Practice Locations</a></li>
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myphysician"}> My Physician</a></li>
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"officeAdminRepresentative"}> My Physician’s Agents / Representatives</a></li>
                        </ul>
                    </div>
                );
            }else{
                return(

                    <div>
                         <ul className="list-group-item-text">
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myProfileAgent"}> My Profile</a></li>
                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"myphysician"}> My Physician</a></li>
                        </ul>
                    </div>
                );
            }
        }
    }



    render(){
            return(
                <div>

                    <div className="fixed-sidebar-left side-nav fixed sidebar-collapsed scrollbar" >
                        <ul id="sidebar-nav">
                            <li className="logo"> <a id="logo-container" href={Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"workqueue"}  className="brand-logo"> <img id="front-page-logo" className="hidden" src="images/new-logo.png" alt="logo"/> <img id="front-page-logo-ks" src="images/KS_logo.png"  className="logo-ks" alt="logo"/> </a> </li>
                            <li>
                            <ul className="collapsible collapsible-accordion hidden" id="accordion">
                                {(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN && UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ? ( <li className="active main-menu-title"> <a className="collapsible-header waves-effect main-menu-header" data-toggle="collapse" data-parent="#accordion" href="#firstLink" aria-expanded="true">  <img src="images/user.png"/> <span className="sidebar-label">{UserProfile.getName()}<i className="fa fa-angle-right rotate-icon"></i> </span> </a>
                                    {/*<div className="collapsible-body main-menu-body">*/}
                                    <div className="collapsible-body">
                                            {this.getRoleBasedRights()}
                                    </div>
                                </li>) : null}
                                <li className="active main-menu-title"> <a className="collapsible-header waves-effect main-menu-header" data-toggle="collapse" data-parent="#accordion" href="#secondLink" aria-expanded="true"> <img src="images/home.png"/> <span className="sidebar-label"> Home <i className="fa fa-angle-right rotate-icon"></i> </span> </a>
                                    <div className="collapsible-body" >
                                        <ul id="secondLink" className="collapse in" aria-expanded="true">
                                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"workqueue"}>Status Queue</a></li>
                                            {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"manageuser" }>User Management</a></li> ) : null }
                                            {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"register"} target="_blank">Create Prescriber/Agent</a></li> ) : null }
                                            {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN ) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"auditLog" }>Audit Log</a></li> ) : null }
                                            {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+"formsSection"} >Forms & Consents</a></li> */}
                                        </ul>
                                    </div>
                                </li>
                                <li className="active main-menu-title"> <a className="collapsible-header waves-effect main-menu-header" data-toggle="collapse" data-parent="#accordion" href="#thirdLink" aria-expanded="true"> <img src="images/resource.png"/> <span className="sidebar-label"> Resources <i className="fa fa-angle-right rotate-icon"></i> </span> </a>
                                    <div className="collapsible-body" >
                                        <ul id="thirdLink" className="collapse in" aria-expanded="true">
                                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"trainingVideo"}>Training</a></li>
                                            {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+""}>Marketing Materials</a></li> */}
                                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"PriorAuthorization"}>Prior Authorization Guidelines</a></li>
                                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"EnrollmentForms"}>Manufacturer Enrollment Forms</a></li>
                                            <li><a href={Constant.FRONTEND_CONTEXT_PATH+"EnrollmentForms_KS"}>KloudScript Enrollment Forms</a></li>
                                            {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+"PrescriberPatientConsents"}>Prescriber & Patient Consents</a></li> */}
                                        </ul>
                                    </div>
                                </li>

                            </ul>
                            </li>
                        </ul>



                        <div id="accordion">
                            <div className="panel list-group">
                                <a href="#web_dev" data-parent="#accordion" data-toggle="collapse" className="list-group-item collapsible-header waves-effect main-menu-header" aria-expanded="true">
                                    <h4><img src="images/user.png"/><span className="sidebar-label">{UserProfile.getName()}<i className="fa fa-angle-right rotate-icon"></i></span></h4>                                    
                                </a>
                            <div className="collapse in" id="web_dev" aria-expanded="true">
                                {this.getRoleBasedRights()}
                            </div>

                            <a href="#desktop" data-parent="#accordion" data-toggle="collapse" className="list-group-item collapsible-header waves-effect main-menu-header" aria-expanded="true">
                                <h4><img src="images/home.png"/> <span className="sidebar-label"> Home <i className="fa fa-angle-right rotate-icon"></i> </span></h4>
                            </a>
                            <div className="collapse in" id="desktop" aria-expanded="true">
                                <ul className="list-group-item-text">
                                <li><a href={Constant.FRONTEND_CONTEXT_PATH+"workqueue"}>Status Queue</a></li>
                                        {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"manageuser" }>User Management</a></li> ) : null }
                                        {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"register"} target="_blank">Create Prescriber/Agent</a></li> ) : null }
                                        {(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN ) ? (<li><a href={Constant.FRONTEND_CONTEXT_PATH+"auditLog" }>Audit Log</a></li> ) : null }
                                        {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+"formsSection"} >Forms & Consents</a></li> */}
                                </ul>
                            </div>


                            <a href="#mobile" data-parent="#accordion" data-toggle="collapse"  className="list-group-item collapsible-header waves-effect main-menu-header" aria-expanded="true">
                                <h4><img src="images/resource.png"/> <span className="sidebar-label"> Resources <i className="fa fa-angle-right rotate-icon"></i> </span></h4>
                            </a>
                            <div className="collapse in" id="mobile" aria-expanded="true">
                                <ul className="list-group-item-text">
                                <li><a href={Constant.FRONTEND_CONTEXT_PATH+"trainingVideo"}>Training</a></li>
                                        {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+""}>Marketing Materials</a></li> */}
                                        <li><a href={Constant.FRONTEND_SERVER_URL+"/ViewForms?maintype=priorAuthorization"}>Prior Authorization Guidelines</a></li>
                                        <li><a href={Constant.FRONTEND_SERVER_URL+"/ViewForms?maintype=EnrollmentForms"}>Manufacturer Enrollment Forms</a></li>
                                        <li><a href={Constant.FRONTEND_SERVER_URL+"/ViewForms?maintype=EnrollmentForms_KS"}>KloudScript Enrollment Forms</a></li>
                                        {/* <li><a href={Constant.FRONTEND_CONTEXT_PATH+"PrescriberPatientConsents"}>Prescriber & Patient Consents</a></li> */}
                                </ul>
                            </div>

                            {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER?
                            <div>
                                <a href="#MDM" data-parent="#accordion" data-toggle="collapse"  className="user-profile-secure list-group-item collapsible-header waves-effect main-menu-header" aria-expanded="true">
                                    <h4><img src="images/user-profile-secure-icon.png"/> <span className="sidebar-label">  Master Data Management <i className="fa fa-angle-right rotate-icon"></i> </span></h4>
                                </a>
                                <div className="collapse in" id="MDM" aria-expanded="true">
                                    <ul className="list-group-item-text">
                                        <li><a href={Constant.FRONTEND_CONTEXT_PATH+"UploadForms"}>Upload Forms</a></li> 
                                    </ul>
                                </div> 
                            </div>
                            : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
}

