import React,{ Component } from 'react';
import ForgotPasswordPage from '../../Component/LoginPage/LoginBox/LoginBox';
import * as Constant from '../../Component/CustomUI/Constant';
import axios from 'axios';
import  { Redirect } from 'react-router-dom';
import $ from 'jquery';
import Footer from '../../Component/PageComponent/Footer/Footer';

class ForgotPassword extends Component {
    state = {

        stepOneData:{
            stepOneDataValid:false,
            stepOneUserIsValid:false,
            stepOneDataError:''
        },
        timer:{
            count: 0,
            running: false,
          },
        stepTwoData:{
        },
        stepThreeData:{
            number:''
        },
        stepFourData:{},
        disable:true,
        hideTimer:false,
        securityQueList:'',
        securityQueId:'',
        questionName:'',
        intName:'',
        questionAnswer:'',
        forgotPassword :false,
        item : '',
        hidePasswordValidMessage:false,
        role:"",
        isAnswar:false,
        stepFiveValid:{
            passwordValid:false,
            reNewPasswordValid:false
        }
    }

    change =(event,step)=> {

        let name = event.target.name
        let value =event.target.value
        if(step === Constant.STEP_ONE){
            this.setState({stepOneData:{...this.stepOneData,[name]:value}})
        }
        if(step === Constant.STEP_TWO){
            this.setState({stepTwoData:{...this.state.stepTwoData,[name]:value}})

        }
        if(step === Constant.STEP_THREE){
            this.setState({questionAnswer:value})

        }
        if(step === Constant.STEP_FOUR){
            this.setState({stepFourData:{...this.state.stepFourData,[name]:value}})
        }

    }

    startTimer = () => {
        this.setState({disable:false})
        let resetPasswordMethod = "EMAIL"
        let userName = this.state.stepOneData.userName
        // this.setState({disable:false})
        // const strSeconds=strSeconds?'':value.toString();
        // if(strSeconds.match(/[0-9]/)) {       
        //     this.setState({hideTimer:true})
        //     this.handleCountdown(parseInt(strSeconds, 10));

        // }  
        axios.get('/forgotPassword/step2/OTP?username='+encodeURIComponent(userName)+'&resetPasswordMethod='+encodeURIComponent(resetPasswordMethod))
        .then(response => {
            window.Materialize.toast(response.data, 3000, 'green rounded')
            this.setState({role:response.data.role})
            const div = document.getElementById('inputs');
            div.classList.add("shiftField");
          
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    handleCountdown = (seconds) => {
        this.setState({timer:{
            count: seconds,
            running: true}
        })
    }

    format= (time) =>{
        let seconds = time % 60;
        let minutes = Math.floor(time / 60);
        minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
        seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        return minutes + ':' + seconds;
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.state.timer.running){
            this.handleStart();
        }
    }

    handleStart =() => {
        this.timer = setInterval(() => {
        const newCount = this.state.timer.count - 1;
        this.setState({timer: {count: newCount >= 0 ? newCount : 0}});
        if(newCount === 0){
            this.handleStop()
        }
        }, 1000);


    }

    handleStop() {
        if(this.timer) {
          clearInterval(this.timer);
          this.setState({timer:{
            running: false}
          })
          this.setState({disable:true})
          this.setState({hideTimer:false})

      }
    }

    clickHandler = (event,step) => {
        event.preventDefault();
        const name = event.target.name
        const div = document.getElementById('inputs');
        const backDiv = document.getElementById('back');

        if(step === Constant.STEP_ONE) {
            if(name === "Next"){
                if(this.state.stepOneData.userName){
                        return this.validateStepOne()
                }else{
                    return this.validateStepOneForm()
                }
            }
        } else if(step === Constant.STEP_TWO) {
            if(name === "Next"){
                 this.validateStepTwoForm();
             }
        }else if(step === Constant.STEP_THREE || event.target.name === "question") {
             if(name === "Next" ||  event.target.name === "question"){
                 this.validateStepThreeForm()
             }
        }else if(step === Constant.STEP_FOUR || event.target.name === "rePassword" || event.target.name === "Password" ){
            if(name === "Finish" || event.target.name === "rePassword" || event.target.name === "Password"){
                this.validateStepFourForm()
            }
         }
        else {
              div.classList.remove("shiftField");
             backDiv.classList.remove("activeBack");
        }
    }

    validateStepOne = () =>{
        let stepOneData = {...this.state.stepOneData}

        axios.get('/forgotPassword/step1/userName?username='+encodeURIComponent(stepOneData.userName))
        .then(response => {
            if(response.data.success === Constant.USER_VERIFY_SUCCESS){
                this.startTimer()  
            }else{
                if(response.data.search("ProntoRx") !== -1) {
                    response.data = response.data.replace("ProntoRx","ProntoRx\u2122");
                }
                if(response.data.search("KETU") !== -1) {
                    response.data = response.data.replace("KETU","KETU\u2122");
                }
                this.setState({stepOneData:{stepOneUserIsValid:true}})
                window.Materialize.toast(response.data, 3000, 'red rounded')
                this.setState({stepOneData:{stepOneDataError:response.data}})

            }
        })
        .catch(function (error) {
            console.log(error);
            return false;
         });
         $("#userName").val('');

    }


    validateStepOneForm = () =>{

           let stepOneData ={...this.state.stepOneData}

            if(!stepOneData.userName){
                this.setState({stepOneData:{stepOneDataValid:true}})
                window.Materialize.toast(Constant.USER_NAME, 3000, 'red rounded')

            }else if(stepOneData.userName){
                return this.validateStepOne()

            }

    }

    validateStepTwoForm =() =>{
        let stepTwoData = {...this.state.stepTwoData}

        if(!stepTwoData.temporary_password){
            window.Materialize.toast("Please enter temporary password", 3000, 'red rounded')

        }
        if(stepTwoData.temporary_password){
            let  username = this.state.stepOneData.userName
            let  tempPassword = this.state.stepTwoData.temporary_password 
                axios.get('/forgotPassword/step2/verifyTempPass?username='+encodeURIComponent(username)+'&tempPassword='+encodeURIComponent(tempPassword)+'')
                .then(response => {

                               if(response.data.isSuccess){

                                this.setState({securityQueList:''})
                                this.setState({securityQueList:response.data.secData})

                                let data = this.state.securityQueList
                                let item = data[Math.floor(Math.random()*data.length)];
                                this.setState({item : item})
                                let answer = "answer"+item.questionId
                                  this.setState({questionName:item.question})
                                  this.setState({intName:answer})

                                const div = document.getElementById('inputs');
                                div.classList.remove("shiftField");
                                div.classList.add("shiftField1");

                            }else{
                                window.Materialize.toast(response.data.message, 3000, 'red rounded')
                            }

            })
            .catch(function (error) {
                console.log(error);
            });
        }

    }

    // TO DO
    validateStepThreeForm = () =>{
        let answer = $("#answer").val();

            if(!answer){
                window.Materialize.toast("Please enter answer", 3000, 'red rounded')
            }else{
                let queystring =''
                queystring += '?username='+encodeURIComponent(this.state.stepOneData.userName)
                queystring += '&answer='+encodeURIComponent(answer)
                queystring += '&questionId='+this.state.item.questionId

                axios.get('/forgotPassword/step3/verifySecQue'+queystring)
                .then(response => {
                    this.setState({isAnswar:response.data})
                    this.switchSecurityQuestion();
                })
                .catch(function (error) {
                    console.log(error);
                });
            }


    }

    switchSecurityQuestion = () => {

        const div = document.getElementById('inputs');

        if(this.state.isAnswar){
            div.classList.remove("shiftField1");
            div.classList.add("shiftField2");
       }else{

           $("#answer").val("");
           let data = this.state.securityQueList

           if(data.length > 1){
                window.Materialize.toast("Invalid Answer", 3000, 'red rounded')
                var removeIndex = data.map(function(itemData) { return itemData.questionId; }).indexOf(this.state.item.questionId);
                data.splice(removeIndex, 1);
                let item = data[Math.floor(Math.random()*data.length)];
                this.setState({item : item})
                let answer = "answer"+item.questionId
                this.setState({questionName:item.question})
                this.setState({intName:answer})
           }else{
                    let  username = this.state.stepOneData.userName
                    axios.get('/forgotPassword/step3/userInactive?username='+encodeURIComponent(username)+'')
                    .then(response => {
                        this.setState({forgotPassword:true})
                        if(response.data.search("ProntoRx") !== -1) {
                            response.data = response.data.replace("ProntoRx","ProntoRx\u2122");
                        }
                        window.Materialize.toast(response.data, 15000, 'red rounded')
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

           }

       }
    }

    validateStepFourForm = () =>{
        let isValid = true
        let reNewPassword = $("#reNewPassword").val();
        let newPassword = $("#newPassword").val();
        let blankValid = false

        if(!newPassword){
            isValid = false  
            blankValid = true
            this.setState({stepFiveValid:{passwordValid:true}})
        }
        if(!reNewPassword){
            isValid = false  
            blankValid = true
            this.setState({stepFiveValid:{reNewPasswordValid:true}})
        }

        if(blankValid){
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000, 'red rounded') 
         }

        if(newPassword !== reNewPassword && newPassword.length >= 9 && reNewPassword.length >= 9){
            isValid = false
            this.setState({stepFiveValid:{reNewPasswordValid:true}})
            window.Materialize.toast(Constant.CONFIRM_PASSWORD_INVALID, 3000, 'red rounded') 
           

        }
        let passwordIsValid = true
         
        if(newPassword.match(Constant.WRONG_PASSWORD_FORMATE) == null && newPassword ){
            isValid = false
            this.setState({stepFiveValid:{passwordValid:true}})
            passwordIsValid = false    
        }

        if(reNewPassword.match(Constant.WRONG_PASSWORD_FORMATE) == null && reNewPassword ){
            isValid = false
            this.setState({stepFiveValid:{reNewPasswordValid:true}})
            passwordIsValid = false
        }

        if(!passwordIsValid){
            window.Materialize.toast(Constant.INVALID_PASSWORD_FORMATE, 3000, 'red rounded') 
        }

        
        if(isValid){
            let  username = this.state.stepOneData.userName
            let   password = this.state.stepFourData.Password?this.state.stepFourData.Password:''
          
                axios.post('/forgotPassword/step4/password?username='+encodeURIComponent(username)+'&password='+encodeURIComponent(password)+'')
                .then(response => {
                    window.Materialize.toast(response.data, 3000, 'green rounded')
                    this.setState({forgotPassword:true})
                })
                .catch(function (error) {
                    console.log(error);
                });
           
        }
       
        
     
    return isValid

    }

    render(){

        if (this.state.forgotPassword) {
            return <Redirect to='/' />


        }

        return (
            <body className="loginPage loginPage v2-back">
                <div className="wrapper">

                    {/*<LogoAndTagline />*/}
                    <ForgotPasswordPage
                     name="ForgotPassword"
                     clickHandler={this.clickHandler}
                     change = {this.change}
                     time = {this.state.timer.count}
                     running ={this.state.timer.running}
                     format ={this.format}
                     disable={this.state.disable}
                     hideTimer = {this.state.hideTimer}
                     questionName ={this.state.questionName}
                     intName={this.state.intName}
                     stepOneDataValid ={this.state.stepOneData.stepOneDataValid}
                     stepOneDataError = {this.state.stepOneData.stepOneDataError}
                     stepOneUserIsValid= {this.state.stepOneData.stepOneUserIsValid}
                     hidePasswordValidMessage= {this.state.hidePasswordValidMessage}
                     role = {this.state.role}
                     stepFiveValid = {this.state.stepFiveValid}
                     />
                    {/* <KeyPointSection />*/}

                    <Footer />
                </div>
            </body>
        );

    }

}

export default ForgotPassword;

