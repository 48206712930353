import React, { Component } from 'react';
import * as Constant from '../../Component/CustomUI/Constant';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import UserProfile from '../../UserProfile';

class Resources extends Component {

     render(){
         return(
             <div>
                <div className="v2-back-inner">
                 <div className="wrapper slide-nav-toggle">
                    <Header h2Tag="Resources" />
                    <main>
                        <div className="max-1500">
                            <div className="row">
                                <div className="col-sm-4">
                                    <a href="/trainingVideo">
                                        <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-1.png"/>
                                            </div>
                                            <h3>Training</h3>
                                        </div>
                                    </a>
                                </div>
                                {/* <div className="col-sm-4">
                                    <a href="">
                                        <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-2.png"/>
                                            </div>
                                            <h3>Marketing Materials</h3>
                                        </div>
                                    </a>
                                </div> */}
                                <div className="col-sm-4">
                                    <a href="/ViewForms?maintype=priorAuthorization">                                    
                                       <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-3.png"/>
                                            </div>
                                            <h3>Prior Authorization Guidelines</h3>
                                        </div>
                                    </a>
                                </div>
                                {/* <div className="col-sm-4">
                                    <a href="">
                                        <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-4.png"/>
                                            </div>
                                            <h3>Prescriber & Patient Consents</h3>
                                        </div>
                                    </a>
                                </div> */}
                                <div className="col-sm-4">   
                                    <a href="/ViewForms?maintype=EnrollmentForms_KS">
                                       <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-5.png"/>
                                            </div>
                                            <h3>KloudScript Enrollment Forms</h3>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-sm-4">
                                    <a href="/ViewForms?maintype=EnrollmentForms">
                                       <div className="resourcebox">
                                            <div className="res-img-box">
                                                <img src="images/res-6.png"/>
                                            </div>
                                            <h3>Manufacturer Enrollment Forms</h3>
                                        </div>
                                    </a>
                                </div>
                                {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER?
                                    <div className="col-sm-4">
                                    <a href="/UploadForms">
                                        <div className="resourcebox">
                                            <div className="res-img-box">
                                            <i class="fa fa-upload"></i>
                                            </div>
                                            <h3>Upload Forms</h3>
                                        </div>
                                    </a>
                                </div> : null}                                                              
                            </div>

                        </div>
                    </main>
                <Footer />
            </div>
        </div>
    </div>

         );
     }
}

export default Resources;