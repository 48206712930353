import React, { Component } from 'react';
import Footer from '../../Component/PageComponent/Footer/Footer';
import LoginTop from '../../Component/LoginPage/LoginTop/LoginTop';
import StepTwoKsUser from '../../Component/Register/StepTwo/StepTwoKsUser';
import axios from 'axios';
import * as Constant from '../../Component/CustomUI/Constant';
import $ from 'jquery';
import UserProfile from '../../UserProfile';

let prescriberJson = []
let selectedPrescriberJson = []
let DuplicateUserName=false
let count=0

export default class TechAdminRegister extends Component {

    constructor(props) {

        super(props)

        this.state = {
            resetPasswordMethod: "EMAIL",
            stateTest: true,
            isUpdatedData: false,
            isHiddenMd: false,
            isHiddenAgent: false,
            isRole: "",
            stepOneData: {},
            stepTwoPrescriberData: {
                whoIsA: ''

            },
            document: [],
            stepTwoAgentData: {},
            StepFourPrescriberData: {},
            StepFourAgentData: {},
            stepFiveData: {},
            roleTitleList: [],
            securityQuestion: [],
            buttonProp: {
                step: '',
                name: ''
            },
            stepFourPrescriberValidate: {
                firstNameValid: false,
                lastNameValid: false,
                addressLine1Valid: false,
                cityValid: false,
                isStateValid: false,
                zipValid: false,
                workphoneValid: false,
                taxonomyCodeValid: false,
                emailValid: false,
                confEmailValid: false,
                userNameValid:false,
                isUserNameAvailable: false,
                duplicateUserNameValid:false
            },
            stepFourAgentValidate: {
                firstNameValid: false,
                lastNameValid: false,
                emailValid: false,
                confEmailValid: false,
                userNameValid:false,
                isUserNameAvailable: false,
                duplicateUserNameValid:false
            },
            stepTwoValidate: {
                npiValid: false,
                stateValid: false,
                agentFNSearchValid: false,
                agentLNSearchValid: false,
                imaValid: false,
                whoIsAValid: false
            },
            prescriberApiData: [],
            associatedData: [],
            isDisabled: false,
            isShowButton:false,
            isUserNameAvailable: false,
            isEmailAvailable:false,
            isValid: true,
            isNpiAlreadyAdded: false,
            practiceLocationsData: [],
            taxonomiesData: [],
            isLoading: false,
            isFromKsUserUpdate : false,
            isStepFiveValidate:false,
            isStepThreeEmailValid:false,
            isStepThreeValidate:false,
            isKsUser:false,
            loginSuccess: false,
            isNpiRegistryDown:false
        }
    }

    removeDocument = (event, row) => {
        let documents = this.state.document;
        documents.splice(row.id, 1);
        this.setState({ document: documents });
    }

    isEmptyString = (stringInput) => {
        if (stringInput === "" || stringInput === null || stringInput === "null") {
            return true;
        }
        return false;
    }

    componentDidMount = () =>{
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            this.setState({isKsUser:true})
           
        }

    }

    change = (event, step) => {

        const name = event.target.name
        const value = event.target.value
        event.preventDefault();
        let changeRole = false;
        if (name === Constant.ROLE_TYPE && value === '') {
            this.setState({ isRole: "" })
            this.setState({ isHiddenMd: false, isHiddenAgent: false ,isShowButton:false })
            this.setState({ document: [] })
        }else if (name === Constant.ROLE_TYPE && value === Constant.ROLE_PRESCRIBER) {
            this.setState({ isHiddenMd: true, isHiddenAgent: false ,isShowButton:false})
            this.setState({ isRole: value })
            this.setState({ stepTwoPrescriberData: { ...this.state.stepTwoPrescriberData, [name]: value } })
            this.setState({ stepTwoAgentData: {} })
            this.setState({ stepTwoValidate: { whoIsAValid: false } })
            this.setState({ StepFourAgentData: {} })
            this.setState({
                stepFourAgentValidate: {
                    firstNameValid: false, lastNameValid: false,
                    emailValid: false, confEmailValid: false
                }
            })
            this.setState({ stepFiveData: {} })
            $('#userNamelogin').val("");
            $('#passwordlogin').val("");
            $('#confirmPasswordlogin').val("");
            $('#securityQuestion1').val("");
            $('#securityQuestion2').val("");
            $('#securityQuestion3').val("");
            $('#securityAnswer1').val("");
            $('#securityAnswer2').val("");
            $('#securityAnswer3').val("");
            this.setState({
                stepFiveValidate: {
                    userNameValid: false, passwordValid: false,
                    confirmPasswordValid: false, securityQuestion1Valid: false,
                    securityQuestion2Valid: false, securityQuestion3Valid: false, securityAnswer1Valid: false,
                    securityAnswer2Valid: false, securityAnswer3Valid: false,
                    isAnswerDuplicate: false, isQuestionDuplicate: false
                }
            })
            changeRole = true;
            prescriberJson = [];
            selectedPrescriberJson = [];
        }else if (name === Constant.ROLE_TYPE && value === Constant.ROLE_AGENT) {
            this.setState({ isHiddenAgent: true, isHiddenMd: false ,isShowButton:false})
            this.setState({ stepTwoAgentData: { ...this.state.stepTwoAgentData, [name]: value } })
            this.setState({
                stepTwoPrescriberData: {}, stepTwoValidate: {
                    npiValid: false,
                    stateValid: false,
                    whoIsAValid: false,
                    agentFNSearchValid: false,
                    agentLNSearchValid: false
                }
            })
            this.setState({ document: [] })
            this.setState({ stepTwoValidate: { whoIsAValid: false } })
            this.setState({ isRole: value })
            this.setState({ StepFourPrescriberData: {} })
            this.setState({
                stepFourPrescriberValidate: {
                    firstNameValid: false, lastNameValid: false,
                    addressLine1Valid: false, cityValid: false, isStateValid: false, zipValid: false,
                    workphoneValid: false, taxonomyCodeValid: false, emailValid: false, confEmailValid: false
                }
            })
            this.setState({ stepFiveData: {} })
            $('#userNamelogin').val("");
            $('#passwordlogin').val("");
            $('#confirmPasswordlogin').val("");
            $('#securityQuestion1').val("");
            $('#securityQuestion2').val("");
            $('#securityQuestion3').val("");
            $('#securityAnswer1').val("");
            $('#securityAnswer2').val("");
            $('#securityAnswer3').val("");
            this.setState({
                stepFiveValidate: {
                    userNameValid: false, passwordValid: false,
                    confirmPasswordValid: false, securityQuestion1Valid: false,
                    securityQuestion2Valid: false, securityQuestion3Valid: false, securityAnswer1Valid: false,
                    securityAnswer2Valid: false, securityAnswer3Valid: false,
                    isAnswerDuplicate: false, isQuestionDuplicate: false
                }
            })

            changeRole = true;
        }else if (step === Constant.STEP_TWO_PRESCRIBER){
            this.setState({ stepTwoPrescriberData: { ...this.state.stepTwoPrescriberData, [name]: value } });
            let isAlreadyExits =  false
            if (name === Constant.INPUT_FILE && value) {
                for(let i=0;i<this.state.document.length;i++){
                              let alreadySelectedDocument = this.state.document[i]?this.state.document[i].name:''
                              let newSelectedDocument = event.target.files?event.target.files[0].name:''
                              if(alreadySelectedDocument === newSelectedDocument){
                                  isAlreadyExits = true
                              }
                }
                if (this.state.document.length > 9) {
                    window.Materialize.toast(Constant.MAX_DOCUMENTS_SELECTED, 3000, 'red rounded')
                } else {
                    if(!isAlreadyExits){
                       try{
                            this.setState({ document: [...this.state.document,...event.target.files] });
                       }catch(error){
                            console.log("not supported in browser",error)
                       }

                    }
                }
            }
        }else if (step === Constant.STEP_TWO_AGENT) {
            this.setState({ stepTwoAgentData: { ...this.state.stepTwoAgentData, [name]: value } })
        }else if (step === Constant.STEP_FOUR_PRESCRIBER) {
            this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, [name]: value } })
        }else if (step === Constant.STEP_FOUR_AGENT) {
            this.setState({ StepFourAgentData: { ...this.state.StepFourAgentData, [name]: value } })
        }else if (step === Constant.STEP_FIVE) {
            this.setState({ stepFiveData: { ...this.state.stepFiveData, [name]: value } })
        }

        if (changeRole) {
            axios.get('/registration/renderRoleTitle/' + value)
                .then(response => {
                    this.setState({ roleTitleList: response.data })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        return true
    }

    getPrescriberApiData = (event,name) => {
        
        // var instance = axios.create({
        //     baseURL: Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH,
        //     timeout: 1000
        // });
        let npi
        if(name==="keyup"){
            this.setState({isShowButton:false})
            npi = event.target.value.trim()
            
        }else{
            npi= event.clipboardData.getData('text').trim()   
        }
        if (npi.length === Constant.TEN && Constant.REGEX_DIGIT.test(npi)) {
            count=0
            this.setState({ isNpiAlreadyAdded: false, isLoading: true ,isShowButton:false})
            //document.getElementById("Step2Next").disabled = true;

            axios.get('/registration/prescriber/npi?number=' + npi.trim())
                .then(response => {
                    this.setState({ prescriberApiData: [], isLoading: false , isShowButton:true })
                  //  document.getElementById("Step2Next").disabled = false;
                    if (response.data) {
					this.setState({isNpiRegistryDown:false})
					if(response.data.result_count == 0){
                        this.setState({isShowButton:false})
                        window.Materialize.toast(Constant.NPI_NOT_FOUND, 3000, 'red rounded')
                        
					}else{	
                        if (!response.data.isNpiAlreadyAdded) {
                            if (!response.data.basic.middle_name) {
                                $('#mNameAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["middleName"]: "" } })
                            }
                            if (!response.data.addresses[0].address_2) {
                                $('#address2AboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["addressLine2"]: "" } })
                            }
                            if (!response.data.addresses[0].fax_number) {
                                $('#fax1PhoneAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["faxOne"]: "" } })
                            }
                            if (!response.data.basic.first_name) {
                                $('#fNameAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["firstName"]: "" } })
                            }
                            if (!response.data.basic.last_name) {
                                $('#lNameAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["lastName"]: "" } })
                            }
                            if (!response.data.addresses[0].address_1) {
                                $('#address1AboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["addressLine1"]: "" } })
                            }
                            if (!response.data.addresses[0].city) {
                                $('#cityAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["city"]: "" } })
                            }
                            if (!response.data.addresses[0].state) {
                                $('#stateAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["state"]: "" } })
                            }
                            if (!response.data.addresses[0].postal_code) {
                                $('#zipAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["zip"]: "" } })
                            }
                            if (!response.data.addresses[0].telephone_number) {
                                $('#workPhoneAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["workphone"]: "" } })
                            }
                            if (!response.data.addresses[0].fax_number) {
                                $('#fax1PhoneAboutPrescriber').val("");
                                this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["faxOne"]: "" } })
                            } 
                            $('#emailAboutPrescriber').val("");
                            this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["email"]: "" } })
                            $('#confirmEmailAboutPrescriber').val("");
                            this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, ["confirmEmail"]: "" } })
                            let prescriberData = response.data
                            this.setState({ prescriberApiData: [prescriberData] })
                        }else{
                                if(count===0){
                                    
                                    window.Materialize.toast(Constant.NPI_ALREADY_IN_SYSTEM, 3000, 'red rounded')
                                    count++
                                }
                                this.setState({ isNpiAlreadyAdded: true ,isShowButton:false });
                        }
					}	
				}else{
					 this.setState({isNpiRegistryDown:true,isShowButton:false})
					 window.Materialize.toast(Constant.NPI_API_DOWN, 3000, 'red rounded')
				}		 
                    this.setState({ isDisabled: true })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }else{
            this.setState({ isNpiAlreadyAdded: false });
            count=0
        }
    }

    workPhone = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (value.match(Constant.REGEX_DIGIT_MASK)) {
            value = value.replace(/-/g, "");
            let regex = /^([^\s]{3})([^\s]{3})([^\s]{4})$/g;
            let match = regex.exec(value);
            if (match) {
                match.shift();
                value = match.join("-")
                event.target.value = value
                value = event.target.value;
            }else {
                event.target.value = ""
                value = event.target.value;
            }
        }
        else {
            event.target.value = ""
            value = event.target.value;
        }
         this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, [name]: value } })
    }
    faxNumber = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (value.match(Constant.REGEX_DIGIT_MASK)) {
            value = value.replace(/-/g, "");
            let regex = /^([^\s]{3})([^\s]{3})([^\s]{4})$/g;
            let match = regex.exec(value);
            if (match) {
                match.shift();
                value = match.join("-")
                event.target.value = value
                value = event.target.value;
            }else {
                event.target.value = ""
                value = event.target.value;
            }
        }else {
            event.target.value = ""
            value = event.target.value;
        }
         this.setState({ StepFourPrescriberData: { ...this.state.StepFourPrescriberData, [name]: value } })
    }


    associatedDataListner = (event) => {
        prescriberJson = []
        let targetName = event.target.name;
        let agntFName = document.getElementById("agentFNSearch").value;
        let agntLName = document.getElementById("agentLNSearch").value;
        let agntNPI = document.getElementById("npiAgent").value;
        let username = UserProfile.getUserName();
        let check = "isFromRegistration"
        this.setState({ stepTwoValidate: { npiValid: false, agentFNSearchValid: false, agentLNSearchValid: false } })

            if ((this.isEmptyString(agntFName) && this.isEmptyString(agntLName)&&this.isEmptyString(agntNPI)) || (agntFName.trim().length == 0 && agntLName.trim().length == 0 && agntNPI.trim().length == 0)){
                        if (targetName === "agentAssociatePrescriberSearch" || targetName === undefined) {
                            window.Materialize.toast(Constant.SELECT_ANY_SEARCH_FIELD, 3000, 'red rounded')
                            this.setState({ stepTwoValidate: { npiValid: true, agentFNSearchValid: true, agentLNSearchValid: true } })
                        }
            }else if(((!this.isEmptyString(agntFName)) && (!agntFName.match(Constant.ALPHABET_SPACE))) ||((!this.isEmptyString(agntLName)) &&(!agntLName.match(Constant.ALPHABET_SPACE)))){
                        window.Materialize.toast("Please Enter Valid FirstName or LastName", 3000, 'red rounded')
            }else if (((agntNPI) && (agntNPI.length === Constant.TEN) && (agntNPI.match(Constant.REGEX_DIGIT))) || (agntFName || agntLName)) {

            let queryString = '';
            queryString += agntFName.trim() ? '?firstName=' + agntFName.trim() : ''
            queryString += (queryString != "") ? (agntLName.trim() ? '&lastName=' + agntLName .trim(): '') : (agntLName.trim() ? '?lastName=' + agntLName.trim() : '')
            queryString += (queryString != "") ? (agntNPI ? '&npiNumber=' + agntNPI : '') : (agntNPI ? '?npiNumber=' + agntNPI : '')
            queryString += (queryString != "") ? (username ? '&username=' + encodeURIComponent(username) : '') : ''
            queryString += (queryString != "") ? (check ? '&check=' + check : '') : ''
            axios.get('/registration/agent/associatedPrescribers' + queryString)
                .then(response => {
                    if(response.data.length === 0){
                      window.Materialize.toast(Constant.MD_NOT_FOUND, 3000, 'red rounded')                          
                    }
                    let phyDetail = []
                    prescriberJson = []

                    phyDetail.push(response.data);

                    let dataLength = phyDetail[0] ? phyDetail[0].length : 0
                    for (var i = 0; i < dataLength; i++) {
                        let facility = ''
                        let speciality=''
                        for (let k = 0; k < phyDetail[0][i].taxonomies.length; k++) {
                            if ((k) == (phyDetail[0][i].taxonomies.length - 1)) {
                                speciality = speciality + phyDetail[0][i].taxonomies[k].desc
                            } else if (phyDetail[0][i].taxonomies[k].desc !== '') {
                                speciality = speciality + phyDetail[0][i].taxonomies[k].desc + ','
                            }
                        }
                        if (phyDetail[0][i].practiceLocations.length != 0) {
                            for (let k = 0; k < phyDetail[0][i].practiceLocations.length; k++) {
                                if ((k) == (phyDetail[0][i].practiceLocations.length - 1)) {
                                    facility = facility + phyDetail[0][i].practiceLocations[k].facility
                                }
                                else if (phyDetail[0][i].practiceLocations[k].facility !== '') {
                                    facility = facility + phyDetail[0][i].practiceLocations[k].facility + ','
                                }
                            }
                        }

                        let innerJson = { index: phyDetail[0][i].id, NPI: phyDetail[0][i].npi, PrescriberName: phyDetail[0][i].firstName + " " + phyDetail[0][i].lastName, FacilityName: facility, NPIStatus: phyDetail[0][i].npiStatus, Speciality: speciality, WorkPhone: phyDetail[0][i].contact[0] ? phyDetail[0][i].contact[0].contactValue : '', Fax: phyDetail[0][i].contact[2] ? phyDetail[0][i].contact[2].contactValue : '' }
                        let findExistingPrescriberIndex = selectedPrescriberJson.findIndex(e => e.index == phyDetail[0][i].id)
                        if (findExistingPrescriberIndex > -1) {
                            innerJson['alreadySelected'] = true
                        } else {
                            innerJson['alreadySelected'] = false
                        }
                        prescriberJson.push(innerJson)
                    }
                    prescriberJson = [...new Set(prescriberJson)];
                    this.setState({ stateTest: false });
                })
                .catch(function (error) {
                    console.log(error);
                });
            }else if (agntNPI) {
                if ((agntNPI.length !== Constant.TEN) || !(agntNPI.match(Constant.REGEX_DIGIT))) {
                    window.Materialize.toast(Constant.NPI_VALIDATION, 3000, 'red rounded')
                    this.setState({
                        stepTwoValidate: {
                            npiValid: true
                        }
                    })
                }
            }
        }

    changePrescriberListner = (event, row, isSelected) => {
        //let removedIndex = -1;
        let alreadySelectedIndex = -1;
        if (!isSelected) {
            alreadySelectedIndex = selectedPrescriberJson.findIndex(e => e.index == row.index);
            if (alreadySelectedIndex == -1) {
                //removedIndex = prescriberJson.findIndex(e => e.index == row.index);
                selectedPrescriberJson.push(row);
                selectedPrescriberJson.sort(function (a, b) {
                    return a.index - b.index;
                }
                );
            }
        }
        // else {
        //     removedIndex = selectedPrescriberJson.findIndex(e => e.index == row.index);
        // }
        this.setState({ stateTest: false });
    }

    formSubmitHandler = (event) => {

        let stepFive = { ...this.state.stepFiveData }
        let securityQuestion = [];
        let userName = ''
        let password = ''
        if(this.state.isKsUser){
            securityQuestion = [];
            userName = $("#userNameloginstep4").val()
        }else{
            securityQuestion.push({ question_id: stepFive.securityQuestion1, answer: $("#securityAnswer1").val() });
            securityQuestion.push({ question_id: stepFive.securityQuestion2, answer: $("#securityAnswer2").val() });
            securityQuestion.push({ question_id: stepFive.securityQuestion3, answer: $("#securityAnswer3").val() });
            userName = stepFive.userName
            password = stepFive.password
        }

        let roles = [];

        if (this.state.isRole === Constant.ROLE_PRESCRIBER) {

            let stepTwo = { ...this.state.stepTwoPrescriberData }
            let resetPasswordMethod = this.state.resetPasswordMethod
            let stepFour = { ...this.state.StepFourPrescriberData }
            let stepTwoDocument = this.state.document
            let npiApiData = { ...this.state.prescriberApiData }
            let practiceLocations = this.state.practiceLocationsData
            let taxonomies = this.state.taxonomiesData
            let stateLicence = npiApiData[0].identifiers
            let stateLicenseList = []
            if(stepFour.prefix==""||stepFour.prefix==undefined){
                stepFour.prefix="DR."
            }

            for (let i = 0; i < stateLicence.length; i++) {
                let innerJson = { "licenseType": "STATE_LICENSE", "licenseValue": stateLicence[i].identifier, "description": stateLicence[i].desc, "state": stateLicence[i].state,"state_full_name": stateLicence[i].state_full_name };
                stateLicenseList.push(innerJson);
            }
            let address = [];
            address.push({ address1: stepFour.addressLine1, address2: stepFour.addressLine2, state: stepFour.state, city: stepFour.city, zip: stepFour.zip,state_full_name: stepFour.state_full_name })
            let contact = [];
            if (stepFour.workphone) contact.push({ contactType: "PHONE", contactValue: stepFour.workphone, fromApi:stepFour.phoneFromApi, isPreferred:stepFour.phonePrefered })
            if (stepFour.email) contact.push({ contactType: "EMAIL", contactValue: stepFour.email, fromApi:true, isPreferred:true })
            if (stepFour.faxOne) contact.push({ contactType: "FAX", contactValue: stepFour.faxOne, fromApi:stepFour.faxFromApi, isPreferred:stepFour.faxPrefered })

            roles.push(stepTwo.ima)

            if (stepTwoDocument.length > 0) {
                let formData = new FormData();
                for (var i = 0; i < stepTwoDocument.length; i++) {
                    formData.append("file", stepTwoDocument[i]);
                }

                formData.append('userData', new Blob([JSON.stringify({
                    roleIds: roles, roleTitleId: stepTwo.whoIsA,
                    npi: stepTwo.npi, statelicenseId: stepTwo.statelicenseId,
                    prefix: stepFour.prefix, middleName: stepFour.middleName, firstName: stepFour.firstName,
                    lastName: stepFour.lastName, email: stepFour.email,
                    prescriber: {
                        prefix: stepFour.prefix, middleName: stepFour.middleName, firstName: stepFour.firstName,
                        lastName: stepFour.lastName, npi: stepTwo.npi, address: address, contact: contact, practiceLocations: practiceLocations,
                        taxonomies: taxonomies, license: stateLicenseList, npiStatus: stepFour.status
                    }, username:userName, password: password, resetPasswordMethod: resetPasswordMethod,
                    securityQuestion: securityQuestion
                })], {
                        type: "application/json; charset=utf-8"
                    }));

                var headers = {
                    'Content-Type': undefined,
                }
              axios.post('/registration/createPrescriber', formData, { headers: headers })
              .then(response => {

                  if(response.data){
                    this.setState({isLoading:!response.data})
                    if(this.state.isKsUser && !this.state.isLoading){ 
                        document.getElementById("Step2Next").disabled = true;
                        window.Materialize.toast("Registration Completed", 9000,  'green rounded')

                        setTimeout(function(){ window.location.href = UserProfile.getUrlName() }, 2000)
                       
                    }
                  }

                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                let prescriberJson = {
                    roleIds: roles, roleTitleId: stepTwo.whoIsA,
                    npi: stepTwo.npi, statelicenseId: stepTwo.statelicenseId,
                    prefix: stepFour.prefix, middleName: stepFour.middleName, firstName: stepFour.firstName,
                    lastName: stepFour.lastName, email: stepFour.email,
                    prescriber: {
                        prefix: stepFour.prefix, middleName: stepFour.middleName, firstName: stepFour.firstName,
                        lastName: stepFour.lastName, npi: stepTwo.npi, address: address, contact: contact, practiceLocations: practiceLocations,
                        taxonomies: taxonomies, license: stateLicenseList, npiStatus: stepFour.status,
                    }, username: userName, password: password, securityQuestion: securityQuestion, resetPasswordMethod: resetPasswordMethod,
                }

             axios.post('/registration/createPrescriber', prescriberJson)
             .then(response => {
                  if(response.data){
                    this.setState({isLoading:!response.data})
                    if(this.state.isKsUser && !this.state.isLoading){
                        document.getElementById("Step2Next").disabled = true;
                        window.Materialize.toast("Registration Completed", 9000,  'green rounded')
                        setTimeout(function(){ window.location.href = UserProfile.getUrlName() }, 2000)
                        
                    }
                  }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        } else {
            let stepTwo = { ...this.state.stepTwoAgentData }
            let stepFour = { ...this.state.StepFourAgentData }
            let resetPasswordMethod = this.state.resetPasswordMethod
            let prescriberIds = [];
            roles.push(stepTwo.ima)

            for (var j = 0; j < selectedPrescriberJson.length; j++) {
                prescriberIds.push(selectedPrescriberJson[j].index);
            }

            let agentJson = {
                roleIds: roles, roleTitleId: stepTwo.I_Work_At_A,
                username: userName, password: password, prefix: stepFour.prefix,
                securityQuestion: securityQuestion, firstName: stepFour.firstName, lastName: stepFour.lastName, middleName: stepFour.middleName, email: stepFour.email,
                prescriberIds: prescriberIds, resetPasswordMethod: resetPasswordMethod,approvalStatus:Constant.APPROVED_STATUS
            }
            console.log(agentJson)
            axios.post('/registration/createAgent', agentJson)
             .then(response => {
                       this.setState({isLoading: !response.data})
                    if(this.state.isKsUser && !this.state.isLoading){
                        document.getElementById("Step2Next").disabled = true;
                        window.Materialize.toast("Registration Completed", 9000,  'green rounded')
                        setTimeout(function(){ window.location.href = UserProfile.getUrlName() }, 2000)
                                           
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    checkDuplicateUserName = (fromFormThree = false) => {

        let duplicateUname=false
        let value = null
        if(this.state.isKsUser && fromFormThree){
            if($("#userNameloginstep4").val().trim().length === 0||$("#userNameloginstep4").val().indexOf('#')!==-1 ||$("#userNameloginstep4").val().indexOf('%')!==-1||$("#userNameloginstep4").val().indexOf('^')!==-1 ||$("#userNameloginstep4").val().indexOf('&')!==-1 || $("#userNameloginstep4").val().indexOf(']')!==-1 || $("#userNameloginstep4").val().indexOf('[')!==-1 ||$("#userNameloginstep4").val().indexOf('{')!==-1 ||$("#userNameloginstep4").val().indexOf('}')!==-1 ||$("#userNameloginstep4").val().indexOf('^')!==-1 ){
                $("#userNameloginstep4").val("")
            }
            value =  $("#userNameloginstep4").val()   
        }else{
            if($("#userNamelogin").val().trim().length === 0||$("#userNamelogin").val().indexOf('#')!==-1 ||$("#userNamelogin").val().indexOf('%')!==-1||$("#userNamelogin").val().indexOf('^')!==-1 ||$("#userNamelogin").val().indexOf('&')!==-1 || $("#userNamelogin").val().indexOf(']')!==-1 || $("#userNamelogin").val().indexOf('[')!==-1 ||$("#userNamelogin").val().indexOf('{')!==-1 ||$("#userNamelogin").val().indexOf('}')!==-1 ||$("#userNamelogin").val().indexOf('^')!==-1 ){
                $("#userNamelogin").val("")
            }
            value =  $("#userNamelogin").val()    
        }
        let KSEmail=false
        let val=value.toLowerCase();
        if(val.includes("@")){
            if(val.split("@")[1]==="kloudscript.net" || val.split("@")[1]==="usewms.com" || val.split("@")[1]==="usekspa.com"){
                KSEmail=true
            }
          }
        if(!KSEmail){
            axios.get('/registration/userName?userName=' + value.trim())
            .then(response => {
                this.isDublicate = response.data
                if (this.isDublicate) {
                    DuplicateUserName=false
                    duplicateUname = false
                    let isUserNameAvailable = false
                    this.setState({ isUserNameAvailable:isUserNameAvailable })
 
                    if(fromFormThree && this.state.isKsUser){
                        if(this.state.isValid){
                            this.setState({isStepThreeEmailValid:true})
                        }     
                    }
                } else {
                    DuplicateUserName=true
                    
                    if(fromFormThree && this.state.isKsUser){
                        window.Materialize.toast(Constant.USER_NAME_DUPLICATE, 3000,  'red rounded')
                        if(this.state.isRole === Constant.ROLE_PRESCRIBER){
                            let stepFourPrescriberValidate = {...stepFourPrescriberValidate} 
                            stepFourPrescriberValidate.duplicateUserNameValid = true
                            this.setState({stepFourPrescriberValidate:stepFourPrescriberValidate}); 
                            this.setState({ isValid: false })
                        }else if(this.state.isRole === Constant.ROLE_AGENT){
                            let stepFourAgentValidate = {...stepFourAgentValidate} 
                            stepFourAgentValidate.duplicateUserNameValid = true
                            this.setState({stepFourAgentValidate:stepFourAgentValidate}); 
                            this.setState({ isValid: false })
                        }
                       
                    }
                }                
            })
            .catch(function (error) {
                console.log(error);
            });
         }else{
            window.Materialize.toast("User Name Should Not Contain @kloudscript.net or @usewms.com or @usekspa.com", 3000,  'red rounded')
         }
        
            return duplicateUname
    }

    // updated checkDuplicateEmail method by Nirav
    checkDuplicateEmail = () => {
        let value = ''
        if(this.state.isRole === Constant.ROLE_AGENT){
            value =  $("#emailAboutAgent").val()
        }else{
            value =  $("#emailAboutPrescriber").val()
        }
       
        console.log(value)
        if(value !==''|| !value.match(Constant.REGEX_SPACE)) {
        axios.get('/registration/verifyEmail?email='+value)
        .then(response => {
        this.isEmailDuplicate = response.data
             if(this.isEmailDuplicate){
                  this.setState({isEmailAvailable:this.isEmailDuplicate})
                  this.setState({isValid:false})
                  window.Materialize.toast(Constant.EMAIL_DUPLICATE, 3000,  'red rounded')
                
            } else {
                this.setState({isEmailAvailable:this.isEmailDuplicate})
                if(this.validateStepThreeForm()){
                    if(this.state.isKsUser){
                        this.setState({isLoading:true})
                         this.formSubmitHandler()
                    }else{
                        this.setState({isValid:true})
                    }   
                }
             }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    }

    clickHandler = (event, step) => {
        console.log(event.target)
        console.log(step)
        let name
        if(event.target.value){
            name = Constant.REGISTER
            step = Constant.STEP_TWO
        }else{
            name = event.target.name;
        }

        console.log(name)
        let isValid = this.validateForm(event, step, name);
        this.setState({ isValid: isValid })
        this.setState({ buttonProp: { step: step, name: name } });
    }

    validateForm = (event, step, name) => {

        if (step === Constant.STEP_TWO && name === Constant.REGISTER) {
            this.setState({
                stepFourPrescriberValidate: {
                    firstNameValid: false,
                    lastNameValid: false,
                    addressLine1Valid: false,
                    cityValid: false,
                    isStateValid: false,
                    zipValid: false,
                    workphoneValid: false,
                    taxonomyCodeValid: false,
                    emailValid: false,
                    confEmailValid: false
                }, stepFourAgentValidate: {
                    firstNameValid: false,
                    lastNameValid: false,
                    emailValid: false,
                    confEmailValid: false
                }, stepOneValidate: {
                    imaValid: false
                }
            })

            if (!this.state.isRole) {
                return this.validateStepOneForm()
            }

            if (this.state.isRole == Constant.ROLE_PRESCRIBER) {
                if(this.validateStepTwoFormPrescriber()){
                    this.setState({isFromKsUserUpdate:true})
                    console.log("------------isFromKsUserUpdate--------------"+this.state.isFromKsUserUpdate)
                }
            } else if (this.state.isRole == Constant.ROLE_AGENT) {
                if(this.validateStepTwoFormAgent()){
                    this.setState({isFromKsUserUpdate:true})
                    console.log("------------isFromKsUserUpdate--------------"+this.state.isFromKsUserUpdate)
                }
              
                
            } else {
                return true;
            }

        }

        return true

        
    }


    validateStepFourForm = () => {
        let isValid = true
        let isEmpty = false
        let isQuestionDuplicate = false
        let isEnteredPasswordValid = false
        let isConfirmPasswordValid = false
        let isAnswerDuplicate = false
        let stepFiveData = { ...this.state.stepFiveData }
        let isUserNameAvailable = this.state.isUserNameAvailable;
        let stepFiveValidate = {
            userNameValid: false,
            passwordValid: false,
            confirmPasswordValid: false,
            securityQuestion1Valid: false,
            securityQuestion2Valid: false,
            securityQuestion3Valid: false,
            securityAnswer1Valid: false,
            securityAnswer2Valid: false,
            securityAnswer3Valid: false,
            isQuestionDuplicate: false,
            isAnswerDuplicate: false
        }

        if (!stepFiveData.userName||stepFiveData.userName.trim().length==0 ) {
            isValid = false
            isEmpty = true
            stepFiveValidate.userNameValid = true
        }

        if (!stepFiveData.password || stepFiveData.password.match(Constant.REGEX_SPACE)) {
            isValid = false
            isEmpty = true
            stepFiveValidate.passwordValid = true
        } else {
            isEnteredPasswordValid = (stepFiveData.password.match(Constant.WRONG_PASSWORD_FORMATE))
            if (!isEnteredPasswordValid) {
                isValid = false
                window.Materialize.toast(Constant.INVALID_PASSWORD_FORMATE, 3000, 'red rounded')
                isUserNameAvailable =false
            }
        }
        if (!stepFiveData.confirmPassword) {
            isValid = false
            isEmpty = true
            stepFiveValidate.confirmPasswordValid = true
        } else {
            if (isEnteredPasswordValid) {
                isConfirmPasswordValid = (stepFiveData.password === stepFiveData.confirmPassword);
                if (!isConfirmPasswordValid) {
                    isValid = false
                    window.Materialize.toast(Constant.CONFIRM_PASSWORD_INVALID, 3000, 'red rounded')
                    isUserNameAvailable =false
                }
            }
        }

        if (!stepFiveData.securityQuestion1) {
            isValid = false
            isEmpty = true
            stepFiveValidate.securityQuestion1Valid = true
        }
        if (!stepFiveData.securityQuestion2) {
            isValid = false
            isEmpty = true
            stepFiveValidate.securityQuestion2Valid = true
        }
        if (!stepFiveData.securityQuestion3) {
            isValid = false
            isEmpty = true
            stepFiveValidate.securityQuestion3Valid = true
        } 
        if (stepFiveData.securityQuestion1 === stepFiveData.securityQuestion2) {
            isQuestionDuplicate = true
        } 
        if (stepFiveData.securityQuestion1 === stepFiveData.securityQuestion3) {
            isQuestionDuplicate = true
        }
        if (stepFiveData.securityQuestion2 === stepFiveData.securityQuestion3) {
            isQuestionDuplicate = true
        }

		   let securityAnswer1=$("#securityAnswer1").val();
           let  securityAnswer2=$("#securityAnswer2").val();
            let securityAnswer3=$("#securityAnswer3").val();

            if (!securityAnswer1 || securityAnswer1.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true
                stepFiveValidate.securityAnswer1Valid = true
            }
            if (!securityAnswer2 || securityAnswer2.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true
                stepFiveValidate.securityAnswer2Valid = true
            } 
            if (!securityAnswer3 || securityAnswer3.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true
                stepFiveValidate.securityAnswer3Valid = true
            }
            if ((securityAnswer1 && securityAnswer2) && (securityAnswer1.toLowerCase().trim() === securityAnswer2.toLowerCase().trim())) {
                isAnswerDuplicate = true

            } 
            if ((securityAnswer1 && securityAnswer3) && (securityAnswer1.toLowerCase().trim() ===securityAnswer3.toLowerCase().trim())) {
                isAnswerDuplicate = true

            }
            if ((securityAnswer2 && securityAnswer3) && securityAnswer2.toLowerCase().trim() === securityAnswer3.toLowerCase().trim()) {
                isAnswerDuplicate = true
			}


        if (isEmpty) {  
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000, 'red rounded')
            isUserNameAvailable =false
           
        } else {
            //     if(!isUserNameAvailable){
            //     isValid =  false
            //     stepFiveValidate.isUserNameAvailable=true
            // }
            //     if(!isEnteredPasswordValid) {
            //         isValid =  false
            //         window.Materialize.toast(Constant.INVALID_PASSWORD_FORMATEA, 1000,  'red rounded')
            //     } else {
            //         if (!isConfirmPasswordValid) {
            //             isValid =  false
            //             window.Materialize.toast(Constant.CONFIRM_PASSWORD_INVALID, 1000,  'red rounded')
            //         }
            //     }

            //only @ and . allowed as special character in Username validation
             if (stepFiveData.userName && stepFiveData.userName.trim().length!=0 
              && (stepFiveData.userName.match(Constant.USER_ALPHABET_SPACE)==null)){
                    isValid = false
                    isUserNameAvailable =false
                    window.Materialize.toast(Constant.USER_NAME_VALID, 3000, 'red rounded')
               }
               if (isQuestionDuplicate) {
                isValid = false
                isUserNameAvailable =false
                window.Materialize.toast(Constant.QUESTION_IS_DUPLICATE, 3000, 'red rounded')
            }
            if (isAnswerDuplicate) {
                isValid = false
                isUserNameAvailable =false
                window.Materialize.toast(Constant.ANSWER_IS_DUPLICATE, 3000, 'red rounded')
            }

        }
        this.setState({ stepFiveValidate: stepFiveValidate, isUserNameAvailable:isUserNameAvailable })
        return isValid


    }

    validateStepOneForm = (event) => {
        let isValid = true
        let stepOneData = { ...this.state.stepTwoData }
        let stepOneValidate = { imaValid: false }
        let stepTwoValidate = { imaValid: false }
        if (!stepOneData.ima) {
            isValid = false
            stepOneValidate.imaValid = true
            stepTwoValidate.imaValid = true
            this.setState({ stepTwoValidate: stepTwoValidate })
            window.Materialize.toast(this.state.isKsUser?Constant.KSROLE_REQUIRED:Constant.ROLE_REQUIRED, 2000, 'red rounded')
        }
        return isValid;

    }

    validateStepTwoFormPrescriber = (event) => {
        let isValid = true
        let stepTwoPrescriberData = { ...this.state.stepTwoPrescriberData }
        let prescriberApiDataLength = this.state.prescriberApiData.length
        let isShowButton = {...this.state.isShowButton}
        let stepTwoValidate = {
            npiValid: false,
            imaValid: false,
            whoIsAValid: false,
            apiValid: false
        }

        if (this.isEmptyString(document.getElementById("whoIsA").value)) {
            isValid = false
            stepTwoValidate.whoIsAValid = true
            window.Materialize.toast(Constant.SELECT_WHO_IS_A_PRESCRIBER, 3000, 'red rounded')
        }

        if (!stepTwoPrescriberData.npi) {
            isValid = false
            stepTwoValidate.npiValid = true
            isShowButton = false
            window.Materialize.toast(Constant.NPI, 3000, 'red rounded')
         
        } else if (stepTwoPrescriberData.npi.length !== Constant.TEN) {
            isValid = false
            isShowButton = false
            window.Materialize.toast(Constant.NPI_VALIDATION, 3000, 'red rounded')
            
        } else if (!Constant.REGEX_DIGIT.test(stepTwoPrescriberData.npi)) {
            isValid = false
            stepTwoValidate.npiValid = true
            isShowButton = false
            window.Materialize.toast(Constant.NPI_VALIDATION, 3000, 'red rounded')
          
        }
        if (isValid) {
            stepTwoValidate.apiValid = (prescriberApiDataLength === 0 && stepTwoPrescriberData.npi);
        }
        if (stepTwoValidate.apiValid && !this.state.isNpiAlreadyAdded && !this.state.isNpiRegistryDown) {
            isValid = false
            isShowButton = false
            window.Materialize.toast(Constant.NPI_NOT_FOUND, 3000, 'red rounded')
           
        } else if (this.state.isNpiAlreadyAdded) {
            isValid = false
            isShowButton = false
            window.Materialize.toast(Constant.NPI_ALREADY_IN_SYSTEM, 3000, 'red rounded')
           
        }else if(this.state.isNpiRegistryDown){
             isValid = false
             isShowButton = false
             window.Materialize.toast(Constant.NPI_API_DOWN, 3000, 'red rounded')
             
        }

        this.setState({ stepTwoValidate: stepTwoValidate ,isShowButton:isShowButton})
       
        if(isValid){
            if(this.validateStepThreeForm()){
                return isValid;
            }
     
        }

        
    }

    validateStepTwoFormAgent = (event) => {

        let isValid = true

        let stepTwoValidate = {
            imaValid: false,
            agentFNSearchValid:false,
            agentLNSearchValid:false,
            npiValid:false
        }
        
        isValid = this.validateStepThreeForm()
        
        if (selectedPrescriberJson.length <= 0) {
            isValid = false
            stepTwoValidate.agentFNSearchValid = true
            stepTwoValidate.agentLNSearchValid = true
            stepTwoValidate.npiValid = true
            window.Materialize.toast(Constant.ASSOCIATE_ONE_PRESCRIBER, 7000, 'red rounded')
        }

        this.setState({ stepTwoValidate: stepTwoValidate })

        console.log("-------------validateStepThreeForm------------------"+isValid)
 
        return isValid
    }

    validateStepThreeForm = () => {
        let isValid = true
        let isEmailValid = false;
        let isConfirmEmailValid = false;
        let isEmpty = false;
        
        if (this.isEmptyString(document.getElementById("whoIsA").value)) {
            isEmpty = true
            isValid = false
            this.setState({stepTwoValidate:{whoIsAValid:true}})
        }


        if (this.state.isRole === Constant.ROLE_AGENT) {

            let StepFourAgentData = { ...this.state.StepFourAgentData }
            let stepFourAgentValidate = {
                firstNameValid: false,
                lastNameValid: false,
                emailValid: false,
                confEmailValid: false,
            }

            if (!StepFourAgentData.firstName||StepFourAgentData.firstName.trim().length==0) {
                isValid = false
                isEmpty = true
                stepFourAgentValidate.firstNameValid = true
            }
            if (!StepFourAgentData.lastName||StepFourAgentData.lastName.trim().length==0) {
                isValid = false
                isEmpty = true
                stepFourAgentValidate.lastNameValid = true
            }
            if ((UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN)) {

                if(!StepFourAgentData.userName || StepFourAgentData.userName.trim().length==0){
                    isValid = false;
                    isEmpty = true;
                    stepFourAgentValidate.userNameValid = true
                }else{
                    if (!isEmpty && StepFourAgentData.userName && StepFourAgentData.userName.trim().length!=0 
                    &&(StepFourAgentData.userName.match(Constant.USER_ALPHABET_SPACE)==null)){
                        isValid = false;
                        stepFourAgentValidate.userNameValid = false;
                        window.Materialize.toast(Constant.USER_NAME_VALID, 3000, 'red rounded')
                    }
                }

            }
             if (StepFourAgentData.middleName && StepFourAgentData.middleName.trim().length==0) {
                isValid = false
             window.Materialize.toast(Constant.MIDDLE_NAME_VALID, 1000, 'red rounded')
            }
            if (!StepFourAgentData.email) {
                isValid = false
                isEmpty = true
                stepFourAgentValidate.emailValid = true

            } else {
                StepFourAgentData.email = StepFourAgentData.email
                if (StepFourAgentData.email.toLowerCase().match(Constant.REGEX_EMAIL)) {
                    isEmailValid = true;
                }
            }
            if (!StepFourAgentData.confirmEmail) {
                isValid = false
                isEmpty = true
                stepFourAgentValidate.confEmailValid = true

            } else {
                StepFourAgentData.confirmEmail = StepFourAgentData.confirmEmail
                if (isEmailValid) {
                    if (StepFourAgentData.email.toLowerCase() === StepFourAgentData.confirmEmail.toLowerCase()) {
                        isConfirmEmailValid = true;
                    }
                }
            }
            if (isEmpty) {
                window.Materialize.toast(Constant.MANDATORY_MESSAGE, 7000, 'red rounded')
            }
            else if (!isEmailValid) {
                isValid = false;
                window.Materialize.toast(Constant.EMAIL_INVALID, 1000, 'red rounded')
            }
            else if (!isConfirmEmailValid && !this.state.isEmailAvailable ) {
                isValid = false;
                window.Materialize.toast(Constant.CONFIRM_EMAIL_INVALID, 1000, 'red rounded')
            }
            this.setState({stepFourAgentValidate:stepFourAgentValidate})

        } else if (this.state.isRole === Constant.ROLE_PRESCRIBER) {
            let allPrescriberApiData = this.state.prescriberApiData
            let prescriberApiData = allPrescriberApiData[0]
            let isEmpty = false;

            let basic = prescriberApiData ? prescriberApiData.basic : ''
            let addresses = prescriberApiData ? prescriberApiData.addresses[0] : []

            let stepFourPrescriberData = { ...this.state.StepFourPrescriberData }
            let stepFourPrescriberValidate = {
                firstNameValid: false,
                lastNameValid: false,
                addressLine1Valid: false,
                cityValid: false,
                isStateValid: false,
                zipValid: false,
                workphoneValid: false,
                emailValid: false,
                confEmailValid: false,
                userNameValid:false,
                isUserNameAvailable:false,
                duplicateUserNameValid:false
            }
            if (!prescriberApiData) {

                if (!stepFourPrescriberData.firstName) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.firstNameValid = true
                }
                if (!stepFourPrescriberData.lastName) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.lastNameValid = true
                }
                if (!stepFourPrescriberData.addressLine1) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.addressLine1Valid = true
                }
                if (!stepFourPrescriberData.zip) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.zipValid = true
                }
                if (!stepFourPrescriberData.city) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.cityValid = true
                }
                if (!stepFourPrescriberData.state) {
                    isValid = false
                    isEmpty = true;
                    stepFourPrescriberValidate.isStateValid = true
                }
                if (!stepFourPrescriberData.workphone) {
                    isValid = false
                    isEmpty = true
                    stepFourPrescriberValidate.workphoneValid = true
                }
            }
            if ((UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN)) {

                if(!stepFourPrescriberData.userName || stepFourPrescriberData.userName.trim().length==0){
                    isValid = false;
                    isEmpty = true;
                    stepFourPrescriberValidate.userNameValid = true
                }else{
                    if (!isEmpty && stepFourPrescriberData.userName && stepFourPrescriberData.userName.trim().length!=0 
                    &&(stepFourPrescriberData.userName.match(Constant.USER_ALPHABET_SPACE)==null)){
                        isValid = false;
                        stepFourPrescriberValidate.userNameValid = false
                        window.Materialize.toast(Constant.USER_NAME_VALID, 3000, 'red rounded')
                    }
                }

            }
            if (addresses.telephone_number) {
                stepFourPrescriberData.workphone = addresses.telephone_number
            }
            if (basic.first_name) {
                stepFourPrescriberData.firstName = basic.first_name
            }
            if (basic.last_name) {
                stepFourPrescriberData.lastName = basic.last_name
            }
            if (basic.status) {
                stepFourPrescriberData.status = basic.status
            }
            if (addresses.address_1) {
                stepFourPrescriberData.addressLine1 = addresses.address_1
            }
            if (addresses.postal_code) {
                stepFourPrescriberData.zip = addresses.postal_code
            }
            if (addresses.city) {
                stepFourPrescriberData.city = addresses.city
            }
            if (addresses.state) {
                stepFourPrescriberData.state = addresses.state
            }
            if(addresses.state_full_name){
                stepFourPrescriberData.state_full_name = addresses.state_full_name
            }
            if (addresses.telephone_number) {
                stepFourPrescriberData.phoneFromApi = true
                stepFourPrescriberData.phonePrefered = true
                stepFourPrescriberData.workphoneValid = addresses.telephone_number
            }else{
                stepFourPrescriberData.phoneFromApi = false
                stepFourPrescriberData.phonePrefered = false
            }

            if (addresses.address_2) {
                stepFourPrescriberData.addressLine2 = addresses.address_2
            }

            if (basic.middle_name) {
                stepFourPrescriberData.middleName = basic.middle_name
            }

            if (basic.name_prefix) {
                stepFourPrescriberData.prefix = basic.name_prefix
            }

            if (addresses.fax_number) {
                stepFourPrescriberData.faxFromApi = true
                stepFourPrescriberData.faxPrefered = true
                stepFourPrescriberData.faxOne = addresses.fax_number
            }else{
                stepFourPrescriberData.faxFromApi = false
                stepFourPrescriberData.faxPrefered = false
            }

            if (!stepFourPrescriberData.firstName || stepFourPrescriberData.firstName.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.firstNameValid = true
            }
            if (!stepFourPrescriberData.lastName || stepFourPrescriberData.lastName.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.lastNameValid = true
            }
            if (!stepFourPrescriberData.addressLine1 || stepFourPrescriberData.addressLine1.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.addressLine1Valid = true
            }
            if (!stepFourPrescriberData.zip || stepFourPrescriberData.zip.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.zipValid = true
            }
            if (!stepFourPrescriberData.city || stepFourPrescriberData.city.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.cityValid = true
            }
            if (!stepFourPrescriberData.state || stepFourPrescriberData.state.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true;
                stepFourPrescriberValidate.isStateValid = true
            }
            if (!stepFourPrescriberData.workphone || stepFourPrescriberData.workphone.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true
                stepFourPrescriberValidate.workphoneValid = true
            }
            if (!stepFourPrescriberData.email || stepFourPrescriberData.email.match(Constant.REGEX_SPACE)) {
                isValid = false
                isEmpty = true
                stepFourPrescriberValidate.emailValid = true
            } else {
                stepFourPrescriberData.email = stepFourPrescriberData.email
                if (stepFourPrescriberData.email.toLowerCase().match(Constant.REGEX_EMAIL)) {
                    isEmailValid = true;
                }
            }
            if (!stepFourPrescriberData.confirmEmail) {
                isValid = false
                isEmpty = true
                stepFourPrescriberValidate.confEmailValid = true
            } else {
                stepFourPrescriberData.confirmEmail = stepFourPrescriberData.confirmEmail
                if (isEmailValid) {
                    if (stepFourPrescriberData.email.toLowerCase() === stepFourPrescriberData.confirmEmail.toLowerCase()) {
                        isConfirmEmailValid = true;
                    }
                }
            }
            if (isEmpty) {
                window.Materialize.toast(Constant.MANDATORY_MESSAGE, 1000, 'red rounded')
                this.pageScrollUp();
            } else if (!isEmailValid) {
                isValid = false;
                window.Materialize.toast(Constant.EMAIL_INVALID, 1000, 'red rounded')
            } else if (!isConfirmEmailValid && !this.state.isEmailAvailable) {
                isValid = false;
                window.Materialize.toast(Constant.CONFIRM_EMAIL_INVALID, 1000, 'red rounded')
            }
            if ((prescriberApiData.addresses.postal_code==null)&&(stepFourPrescriberData.zip) && (stepFourPrescriberData.zip.match(Constant.REGEX_ANY)==null)) {
                isValid = false
                window.Materialize.toast(Constant.ZIP_VALID, 1000, 'red rounded')
            }
            if (isValid) {
                let practiceLocations = prescriberApiData.practiceLocations;
                let facilityList = []
                let facilityContactList = []
                for (let i = 0; i < practiceLocations.length; i++) {

                    let facility = document.getElementById("facility_" + i).value

                    if(practiceLocations[i].telephone_number){
                    let contactPhoneJson = {"contactType":"PHONE_1","contactValue":practiceLocations[i].telephone_number,"fromApi":true,isPreferred:true}
                        facilityContactList.push(contactPhoneJson)
                    }

                    if(practiceLocations[i].fax_number){
                    let contactFaxcJson = {"contactType":"FAX_1","contactValue":practiceLocations[i].fax_number,"fromApi":true,isPreferred:true}
                        facilityContactList.push(contactFaxcJson)
                    }

                    let innerJson = {
                        "facility": facility, "country_code": practiceLocations[i].country_code, "address_purpose": practiceLocations[i].address_purpose,
                        "address_type": practiceLocations[i].address_type, "city": practiceLocations[i].city,
                        "address_1": practiceLocations[i].address_1, "address_2": practiceLocations[i].address_2,
                        "telephone_number": practiceLocations[i].telephone_number, "country_name": practiceLocations[i].country_name,
                        "state": practiceLocations[i].state,"fax_number":practiceLocations[i].fax_number, "postal_code": practiceLocations[i].postal_code, "type": practiceLocations[i].type, "fromApi": true,
                        "contact":facilityContactList,"state_full_name": practiceLocations[i].state_full_name
                    }

                    facilityList.push(innerJson);
                }
                let taxonomies = prescriberApiData ? prescriberApiData.taxonomies : []
                let taxonomiesDataList = []
                for (let i = 0; i < taxonomies.length; i++) {
                    let amacode = document.getElementById("amacode_" + taxonomies[i].uniqueId).value
                    let innerJson = {
                        "index": i,
                        "type": taxonomies[i].type,
                        "amacode": amacode,
                        "desc": taxonomies[i].desc,
                        "code": taxonomies[i].code,
                        "state": taxonomies[i].state,
                        "state_full_name": taxonomies[i].state_full_name,
                        "license": taxonomies[i].license
                    }
                    taxonomiesDataList.push(innerJson)
                }

                this.setState({
                    taxonomiesData: taxonomiesDataList,
                    practiceLocationsData: facilityList
                })

            }
            this.setState({StepFourPrescriberData: stepFourPrescriberData, stepFourPrescriberValidate: stepFourPrescriberValidate})
        }
     
        return isValid

    }

    pageScrollUp = () => {
        window.scrollTo(0, 0)
    }

    render() {

        if(this.state.isFromKsUserUpdate){
            this.checkDuplicateUserName(true)
            this.setState({isFromKsUserUpdate :false});
        }

        if(this.state.isStepThreeEmailValid){
            this.checkDuplicateEmail()
            this.setState({isStepThreeEmailValid :false});
            
        }

        if(this.state.isStepThreeValidate){
            this.validateStepThreeForm()
            this.setState({isStepThreeValidate:false})
        }

        let styleClass = {
            wizardPaneStepTwo: 'wizard-pane ',
            wizardLiClassStepTwo: 'active'
        }

        let buttonProps = this.state.buttonProp;

      
         if (buttonProps.step === Constant.STEP_TWO) {
            if (buttonProps.name === Constant.REGISTER) {
                if (!this.state.isValid) {
                    styleClass.wizardPaneStepTwo = 'wizard-pane';
                    styleClass.wizardLiClassStepTwo = 'active';
                } 
            } 
        }

        return (
            <div className="loginPage v2-back">

            <div className="wrapper">
                <LoginTop />
                <section className="registrationWizard">
                    <div className="">
                        <div id="registerWizard">
                            <div className="wizard width-full">
                            <div className="row">
                                {/* <div className="col-20">
                                  <nav className="navbar-default" role="navigation">
                                        <div className="wizard-nav">
                                            <ul className="nav nav-wizard">

                                                <li id="step2li" className={styleClass.wizardLiClassStepTwo}>
                                                    <a href="#" className={Constant.STEP_TWO}>
                                                        <i className="icon hidden-xs"></i>
                                                    </a>
                                                </li>
                                            
                                            </ul>
                                        </div>
                                    </nav>
                                </div> */}
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="wizard-body">
                                        <div className="wizard-content">

                                        <div className={styleClass.wizardPaneStepTwo} id={Constant.STEP_TWO}>
                                            <StepTwoKsUser
                                                isUpdatedData={this.state.isUpdatedData}
                                                isHiddenMd={this.state.isHiddenMd}
                                                isHiddenAgent={this.state.isHiddenAgent}
                                                change={this.change}
                                                roleTitleList={this.state.roleTitleList}
                                                click={this.clickHandler}
                                                stepTwoValidate={this.state.stepTwoValidate}
                                                data={this.state.stepTwoPrescriberData}
                                                getPrescriberApiData={this.getPrescriberApiData}
                                                document={this.state.document}
                                                associatedData={this.associatedData}
                                                NPI={this.state.associatedData.NPI}
                                                associatedDataListner={this.associatedDataListner}
                                                changePrescriberListner={this.changePrescriberListner}
                                                selectedPrescriberJson={selectedPrescriberJson}
                                                prescriberJson={prescriberJson}
                                                agentData={this.state.associatedData}
                                                removeDocument={this.removeDocument}
                                                isLoading={this.state.isLoading}
                                                checkDuplicateEmail={this.checkDuplicateEmail}
                                                stepTwoPrescriberData={this.state.stepTwoPrescriberData.ima}
                                                stepTwoAgentData={this.state.stepTwoAgentData.ima}
                                                StepFourAgentData={this.state.StepFourAgentData}
                                                StepFourPrescriberData={this.state.StepFourPrescriberData}
                                                change={this.change}
                                                workPhone={this.workPhone}
                                                faxNumber={this.faxNumber}
                                                click={this.clickHandler}
                                                stepFourPrescriberValidate={this.state.stepFourPrescriberValidate}
                                                stepFourAgentValidate={this.state.stepFourAgentValidate}
                                                isDisabled={this.state.isDisabled}
                                                prescriberApiData={this.state.prescriberApiData}
                                                selectedPrescriberJson={selectedPrescriberJson}
                                                changeFacilityListner={this.changeFacilityListner}
                                                checkDuplicateUserName={this.checkDuplicateUserName}
                                                isLoading={this.state.isLoading}
                                                isShowButton = {this.state.isShowButton}
                                            />
                                        </div>
                                    
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                </div>

        </div>

        );
    }
}