import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import * as Constant from './Component/CustomUI/Constant';

axios.defaults.baseURL = Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(function (response) {
    if(response.data == Constant.EXPIRE_SESSION_URL){
        window.location.reload();
    }
    return response;
}, function (error) {
const originalRequest = error.config;
if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;     
}
return Promise.reject(error);
});

serviceWorker.unregister();

ReactDOM.render(<App />, document.getElementById('root'));