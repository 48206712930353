import React, { Component } from 'react';
import Agent from './Agent';
import Prescriber from './Prescriber';
import Button from '../../CustomUI/Button';
import * as Constant from '../../CustomUI/Constant';
import $ from 'jquery';
import UserProfile from '../../../UserProfile';

class StepFour extends Component {

	state = {
		btnName:"Next",
		secondParams:"step3"

	}


	render() {
			let href = ''

				if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
					href = UserProfile.getUrlName()
				}else{
				 	href = Constant.FRONTEND_CONTEXT_PATH
				}
		return (

			<div>

				<h1>About You </h1>
				{$("#ima").val() == 2?
				 <div className="alert alert-info">
  					<strong> <i className="small material-icons">info</i> Your NPI and contact information is provided by NPPES registry by CMS. To update your information, visit [<a href="https://npiregistry.cms.hhs.gov/" target="_blank">NPPES NPI Registry</a>]</strong>
      	 </div>:''}
	 			<div className="bodyContent ">
				<div>
				 {this.props.stepTwoPrescriberData === "2"?<Prescriber change={this.props.change}
				//   emailcheck={this.props.emailcheck}
				//   confirmemailchecking={this.props.confirmemailchecking}
					 checkDuplicateEmail={this.props.checkDuplicateEmail}
					 checkDuplicateUserName={this.props.checkDuplicateUserName}
			 		StepFourPrescriberData={this.props.StepFourPrescriberData}
				  stepFourPrescriberValidate={this.props.stepFourPrescriberValidate}
				  faxNumber={this.props.faxNumber	}
				  workPhone={this.props.workPhone}
				  isDisabled={this.props.isDisabled}
				  prescriberApiData={this.props.prescriberApiData}
					changeFacilityListner={this.props.changeFacilityListner}
					isLoading={this.props.isLoading}
					
				  />:null}

				 { this.props.stepTwoAgentData === "1"?
				 <Agent change={this.props.change}
				//  confirmemailchecking={this.props.confirmemailchecking}
				//  emailcheck={this.props.emailcheck}
					// checkDuplicateEmail={this.props.checkDuplicateEmail}
					checkDuplicateEmail={this.props.checkDuplicateEmail}
					checkDuplicateUserName={this.props.checkDuplicateUserName}
					StepFourAgentData={this.props.StepFourAgentData}
				  selectedPrescriberJson={this.props.selectedPrescriberJson}
					stepFourAgentValidate = {this.props.stepFourAgentValidate}
					isLoading={this.props.isLoading} />:null}
				</div>
				</div>
				<div className="wizard-footer">
          <div className="btn-toolbar pull-right">
							<Button btnName="Back" className="btn btn-primary wizard-next btn-dark scroll-top" click={this.props.click} secondParams="step3" />
							<a href={href}>
								<Button btnName="Cancel" className="btn btn-primary wizard-next btn-white scroll-top"  secondParams="step3" />
							</a>
							<Button btnName={UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN?"Submit":"Next"} className="btn btn-primary wizard-back scroll-top" click={this.props.click} secondParams={UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN?"step4":"step3"} />
          </div>
        </div>
			</div>

		);
	}
}
export default StepFour;