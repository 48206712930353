import React, { Component } from 'react';
import { Input } from 'react-materialize';
import Input1 from '../../../Component/CustomUI/Input';
import Label from '../../../Component/CustomUI/Label';
import * as Constant from '../../../Component/CustomUI/Constant';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import axios from 'axios';
import CustomMaskedInput from '../../../Component/CustomUI/MaskedInput';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import UserProfile from '../../../UserProfile';
import Button from '../../../Component/CustomUI/Button';

let contactList = [];
let formattedContact = [];
let prescriberId;
let isPreferredGloble=false

export default class prescriberProfile extends Component {

        state = {
            srcUrl : "",
            contactData : [],
            fromEdit : false,
            isAnyApiData: false,
            isContactTypeEmpty: false,
            isContactValueEmpty: false,
            randomPassword:'',
            isFaxFromApi:false,
            isPhoneFromApi:false,
            ContactTypeData:"",
            onePhonePreferred:false,
            oneFaxPreferred:false
        }


    amaCodeListner = (cell, row) => {
        let id = "amacode_" + row.id;
        let name = "amacode_" + row.id;
        return (
            <input maxLength={10} type="text" defaultValue={row.amacode} name={name} placeholder="AMA Code" id={id} />
        )
    }

    tooltipFormatter(cell, row){
        return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
    };

    viewPrescriberProfileModel = () => {
        //   let styleClass = { width :'50%'}
        return(
            <div id="viewPrescriberProfile" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center" >
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Consent Document</h4>
                       </div>
                       <div className="modal-body">
                          <iframe name="test" height="500px" width="100%" name="plugin" src={this.state.srcUrl} type="application/pdf"></iframe>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <button type="button" id="deleteModalCancel" className="btn btn-primary wizard-next btn-dark " data-dismiss="modal">Close</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

    onChangeListner = () =>{
        this.setState({ ContactTypeData:$("#contactType").val()})
           let isApi = false
            if($("#contactType").val() === "PHONE" && this.state.isPhoneFromApi){
                isApi = true
            }else if($("#contactType").val() === "FAX" && this.state.isFaxFromApi){
                isApi =true
            }
         this.setState({isAnyApiData:isApi})
    }

    getContactData = () => {
        if(prescriberId) {
            axios.get('/prescriberProfile/getContactData?prescriberId='+prescriberId)
            .then(response => {
                // console.log(response)
                formattedContact = []
                contactList = []
           //     let isAnyApiData = []
                let contact = response.data

                if(contact) {
                    for(let i =0; i<contact.length; i++){
                        let innerJson = {"index":i, "contactType":contact[i].contactType, "fromApi":contact[i].fromApi, "contactValue":contact[i].contactValue, "isPreferred":contact[i].isPreferred}
                        formattedContact.push(innerJson);
                         if(contact[i].fromApi && contact[i].contactType === "FAX"){
                            this.setState({isFaxFromApi:true})
                         }
                         if(contact[i].fromApi && contact[i].contactType === "PHONE"){
                            this.setState({isPhoneFromApi:true})
                         }
                    }
                }
                contactList = contact;
                this.setState({contactData:contact})
                this.checkContactLength();
            })
            .catch(function (error) {
                 console.log("Exception occured"+error)
            });
        }
    }

    saveContact = () => {

        let isValid = this.validateContact();
        if(isValid) {
            let contactTypeId = document.getElementById('contactType');
            let contactType = contactTypeId.options[contactTypeId.selectedIndex].value;
            //let contactType = $("#contactType").val();
            let contactValue = $("#contactValue").val();
            let contactIndex =  $("#index").val();
            let isPreferred = false;
            if(this.state.fromEdit&&isPreferredGloble){
                isPreferred = true
            }else{
                    isPreferred = $('#preferredContact').is(":checked");
            }

            let isDataChanged = true

            if(!this.state.fromEdit) {
                let contactJson = {fromApi:false, contactType:contactType, contactValue:contactValue, isPreferred:isPreferred}
                contactList.push(contactJson);

            } else {

                    let id = contactList[contactIndex].id
                    let fromApi = contactList[contactIndex].fromApi

                    if(fromApi && contactList[contactIndex].contactValue === $("#contactValue").val()) {
                        isDataChanged = false
                    } else {
                        contactList.splice(contactIndex, 1);
                        let contactJson = {"id":id, "fromApi":fromApi, contactType:contactType, contactValue:contactValue, "isPreferred":isPreferred}
                        contactList.push(contactJson)
                    }
            }

            if(isDataChanged) {
                if(this.state.fromEdit) {
                    let id = this.state.editContactId;
                    axios.put('/prescriberProfile/updatePrescriberContactData?id='+id+'&contactType='+contactType+'&contactValue='+contactValue+'&isPreferred='+isPreferred)
                    .then(response => {
                        // console.log(response)
                        $('#contactModalCancel').click();
                        if(response.data) {
                            window.Materialize.toast("Contact updated successfully", 3000,  'green rounded')
                            this.getContactData();
                        } else {
                            window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                        }
                    })
                    .catch(function (error) {
                         console.log("Exception occured-->>"+error)
                    });
                } else {
                    axios.post('/prescriberProfile/savePrescriberContactData?contactType='+contactType+'&contactNumber='+contactValue+'&prescriberId='+prescriberId+'&isPreferred='+isPreferred)
                    .then(response => {
                        // console.log(response)
                        $('#contactModalCancel').click();
                        if(response.data) {
                            window.Materialize.toast("Contact added successfully", 3000,  'green rounded')
                            this.getContactData();
                        } else {
                            window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                        }
                    })
                    .catch(function (error) {
                        console.log("Exception occured-->>"+error)
                    });
                }

            } else {
                this.getContactData();
                $('#contactModalCancel').click();
            }
        }
    }

    addContactModal = () => {
        // let styleClass = { width :'90%'}
        return(
             <div id="addContactModal" className="modal fade" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.fromEdit?'Edit Contact Information':'Add Contact Information'}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    {this.createUI()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <section className="buttonWrapper">
                                <Button id="submitContact" btnName="Save" className="btn btn-primary wizard-next btn-darkpurpale" click={this.saveContact}/>
                                <button type="button" id="contactModalCancel" className="btn btn-primary wizard-next" data-dismiss="modal">Cancel</button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createUI = () => {
        return (
            <div key="key" id="practiceDiv" className='row'>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pl0 pr0">
                    <div className="max-height-drop">
                        <Input s={12} name="contactType" id="contactType" onChange={this.onChangeListner} type="select" label=" Contact Type*" className={this.state.isContactTypeEmpty?'validate invalid':'validate'}>

                            <option value=""  selected>--Select--</option>
                            <option value="PHONE">Phone</option>
                            <option value="FAX">Fax</option>
                        </Input>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="input-field">
                        <input name="index" id="index" type="hidden" />
                        <Label lblhtmlFor="contactValue"  lblname={"Contact Number"+Constant.STAR} lblclassName={'active'} />
                        <CustomMaskedInput intId="contactValue" intType="text" intMask={Constant.REGEX_NUMBER_MASK} intName="contactValue"  intClassName={this.state.isContactValueEmpty? 'validate invalid':null}/>

                    </div>
                </div>
                {(this.state.isAnyApiData||(this.state.ContactTypeData==="FAX"&&this.state.oneFaxPreferred||this.state.ContactTypeData==="PHONE"&&this.state.onePhonePreferred))?"":
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className = "form-group p20">
                            <input type="checkbox" name="preferredContact" id="preferredContact" className="form-control preferredAddress pull-left "/>
                            <label htmlFor="preferredContact" className="show pull-left">Preferred</label>
                        </div>
                    </div>}


            </div>
        )
    }

    componentDidMount() {
        this.setState({isAnyApiData:false})
        this.getContactData();
     }

    viewDocuments = (event,row) =>{
        let queryString = '';
        queryString +='?docId='+row.id;
        let pathUrl =  Constant.PRESCRIBER_PROFILE_DOCUMENT_PATH + queryString

        this.setState({srcUrl:pathUrl});
    }

    setPreferredIcon = (cell,row) => {
        if(row.isPreferred) {
            return (
                <div className = "form-group">
                    <input type="checkbox" name="preferred" id="preferred" className="form-control preferredAddress pull-left " checked={row.isPreferred?true:false}/>
                    <label htmlFor="preferred" className="show pull-left"></label>
                </div>
            );
        }
    }

    setContactType = (cell,row) => {
        return (
            <label htmlFor="contactInput" className="form-control phone phoneNumber">{row.contactType}</label>
        );
    }

    validateContact = () => {
        let isValid = true;
        let contactTypeId = document.getElementById('contactType');
        let contactType = contactTypeId.options[contactTypeId.selectedIndex].value;
        if(contactType === "" || contactType === null) {
            isValid = false
            window.Materialize.toast("Please Select Contact Type", 3000,  'red rounded')
            this.setState({isContactTypeEmpty : true});
        } else {
            this.setState({isContactTypeEmpty : false});
        }
        // if($("#contactValue").val() === ""){
        //     isValid = false
        //     this.setState({isContactValueEmpty : true});
        //      }
         if($("#contactValue").val() === ""){
            isValid = false
            this.setState({isContactValueEmpty : true});
            window.Materialize.toast("Please Enter Contact Number", 3000,  'red rounded')

        }else if(($("#contactValue").val().match(Constant.PHONE_NUMBER_REGX1)!=null||$("#contactValue").val().match(Constant.PHONE_NUMBER_REGX)==null )&&($("#contactValue").val().match(Constant.PHONE_NUMBER_REGX1)==null||$("#contactValue").val().match(Constant.PHONE_NUMBER_REGX)!=null )){
            isValid = false
            window.Materialize.toast("Please Enter Valid Contact Number", 3000,  'red rounded')
            this.setState({isContactValueEmpty : true});

        }

        return isValid
    }

    addContactListener = () => {
        $("#contactType option[value='']").prop("disabled",false);
        this.setState({fromEdit:false, isContactTypeEmpty:false, isContactValueEmpty:false})
        $("#contactType,#contactValue").prop("disabled",false);
        $("#contactType").val("");
        $("#contactValue").val("");
        $("#preferredContact").prop('checked',false);
        this.checkContactLength();
    }

    checkContactLength = (name) => {

        let contactData = this.state.contactData
        let countPhone = 0;
        let countFax = 0;
        let isPreferredcountPhone=0
        let isPreferredcountFax=0
        let data
        for(let i=0; i<contactData.length; i++) {
            if (contactData[i].contactType === "PHONE") {
                countPhone++;
            }
            if(contactData[i].contactType === "FAX") {
                countFax++;
            }
            if (contactData[i].contactType === "PHONE"&&contactData[i].isPreferred) {
                isPreferredcountPhone++
            }
            if(contactData[i].contactType === "FAX"&&contactData[i].isPreferred) {
                isPreferredcountFax++
            }
        }
        if(isPreferredcountPhone>0){
                this.setState({onePhonePreferred:true})
        }else{
            this.setState({onePhonePreferred:false})
        }
        if(isPreferredcountFax>0){
            this.setState({ oneFaxPreferred:true})

        }else{
            this.setState({ oneFaxPreferred:false})

        }
        if(name!==undefined){
             data=name
        }
        if(data!=="fromedit"){
        if(countPhone === 3) {
            $("#contactType option[value='PHONE']").prop("disabled",true);
        } else {
            $("#contactType option[value='PHONE']").prop("disabled",false);
        }
        if(countFax === 3) {
            $("#contactType option[value='FAX']").prop("disabled",true);
        } else {
            $("#contactType option[value='FAX']").prop("disabled",false);
        }
    }
        if(countPhone === 3 && countFax === 3) {
            $("#modalId").attr("disabled","disabled");
            $("#btnAddContact").attr("disabled","disabled");

        } else {
            $("#modalId").attr("disabled",false);
            $("#btnAddContact").attr("disabled",false);
        }
    }

    editContactListener = (event,row) => {
        let id = contactList[row.index].id
        this.setState({editContactId:id, isContactTypeEmpty:false, isContactValueEmpty:false,isAnyApiData:false});
        let mdContact = contactList[row.index];
        if(row.contactValue.length===17){
            this.setState({mask:Constant.REGEX_NUMBER_MASK1})
        }
        this.setState({fromEdit:true})
        if(mdContact.fromApi) {
            $("#contactType,#contactValue").prop("disabled",true);
        } else {
            $("#contactType,#contactValue").prop("disabled",false);
            if(mdContact.isPreferred) {
                isPreferredGloble=true
                $("#preferredContact").prop('checked',true);
            } else {
                isPreferredGloble=false
                $("#preferredContact").prop('checked',false);

            }
        }
        this.setState({ContactTypeData:mdContact.contactType})
        console.log("------------isAnyApiData---------"+row.isPreferred)
        if(row.isPreferred){
            console.log("------------isAnyApiData---------"+this.state.isAnyApiData)
            this.setState({isAnyApiData:true})
        }

        $("#contactValue").val(mdContact.contactValue);
        //let contactTypeId = document.getElementById('contactType');
        //contactTypeId.options[contactTypeId.selectedIndex].value(mdContact.contactType);
        $("#contactType").val(mdContact.contactType);
        $("#index").val(row.index);
        $("#contactType").prop("disabled",true);
        this.checkContactLength("fromedit");
    }

    setContactActionIcon = (cell,row) => {
        let styleClass = { display :'none'}
        if(!row.fromApi) {
            styleClass.display = 'block inline';
            return (
                <div className='actions-list-div'>
                 <ReactTooltip place="top" html={true}/>
                    <a href='#' onClick={(event) => this.editContactListener(event,row)} data-toggle="modal"  data-target="#addContactModal"  data-tip='Edit'>
                        <i className='themify-icons ti-pencil-alt'></i>
                    </a>

                    <a href='#' onClick={(event) => this.setRemoveContact(event,row)} data-toggle="modal" data-target="#deleteModal" style={styleClass} data-tip='Delete'>
                        <i className='themify-icons ti-trash '></i>
                    </a>
                </div>
            )
        }
    }

    setRemoveContact = (event,row) =>{
        let id = contactList[row.index].id
        this.setState({removeContactId:id});
     }

    removeContactListener = (event) => {
        let id = this.state.removeContactId;
        axios.put('/prescriberProfile/deletePrescriberContact?id='+id)
            .then(response => {
                if(response.data) {
                    window.Materialize.toast("Contact deleted successfully", 3000,  'green rounded')
                    this.getContactData();
                    this.checkContactLength();
                } else {
                    window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                }
                $('#deleteContactCancelModal').click();
            })
            .catch(function (error) {
               console.log("Exception occur"+error)
            });
     }

     deleteContactModal(){
        return(
            <div id="deleteModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete Contact</h4>
                       </div>
                       <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <p>Are you sure you want to delete this contact?</p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="deleteContact" btnName="Ok" className="btn btn-primary wizard-next " click={this.removeContactListener} />
                       <button type="button" id="deleteContactCancelModal" className="btn btn-primary wizard-next " data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }


    setActionIcon = (cell,row) => {
        let styleClass = { display :'none'}
        if(!row.fromApi){
            styleClass.display = 'block inline';
        }

        return (
                <div className='actions-list-div'>
                 <ReactTooltip place="top" html={true}/>
                    <a href='#'  data-toggle="modal" onClick={(event) => this.viewDocuments(event,row)} data-position='top'  data-target="#viewPrescriberProfile"  data-tip="View" >
                    <i className="fa fa-eye"></i>
                    </a>

                    <a href='#'  onClick={(event) => this.props.setRemoveConsentDocument(event,row)}  data-toggle="modal"  data-target="#deleteConsentModal"  style={styleClass}  data-tip="Delete"  >
                    <i className="fa fa-trash"></i>
                    </a>
                </div>
            )
    }

    render() {

        $(".specialCharRestriction").on('keypress keyup blur', function (event) {
            // var regex = new RegExp("^[#%^&{}[\\]]+$");
            var regex = new RegExp("^[#%^&{}[\\]]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                event.preventDefault();
                return false;
            }
         });
         //specific characters allowed ,alphabets allowed,digit allowed
         $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._']+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });

        $('.noSpace').keyup(function() {
            this.value = this.value.replace(/\s/g,'');
           });

        const cellEditProp = {
        mode: 'click',
        blurToSave: true,
      };
    //   console.log("this.props.documentTest"+this.props.documentTest);

        let allPrescriberApiData = this.props.prescriberApiData
        let prescriberApiData = allPrescriberApiData[0]
        let basic = prescriberApiData ? prescriberApiData.basic : ''
        let addresses = prescriberApiData ? prescriberApiData.addresses[0] : []
        let user = this.props.userData[0]?this.props.userData[0]:''
        // console.log(user.resetPasswordMethod)
        let resetPasswordMethod = user.resetPasswordMethod ? user.resetPasswordMethod : ''
        let userData = user ? user : []
        let prescriber = userData.prescriber ? userData.prescriber : []
        prescriberId = prescriber.id
        let userAddresses = prescriber.address ? prescriber.address[0] : []
        let userTaxonomies = prescriber.taxonomies ? prescriber.taxonomies : []
        let userLicence = prescriber.license ? prescriber.license : []
        let userContact = prescriber.contact ? prescriber.contact : []
        let roleTitle = userData.roleTitle ? userData.roleTitle : null
        let roleList = userData.role ? userData.role : []
        let email = null;
        let faxOne = null;
        let roleTitleId = 0
        let roleId = 0

        if (roleTitle != null) {
            roleTitleId = roleTitle.id
        }

        if (roleList.length > 0) {
            roleId = roleList[0].id
        }

        for (let i = 0; i < userContact.length; i++) {
            if (userContact[i].contactType == "PHONE") {
                //phone = userContact[i].contactValue;
                //contactList.push({id:userContact[i].id,contactType:"PHONE",contactValue:phone})
            }
            if (userContact[i].contactType == "EMAIL") {
                email = userContact[i].contactValue;
            }

            if (userContact[i].contactType == "FAX") {
                if (faxOne === null) {
                    faxOne = userContact[i].contactValue;
                    //contactList.push({id:userContact[i].id,contactType:"FAX",contactValue:faxOne})
                } else {
                    //faxTwo = userContact[i].contactValue;
                    //contactList.push({id:userContact[i].id,contactType:"FAX",contactValue:faxTwo})
                }
            }
        }

        // let contactData = this.state.contactData;
        // let contactList = [];
        // for(let i = 0;i < contactData.length; i++) {
        //     let innerJson = {"index":contactData[i].id,"contactType":contactData[i].contactType,"contactValue":contactData[i].contactValue}
        //     contactList.push(innerJson);
        // }

        let userStateLicence = []
        let deaNumber = null
        for (let i = 0; i < userLicence.length; i++) {
            if (userLicence[i].licenseType===Constant.STATE_LICENSE) {
                userStateLicence.push(userLicence[i]);
            }
            if (userLicence[i].licenseType === "DEA") {
                deaNumber = userLicence[i].licenseValue;
            }
        }


        let ProfileValidate = { ...this.props.ProfileValidate }
        return (
            <div>
                <div className="prescriberProfile">
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
							<Label lblhtmlFor="username" lblclassName={(ProfileValidate.userNameValid) ? 'active' : null} lbldataError={ProfileValidate.userNameValid ? "Please Enter username" : null} lblname={Constant.LABEL_USER_NAME + Constant.STAR} />
                            <input type="hidden" value={userData.id} id="userId" name="userId" />
                            <input type="hidden" value={userData.password} id="password" name="password" />
                            <input type="hidden" value={prescriber.id} id="prescriberId" name="prescriberId" />
                            <input type="hidden" value={roleTitleId} id="roleTitleId" name="roleTitleId" />
                            <input type="hidden" value={roleId} id="roleId" name="roleId" />
                            <input type="hidden" value={basic ? basic.status : prescriber.npiStatus} id="npiStatus" name="npiStatus" />
                            <input type="hidden" value={userData.status} id="userStatus" name="userStatus" />
                            <input type="hidden" value={userData.approvalStatus} id="userApprovalStatus" name="userApprovalStatus" />
                            <input type="hidden" value={userData.userKey} id="userKey" name="userKey" />

                            {/*<Input1 intid="username" intName={Constant.INPUT_USER_NAME} intType="text" intClasssName={ProfileValidate.userNameValid ? 'validate invalid' : 'validate'} value={userData.username} />
                            <Label lblhtmlFor="username" lblclassName={(ProfileValidate.userNameValid) ? 'active' : null} lbldataError={ProfileValidate.userNameValid ? "Please Enter username" : null} lblname={Constant.LABEL_USER_NAME + Constant.STAR} />*/}
                           
                             <Input1 intid="username" intName={Constant.INPUT_USER_NAME} intType="text" intClasssName={'specialCharRestriction alphanumeric noSpace'} value={userData.username} fromApi={false}/>

                        </div>

                    </div>
                    {userData.prefix ? <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor={Constant.LABEL_PREFIX} lblclassName={(userData.prefix) ? 'active' : null} lblname={Constant.LABEL_PREFIX + Constant.STAR} />
                           <Input1 intid="prefix" intName={Constant.INPUT_PREFIX} intType="text" fromApi={userData.prefix ? true : false} required value={basic.name_prefix ? basic.name_prefix : userData.prefix} />

                        </div>
                    </div> : <Input xl={2} l={3} m={6} s={12} type='select' onChange={this.onChangeListner} required id="prefix" label={Constant.LABEL_PREFIX} name={Constant.INPUT_PREFIX} >
                        <option  selected value="" >--Select--</option>
                        <option selected = {userData.prefix === Constant.PREFIX_VALUE_DR} value={userData.prefix === Constant.PREFIX_VALUE_DR ? userData.prefix : Constant.PREFIX_VALUE_DR}>{userData.prefix === Constant.PREFIX_VALUE_DR ? userData.prefix : Constant.PREFIX_NAME_DR}</option>
                        <option selected = {userData.prefix === Constant.PREFIX_VALUE_MISS} value={userData.prefix === Constant.PREFIX_VALUE_MISS ? userData.prefix : Constant.PREFIX_VALUE_MISS}>{userData.prefix === Constant.PREFIX_VALUE_MISS ? userData.prefix : Constant.PREFIX_NAME_MISS}</option>
						<option selected = {userData.prefix === Constant.PREFIX_VALUE_MR} value={userData.prefix === Constant.PREFIX_VALUE_MR ? userData.prefix : Constant.PREFIX_VALUE_MR}>{userData.prefix ===Constant.PREFIX_VALUE_MR ? userData.prefix : Constant.PREFIX_NAME_MR}</option>
						<option selected = {userData.prefix === Constant.PREFIX_VALUE_MRS} value={userData.prefix === Constant.PREFIX_VALUE_MRS ? userData.prefix : Constant.PREFIX_VALUE_MRS}>{userData.prefix === Constant.PREFIX_VALUE_MRS ? userData.prefix : Constant.PREFIX_NAME_MRS}</option>
						<option selected = {userData.prefix === Constant.PREFIX_VALUE_MS} value={userData.prefix === Constant.PREFIX_VALUE_MS ? userData.prefix : Constant.PREFIX_VALUE_MS}>{userData.prefix === Constant.PREFIX_VALUE_MS ? userData.prefix : Constant.PREFIX_NAME_MS}</option>
                        <option selected = {userData.prefix === Constant.PREFIX_VALUE_PROF} value={userData.prefix === Constant.PREFIX_VALUE_PROF ? userData.prefix :Constant.PREFIX_VALUE_PROF}>{userData.prefix === Constant.PREFIX_VALUE_PROF ? userData.prefix : Constant.PREFIX_NAME_PROF}</option>
                        </Input>}



                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="fname" lblclassName={(basic.first_name || userData.firstName) ? 'active' : null} lblname={Constant.LABEL_FIRST_NAME + Constant.STAR} />
                            <Input1 intid="fname" intType="text" value={basic.first_name ? basic.first_name : userData.firstName} fromApi={true} intClasssName={this.props.firstNameValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="mname" lblclassName={(basic.middle_name || userData.middleName) ? 'active' : null} lblname={Constant.LABEL_MIDDLE_NAME} />
                            <Input1 intid="mname" value={basic.middle_name ? basic.middle_name : userData.middleName} intType="text" fromApi={basic.middle_name ? true : false} />

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="lname" lblclassName={(basic.last_name || userData.lastName) ? 'active' : null} lbldataError={this.props.lastNameValid ? Constant.LAST_NAME : null} lblname={Constant.LABEL_LAST_NAME + Constant.STAR} />
                            <Input1 intid="lname" intType="text" value={basic.last_name ? basic.last_name : userData.lastName} fromApi={true} intClasssName={this.props.lastNameValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_LAST_NAME} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="address1" lblclassName={(addresses.address_1 || userAddresses.address1) ? 'active' : null} lbldataError={this.props.addressLine1Valid ? Constant.ADDRESS_LINE_1 : null} lblname={Constant.LABEL_ADDRESS_LINE_1 + Constant.STAR} />
                            <Input1 intid="address1" intType="text" value={addresses.address_1 ? addresses.address_1 : userAddresses.address1} fromApi={true} intClasssName={this.props.addressLine1Valid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_ADDRESSLINE_1} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="address2" lblclassName={(addresses.address_2 || userAddresses.address2) ? 'active' : null} lblname={Constant.LABEL_ADDRESS_LINE_2} />
                            <Input1 intid="address2" intType="text" change={this.props.change} value={addresses.address_2 ? addresses.address_2 : userAddresses.address2} fromApi={addresses.address_2 ? true : false} intStep={Constant.STEP_FOUR_PRESCRIBER} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="city" lblclassName={addresses.city ||userAddresses? 'active' : null} lbldataError={this.props.cityValid ? Constant.CITY : null} lblname={Constant.LABEL_CITY + Constant.STAR} />
                            <Input1 intid="city" intType="text" value={addresses.city ? addresses.city : userAddresses.city} fromApi={true} intClasssName={this.props.cityValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_CITY} />
                        </div>

                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                        <Label lblhtmlFor="state_full_name" lblclassName={addresses.state_full_name ||userAddresses.state_full_name? 'active' : null} lbldataError={this.props.stateValid ? Constant.STATE : null} lblname={Constant.LABEL_STATE + Constant.STAR} />
                            <input type="hidden" value={addresses.state ? addresses.state : userAddresses.state} id="state" name="state" />
                        <Input1 intid="state_full_name" intType="text" value={addresses.state_full_name ? addresses.state_full_name : userAddresses.state} fromApi={true} intClasssName={this.props.stateValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_STATE_FULL_NAME} />
                    </div>

                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="zip" lblclassName={addresses.postal_code||userAddresses.zip ? 'active' : null} lbldataError={this.props.zipValid ? Constant.ZIP : null} lblname={Constant.LABEL_ZIP + Constant.STAR} />
                            <Input1 intid="zip" intType="text" value={addresses.postal_code ? addresses.postal_code : userAddresses.zip} fromApi={true} intClasssName={this.props.zipValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_ZIP} />
                        </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Input1 intid="workPhone" intType="text" value={addresses.telephone_number ? addresses.telephone_number : phone} fromApi={addresses.telephone_number ? true : false} intClasssName={this.props.workphoneValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_WORK_PHONE} />
                            <Label lblhtmlFor="workPhone" lblclassName={addresses.telephone_number || phone ? 'active' : null} lbldataError={this.props.workphoneValid ? Constant.WORK_PHONE : null} lblname={Constant.LABEL_WORK_PHONE + Constant.STAR} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <CustomMaskedInput intId="faxOne" intType="text" value={addresses.fax_number ? addresses.fax_number : faxOne} fromApi={addresses.fax_number ? true : false} required intMask={Constant.REGEX_NUMBER_MASK} intName={Constant.INPUT_FAX_1} />
                            <Label lblhtmlFor="faxOne" lblclassName={addresses.fax_number || faxOne ? 'active' : null} lblname={Constant.LABEL_FAX_1} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <CustomMaskedInput intId="faxTwo" intType="text" value={faxTwo} fromApi={false} required intMask={Constant.REGEX_NUMBER_MASK} intName={Constant.INPUT_FAX_2} />
                            <Label lblhtmlFor="faxTwo" lblclassName={faxTwo ? 'active' : null} lblname={Constant.LABEL_FAX_2} />
                        </div>
                    </div> */}
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="email" lblclassName={ProfileValidate.emailValid ? 'active null' : 'null active'} lbldataError={ProfileValidate.emailValid ? "Please Enter Email" : null} lblname={Constant.LABEL_EMAIL + Constant.STAR} />
                            <Input1 intid="email" intType="email" value={basic.email ? basic.email : email}  intClasssName={ProfileValidate.emailValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_EMAIL} />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="npi" lblclassName={prescriberApiData || prescriber.npi ? 'active' : null} lblname={'NPI'} />
                            <Input1 intid="npi" intType="text" intClasssName='specialCharRestriction' fromApi={true} intName={Constant.INPUT_CONFIRM_EMAIL} value={prescriberApiData ? prescriberApiData.number : prescriber.npi}/>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pl0 pr0">
                    <Input xl={12} l={12} m={12} s={12} disabled id='resetpasswordmethod'className="hide-icon" type='select' label='Reset Password Method *' name='resetpassword'>
                         <option disabled value=""></option>
                        <option value="EMAIL"  selected={resetPasswordMethod === "EMAIL"?resetPasswordMethod:""}>{resetPasswordMethod} </option>
                    </Input>
                    </div>
                    {/* <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Input1 intid="resetpasswordmethod" intType="text" fromApi={prescriberApiData ? prescriberApiData.number ? true : false : false} intName={Constant.INPUT_CONFIRM_EMAIL} value={resetPasswordMethod}/>
                            <Label lblhtmlFor="resetpasswordmethod" lblclassName={resetPasswordMethod?"active null":"null active"} lblname={'Reset Password Method *'} />
                        </div>
                    </div> */}


                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                        <Label lblhtmlFor="deaNumber" lblclassName={deaNumber ? 'active ' : 'active null'} lblname={Constant.DEA} />
                          <Input1 intid="deaNumber" value={deaNumber} fromApi={false} required intName="DEA" maxlength = "9"/>
                        </div>
                    </div>
                    {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN?
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                    <div className="input-field">
                    <Label lblhtmlFor={Constant.LABEL_CREATE_AND_SHOW_PASSWORD} lblclassName='active' lblname= {Constant.LABEL_CREATE_AND_SHOW_PASSWORD}/>
                            <input type="text" className="form-control" id="displayTempPassword" name="displayTempPassword" value={this.state.randomPassword} autoComplete="off" disabled="disabled" />
                            {/* <input type="hidden" name="tempPassword" id="tempPassword" value="u3YFk2rE" className="form-control" autoComplete="off" /> */}

                            <button className="btn-link"
                                disabled = {userData.status === "INACTIVE"?"disabled":''}
                                onClick={
                                    () => {
                                        axios.get('agentProfile/generatePassword')
                                            .then(response => {
                                                this.setState({randomPassword:response.data})
                                                //$("#displayTempPassword").val(response.data)
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });
                                    }
                                }>
                                <span className="fa-passwd-reset fa-stack generatePasswordIcon"  title="Generate Password" >
                                    <i className="fa fa-undo fa-stack-2x"></i>
                                    <i className="fa fa-lock fa-stack-1x"></i>
                                </span>
                            </button>
                        </div>
                </div>:""}

                    <div className="clearfix"></div>
                </div>

                <div className="mt20">
                    <div className="tabSectionTitle clearfix">
                    <div className="col-xs-12 col-lg-12 col-md-12 pl0">
                            <h3>Taxonomy</h3>
                        </div>
                    </div>
                    <div className="responsive-table max-width-768">
                    <BootstrapTable data={userTaxonomies} striped bordered={false}>
                        <TableHeaderColumn  width="15%" dataField='type' dataFormat={this.tooltipFormatter}><span data-tip="Type">Type</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="15%" editable={ cellEditProp} dataField='amacode' dataFormat={this.amaCodeListner}><span data-tip="AMA CODE">AMA Code</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="25%" dataField='desc' dataFormat={this.tooltipFormatter}><span data-tip="Specialty Name">Specialty Name</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="15%" isKey dataField='code' dataFormat={this.tooltipFormatter}><span data-tip="Taxonomy Code">Taxonomy Code</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="15%" dataField='state_full_name' dataFormat={this.setIcon} dataFormat={this.tooltipFormatter}> <span data-tip="State">State</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="15%" dataField='license' dataFormat={this.setIcon} dataFormat={this.tooltipFormatter}> <span data-tip="License Number">License Number</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                    </BootstrapTable>
                    </div>
                </div>

                <div className="mt20">
                    <div className="tabSectionTitle clearfix">
                    <div className="col-xs-12 col-lg-12 col-md-12 pl0">
                            <h3>State License</h3>
                        </div>
                    </div>
                    <div className="responsive-table max-width-768">
                    <BootstrapTable data={userStateLicence} striped bordered={false}>
                        <TableHeaderColumn  width="33.33%" dataField='description' dataFormat={this.tooltipFormatter}><span data-tip="Issuer">Issuer</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="33.33%" dataField='state_full_name' dataFormat={this.tooltipFormatter}><span data-tip="State">State</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  width="33.33%" isKey dataField='licenseValue' dataFormat={this.tooltipFormatter}><span data-tip="Number">Number</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                    </BootstrapTable>
                    </div>

                </div>

                <div className="mt20">
                    <div className="tabSectionTitle clearfix">
                    <div className="col-xs-12 col-lg-12 col-md-12 pl0">
                            <h3 className="pull-left">Contact Information</h3>
                            <div className="pull-right">
                                <a href='#' id="modalId" onClick={this.addContactListener} data-toggle="modal" data-target="#addContactModal">
                                    <button id="btnAddContact" className="btn btn-primary wizard-next green" type="button">Add Contact</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="responsive-table max-width-768">
                    <BootstrapTable data={formattedContact} striped bordered={false}>
                        <TableHeaderColumn dataField='contactType' dataFormat={this.setContactType} width="25%">Contact Type</TableHeaderColumn>
                        <TableHeaderColumn isKey dataField='contactValue' width="25%">Contact Value</TableHeaderColumn>
                        <TableHeaderColumn dataField='isPreferred' dataFormat={this.setPreferredIcon} width="25%">Preferred</TableHeaderColumn>
                        <TableHeaderColumn dataField='fromApi' dataFormat={this.setContactActionIcon} width="25%">Action</TableHeaderColumn>
                    </BootstrapTable>
                    </div>

                </div>

                 <div className="mt20">
                    <div className="tabSectionTitle clearfix">
                    <div className="col-xs-12 col-lg-12 col-md-12 pl0">
                            <h3>Consent Documents</h3>
                        </div>
                    </div>
                    <div className="responsive-table max-width-768">
                    <BootstrapTable data={this.props.documentTest} striped bordered={false}>
                        <TableHeaderColumn   dataField='fileName' dataFormat={this.tooltipFormatter} width="25%"><span data-tip="File Name">File Name</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn  isKey dataField='description' dataFormat={this.tooltipFormatter}  width="50%"><span data-tip="Description">Description</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                        <TableHeaderColumn   dataFormat={this.setActionIcon}><span data-tip="Action" width="25%">Action</span><ReactTooltip place="top" html={true} /></TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>

            {this.viewPrescriberProfileModel()}
            {this.addContactModal()}
            {this.deleteContactModal()}
            </div>
        )
    }
}