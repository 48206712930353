import React from 'react';
import LoginInputs from './LoginInputs';
import ForgotPasswordInputs from '../../ForgotPassword/ForgotPasswordInputs';

const loginbox = (props) => {
    return (
			<div>
				<section className="loginBox forgotPasswordBox new-design">
					<div className="loginBoxDiv">
					<div className="logo-part">
						<img alt="ProntoRx" src="images/new-logo.png" className="img-responsive hidden-xs"/>
						<img alt="ProntoRx" src="images/logo-white.png" className="img-responsive visible-xs"/>
					</div>
						<div className="loginForm">
							<form action="/" method="POST">
								<div className="row">
										{props.name === 'ForgotPassword' ? <ForgotPasswordInputs
																				clickHandler={props.clickHandler}
																				change={props.change}
																				time={props.time}
																				running={props.running}
																				format={props.format}
																				disable={props.disable}
																				hideTimer={props.hideTimer}
																				questionName={props.questionName}
																				intName={props.intName}
																				stepOneDataValid = {props.stepOneDataValid}
																				stepOneDataError= {props.stepOneDataError}
																				stepOneUserIsValid = {props.stepOneUserIsValid}
																				hidePasswordValidMessage={props.hidePasswordValidMessage}
																				role = {props.role}
																				stepFiveValid = {props.stepFiveValid}
																				/>:
																			<LoginInputs
																				loginSubmit={props.loginSubmit.bind(this)}
																				username={props.username}
																				password={props.password}
																				changeLoginDetail={props.changeLoginDetail}
																				isUserNameEmpty={props.isUserNameEmpty}
                                												isPasswordEmpty={props.isPasswordEmpty}/>
										}
									</div>
							</form>
						</div>
					</div>
				</section>
				<p className="warning"></p>
			</div>
		);
	 }
export default loginbox;
