import React ,{ Component }from 'react';
import axios from 'axios';
import CustomInput from '../../Component/CustomUI/Input'
import {Input} from 'react-materialize';
import * as Constant from '../../Component/CustomUI/Constant';
import $ from 'jquery';

export default class UserSearch extends Component {

    state = {
        roleList : [],
        roleTitleList : [],
        authorizationList : [],
        statusList : [],
        defaultAuthorization : "",
        page:1, sizePerPage:100, sortName:'userName', sortOrder:'asc'
     }

     

    componentDidMount(){
        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/dashboardDefaultData')
        .then(response => {
            let data = response.data
            this.setState({roleList : data.roles})
            this.setState({authorizationList : data.authorizationList})
            this.setState({statusList : data.statusList})
            this.setState({defaultAuthorization: 'PENDING'});
        })
        .catch(function (error) {
            console.log("dashboardDefaultData Error :::::"+error);
        });

        $(".disablednumber").on('keypress keyup blur', function (event) {
            var regex = new RegExp("[0-9]");
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
			if (regex.test(key)) {
				event.preventDefault();
				return false;
			}
        });
        $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._-]+$");
           var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
           if(event.key=='Enter'||event.key==13){
            return true;
            }else if (!regex.test(key)){
                event.preventDefault();
                return false;
            }
        });

    }

    changeRoleHandler(event) {
        if(event.target.value) {
            axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/renderRoleTitle/'+event.target.value)
            .then(response => {
                this.setState({roleTitleList : response.data});
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            this.setState({roleTitleList : []});
        }

    }


    reset() {
       
        $("#firstName").val("");
        $("#lastName").val("");
        $("#email").val("");
		$("#userRole").val("");
        $("#userName").val("");
        $("#userTitle").val("");
        $("#status").val("");
        $("#usernpi").val("");
        $("#authorization").val("PENDING").change();
        this.setState({roleTitleList : []});
        this.props.searchUser(this.state.page, this.state.sizePerPage, this.state.sortName, this.state.sortOrder, true);
    }

   render () {


    const roleListData = this.state.roleList.map(role => {
        return <option data-id={role.value} key={role.id} value={role.id} >{role.name}</option>
    })

    const roleTitleList = this.state.roleTitleList.map(roleTitle =>{
        return <option value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
     });

    const authorizationListData = this.state.authorizationList.map( auth =>{
        return <option  value={auth.name} key={auth.name} selected={auth.name == "PENDING"}>{auth.value}</option>
    } );

    const statusListData = this.state.statusList.map(status =>{
        return <option value={status.name} key={status.name} >{status.value}</option>
    });

    return (
        <div>
            <section className="searchReportContainer boxWithShadow" id="searchField">
                {/* <div className="col-xs-12">
                    <h4>Search User</h4>
                </div> */}
                <div id="Search">
                    <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <label htmlFor="usernpi">NPI</label>
                                <CustomInput intid="usernpi" intType="text" intClasssName="onlyInteger" keyPress={this.props.searchData}  keyEvent='Enter' maxlength="10"/>

                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <label htmlFor="firstName">{Constant.LABEL_FIRST_NAME}</label>
                                <CustomInput intid="firstName" intType="text" intClasssName=" alphanumeric disablednumber" keyPress={this.props.searchData}  keyEvent='Enter' maxlength="30"/>

                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <label htmlFor="lastName">{Constant.LABEL_LAST_NAME}</label>
                                <CustomInput intid="lastName" intType="text" intClasssName="alphanumeric disablednumber" keyPress={this.props.searchData} keyEvent='Enter' maxlength="30"/>

                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field suffix">
                                 <label htmlFor="email">{Constant.LABEL_EMAIL}</label>
                                <CustomInput intid="email" intType="email" intClasssName="alphanumeric disablednumber" keyPress={this.props.searchData} keyEvent='Enter'/>

                                <i className="icon-envelope simple-icons"></i>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <label htmlFor="userName">{ Constant.LABEL_USER_NAME }</label>
                                <CustomInput intid="userName" intType="text"  intClasssName="alphanumeric" keyPress={this.props.searchData} keyEvent='Enter' maxlength="30"/>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pl0 pr0">
                            <Input xs={12} s={12} m={12} l={12} xl={12}  type='select' id="userRole" label="User Role" name="userRole" onChange={(event) => this.changeRoleHandler(event)}>
                                            <option value="" >--Select--</option>
                                            {roleListData}
                            </Input>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pl0 pr0">
                            <Input xs={12} s={12} m={12} l={12} xl={12}  type='select' id="userTitle" label="User Title" name="userTitle" readOnly>
                                            <option value="" >--Select--</option>
                                            {roleTitleList}
                            </Input>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pl0 pr0">
                            <Input xs={12} s={12} m={12} l={12} xl={12}  type='select' id="authorization" label="Authorization" name="authorization" readOnly>
                                            <option value="" >--Select--</option>
                                            {authorizationListData}
                            </Input>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pl0 pr0">
                            <Input xs={12} s={12} m={12} l={12} xl={12}  type='select' id="status" label="Status" name="status" readOnly>
                                            <option value="" >--Select--</option>
                                            {statusListData}
                            </Input>
                        </div>
                    </div>
                </div>
                <section className="buttonWrapper">
                    <button className="btn btn-darkpurpale" onClick={(event)=>this.props.searchData(event)}>Search</button>
                    <button className="btn  btn-white" onClick={(event)=>this.reset()}>Reset</button>
                </section>
            </section>
        </div>
   )}
}