import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

const data = [
    {firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},{firstName : 'Carl', lastName : 'Kelly', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Agent'},
    {firstName : 'R P', lastName : 'Sngh', email : 'john@smith.com', userRole: 'Nurse', userName : 'John Cena', type : 'Prescriber'},
]




export default class PendingUserApproval extends Component {


    actionIcons = () => {
        return(
            <div>
                <div className='actions-list-div'>
                    <a href='#' data-toggle="modal" data-target="#myModal" className='tooltipped view' data-tooltip='View' data-position='top'>
                        <i className="material-icons">visibility</i>
                    </a>
                    <a href='#' className='tooltipped' data-tooltip='Approve' data-position='top'>
                    <i className="material-icons">done</i>
                    </a>
                    <a href='#' className='tooltipped' data-tooltip='Decline' data-position='top'>
                        <i className="material-icons">clear</i>
                    </a>
                </div>

            </div>
        )
    }

  render() {
    return (
      <div>
        <BootstrapTable data={data} striped bordered={ true } className="table-responsive table-height">
            <TableHeaderColumn dataSort={true} isKey dataField='firstName'>First Name</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='lastName'>Last Name</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='email'>Email Address</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='userRole'>User Role</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='userName'>User Name</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='type'>Type</TableHeaderColumn>
            <TableHeaderColumn dataSort={false} dataFormat={this.actionIcons} width="200">Action</TableHeaderColumn>
        </BootstrapTable>

        <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Patient Name</h4>
                    </div>
                    <div className="modal-body">
                        <div className="agengtDetailsAdd inner-page-container">
                            <div className="col-sm-12">
                                <ul className="list-group">
                                    <li className="list-group-item"><i className="fa fa-user-circle-o" aria-hidden="true"></i> Dr.John Smith</li>
                                    <li className="list-group-item"><i className="fa fa-building" aria-hidden="true"></i> StarkMed Clinic</li>
                                    <li className="list-group-item"><i className="fa fa-plus-square" aria-hidden="true"></i> 1234567890</li>
                                    <li className="list-group-item"><i className="fa fa-user-md" aria-hidden="true"></i> Internal Medicine</li>
                                    <li className="list-group-item"><i className="fa fa-phone" aria-hidden="true"></i> 123-456-7890</li>
                                    <li className="list-group-item"><i className="fa fa-fax" aria-hidden="true"></i> 123-456-7890</li>
                                </ul>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}