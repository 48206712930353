import React from 'react';
import MaskedInput from 'react-text-mask';

export default (props) => {
    return (
<MaskedInput mask={props.intMask} 
guide={false}
required={props.required} 
    type={props.intType}
    id={props.intId}
    disabled={(props.fromApi && props.value)}
    name={props.intName}
    onBlur={props.onBlur}  
    className={props.intClassName}
    defaultValue={props.value} 
    placeholder ={props.placeholder}
    onChange={props.change ? ((event) => props.change(event,props.intStep)) : function(){}}
    />       
    );
}

    