import React, { Component } from 'react';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import SearchSection from '../../Component/PageComponent/SearchSection/SearchSection';
import {DynamicTable,DynamicHistoryTable} from '../../Component/PageComponent/Table/DynamicTable';
import axios from 'axios';
import  * as Constant  from '../../Component/CustomUI/Constant';
import Modal from 'react-modal';
import $ from 'jquery';
import jsPDF from 'jspdf';
import ReactTooltip from 'react-tooltip';
import UserProfile from '../../UserProfile';
import CustomModal from '../../../src/Component/PageComponent/Modal/CustomModal';
import UploadDoc from '../../../src/Component/PageComponent/Modal/UploadDoc';
let pharmacyStore=[]
let markAsDoneData
let child
let caseId = ""
let patientName = ""
let DOB = ""
let patientUniqueId =""
let ksPatientId = ""
let tenantId = ""
let patientId = ""
let caseDetailId = ""
let ketuCaseDetailId = ""
let ketuRxId = ""
let phRxId = ""
let prescriberId = ""
let mdDocId = ""
let ckEditorContent = ""
let rowStatusData
var dateFormat = require('dateformat');
let dashBoardDataLength = 0
let dashBoard
let dataCount = 0
let statusGridData = []
let isDefaultData="1"

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '900px',
      height                 : '600px',
      backgroundColor: '#000'
    }
  };

  Modal.setAppElement(document.getElementById("testModel"));

let files =[]
let tempFiles = []
let description = []
let documentName = []
let documentFiles =[]
let isPaginationAllow = false

export default class WorkQueue extends Component {

  constructor() {
    super();

   this.onBlur = this.onBlur.bind(this);
   this.updateContent = this.updateContent.bind(this);
   this.setDashbordCount = this.setDashbordCount.bind(this)  
  }

  state = {
    dashBoardData:'',
    followUpStatuses:[],
    therapeuticCategories:[],
    followUpReasones:[],
    hqList:[],
    userType:Constant.PRESCRIBER.toUpperCase(),
    statusData:false,
    historydata:false,
    NPIList:[],
    test:false,
    dashBoardDataCount:0,
    isLoading: false,
    historyData:'',
    historyDataCount:0,
    page:1, sizePerPage:100, sortName:'caseId', sortOrder:'desc',
    historyPage:1, historySizePerPage:100,
    historySortName:'uploaded_date',
    historySortOrder:'desc',
    content: "",
    isDocumentNameEmpty:false,
    isDocumentDescriptionEmpty:false,
    srcUrl : "",
    isModalOpen:true,
    documentName:"",
    documentTitle:"",
    defaultDataCount:0,
    totalDataCount:0

    // isWQExportClicked : false,
    // isHistoryExportClicked : false
  }
   onlyInteger = (element) => {
	$(element).keypress(function(event) {
		var keynum
		if(window.event) { // IE8 and earlier
			keynum = event.keyCode;
		} else if(event.which) { // IE9/Firefox/Chrome/Opera/Safari
			keynum = event.which;
		} else {
			keynum = 0;
		}

		if(keynum == 8 || keynum == 0 || keynum == 9 || keynum == 13) {
			return;
		}
		if(keynum <= 46
		|| keynum > 57
		|| keynum == 47) {
			event.preventDefault();
		} // prevent if not number/dot

	});

}

    onlyAlphaNumeric = (element) => {
        $(element).keypress(function(event) {
            var keynum
            if(window.event) { // IE8 and earlier
                keynum = event.keyCode;
            } else if(event.which) { // IE9/Firefox/Chrome/Opera/Safari
                keynum = event.which;
            } else {
                keynum = 0;
            }
            var regex = new RegExp("^[a-zA-Z0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if(keynum == 8 || keynum == 0 || keynum == 9 || keynum == 13) {
                return;
            }
            if (!regex.test(key)) {
            event.preventDefault();
            return false;
            }
        });
    }

    componentDidMount(){
        isDefaultData="1"
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            this.noPrescriberAssociated()
        }else{
             this.displayData();
            this.onlyInteger($("#caseId"));
            this.onlyInteger($("#caseIdHistory"));
            this.onlyAlphaNumeric($(".onlyAlphaNumeric"));
        }
        $("#Preview").attr("disabled","true");
        $("#AddBtn").attr("disabled","true");
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            $(".nav-tabs").remove();
        }
    }


    change = (event) =>{
        //event.preventDefault();
        this.value=event.target.value
        if(event.target.name === "document"){
            let document = event.target.files?event.target.files[0]:''
            tempFiles.push(document)
        }
    }

    noPrescriberAssociated = () =>{
        axios.get('/status/prescriberStatus?agentId='+UserProfile.getUserId())
        .then(response => {
            if(response.data){
                    this.displayData();
                    this.onlyInteger($("#caseId"));
                    this.onlyInteger($("#caseIdHistory"));
                    this.onlyAlphaNumeric($(".onlyAlphaNumeric"));
            }else{
                this.setState({isModalOpen:response.data})
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    }

    click = () => {
        let isValid = this.documentValidation();
        if(isValid) {
            let filesTemp = []
            let descriptionTemp = []
            let documentNameTemp = []
            filesTemp.push(tempFiles[tempFiles.length-1])
            descriptionTemp.push($("#npdescriptioni").val())
            documentNameTemp.push($("#documentName").val())
            files.push(tempFiles[tempFiles.length-1])
            description.push($("#npdescriptioni").val())
            documentName.push($("#documentName").val())
            documentFiles.push({filesTemp,descriptionTemp,documentNameTemp})
            tempFiles = []
            $("#npdescriptioni").val("")
            $("#documentName").val("")
            $("#fileType").val("")
            $("#file").val("")

            this.setState({clicked:true})
        }
    }
    documentValidation = () => {
        let isValid = true;
        if($("#fileType").val() === "") {
            isValid = false;
            this.setState({isDocumentSelected : true});
        } else {
            this.setState({isDocumentSelected : false});
        }

        // if($("#npdescriptioni").val() === "") {
        //     isValid = false;
        //     this.setState({isDocumentDescriptionEmpty : true});
        // } else {
        //     this.setState({isDocumentDescriptionEmpty : false});
        // }

        if($("#documentName").val() ===null || $("#documentName").val() ==="--Select--") {
            isValid = false;
            // this.setState({isDocumentNameEmpty : true});
            window.Materialize.toast("Please select type of document in upload document Section", 5000,  'red rounded')

        } else {
            // this.setState({isDocumentNameEmpty : false});
        }
        return isValid;
    }

    chartNotesValidation = () => {
        let isValid = true;
        // console.log( $("#documentTitle").val())
        if($("#documentTitle").val() === null || $("#documentTitle").val()==="--Select--") {
            isValid = false;
            window.Materialize.toast("Please select type of document in create your own Section", 5000,  'red rounded')
        }
        return isValid;
    }

    elementHandler = {
        '#ignorePDF': function (element, renderer) {
            return true;
        }
    };

    jsPdf = (event) => {
        //event.preventDefault();


            let content =""
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            var date = mm+"/"+dd+"/"+yyyy

             content +='Patient First Name:<b>'+patientName+'</b>'
             content +='Patient Last Name:<b>'+'Last Name'+'</b>'
             content +='DOB:<b>'+DOB+'</b>'

            content += ckEditorContent

            content +='Authorized by:'
            content += '<br/>Submitted by:'+UserProfile.getUserName()
            content +='<br/>Submission date:'+date

            let doc = new jsPDF();
          doc.fromHTML(
            content,
            15,
            15,
            {
            'width': 180,'elementHandler':this.elementHandler
            });
            // doc.setFontSize(12);
            // doc.text('Patient First Name:'+patientName, 10, 10)
            // doc.text('Patient Last Name:', 80, 10)
            // doc.text('DOB:'+DOB, 150, 10)
            // doc.text('Authorized by:', 10,285)
            // doc.text('Submitted by:'+UserProfile.getUserName(), 10, 290)
            // doc.text('Submission date:'+date, 10, 295)
            window.open(doc.output('bloburl'),'Window','toolbar=0');
    }

    uploadDoc = (event) => {

        //event.preventDefault();
        let isValid = this.chartNotesValidation();
        if(isValid) {
            let docTitle = $("#documentTitle").val();

            let content =""
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            var date = mm+"/"+dd+"/"+yyyy

             content +='Patient First Name:<b>'+patientName+'</b>'
             content +='Patient Last Name:<b>'+'Last Name'+'</b>'
             content +='DOB:<b>'+DOB+'</b>'

            content += ckEditorContent

            content +='Authorized by:'
            content += '<br/>Submitted by:'+UserProfile.getUserName()
            content +='<br/>Submission date:'+date
            // let content = this.state.content
            let doc = new jsPDF();
            doc.fromHTML(
                content,
                15,
                15,
                {
                'width': 180,'elementHandler':this.elementHandler
                });

            let document = doc.output('blob')

            var file = new File([document], docTitle+".pdf", {type: "application/pdf", lastModified: Date.now()});
            let filesTemp = []
            let descriptionTemp = []
            let documentNameTemp = []
            filesTemp.push(file);
            descriptionTemp.push("");
            documentNameTemp.push(docTitle)
            files.push(file);
            description.push("");
            documentName.push(docTitle)
            documentFiles.push({filesTemp,descriptionTemp,documentNameTemp})
            $('#documentTitle').prop('selectedIndex',0);
            // this.setState({content:"",documentTitle:""});
            this.setState({clicked:true})
        }
    }

    onBlur(evt){
        ckEditorContent = evt.editor.getData();
        if(ckEditorContent.trim().length>0){
            $('#Preview').removeAttr("disabled")
            $('#AddBtn').removeAttr("disabled")
        }else{
            $("#Preview").attr("disabled","true");
            $("#AddBtn").attr("disabled","true");
        }


    }
    
    updateContent(newContent) {
        this.setState({
            content: newContent
        })
    }
changFlag=()=>{
    window.location.reload();
     isDefaultData ="1";
    }
callDisplayData=()=>{
     this.displayData();
}
    displayData = () =>{   
       isDefaultData ="1"
        let limit = 100;
        let offset = 0;
        let sortName = 'caseId';
        let sortOrder = 'desc';
        let patientFirstName = ($("#patientFirstName").val() != undefined) ? $("#patientFirstName").val() : "";
        let patientLastName = ($("#patientLastName").val() != undefined) ? $("#patientLastName").val() : "";
        let dob = ($("#dob").val() != undefined) ? $("#dob").val() : "";
        let therapeuticProgram = ($("#therapeuticProgram").val() != undefined) ? $("#therapeuticProgram").val() : "";
        let drugName = ($("#drugName").val() != undefined) ? $("#drugName").val() : "";
        let phRxId = ($("#phRxId").val() != undefined) ? $("#phRxId").val() : "";
        let prescriberFirstName = ($("#prescriberFirstName").val() != undefined) ? $("#prescriberFirstName").val() : "";
        let prescriberLastName = ($("#prescriberLastName").val() != undefined) ? $("#prescriberLastName").val() : "";
        let pharmacyStore = ($("#pharmacyStore").val() != undefined) ? $("#pharmacyStore").val() : "";
        let caseId = ($("#caseId").val() != undefined) ? $("#caseId").val() : "";
        let followUpStatus = ($("#followUpStatus").val() != undefined) ? $("#followUpStatus").val() : "";
        let followUpReason = ($("#followUpReason").val() != undefined) ? $("#followUpReason").val() : "";
        let queryString = '';
        let NPIList = this.state.NPIList.toString();
        queryString += '?patientFirstName='+patientFirstName.trim()
        queryString += '&patientLastName='+patientLastName.trim()
        queryString += '&dob='+dob
        queryString += '&therapeuticProgram='+therapeuticProgram
        queryString += '&drugName='+drugName.trim()
        queryString += '&phRxId='+phRxId.trim()
        if(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_PRESCRIBER){
            queryString += '&prescriberFirstName='+prescriberFirstName.trim()
            queryString += '&prescriberLastName='+prescriberLastName.trim()
        }else{
            queryString += '&prescriberFirstName='
            queryString += '&prescriberLastName='
        }
        queryString += '&pharmacyStore='+pharmacyStore
        queryString += '&caseId='+caseId
        queryString += '&followUpStatus='+followUpStatus
        queryString += '&followUpReason='+followUpReason
        queryString += '&isDecending=1'
        queryString += '&limit='+limit
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        queryString += '&NPI='+NPIList
        queryString += '&isDefaultData='+"1"
        queryString += '&includeDocDone='+($("#includeDocDone ").prop("checked") ? "1" : "0")
        queryString += '&includeComplete='+($("#includeComplete ").prop("checked") ? "1" : "0")
        this.setState({
             isLoading: true
        })
        axios.get('/status/displayData'+queryString)
        .then(response => {
            let data = response.data
            if(data[0] && data[0].length != 0){
                isPaginationAllow = true

                dashBoard = JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : []
                dashBoardDataLength = dashBoard.length
                dataCount = 0;
                dataCount = (JSON.parse((response.data[1].resultCount))[0]);
                statusGridData = []

                for(var i=0;i<dashBoardDataLength;i++){
                    if(dashBoard[i].caseId != null){
                        if(parseInt(dashBoard[i].caseId) < 1){
                            dashBoard[i].caseId = "";
                        }

                        let innerData= {caseId :dashBoard[i].caseId, caseDetailId :dashBoard[i].caseDetailId,
                        patientName : dashBoard[i].patientName, dateOfBirth :dateFormat(dashBoard[i].dateOfBirth, "mm/dd/yyyy"),
                        therapeuticCategoryName : dashBoard[i].therapeuticCategoryName, drug : dashBoard[i].drug,
                        organizationName : dashBoard[i].organizationName, prescriber : dashBoard[i].prescriber,
                        insurance :dashBoard[i].insurance, followUpStatus : dashBoard[i].followUpStatus,
                        patientUniqueId:dashBoard[i].ketuPatientId, patientId:dashBoard[i].patientId, ksPatientId:dashBoard[i].ksPatientId,
                        tenantId: dashBoard[i].tenantId, ketuCaseDetailId: dashBoard[i].ketuCaseDetailId, userPermissions: dashBoard[i].userPermissions,
                        ketuRxId: dashBoard[i].ketuRxId,
                        phRxId: dashBoard[i].phRxId,
                        prescriberId: dashBoard[i].prescriberId,
                        mdDocId:dashBoard[i].mdDocId,
                        rxCaseTc :dashBoard[i].rxCaseTc,
                        docDone:dashBoard[i].docDone,md:dashBoard[i].md,
                        mdid:dashBoard[i].mdid,
                        includeComplete:dashBoard[i].includeComplete,
                        caseStatus:dashBoard[i].caseStatus}
                        statusGridData.push(innerData)
                    }
                }

                this.setState({followUpStatuses:JSON.parse(JSON.stringify(data[0].followUpStatuses)),
                    therapeuticCategories:JSON.parse(JSON.stringify(data[0].therapeuticCategories)),
                    hqList:JSON.parse(JSON.stringify(data[0].clientList)),
                    dashBoardData:JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : [],
                    dashBoardDataCount:(JSON.parse((response.data[1].resultCount))[0]),
                    historydata:false,
                    isLoading: false
                })

            }
        })
        .catch(function (error) {
           // window.location.reload();
            console.log(error);
        });


    }

    getFollowUpReasonListener = (event) =>{
        //event.preventDefault();
         let value=''
        if(event){
             value = event.value?event.value:'';
        }
        if(value){
            this.setState({
                isLoading: true
            })
            axios.get('/status/getFollowUpReason/'+value)
            .then(response => {
                let data = response.data
                this.setState({followUpReasones:[]})
                if(data[0].followUpReasones){
                    this.setState({followUpReasones:JSON.parse(JSON.stringify(data[0].followUpReasones)), isLoading:false})
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }else{
            this.setState({followUpReasones:[]})
        }

    }

    createExportQueryString = () =>{

        axios.post('/auth/isUserLoggedIn?path='+window.location.href)
        .then(response => {
            if(response.data.userId === undefined) {
              return false;
            }
        })
        .catch(function (error) {
          return false;
        });

        let sortName = this.state.sortName;
        let sortOrder = this.state.sortOrder;

        let queryString = ''
        let NPIList = [];
        queryString += '?patientFirstName='+$("#patientFirstName").val().trim()
        queryString += '&patientLastName='+$("#patientLastName").val().trim()
        queryString += '&dob='+$("#dob").val()
        queryString += '&therapeuticProgram='+$("#therapeuticProgram").val()
        queryString += '&drugName='+$("#drugName").val().trim()
        queryString += '&phRxId='+$("#phRxId").val().trim()
        if(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_PRESCRIBER){
            queryString += '&prescriberFirstName='+$("#prescriberFirstName").val().trim()
            queryString += '&prescriberLastName='+$("#prescriberLastName").val().trim()
        }else{
            queryString += '&prescriberFirstName='
            queryString += '&prescriberLastName='
        }

        // var pharmacyStoreObj = $("input[name='pharmacyStore']");
        var followUpStatusObj = $("input[name='followUpStatus']");
        var followUpReasonObj = $("input[name='followUpReason']");

        var followUpStatusLen = followUpStatusObj.length;
        var followUpStatus = '';
        for(var i=0;i<followUpStatusLen;i++){
            followUpStatus = followUpStatus + (followUpStatus !="" ? "," : "") +followUpStatusObj[i].value;
        }
        var followUpReasonLen = followUpReasonObj.length;
        var followUpReason = '';
        for(var count=0;count<followUpReasonLen;count++){
            followUpReason = followUpReason + (followUpReason !="" ? "," : "") +followUpReasonObj[count].value;
        }
        // var pharmacyStoreLen = pharmacyStoreObj.length;
        // var pharmacyStore = '';
        // for(var i=0;i<pharmacyStoreLen;i++){
        //     pharmacyStore = pharmacyStore + (pharmacyStore !="" ? "," : "") +pharmacyStoreObj[i].value;
        // }
        queryString += '&pharmacyStore='+pharmacyStore
        queryString += '&caseId='+$("#caseId").val()
        queryString += '&followUpStatus='+followUpStatus
        queryString += '&followUpReason='+followUpReason
        queryString += '&isDecending=0'
        queryString += '&limit=100000000'
        queryString += '&offset=0'
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        queryString += '&NPI='+NPIList.toString();
        queryString += '&isDefaultData='+isDefaultData;
        queryString += '&includeDocDone='+($("#includeDocDone ").prop("checked") ? "1" : "0")
        queryString += '&includeComplete='+($("#includeComplete ").prop("checked") ? "1" : "0")

        window.location = Constant.EXPORT_WORKQUEUE_URL + queryString;

    }
getpharmacyStoreval=(value)=>{  
    pharmacyStore=[] 
    for(let i=0;i<value.length;i++){
         pharmacyStore.push(value[i].value)
    }
}
   getSearchData = (event) => {
        isDefaultData=0
        let limit = 100;
        let offset = 0;
        //this.setState({sortName:'caseId',sortOrder:'desc'});
        let sortName = 'caseId';
        let sortOrder = 'desc';
        // let sortName = this.state.sortName;
        // let sortOrder = this.state.sortOrder;
        // const name = event.target.name
        // const value = event.target.value
        // this.setState({statusSearchData:{...this.state.statusSearchData,[name]:value}});
        // console.log(this.state.statusSearchData);
        let queryString = '';
        let NPIList = this.state.NPIList.toString();
        queryString += '?patientFirstName='+$("#patientFirstName").val().trim()
        queryString += '&patientLastName='+$("#patientLastName").val().trim()
        queryString += '&dob='+$("#dob").val()
        queryString += '&therapeuticProgram='+$("#therapeuticProgram").val()
        queryString += '&drugName='+$("#drugName").val().trim()
        queryString += '&phRxId='+$("#phRxId").val().trim()
        if(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_PRESCRIBER){
            queryString += '&prescriberFirstName='+$("#prescriberFirstName").val().trim()
            queryString += '&prescriberLastName='+$("#prescriberLastName").val().trim()
        }else{
            queryString += '&prescriberFirstName='
            queryString += '&prescriberLastName='
        }
        // var pharmacyStoreObj = this.state.pharmacyStore
        var followUpStatusObj = $("input[name='followUpStatus']");
        var followUpReasonObj = $("input[name='followUpReason']");
        var followUpStatusLen = followUpStatusObj.length;
        var followUpStatus = '';
        for(var i=0;i<followUpStatusLen;i++){
            followUpStatus = followUpStatus + (followUpStatus !="" ? "," : "") +followUpStatusObj[i].value;
        }
        var followUpReasonLen = followUpReasonObj.length;
        var followUpReason = '';
        for(var j=0;j<followUpReasonLen;j++){
            followUpReason = followUpReason + (followUpReason !="" ? "," : "") +followUpReasonObj[j].value;
        }
        // var pharmacyStoreLen = this.state.pharmacyStore.length;
        // var pharmacyStore = '';
        
        // for(var i=0;i<pharmacyStoreLen;i++){
        //     console.log(pharmacyStoreObj[i].value)
        //     pharmacyStore = pharmacyStore + (pharmacyStore !="" ? "," : "") +pharmacyStoreObj[i].value;
        // }   
        queryString += '&pharmacyStore='+pharmacyStore
        queryString += '&caseId='+$("#caseId").val()
        queryString += '&followUpStatus='+followUpStatus
        queryString += '&followUpReason='+followUpReason
        queryString += '&isDecending=0'
        queryString += '&limit='+limit
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        queryString += '&NPI='+NPIList
        queryString += '&isDefaultData='+"0"
        queryString += '&includeDocDone='+($("#includeDocDone ").prop("checked") ? "1" : "0")
        queryString += '&includeComplete='+($("#includeComplete ").prop("checked") ? "1" : "0")
        // console.log(queryString);
        this.setState({
            isLoading: true,
            sizePerPage:limit
        })
        axios.get('/status/searchData'+queryString)
        .then(response => {
            let data = response.data
            if(data[0] && data[0].length != 0){
                if(JSON.parse((response.data[1].resultCount))[0] != 0) {
                    isPaginationAllow = true
                }
                dashBoard = JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : []
                dashBoardDataLength = dashBoard.length
                dataCount = 0;
                dataCount = parseInt(JSON.parse((response.data[1].resultCount))[0]);
                statusGridData = []
                for(var i=0;i<dashBoardDataLength;i++){
                    if(dashBoard[i].caseId != null){
                        if(parseInt(dashBoard[i].caseId) < 1){
                            dashBoard[i].caseId = "";
                        }
                        let innerData= {caseId :dashBoard[i].caseId, caseDetailId :dashBoard[i].caseDetailId,
                            patientName : dashBoard[i].patientName, dateOfBirth :dateFormat(dashBoard[i].dateOfBirth, "mm/dd/yyyy"),
                            therapeuticCategoryName : dashBoard[i].therapeuticCategoryName, drug : dashBoard[i].drug,   
                            organizationName : dashBoard[i].organizationName, prescriber : dashBoard[i].prescriber,
                            insurance :dashBoard[i].insurance, followUpStatus : dashBoard[i].followUpStatus,
                            patientUniqueId:dashBoard[i].ketuPatientId, patientId:dashBoard[i].patientId, ksPatientId:dashBoard[i].ksPatientId,
                            tenantId: dashBoard[i].tenantId, ketuCaseDetailId: dashBoard[i].ketuCaseDetailId, userPermissions: dashBoard[i].userPermissions,
                            ketuRxId: dashBoard[i].ketuRxId,
                            phRxId: dashBoard[i].phRxId,
                            prescriberId: dashBoard[i].prescriberId,
                            mdDocId:dashBoard[i].mdDocId,
                            rxCaseTc :dashBoard[i].rxCaseTc,
                            docDone:dashBoard[i].docDone,md:dashBoard[i].md,
                            mdid:dashBoard[i].mdid,
                            includeComplete:dashBoard[i].includeComplete,
                            caseStatus:dashBoard[i].caseStatus}
                        statusGridData.push(innerData)
                    }
                }
                this.setState({dashBoardData:JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : [],
                    dashBoardDataCount:parseInt(JSON.parse((response.data[1].resultCount))[0]),
                    isLoading: false,page:1,sizePerPage:100})
            }
        })
        .catch(function (error) {
            console.log(error);
          //  window.location.reload();
        });
    }
    onPageChange = (page, sizePerPage) => {
        this.setState({page:page,sizePerPage:sizePerPage,isLoading: true})
        this.onSearchStatus(page, sizePerPage, this.state.sortName, this.state.sortOrder);
    }
    onSortChange = (sortName, sortOrder) => {
        this.setState({sortName:sortName,sortOrder:sortOrder,isLoading: true})
        this.onSearchStatus(this.state.page, this.state.sizePerPage, sortName, sortOrder);
    }
    onSearchStatus = (page, sizePerPage, sortName, sortOrder) => {
        let offset = (page-1)*sizePerPage;
        let queryString = '';
        let NPIList = this.state.NPIList.toString();
        queryString += '?patientFirstName='+$("#patientFirstName").val().trim()
        queryString += '&patientLastName='+$("#patientLastName").val().trim()
        queryString += '&dob='+$("#dob").val()
        queryString += '&therapeuticProgram='+$("#therapeuticProgram").val()
        queryString += '&drugName='+$("#drugName").val().trim()
        queryString += '&phRxId='+$("#phRxId").val().trim()
        if(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_PRESCRIBER){
            queryString += '&prescriberFirstName='+$("#prescriberFirstName").val().trim()
            queryString += '&prescriberLastName='+$("#prescriberLastName").val().trim()
        }else{
            queryString += '&prescriberFirstName='
            queryString += '&prescriberLastName='
        }
        // var pharmacyStoreObj = $("input[name='pharmacyStore']");
        var followUpStatusObj = $("input[name='followUpStatus']");
        var followUpReasonObj = $("input[name='followUpReason']");
        var followUpStatusLen = followUpStatusObj.length;
        var followUpStatus = '';
        for(var i=0;i<followUpStatusLen;i++){
            followUpStatus = followUpStatus + (followUpStatus !="" ? "," : "") +followUpStatusObj[i].value;
        }
        var followUpReasonLen = followUpReasonObj.length;
        var followUpReason = '';
        for(var fuCount=0;fuCount<followUpReasonLen;fuCount++){
            followUpReason = followUpReason + (followUpReason !="" ? "," : "") +followUpReasonObj[fuCount].value;
        }
        // var pharmacyStoreLen = pharmacyStoreObj.length;
        // var pharmacyStore = '';
        // for(var i=0;i<pharmacyStoreLen;i++){
        //     pharmacyStore = pharmacyStore + (pharmacyStore !="" ? "," : "") +pharmacyStoreObj[i].value;
        // }
        queryString += '&pharmacyStore='+pharmacyStore
        queryString += '&caseId='+$("#caseId").val()
        queryString += '&followUpStatus='+followUpStatus
        queryString += '&followUpReason='+followUpReason
        queryString += '&isDecending=0'
        queryString += '&NPI='+NPIList
        queryString += '&limit='+sizePerPage
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        queryString += '&isDefaultData='+isDefaultData
        queryString += '&includeDocDone='+($("#includeDocDone ").prop("checked") ? "1" : "0")
        queryString += '&includeComplete='+($("#includeComplete ").prop("checked") ? "1" : "0")

        console.log(queryString);
        axios.get('/status/searchData'+queryString)
        .then(response => {
            if(JSON.parse((response.data[1].resultCount))[0] != 0) {
                isPaginationAllow = true
            }
                dashBoard = JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : []
                dashBoardDataLength = dashBoard.length
                dataCount = 0;
                dataCount = parseInt(JSON.parse((response.data[1].resultCount))[0]);
                statusGridData = []

                for(var i=0;i<dashBoardDataLength;i++){
                    if(dashBoard[i].caseId != null){
                        if(parseInt(dashBoard[i].caseId) < 1){
                            dashBoard[i].caseId = "";
                        }

                        let innerData= {caseId :dashBoard[i].caseId, caseDetailId :dashBoard[i].caseDetailId,
                        patientName : dashBoard[i].patientName, dateOfBirth :dateFormat(dashBoard[i].dateOfBirth, "mm/dd/yyyy"),
                        therapeuticCategoryName : dashBoard[i].therapeuticCategoryName, drug : dashBoard[i].drug,
                        organizationName : dashBoard[i].organizationName, prescriber : dashBoard[i].prescriber,
                        insurance :dashBoard[i].insurance, followUpStatus : dashBoard[i].followUpStatus,
                        patientUniqueId:dashBoard[i].ketuPatientId, patientId:dashBoard[i].patientId, ksPatientId:dashBoard[i].ksPatientId,
                        tenantId: dashBoard[i].tenantId, ketuCaseDetailId: dashBoard[i].ketuCaseDetailId, userPermissions: dashBoard[i].userPermissions,
                        ketuRxId: dashBoard[i].ketuRxId,
                        phRxId: dashBoard[i].phRxId,
                        prescriberId: dashBoard[i].prescriberId,
                        mdDocId:dashBoard[i].mdDocId,
                        rxCaseTc :dashBoard[i].rxCaseTc,
                        docDone:dashBoard[i].docDone,
                        md:dashBoard[i].md,mdid:dashBoard[i].mdid,
                        includeComplete:dashBoard[i].includeComplete,
                        caseStatus:dashBoard[i].caseStatus}
                        statusGridData.push(innerData)
                    }
                }

                this.setState({dashBoardData:JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : [],
                    dashBoardDataCount:parseInt(JSON.parse((response.data[1].resultCount))[0]),
                    page:page,sizePerPage:sizePerPage,
                    isLoading:false})


		})
        .catch(function (error) {
            console.log(error);
        });
    }

    onHistoryPageChange = (page, sizePerPage) => {
        this.setState({historyPage:page})
        this.setState({historySizePerPage:sizePerPage})
        this.onSearchHistory(page, sizePerPage, this.state.historySortName, this.state.historySortOrder);
    }

    onHistorySortChange = (sortName, sortOrder) => {
        this.setState({historySortName:sortName})
        this.setState({historySortOrder:sortOrder})
        this.onSearchHistory(this.state.historyPage, this.state.historySizePerPage, sortName, sortOrder);
    }


    onSearchHistory = (page, sizePerPage, sortName, sortOrder) => {

        let offset = (page-1)*sizePerPage;

        let queryString = '';
        queryString +='?patientFirstName='+($("#fNameHistory").val() != undefined ? $("#fNameHistory").val().trim() : "")
        queryString +='&patientLastName='+($("#lNameHistory").val() != undefined ? $("#lNameHistory").val().trim() : "")
        queryString +='&dob='+($("#dobHistory").val() != undefined ? $("#dobHistory").val() : "")
        queryString +='&uploadedFrom='+($("#subDateFromHistory").val() != undefined ? $("#subDateFromHistory").val() : "")
        queryString +='&uploadedTo='+($("#subDateToHistory").val() != undefined ? $("#subDateToHistory").val() : "")
        queryString +='&subType='+($("#subTypeHistory").val() != undefined ? $("#subTypeHistory").val() : "")
        queryString +='&caseId='+($("#caseIdHistory").val() != undefined ? $("#caseIdHistory").val() : "")
        queryString +='&userFirstName='+($("#userfNameHistory").val() != undefined ? $("#userfNameHistory").val().trim() : "")
        queryString +='&userLastName='+($("#userlNameHistory").val() != undefined ? $("#userlNameHistory").val().trim() : "")
        queryString +='&documentType='+($("#documentType").val() != undefined ? $("#documentType").val().trim() : "")
        queryString += '&limit='+sizePerPage
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        this.setState({isLoading: true})
        axios.get('/history/getSearchData'+queryString)
        .then(response => {
            if(JSON.parse((response.data[1].resultCount))[0] != 0) {
                isPaginationAllow = true
            }
            this.setState({
                //historyData:parseInt(JSON.parse(response.data[0].resultCount)) > 0 ?  JSON.parse(response.data[0].result) : [],
                //historyDataCount:response.data[0] ? JSON.parse(response.data[0].resultCount) : 0,
                historyData:JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : [],
                historyDataCount:(JSON.parse((response.data[1].resultCount))[0]),
                isLoading:false
            })
        })
        .catch(function (error) {
            console.log(error);
        });
	}

    getSearchHistoryData = (event) => {

        //event.preventDefault();

        let limit = 100;
        let offset = 0;
        let sortName = 'uploaded_date';
        let sortOrder = 'desc';
        let queryString = '';
        queryString +='?patientFirstName='+($("#fNameHistory").val() != undefined ? $("#fNameHistory").val().trim() : "")
        queryString +='&patientLastName='+($("#lNameHistory").val() != undefined ? $("#lNameHistory").val().trim() : "")
        queryString +='&dob='+($("#dobHistory").val() != undefined ? $("#dobHistory").val() : "")
        queryString +='&uploadedFrom='+($("#subDateFromHistory").val() != undefined ? $("#subDateFromHistory").val() : "")
        queryString +='&uploadedTo='+($("#subDateToHistory").val() != undefined ? $("#subDateToHistory").val() : "")
        queryString +='&subType='+($("#subTypeHistory").val() != undefined ? $("#subTypeHistory").val() : "")
        queryString +='&caseId='+($("#caseIdHistory").val() != undefined ? $("#caseIdHistory").val() : "")
        queryString +='&userFirstName='+($("#userfNameHistory").val() != undefined ? $("#userfNameHistory").val().trim() : "")
        queryString +='&userLastName='+($("#userlNameHistory").val() != undefined ? $("#userlNameHistory").val().trim() : "")
        queryString +='&documentType='+($("#documentType").val() != undefined ? $("#documentType").val().trim() : "")
        queryString += '&limit='+limit
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        this.setState({isLoading: true,sizePerPage:limit})
            axios.get('/history/getSearchData'+queryString)
            .then(response => {

                if(JSON.parse((response.data[1].resultCount))[0] != 0) {
                    isPaginationAllow = true
                }

                this.setState({
                    //historyData:parseInt(JSON.parse(response.data[0].resultCount)) > 0 ?  JSON.parse(response.data[0].result) : [],
                    //historyDataCount:response.data[0] ? JSON.parse(response.data[0].resultCount) : 0,
                    historyData:JSON.parse((response.data[1].resultCount))[0] > 0 ? JSON.parse(response.data[0].result) : [],
                    historyDataCount:(JSON.parse((response.data[1].resultCount))[0]),
                    isLoading:false,historyPage:1,historySizePerPage:100
                })
            })
            .catch(function (error) {
                this.setState({isLoading:false})
                console.log(error);
            });



    }

    isHistory = (flag) =>{

        this.setState({historydata:flag})
        if(flag === true && UserProfile.getRole() !== Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER && UserProfile.getRole() !== Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            this.onSearchHistory(this.state.historyPage, this.state.historySizePerPage, this.state.historySortName, this.state.historySortOrder);
        }else{
            this.displayData();
        }
    }

    onCloseUploadDocModal = () => {
    $("#modalCancel").click();
    }

    removeDocument = (event,row) => {
        //event.preventDefault();
        let descriptionIndex = description.indexOf(row.description[0])
        let documentNameIndex = documentName.indexOf(row.fileName[0])
        description.splice(descriptionIndex,1)
        documentName.splice(documentNameIndex,1)
        files.splice(row.id,1)
        documentFiles.splice(row.id,1)
        this.setState({test:true})
    }

    onSubmmite = (event) => {

        event.preventDefault();

		let ketuRxIdData = 0
        let mdDocIdData = 0

        if(mdDocId == 0){
            ketuRxIdData = ketuRxId
        }else{
            ketuRxIdData = ketuRxId
            mdDocIdData = mdDocId
        }

        if($("#file").val()!=="" ||ckEditorContent.trim().length>0){
            $("#modalCancel").click()
        }else{

        var doc=$('#documentName').val()
        $("#closeModalTest").click();
        if(documentFiles[0] != null) {

            let formData = new FormData();
            for(var i=0;i<files.length;i++){
                    let fileObject = files[i]
                    formData.append("file", fileObject);
            }
            formData.append("data", new Blob([JSON.stringify({
                    caseId: caseId,
                    patientUniqueId: patientUniqueId,
                    ksPatientId: ksPatientId,
                    tenantId: tenantId,
                    ketuPatientId: patientId,
                    caseDetailId: caseDetailId,
                    doctype:doc,
                    ketuRxId: ketuRxIdData,
                    phRxId: phRxId,
                    prescriberId: prescriberId,
                    //ketuCaseDetailId: ketuCaseDetailId,
                    submissionType:'DOCUMENT',
                    physician:"firstName"

            })], {
            type: "application/json; charset=utf-8"
            }));
            files = [];
            axios.post('/status/documentData?ketuCaseDetailId='+ketuCaseDetailId+'&description='+description+'&documentName='+documentName+''+'&mdDocId='+mdDocIdData+'', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                // console.log(response)
                description = []
                documentName = []
                if(response.data) {
                    window.Materialize.toast("Document Uploaded Successfully", 5000,  'green rounded')
                } else {
                    window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        }
        }

    }

    rowData =(event, row) => {

        if($("#file").val()){
            $("#file").val("")
        }
        if($("#fileType").val()){
            $("#fileType").val("")
        }
        if($("#documentName").val()){
            $("#documentName").val("")
        }
        if($("#npdescriptioni").val()){
            $("#npdescriptioni").val("")
        }
        if($("#documentTitle").val()){
            $("#documentTitle").val("")
        }

		documentFiles = []
        caseId = row.caseId
        patientUniqueId = row.patientUniqueId
        ksPatientId = row.ksPatientId
        tenantId = row.tenantId
        DOB = row.dateOfBirth
        patientName = row.patientName
        patientId = row.patientId
        caseDetailId= row.caseDetailId
        if(row.ketuCaseDetailId != null){
            ketuCaseDetailId = row.ketuCaseDetailId
        }
        ketuRxId = row.ketuRxId
        mdDocId = row.mdDocId
        phRxId = row.phRxId
        prescriberId = row.prescriberId

        $("#uploadDocCaseId").val(caseId);
        $("#uploadDocPatientUniqueId").val(patientUniqueId);
        $("#uploadDocKsPatientId").val(ksPatientId);
        $("#uploadDocTenantId").val(tenantId);
        $("#uploadDocDOB").val(DOB);
        $("#uploadDocPatientName").val(patientName);
        $("#uploadDocPatientId").val(patientId);
        $("#uploadDocCaseDetailId").val(caseDetailId);
        if(row.ketuCaseDetailId != null){
            $("#uploadDocKetuCaseDetailId").val(ketuCaseDetailId);
        }
        $("#uploadDocKetuRxId").val(ketuRxId);
        $("#uploadDocPhRxId").val(phRxId);
        $("#uploadDocPrescriberId").val(prescriberId);
        $("#isDocumentDescriptionEmpty").val(false);
        $("#isDocumentNameEmpty").val(false);
        $("#isDocumentSelected").val(false);

        $("#uploadDocTitleDateOfBirth").html(" ("+DOB+")");
        $("#uploadDocTitlePatientName").html(patientName);

        $("#authorizedByName").val(row.md)
        $("#mdid").val(row.mdid)

        this.child.updateState();

    }


	viewHistoryDocument = (event,row) =>{
        let queryString = '';
        queryString +='?id='+row.id;
        let pathUrl =  Constant.HISTORY_DOCUMENT_PATH + queryString
        this.setState({srcUrl:pathUrl});
    }


    viewStatusDocument = (event,row) =>{
        let queryString = '';
        queryString +='?id='+row.mdDocId;
        let pathUrl =  Constant.STATUS_DOCUMENT_PATH + queryString
        this.setState({srcUrl:pathUrl});
    }

    viewHistoryDocumentModal(){
        // let styleClass = { width :'50%'}
    return(
        <div id="viewHistoryDocModal" className="modal fade" role="dialog" >
            <div className="modal-dialog v-center">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Document</h4>
                    </div>
                    <div className="modal-body">
                        <div className="agengtDetailsAdd inner-page-container">
                            <div className="col-sm-12">
                                <iframe name="test" height="500px" width="100%" name="plugin" src={this.state.srcUrl} type="application/pdf"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <section className="buttonWrapper">

                    <button type="button" id="deleteModalCancel" className="btn btn-primary wizard-next " data-dismiss="modal">Close</button>
                    </section>
                    </div>
                </div>
            </div>
        </div>
    )
    }
    actionMarkAsDone=(row)=>{

        if(row != undefined && row.mdDocId){

                this.setState({isLoading:true})
                axios.get('/status/markAsDone?mdDocId='+row.mdDocId)
                .then(response => {
                    // window.location.reload();
                    this.setState({isLoading:false})
                    this.onSortChange(this.state.sortName,this.state.sortOrder)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }
    markAsDone=(event,row)=>{
        markAsDoneData=row
        return(
            <div id="markAsDoneModal" className="modal fade" role="dialog" >
                <div className="modal-dialog v-center">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Confirmation</h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <h4>Are you sure you want to mark it as done?</h4>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        <section className="buttonWrapper">
                        <button type="button" id="markAsDoneOk" onClick={()=>this.actionMarkAsDone(markAsDoneData)} className="btn btn-primary wizard-next " data-dismiss="modal">Yes</button>
                        <button type="button" id="markAsDoneCancel" className="btn btn-primary wizard-next " data-dismiss="modal">No</button>
                        </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setIcon = (cell, row) => {
        $('#documenttype').prop('selectedIndex',0);
		var isVisible = false;
        var mdDocId = (row.mdDocId !== "") ? parseInt(row.mdDocId) : 0;
		if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
			isVisible = true;
		}else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT && (row.userPermissions.toString().indexOf(Constant.UPLOAD_DOCUMENTATION) > -1)){
			isVisible = true;
		}

        return (
        <div>


            <div className="actions-list-div">

                    { (mdDocId > 0) ?

                            <a href='#' data-toggle="modal" onClick={(event) => this.viewStatusDocument(event,row)} data-target="#viewHistoryDocModal"  data-tip='View'>
                                <i className="fa fa-paperclip"></i>
                            </a>

                        : null
                    }


                    { (isVisible === true && !row.docDone && row.caseStatus !== "Complete") ?

                        <a href="#myModal" data-toggle="modal" data-target="#myModal" data-tip="Upload" onClick={(event) => {this.rowData(event,row);
						}}>
							<i className="fa fa-upload"></i>
						</a>
                        : null

                    }

                    { (mdDocId > 0&&isVisible === true&&!row.docDone) ?

                        <a href='#markAsDoneModal' data-toggle="modal" data-target="#markAsDoneModal" onClick={(event) => this.markAsDone(event,row)}>
                            <i className="fa fa-check"></i>
                        </a>
                        : null

                    }

                    { (mdDocId > 0 && row.docDone) ?

                        <a href='#'>
                            <i className="fa fa-check-square"></i>
                        </a>
                        : null

                    }

            </div>


        </div>
        );
    }

    setSortStatus = (cell, row) => {
        let returnFlag = false;
        if(this.state.dashBoardDataCount > 0) {
            returnFlag = true
        }
        return returnFlag
    }

    setHistoryIcon = (cell, row) => {
        return (
                <div>
                    <div className="actions-list-div">
                        <ReactTooltip place="top" html={true} afterShow={false}/>
                        <a href='#' data-toggle="modal" onClick={(event) => this.viewHistoryDocument(event,row)} data-target="#viewHistoryDocModal"  data-tip='View'>
                            <i className="fa fa-eye"></i>
                        </a>
                    </div>
                </div>
            );
    }

  setSortHistory = (cell, row) => {
        let returnFlag = false;
        if(this.state.historyDataCount > 0){
            returnFlag = true;
        }
        return returnFlag;
  }

//   exportWQ = () => {
//       this.setState({isWQExportClicked : true});
//   }

//   exportHistory = () => {
//     this.setState({isHistoryExportClicked : true});
//   }
validationCheck=()=>{
    if($("#file").val()!=="" || ckEditorContent.trim().length>0){
        $("#validationTest").click()
    }
}
resetDataCount=()=>{
    this.setState({defaultDataCount:0,totalDataCount:0})
}
setDashbordCount(arg){    
    this.setState({isLoading:true})
    let queryString='?isDefaultData='+arg
    if($("#includeComplete ").prop("checked") && arg === 0){
        queryString += '&includeComplete=1'
    }else{
        queryString += '&includeComplete=0'
    }
    axios.get('/status/dashbordCounts'+queryString)
    .then(response => {

                if(arg===1){
                 this.setState({defaultDataCount:response.data})
                }else{
                  this.setState({totalDataCount:response.data})
                }
                this.setState({isLoading:false})
    })
    .catch(function (error) {
        console.log(error);
      //  window.location.reload();
    });

}
render() {

    UserProfile.setUrlName(window.location.href)

        // let dashBoard = this.state.dashBoardData?this.state.dashBoardData:[]
        // let dashBoardDataLength = dashBoard.length
        // let dataCount = 0;
        // dataCount = this.state.dashBoardDataCount;
        // let data = []

        // for(var i=0;i<dashBoardDataLength;i++){
        //     if(dashBoard[i].caseId != null){
        //         if(parseInt(dashBoard[i].caseId) < 1){
        //             dashBoard[i].caseId = "";
        //         }

        //         let innerData= {caseId :dashBoard[i].caseId, caseDetailId :dashBoard[i].caseDetailId,
        //                 patientName : dashBoard[i].patientName, dateOfBirth :dateFormat(dashBoard[i].dateOfBirth, "mm/dd/yyyy"),
        //                 therapeuticCategoryName : dashBoard[i].therapeuticCategoryName, drug : ((dashBoard[i].drug != null) ? (dashBoard[i].drug.toString().split("---")[1]) : ""),
        //                 organizationName : dashBoard[i].organizationName, prescriber : dashBoard[i].prescriber,
        //                 insurance :dashBoard[i].insurance, followUpStatus : dashBoard[i].followUpStatus,
        //                 patientUniqueId:dashBoard[i].ketuPatientId, patientId:dashBoard[i].patientId, ksPatientId:dashBoard[i].ksPatientId,
        //                 tenantId: dashBoard[i].tenantId, ketuCaseDetailId: dashBoard[i].ketuCaseDetailId, userPermissions: dashBoard[i].userPermissions,ketuRxId: dashBoard[i].ketuRxId, phRxId: dashBoard[i].phRxId, prescriberId: dashBoard[i].prescriberId}
        //         data.push(innerData)
        //     }
        // }

        return (
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                {this.viewHistoryDocumentModal()}
                {this.markAsDone()}
                <Header h2Tag={"Status Queue"}/>
                   {this.state.isModalOpen?" ":<CustomModal isModalOpen = {this.state.isModalOpen} />}
                    <main>
                        <div className="inner-page-container">
                            <SearchSection therapeuticCategories ={this.state.therapeuticCategories}
                                            followUpStatuses ={this.state.followUpStatuses}
                                            getFollowUpReasonListener = {this.getFollowUpReasonListener}
                                            followUpReasones = {this.state.followUpReasones}
                                            // exportWQ = {this.exportWQ}
                                            // exportHistory = {this.exportHistory}
                                            getSearchHistoryData= {this.state.historydata?this.getSearchHistoryData:this.getSearchData}
                                            userType= {this.state.userType}
                                            isHistory = {this.isHistory}
                                            hqList={this.state.hqList}
                                            NPIList = {this.state.NPIList}
                                            createExportQueryString = {this.createExportQueryString}
                                            changFlag={this.changFlag}
                                            callDisplayData={this.callDisplayData}
                                            resetDataCount={this.resetDataCount}
                                            getpharmacyStoreval={this.getpharmacyStoreval}>
                            </SearchSection>
                            <section className="searchReportContainer boxWithShadow">
                                <div className="pageListContainer">

                                  {this.state.historydata?
                                  <DynamicHistoryTable historyDashBoardData = {this.state.historyData} isPaginationAllow = {isPaginationAllow} document={this.state.document} setHistoryIcon={this.setHistoryIcon} onPageChange={this.onHistoryPageChange} onSortChange={this.onHistorySortChange} sizePerPage={this.state.historySizePerPage} dataCount={this.state.historyDataCount} page={this.state.historyPage}/>:
                                  <DynamicTable defaultDataCount={this.state.defaultDataCount} totalDataCount={this.state.totalDataCount} setDashbordCount={this.setDashbordCount}isPaginationAllow = {isPaginationAllow}   data={statusGridData} setIcon={this.setIcon} onPageChange={this.onPageChange} onSortChange={this.onSortChange} sizePerPage={this.state.sizePerPage} dataCount={dataCount} page={this.state.page}/>}

                                </div>
                            </section>
                            {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                            <UploadDoc  ref={child => { this.child = child}} rowStatusData={rowStatusData}/>
                        </div>
                    </main>
                    <Footer />

                </div>
            </div>
        );
    }
}