import React, {Component} from 'react';
import {Input} from 'react-materialize';
import Footer from '../PageComponent/Footer/Footer';
import Header from '../PageComponent/Header/Header';
import CustomInput from '../CustomUI/Input';
import Label from '../CustomUI/Label';
import * as Constant from '../CustomUI/Constant';
import UserProfile from '../../UserProfile';
import $ from 'jquery';
import axios from 'axios';
import  { Redirect } from 'react-router-dom';


let securityQuestionsData=[]
let securityQuestionAnswerData =[]

let oldPassword = ""
let password = ""
let confirmPassword = ""


export default class ChangePasswordAndSecurityQuestion extends Component {
    state = {
        inactiveDueToIncorrectPass:false,
        ProfileValidate :{
            securityAnswer1Valid:false,
            securityAnswer2Valid:false,
            securityAnswer3Valid:false,
            securityQuestion1Valid:false,
            securityQuestion2Valid:false,
            securityQuestion3Valid:false,
            isDuplicateAnswer:false,
            isDuplicateQuestion:false
        },
        changePasswordValid : {
            oldPasswordValid : false,
            passwordValid : false,
            confirmPasswordValid : false,
       },
       isUpdated:false,
       oldPasswordNotMatch:true,
       isSuccess:false
    }

    componentDidMount(){
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            this.getAgentSecurityQuestionList()
        }else{
            this.getPrescriberSecurityQuestionList()
        }

    }

    getAgentSecurityQuestionList = () =>{

        let queryString = '';
        let isProfile = false
        queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
        queryString += '&isProfile='+isProfile

        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/agentProfile/getAgentProfileData'+queryString)
            .then(response => {
                securityQuestionsData.push(response.data.securityQuestions)
                securityQuestionAnswerData.push(response.data.securityQuestionAnswer)
                this.setState({isAgent:true});


            })
            .catch(function (error) {
                console.log(error);
        });

    }

    getPrescriberSecurityQuestionList = () =>{

        let queryString = '';
        let isProfile = false
        queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
        queryString += '&isProfile='+isProfile

        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/prescriberProfile/getPrescriberProfileData'+queryString)
            .then(response => {
                securityQuestionsData.push(response.data.securityQuestions)
                securityQuestionAnswerData.push(response.data.securityQuestionAnswer)
                this.setState({isPrescriber:true});

        })
            .catch(function (error) {
                console.log(error);
        });
    }



    submitChangePasswordListner = () =>{

        this.securityQuestion = []
        let isValid = true
        let blankPassword = true


        let showSecQueValidation = true;
        if( UserProfile.getInactiveDueToIncorrectPass() ) {
            showSecQueValidation = false
        } else if( UserProfile.getIsPasswordExpire() ) {
            showSecQueValidation = false
        }

        if( showSecQueValidation ){

            let securityQuestion1Valid = false;
            let securityQuestion2Valid = false;
            let securityQuestion3Valid = false;
            let securityAnswer1Valid = false;
            let securityAnswer2Valid = false;
            let securityAnswer3Valid = false;
            let isDuplicateAnswer12= false;
            let isDuplicateAnswer23= false;
            let isDuplicateAnswer31= false;
            let isDuplicateAnswer = false
            let isDuplicateQuestion =false

            let securityQuestion1 = $("#securityQuestion1").val();
            let securityQuestion2 = $("#securityQuestion2").val();
            let securityQuestion3 = $("#securityQuestion3").val();
            let securityAnswer1 = $("#securityAnswer1").val();
            let securityAnswer2 = $("#securityAnswer2").val();
            let securityAnswer3 = $("#securityAnswer3").val();
            let answerId1 = $("#answerId1").val();
            let answerId2 = $("#answerId2").val();
            let answerId3 = $("#answerId3").val();


            if(!securityAnswer1||securityAnswer1.trim().length===0){
                securityAnswer1Valid = true
                isValid = false
            }
            if(!securityAnswer2||securityAnswer2.trim().length===0){
                securityAnswer2Valid = true
                isValid = false
            }

            if(!securityAnswer3||securityAnswer3.trim().length===0){
                securityAnswer3Valid = true
                isValid = false
            }

            if(!securityQuestion1){
               securityQuestion1Valid = true
               isValid = false
            }
            if(!securityQuestion2){
               securityQuestion2Valid = true
               isValid = false
            }
            if(!securityQuestion3){
               securityQuestion3Valid = true
               isValid = false
            }

            if(securityAnswer1Valid || securityAnswer2Valid ||securityAnswer3Valid || securityQuestion1Valid || securityQuestion2Valid || securityQuestion3Valid){
                blankPassword = false
            }

            if(securityAnswer1 && securityAnswer1.trim().length!==0 && securityAnswer2.trim().length!==0 && securityAnswer1.toLowerCase().trim() === securityAnswer2.toLowerCase().trim()){
                isDuplicateAnswer12 = true
                isValid = false
                isDuplicateAnswer = true
            }
            if(securityQuestion1 === securityQuestion2 && securityQuestion1){
                securityQuestion1Valid = true
                securityQuestion2Valid = true
                isDuplicateQuestion = true
                isValid = false
            }
            if(securityAnswer2 && securityAnswer2.trim().length!== 0 && securityAnswer3.trim().length!== 0 && securityAnswer2.toLowerCase().trim() === securityAnswer3.toLowerCase().trim()){
                isDuplicateAnswer23 = true
                isDuplicateAnswer = true
                isValid = false
            }
            if(securityQuestion2 === securityQuestion3 && securityQuestion2){
                securityQuestion2Valid = true
                securityQuestion3Valid = true
                isDuplicateQuestion = true
                isValid = false
            }
            if(securityAnswer3 && securityAnswer3.trim().length!== 0 && securityAnswer1.trim().length!==0 && securityAnswer3.toLowerCase().trim() === securityAnswer1.toLowerCase().trim()){
                isDuplicateAnswer31 = true
                isDuplicateAnswer = true
                isValid = false
            }
            if(securityQuestion3 === securityQuestion1 && securityQuestion3){
                securityQuestion3Valid = true
                securityQuestion1Valid = true
                isDuplicateQuestion = true
                isValid = false
            }

            if(isDuplicateQuestion){
                window.Materialize.toast(Constant.QUESTION_IS_DUPLICATE, 5000,  'red rounded')
                isValid = false
            }
            if(isDuplicateAnswer){
                window.Materialize.toast(Constant.ANSWER_IS_DUPLICATE, 5000,  'red rounded')
                isValid = false
            }



            this.securityQuestion.push({id:answerId1,question_id:securityQuestion1,answer:securityAnswer1.trim()});
            this.securityQuestion.push({id:answerId2,question_id:securityQuestion2,answer:securityAnswer2.trim()});
            this.securityQuestion.push({id:answerId3,question_id:securityQuestion3,answer:securityAnswer3.trim()});

            this.setState({
                ProfileValidate :{
                securityAnswer1Valid:securityAnswer1Valid,securityAnswer2Valid:securityAnswer2Valid,securityAnswer3Valid:securityAnswer3Valid,
                securityQuestion1Valid:securityQuestion1Valid,securityQuestion2Valid:securityQuestion2Valid,securityQuestion3Valid:securityQuestion3Valid,
                isDuplicateAnswer12:isDuplicateAnswer12,isDuplicateAnswer23:isDuplicateAnswer23,isDuplicateAnswer31:isDuplicateAnswer31
              }
           })
        }


        let oldPasswordValid = false
        let passwordValid = false
        let confirmPasswordValid = false


     //   let name = UserProfile.getName();

         oldPassword = $("#oldPasswordlogin").val();
         password = $("#passwordlogin").val();
         confirmPassword = $("#confirmPasswordlogin").val();

        if(!oldPassword){
            oldPasswordValid = true
        }
        if(!password){
            passwordValid = true

        }

        if(!confirmPassword){
            confirmPasswordValid = true
        }else {
            if(password !== confirmPassword && password.length >= 9 && confirmPassword.length >= 9)   {
                isValid = false
                window.Materialize.toast(Constant.CONFIRM_PASSWORD_INVALID, 5000,  'red rounded')
            }
        }
        if(oldPasswordValid || passwordValid || confirmPasswordValid){
            blankPassword = false
        }

        if(!blankPassword){
            isValid = false
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 5000,  'red rounded')

        }

        let passwordIsValid = true

        if(password.match(Constant.WRONG_PASSWORD_FORMATE) == null && password){
            isValid = false
            passwordValid = true
            passwordIsValid = false
        }
        if(confirmPassword.match(Constant.WRONG_PASSWORD_FORMATE) == null && password){
            isValid = false
            confirmPasswordValid = true
            passwordIsValid = false

        }

        if(!passwordIsValid){
            window.Materialize.toast(Constant.INVALID_PASSWORD_FORMATE, 4000, 'red rounded')
        }


        this.setState({changePasswordValid:{
            oldPasswordValid:oldPasswordValid,
            passwordValid:passwordValid,
            confirmPasswordValid:confirmPasswordValid
        }})


        let showChangePassword = UserProfile.getShowChangePassword()?UserProfile.getShowChangePassword():""
        let inactiveDueToIncorrectPass =  UserProfile.getInactiveDueToIncorrectPass()?UserProfile.getInactiveDueToIncorrectPass():""
        let  isInactiveDueToIncorrectSecQue = UserProfile.getIsInactiveDueToIncorrectSecQue()

        this.setState({isSuccess:true})
        // console.log(UserProfile.getIsInactiveDueToIncorrectSecQue())
        let queryString = '';
        queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
        queryString += '&password='+ encodeURIComponent(password)
        queryString += '&showChangePassword='+showChangePassword
        queryString += '&inactiveDueToIncorrectPass='+inactiveDueToIncorrectPass
        queryString += '&isInactiveDueToIncorrectSecQue='+isInactiveDueToIncorrectSecQue



        if(this.state.oldPasswordNotMatch && isValid){

                axios.put(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/user/updateCredentials'+queryString,this.securityQuestion)
                .then(response => {
                let data = response.data
                let matchData = "successfully"
                if(data === matchData){
                    if(UserProfile.getInactiveDueToIncorrectPass() || UserProfile.getIsPasswordExpire()){
                        window.Materialize.toast(Constant.CHANGE_PASSWORD_UPDATED_SUCESSFULLY, 4000, 'green rounded')
                        this.setState({isUpdated:true})
                        UserProfile.setInactiveDueToIncorrectPass(false)
                        UserProfile.setIsPasswordExpire(false)
                    }else{
                        window.Materialize.toast(Constant.SECURITY_QUE_UPDATE_AND_CHANGE_PASSWORD_UPDATE, 4000, 'green rounded')
                        this.setState({isUpdated:true})
                        UserProfile.setShowChangePassword(false)
                        UserProfile.setIsInactiveDueToIncorrectSecQue(false)
                    }
                }else{
                    window.Materialize.toast(response.data, 4000, 'red rounded')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
         }
    }



        checkOldPassword = () =>{

            oldPassword = $("#oldPasswordlogin").val();



            let queryString  = '';
            queryString +=  '?oldPass='+ encodeURIComponent(oldPassword) +'&userId='+UserProfile.getUserId()

            axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/user/oldPassword'+queryString).then(response => {
                if(response.data){
                    this.setState({oldPasswordNotMatch:true})
                }else{
                    if(oldPassword){
                        window.Materialize.toast(Constant.PASSWORD_DOES_NOT_MATCH, 5000,  'red rounded')
                        this.setState({oldPasswordNotMatch:false})
                        this.setState({changePasswordValid:{oldPassword:true}})
                    }

                }
            });


        }


        logoutSubmit = (event) => {

            var form = $("#cancelForm");
            var url = form.attr('action');

            event.preventDefault();
            $.ajax({
            type: "POST",
            url: url,
            xhrFields: {
                withCredentials: true
            },
            data: form.serialize(), // serializes the form's elements.
                success: function(data)
                {
                    UserProfile.clearAll();
                    window.open(Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH, '_self')
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                                //alert("Status: " + textStatus); alert("Error: " + errorThrown);
                   }
            });
            event.preventDefault();

        }

       render () {

        if (this.state.isUpdated) {
            return <Redirect to='/' />


        }

        //let questionList = this.state.questionList ? this.state.questionList:[]
        // const questionListData = questionList.map(question =>{
        //     return <option data-id={question.id} value={question.id} key={question.id} >{question.question}</option>
        // });

        let secQueAns =securityQuestionAnswerData[0] ? securityQuestionAnswerData[0] : [];
        let secQue = securityQuestionsData[0] ? securityQuestionsData[0] : [];
        // const tempData = secQueAns.map(questionData =>{
        //     // console.log(questionData)
        //    questionIdList.push(questionData.questionId);
        // });



        const questionListData1 = secQue.map(questionData =>{
              return  <option data-id={questionData.id} value={questionData.id} key={questionData.id} >{questionData.question}</option>

        });

        const questionListData2 = secQue.map(questionData =>{
             return <option data-id={questionData.id}  value={questionData.id} key={questionData.id} >{questionData.question}</option>
        });

       const questionListData3 = secQue.map(questionData =>{
             return <option data-id={questionData.id}  value={questionData.id} key={questionData.id} >{questionData.question}</option>
        });

        let answerOneId = null;
        let answerTwoId = null;
        let answerThreeId = null;

        if(secQueAns && secQueAns[0]){
            answerOneId = secQueAns[0].answerId
            answerTwoId = secQueAns[1].answerId
            answerThreeId = secQueAns[2].answerId
        }

        let styleClass = {
            hideSideBarPanel:""
       }

       if(UserProfile.getInactiveDueToIncorrectPass() || UserProfile.getIsInactiveDueToIncorrectSecQue() || UserProfile.getIsPasswordExpire() || UserProfile.getShowChangePassword()){
            styleClass.hideSideBarPanel =  "wrapper slide-nav-toggle hide-sidebar"
       }else{
           styleClass.hideSideBarPanel =  "wrapper slide-nav-toggle"
       }



        let ProfileValidate = {...this.state.ProfileValidate}


        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={"Security"} />
                        <main>
                            <div className="inner-page-container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="boxWithShadow boxWithSpace max-height-drop">
                                            {UserProfile.getInactiveDueToIncorrectPass() || UserProfile.getIsPasswordExpire()?"":
                                                <div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="section-title font20 ptb15">
                                                                Security Questions:
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                            <input type="hidden" value={answerOneId} id="answerId1" name="answerId1"/>
                                                            <input type="hidden" value={answerTwoId} id="answerId2" name="answerId2"/>
                                                            <input type="hidden" value={answerThreeId} id="answerId3" name="answerId3"/>
                                                        <Input s={12} m={12} l={5} xl={4}  id='securityQuestion1'  type='select'  label='Security Question 1' name='securityQuestion1' required className={ProfileValidate.securityQuestion1Valid ? 'validate invalid': 'validate'}  >
                                                        <option value="">--Select--</option>
                                                                {questionListData1}
                                                        </Input>
                                                            <div className="col-lg-4 col-md-5 col-xm-12 col-xs-12">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="securityAnswer1" lblclassName={ProfileValidate.securityAnswer1Valid || this.state.isUpdate ?'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                                    <CustomInput intid="securityAnswer1" intName={Constant.INPUT_SECURITY_ANSWER_1} intType="text" required intClasssName={ProfileValidate.securityAnswer1Valid || ProfileValidate.isDuplicateAnswer12 || ProfileValidate.isDuplicateAnswer31? 'validate invalid': 'validate'} />
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="row">
                                                            <Input s={12} m={12} l={5} xl={4}  id='securityQuestion2'  type='select'  label='Security Question 2' name='securityQuestion2' required className={ProfileValidate.securityQuestion2Valid ? 'validate invalid': 'validate'}   >
                                                            <option value="">--Select--</option>
                                                                        {questionListData2}
                                                            </Input>
                                                            <div className="col-lg-4 col-md-5 col-xm-12 col-xs-12">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="securityAnswer2" lblclassName={ProfileValidate.securityAnswer2Valid || this.state.isUpdate? 'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                                    <CustomInput intid="securityAnswer2" intName={Constant.INPUT_SECURITY_ANSWER_2} intType="text" required intClasssName={ProfileValidate.securityAnswer2Valid  || ProfileValidate.isDuplicateAnswer12 || ProfileValidate.isDuplicateAnswer23? 'validate invalid': 'validate'} />
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="row">
                                                            <Input s={12} m={12} l={5} xl={4}  id='securityQuestion3'  type='select'  label='Security Question 3' name='securityQuestion3' required className={ProfileValidate.securityQuestion3Valid? 'validate invalid': 'validate'}  >
                                                            <option value = "">--Select--</option>
                                                                        {questionListData3}
                                                            </Input>
                                                            <div className="col-lg-4 col-md-5 col-xm-12 col-xs-12">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="securityAnswer3" lblclassName={ProfileValidate.securityAnswer3Valid || this.state.isUpdate ? 'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                                    <CustomInput intid="securityAnswer3" intName={Constant.INPUT_SECURITY_ANSWER_3} intType="text" required  intClasssName={ProfileValidate.securityAnswer3Valid || ProfileValidate.isDuplicateAnswer31 || ProfileValidate.isDuplicateAnswer23? 'validate invalid': 'validate'} />
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div> }
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label className="section-title font20 ptb15">
                                                            Change Password:
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                            <div className="col-sm-4 col-md-4 col-lg-3">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="oldPasswordlogin"  lblclassName={this.state.changePasswordValid.oldPasswordValid || oldPassword? 'active ': null}  lblname={Constant.LABEL_OLDPASSWORD+Constant.STAR}/>
                                                                    <CustomInput intid="oldPasswordlogin" intName="password" intType="password" intClasssName={this.state.changePasswordValid.oldPasswordValid? 'validate invalid': 'validate'} maxlength="16" blur = {this.checkOldPassword} required />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-md-4 col-lg-3">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="passwordlogin" lblclassName={this.state.changePasswordValid.passwordValid || password? 'active ': null}  lblname={Constant.LABEL_PASSWORD+Constant.STAR}/>
                                                                    <CustomInput intid="passwordlogin" intName="password" intType="password" intClasssName={this.state.changePasswordValid.passwordValid? 'validate invalid': 'validate'} maxlength="16" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-md-4 col-lg-3">
                                                                <div className="input-field">
                                                                    <Label lblhtmlFor="confirmPasswordlogin" lblclassName={this.state.changePasswordValid.confirmPasswordValid || confirmPassword? 'active ': null} lblname={Constant.LABEL_CONFIRM_PASSWORD+Constant.STAR}/>
                                                                    <CustomInput intid="confirmPasswordlogin" intType="password" intName={Constant.INPUT_CONFIRM_PASSWORD} intClasssName={this.state.changePasswordValid.confirmPasswordValid? 'validate invalid': 'validate'} maxlength="16" required />
                                                                </div>
                                                            </div>
                                                </div>

                                                <section className="buttonWrapper mb20">

                                                <ul>
                                                    <li> <button className="btn waves-effect waves-light btn-darkpurpale"  onClick={this.submitChangePasswordListner}>Save</button>  </li>
                                                    <li>
                                                        <form method="post" action={Constant.SIGNOUT_CONTEXT_PATH} id="cancelForm" onSubmit={this.logoutSubmit}>
                                                            <button className="btn btn-dark" type="submit" value="Cancel">Cancel</button>
                                                        </form>
                                                    </li>

                                                </ul>

                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                    <Footer />
                </div>
            </div>


        );
    }
}