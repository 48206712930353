import React, { Component } from 'react';
import axios from 'axios';
import * as Constant from '../../Component/CustomUI/Constant';
import $ from 'jquery';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import CustomInput from '../../Component/CustomUI/Input';
import Label from '../../Component/CustomUI/Label';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

let files =[]
let tempFiles = []            
let documentFiles =[]
let uploadForms = ''
let uploadSubForms = ''
let subUploadForms = ''
let flag = false
let dropdownData = []

const createOption = (label) => ({    
    label,
    value: label,
  });
class UploadForms extends Component {
    
    state = {    
    isLoading:false,
    selectedValue:null,
    selectedUploadForm:'',
    selectedSubUploadForm:'',
    uploadForm:[],
    uploadSubForm:[],
    }
    handleChange = (newValue) => {                 
        this.setState({ selectedSubUploadForm: newValue });
      };
      handleCreate = (inputValue) => {
        let tempMainType = this.state.selectedUploadForm.value? this.state.selectedUploadForm.value: '';        
        if(tempMainType !== ''){                    
         if(inputValue.length < 51){
             if(!inputValue.match(Constant.REGEX_CREATE_CATEGORY)){
                this.setState({ isLoading: true });
                setTimeout(() => {       
                uploadSubForms = this.state.uploadSubForm;                              
                const newOption = createOption(inputValue);
                this.setState({
                    isLoading: false,
                    uploadSubForm: [...uploadSubForms, newOption.value],
                    selectedSubUploadForm: newOption,
                });
                if(inputValue !== '') {
                    flag = true;
                }else{
                    flag = false;
                }         
                }, 1000);
             }else{
                window.Materialize.toast(Constant.INVALID_CATEGORY, 5000,  'red rounded');                             
             }                       
        }else{
            window.Materialize.toast(Constant.MAX_CATEGORY, 5000,  'red rounded');                        
        }
      }else{
        window.Materialize.toast(Constant.SELECT_MAIN_TYPE, 5000,  'red rounded');                           
      }         
    };
    componentDidMount() {
        dropdownData=[{ value: "EnrollmentForms_KS", label: "KloudScript Enrollment Forms"},{ value: "EnrollmentForms", label: "Manufacturer Enrollment Forms"},{ value: "PriorAuthorization", label: "Prior Authorization Guidelines"},{ value: "Training", label: "Training"}]
        this.setState({uploadForm:dropdownData });
        $('#reset').click(function() {            
            document.location.reload(true);
        })                  
    }
    getSub = (event) =>{       
        let value=''
        if(event){
             value = event.value?event.value:'';             
        }               
        if(value){                        
            axios.get('/status/getFormData?mainType='+ value)
            .then(response => {                                          
                    if(response.data){
                        this.setState({uploadSubForm:response.data})                       
                    }                    
            })           
        }else { 
            this.setState({uploadSubForm:[]});
        }  
    }
    handleUploadForm = (selectedUploadForm) => {
        this.setState({selectedUploadForm:selectedUploadForm,selectedSubUploadForm:''})        
    } 
    change = (event) =>{
        // event.preventDefault();                     
            if(event.target.name === Constant.UPLOADED_FILE_NAME){
                let document = event.target.files?event.target.files[0]:''    
                if(document != "undefined" && document != undefined && document && document.length != 0) {
                    var name = document.name.substr(0, document.name.lastIndexOf('.'));                
                    this.value=event.target.value
                    if(document.size > 104857600) {
                        window.Materialize.toast(Constant.MAX_FILESIZE, 5000,  'red rounded');                     
                        $('#fileType').val('');
                        $('#file').val('')
                    }else{
                        if(name.length > 100){                    
                            window.Materialize.toast(Constant.MAX_FILENAME, 5000,  'red rounded');                    
                            $('#fileType').val('');
                            $('#file').val('');
                        }else{
                            tempFiles.push(document)
                        }
                    }   
                }  
                                            
            }                    
    }    

    
    click = () => {
        let isValid = this.documentValidation();
      
        if(isValid) {           
            if(this.state.selectedUploadForm.value !== null && this.state.selectedSubUploadForm.value !== null){
                uploadForms = this.state.selectedUploadForm.value;
                subUploadForms = encodeURIComponent(this.state.selectedSubUploadForm.value);
                subUploadForms = (uploadForms == 'Training')?'Training':subUploadForms
            } 
            let filesTemp = []                        
            filesTemp.push(tempFiles[tempFiles.length-1])
            files.push(tempFiles[tempFiles.length-1])
            documentFiles.push({filesTemp})
            tempFiles = []                 
            this.setState({clicked:true})

            if(documentFiles[0] != null) {
                let formData = new FormData();
                for(var i=0;i<files.length;i++){
                        let fileObject = files[i]
                        formData.append("file", fileObject);
                }
                formData.append("data", new Blob([JSON.stringify({
                        mainType: uploadForms,
                        subType: subUploadForms                                    
                })], {
                type: "application/json; charset=utf-8"
                }));

                files = [];
                axios.post('/status/uploadForm?main_type=' + uploadForms + '&sub_type=' + subUploadForms, formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {                   
                    if(response.data) {                                                 
                        window.Materialize.toast(Constant.UPLOADED_FILE, 5000,  'green rounded');                        
                        if(flag){                           
                            window.Materialize.toast(Constant.NEW_CATEGORY, 5000,  'green rounded')                        
                        }
                    flag = false;
                    } else {
                        window.Materialize.toast(Constant.NOT_UPLOADED_FILE, 5000,  'red rounded')
                    }
                    this.resetData();                    
                    this.setState({test:true})
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }     
    }

    documentValidation = () => {
        let isValid = true;
        let tempMainType = this.state.selectedUploadForm.value? this.state.selectedUploadForm.value: '';
        let tempSubType = this.state.selectedSubUploadForm? this.state.selectedSubUploadForm.value:'';     
        if(((tempMainType === null || tempMainType === '' || tempMainType === undefined || tempSubType === null || tempSubType === '' || tempSubType === undefined || ($("#fileType").val() === "")) && (tempMainType !== "Training")) || (tempMainType === "Training" && ($("#fileType").val() === ""))){
            isValid = false;
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 5000,  'red rounded')                      
        }else{
            if($("#fileType").val() !== "") {                
                let myfile= $("#fileType").val();
                var ext = myfile.split('.').pop();              
                    if(ext !== "pdf" && ext !== "docx" && ext !== "doc") {
                        isValid = false;
                        window.Materialize.toast(Constant.SELECT_FILE_TYPE, 5000,  'red rounded')                
                    }                             
            }            
        }      
        return isValid;
    }
    resetData = () => {
        $('#uploadForms').val('');
        $('#subUploadForms').val('');
        $('#fileType').val('');
        $('#file').val('');
        $('#fileType').removeClass("valid");       
        this.setState({            
            selectedValue:'',
            selectedUploadForm:'',
            selectedSubUploadForm:'',            
            isDocumentSelected:false,
            uploadSubForm:[]
        });
       }
     render(){       
        let i;        
        let uploadForms = [];
        const {selectedUploadForm} = this.state;       
        if(this.state.uploadForms === null) {
            uploadForms.push({ value: "", label: "--Select--"})               
        }else{
            uploadForms.push({ value: "", label: "--Select--"})            
            for(i=0; i<this.state.uploadForm.length; i++)
            {
                uploadForms.push(this.state.uploadForm[i])
            }
        }    
        
        let uploadSubForms = [];        
        const {selectedSubUploadForm} = this.state;        
        if(this.state.uploadSubForm === null) {
           // uploadSubForms.push({ value: "", label: "--Select--"})    
        }else{
            //uploadSubForms.push({ value: "", label: "--Select--"})            
            for(i=0; i<this.state.uploadSubForm.length; i++)
            {
                uploadSubForms.push({ value: this.state.uploadSubForm[i], label: this.state.uploadSubForm[i]})
            }
        }
        const { isLoading } = this.state;               
         return (                         
             <div>                
                <div className="v2-back-inner">
                 <div className="wrapper slide-nav-toggle">
                    <Header h2Tag="Upload Forms"  />
                    <main>
                        <div className="max-1500">                                                                                                             
                            <div className="inner-page-container">
                                <div className="row">
                                    <div className="boxWithShadow boxWithSpace clearfix">
                                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6"> 
                                            <div className="input-field suffix">
                                                    <label className="active" forhtml="UploadForms">Type*</label>
                                                        <Select
                                                        id = "uploadForms"
                                                        name="uploadForms"
                                                        isMulti = {false}
                                                        isSearchable={false}
                                                        closeMenuOnSelect = {true}                                                
                                                        value={selectedUploadForm}                                                    
                                                        placeholder=" --Select--"
                                                        onChange={(event)=>{this.getSub(event);this.handleUploadForm(event)}}
                                                        options={uploadForms}                                                  
                                                        />
                                            </div>
                                        </div>
                                        {
                                            selectedUploadForm.value != 'Training'?
                                            <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                                            <div className="input-field suffix">
                                                    <label className="active" forhtml="UploadSubForms">Category*</label>                                            
                                                        <CreatableSelect
                                                            id = "subUploadForms"
                                                            name="subUploadForms"
                                                            placeholder="--Select--"
                                                            isClearable
                                                            isDisabled={isLoading}
                                                            isLoading={isLoading}
                                                            onChange={this.handleChange}
                                                            onCreateOption={this.handleCreate}
                                                            options={uploadSubForms}
                                                            value={selectedSubUploadForm} 
                                                            className="creatableSelect"                                                           
                                                        />  
                                            </div>
                                        </div>:''
                                        }
                                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-6">
                                            <div className="input-field">
                                                <label className="active">Select Document*</label>
                                                <div className="file-field  select-file">
                                                    <div className="btn">
                                                        <span>Browse Document</span>
                                                        <input type="file" id="file" accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="document"  onChange={this.change.bind(this)}/>
                                                    </div>
                                                    <div className="file-path-wrapper">
                                                        <CustomInput intType="text" intid="fileType" intplaceholder="Select File" intName="document" change={this.change.bind(this)} intClasssName={this.state.isDocumentSelected? 'file-path validate invalid': 'file-path validate'}/>
                                                        <Label lblhtmlFor="fileType" lblclassName={this.state.isDocumentSelected? 'active': null } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                                            <div className="mt40">
                                            <Tooltip placement="top" trigger={['hover']} overlay={<span>Upload</span>}> 
                                                <button className="btn waves-effect waves-light btn-darkpurpale" onClick={this.click}>Upload</button>    
                                            </Tooltip>
                                            <Tooltip placement="top" trigger={['hover']} overlay={<span>Reset</span>}> 
                                                <button className="btn waves-effect waves-light btn-white" id="reset" onClick={this.resetData}>Reset</button>                          
                                            </Tooltip>
                                            </div>   
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                <Footer />
            </div>
        </div>
    </div>
         );
     }
}

export default UploadForms;