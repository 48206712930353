import React, { Component } from 'react';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
export default class PickyDropdown extends Component{

  render() {
    return (
            <Picky
              numberDisplayed={this.props.numberDisplayed}
              value={this.props.value}            
              options={this.props.options}
              onChange={this.props.onChange}
              open={this.props.open}
              valueKey="countData"
              labelKey="label"
              multiple={this.props.multiple}
              includeSelectAll={this.props.includeSelectAll}
              includeFilter={this.props.includeFilter}
              placeholder={this.props.placeholder}
              allSelectedPlaceholder={this.props.allSelectedPlaceholder}
              manySelectedPlaceholder={this.props.manySelectedPlaceholder}
              dropdownHeight={this.props.dropdownHeight}
              render={({                
                index,
                style,
                item,
                isSelected,
                selectValue,
                labelKey,
                valueKey,
                multiple
              }) => {
                return (
                  
                    <li
                    style={{ ...style }} // required
                    className= "col-sm-4" // required to indicate is selected
                    key={item[valueKey]}
                    onClick={() => selectValue(item)} // required
                    >
                     {(item[labelKey].length >10) ?
                    <OverlayTrigger placement="top" overlay={<Tooltip id={item[valueKey]}> {item[labelKey]}  </Tooltip>}>
                    <div className={isSelected ? "selected form-group" : ""} >
                      <input type="checkbox" 
                        readOnly
                        checked={isSelected}
                        style={{height: "auto", width: "auto" }} />
                       <label
                      style={{
                        fontWeight: "normal"
                      }} data-tip={labelKey}>
                
                      {item[labelKey]}
                    </label>
                    </div>
                       </OverlayTrigger>
                       :<div className={isSelected ? "selected form-group" : ""} >
                       <input type="checkbox" 
                         readOnly
                         checked={isSelected}
                         style={{height: "auto", width: "auto" }} />
                        <label
                       style={{
                         fontWeight: "normal"
                       }} data-tip={labelKey}>
                 
                       {item[labelKey]}
                     </label>
                     </div>}
                  </li>
                );
              }}
            />     
         );
        }
    }
    // render(<PickyDropdown />, document.getElementById('root'));