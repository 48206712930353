import React, { Component } from 'react';
import { Input } from 'react-materialize';
import CustomInput from '../../CustomUI/CustomInput'
import * as Constant from '../../CustomUI/Constant';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import CustomMaskedInput from '../../CustomUI/MaskedInput';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import $ from 'jquery';
import UserProfile from '../../../UserProfile';

class PrescriberKsUser extends Component {

	tooltipFormatter(cell, row){
		return (
		<OverlayTrigger placement="bottom" overlay={
		<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
	};
		facilityListner = (cell,row) =>{
		 let id = "facility_"+row.index;
		 let name = "facility_"+row.index;

		return(
			<input type="text" placeholder="Practice Name "name={name} id={id}/>
		)
	}
	amaCodeListner = (cell,row) =>{
		let id = "amacode_"+row.uniqueId;
		let name = "amacode_"+row.uniqueId;

			   return(
			<input maxLength={10} placeholder="AMA Code" type="text" name={name} id={id}/>
	   )
   }
	render() {

		$(".specialCharRestriction").on('keypress keyup blur', function (event) {
            // var regex = new RegExp("^[#%^&{}[\\]]+$");
            var regex = new RegExp("^[#%^&{}[\\]]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                event.preventDefault();
                return false;
            }
         });
         //specific characters allowed ,alphabets allowed,digit allowed
         $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._']+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });

	   let allPrescriberApiData = this.props.prescriberApiData? this.props.prescriberApiData:''
	   let prescriberApiData = allPrescriberApiData[0]
	   let basic = prescriberApiData?prescriberApiData.basic:''
	   let addresses = prescriberApiData?prescriberApiData.addresses[0]:[]
	   let taxonomies =  prescriberApiData?prescriberApiData.taxonomies:[]
	   let practiceLocation = prescriberApiData?prescriberApiData.practiceLocations:[]
	   let formaattedPracticeLocation = []
	   let stateLicence	 = prescriberApiData?prescriberApiData.identifiers:[]

	   if(practiceLocation){
		   for(let i =0;i<practiceLocation.length;i++){
			   let type=''
			   if(practiceLocation[i].type=='Primary'){
			   type='Primary'}else{
				type='other'
			   }
			let innerJson = {"index":i,"facility":practiceLocation[i].facility,"type":type,"address":practiceLocation[i].address_1+ " "+practiceLocation[i].address_2+","+practiceLocation[i].city+", "+practiceLocation[i].state_full_name+" "+practiceLocation[i].postal_code+", "+practiceLocation[i].country_name}
			   formaattedPracticeLocation.push(innerJson);
		   }

	   }

	   let stepFourPrescriberValidate ={...this.props.stepFourPrescriberValidate}



	   $(".allownumericwithoutdecimal").on("keypress keyup blur",function (event) {
		$(this).val($(this).val().replace(/[^-\d].+/, ""));
		 if ((event.which < 48 || event.which > 57)) {
			 event.preventDefault();
		 }
	 });
	 $('.noSpace').keyup(function() {
		this.value = this.value.replace(/\s/g,'');
	   });
  	return (
				<div className="prescriberDetails ">
				<div className="row">
				    {this.props.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
					{(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN) ?
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
								<div className="input-field">
									<CustomInput intid="userNameloginstep4" intplaceholder={Constant.LABEL_USER_NAME+Constant.STAR} intType="text"  fromApi={false} intClasssName={stepFourPrescriberValidate.userNameValid || stepFourPrescriberValidate.duplicateUserNameValid? 'validate invalid noSpace specialCharRestriction alphanumeric': 'validate noSpace specialCharRestriction alphanumeric'} intName={Constant.INPUT_USER_NAME}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } required keyPress={this.props.click}  keyEvent='Enter'/>
									{/* <Label  lblhtmlFor="userNamelogin"   lblclassName={(this.props.StepFourPrescriberData.userName || stepFourPrescriberValidate.userNameValid )? 'active': null} lbldataError={stepFourPrescriberValidate.userNameValid ? Constant.USER_NAME:null}  /> */}
								</div>
						</div>:''}
					<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
						<div className="input-field label-0">
							<Input s={12} m={12} xl={12} l={12} type='select' id="prefix"  disabled={basic.name_prefix?basic.name_prefix:false} name={Constant.INPUT_PREFIX} label={Constant.LABEL_PREFIX} onChange={(event)=>this.props.change(event,Constant.STEP_FOUR_PRESCRIBER)}>
							<option   value="" >--Select--</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_DR} value={basic.name_prefix === Constant.PREFIX_VALUE_DR ? basic.name_prefix : Constant.PREFIX_VALUE_DR}>{ Constant.PREFIX_NAME_DR}</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_MISS} value={basic.name_prefix === Constant.PREFIX_VALUE_MISS ? basic.name_prefix : Constant.PREFIX_VALUE_MISS}>{Constant.PREFIX_NAME_MISS}</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_MR} value={basic.name_prefix === Constant.PREFIX_VALUE_MR ? basic.name_prefix : Constant.PREFIX_VALUE_MR}>{Constant.PREFIX_NAME_MR}</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_MRS} value={basic.name_prefix === Constant.PREFIX_VALUE_MRS ? basic.name_prefix : Constant.PREFIX_VALUE_MRS}>{Constant.PREFIX_NAME_MRS}</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_MS} value={basic.name_prefix === Constant.PREFIX_VALUE_MS ? basic.name_prefix : Constant.PREFIX_VALUE_MS}>{ Constant.PREFIX_NAME_MS}</option>
							<option selected = {basic.name_prefix === Constant.PREFIX_VALUE_PROF} value={basic.name_prefix === Constant.PREFIX_VALUE_PROF ?basic.name_prefix :Constant.PREFIX_VALUE_PROF}>{Constant.PREFIX_NAME_PROF}</option>
							</Input>
						</div>
					</div>
					<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
							<CustomInput intid="fNameAboutPrescriber" intplaceholder={Constant.LABEL_FIRST_NAME+Constant.STAR} intType="text" value={basic.first_name?basic.first_name:''} fromApi ={basic.first_name?true:false}   intClasssName={stepFourPrescriberValidate.firstNameValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_FIRST_NAME}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } maxLength="50"/>
								{/*<Label lblhtmlFor="fNameAboutPrescriber" lblclassName={(stepFourPrescriberValidate.firstNameValid ||basic.first_name||this.props.StepFourPrescriberData.firstName)? 'active': null} lbldataError={stepFourPrescriberValidate.firstNameValid ? Constant.FIRST_NAME: null} lblname={Constant.LABEL_FIRST_NAME+Constant.STAR}/>*/}
							</div>
						</div>
					<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
						<div className="input-field">
							<CustomInput intid="mNameAboutPrescriber" intplaceholder={Constant.LABEL_MIDDLE_NAME}  intType="text" change={this.props.change} fromApi ={basic.middle_name?true:false}  intName={Constant.INPUT_MIDDLE_NAME} value={basic.middle_name?basic.middle_name:''}  intStep={Constant.STEP_FOUR_PRESCRIBER } maxLength="30"/>
							{/*<Label lblhtmlFor="mNameAboutPrescriber" lblclassName={basic.middle_name? 'active': null}  lblname={Constant.LABEL_MIDDLE_NAME}/>*/}

						</div>
					</div>
					<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
						<div className="input-field">
							<CustomInput intid="lNameAboutPrescriber" intplaceholder={Constant.LABEL_LAST_NAME+Constant.STAR} intType="text" value={basic.last_name?basic.last_name:''} fromApi ={basic.last_name?true:false} intClasssName={stepFourPrescriberValidate.lastNameValid? 'validate invalid': 'validate'}  required  intName={Constant.INPUT_LAST_NAME}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } maxLength="30"/>
							{/*<Label lblhtmlFor="lNameAboutPrescriber" lblclassName={stepFourPrescriberValidate.lastNameValid ||basic.last_name||this.props.StepFourPrescriberData.lastName ?'active': null} lbldataError={stepFourPrescriberValidate.lastNameValid ? Constant.LAST_NAME: null} lblname={Constant.LABEL_LAST_NAME+Constant.STAR}/>*/}

						</div>
					</div>

					<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
						<div className="input-field">
						<CustomInput intid="address1AboutPrescriber" intplaceholder={Constant.LABEL_ADDRESS_LINE_1+Constant.STAR} intType="text" value={addresses.address_1?addresses.address_1:''} fromApi ={addresses.address_1?true:false} intClasssName={stepFourPrescriberValidate.addressLine1Valid? 'validate invalid': 'validate'}  required  intName={Constant.INPUT_ADDRESSLINE_1}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER }/>
							{/*<Label lblhtmlFor="address1AboutPrescriber" lblclassName={stepFourPrescriberValidate.addressLine1Valid ||addresses.address_1||this.props.StepFourPrescriberData.addressLine1? 'active': null} lbldataError={stepFourPrescriberValidate.addressLine1Valid ? Constant.ADDRESS_LINE_1: null} lblname={Constant.LABEL_ADDRESS_LINE_1+Constant.STAR}/>*/}

						</div>
					</div>
						<div className="col-lg-3 col-md-3  col-sm-4  pl5 pr5">
							<div className="input-field">
								<CustomInput intid="address2AboutPrescriber" intplaceholder={Constant.LABEL_ADDRESS_LINE_2}  intType="text" value={addresses.address_2?addresses.address_2:''} fromApi ={addresses.address_2?true:false} change={this.props.change}  value={addresses.address_2}   intStep={Constant.STEP_FOUR_PRESCRIBER } intName={Constant.INPUT_ADDRESSLINE_2}/>
								{/*<Label lblhtmlFor="address2AboutPrescriber" lblclassName={addresses.address_2? 'active': null} lblname={Constant.LABEL_ADDRESS_LINE_2}/>*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
								<CustomInput intid="cityAboutPrescriber" intplaceholder={Constant.LABEL_CITY+Constant.STAR} intType="text" value={addresses.city?addresses.city:''}  fromApi ={addresses.city?true:false}  intClasssName={stepFourPrescriberValidate.cityValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_CITY}   change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER }/>
								{/*<Label lblhtmlFor="cityAboutPrescriber" lblclassName={stepFourPrescriberValidate.cityValid||addresses.city||this.props.StepFourPrescriberData.city?'active': null} lbldataError={stepFourPrescriberValidate.cityValid ? Constant.CITY: null} lblname={Constant.LABEL_CITY+Constant.STAR}/>*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
								<CustomInput intid="stateAboutPrescriber"  intplaceholder={Constant.LABEL_STATE+Constant.STAR} intType="text" value={addresses.state_full_name?addresses.state_full_name:''}  fromApi ={addresses.state_full_name?true:false}  intClasssName={stepFourPrescriberValidate.isStateValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_STATE}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER }/>
								{/*<Label lblhtmlFor="stateAboutPrescriber" lblclassName={stepFourPrescriberValidate.isStateValid||addresses.state_full_name||this.props.StepFourPrescriberData.state?'active': null} lbldataError={stepFourPrescriberValidate.isStateValid ? Constant.STATE: null} lblname={Constant.LABEL_STATE+Constant.STAR}/>*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
									<CustomInput intid="zipAboutPrescriber" intplaceholder={Constant.LABEL_ZIP+Constant.STAR} intType="text" value={addresses.postal_code?addresses.postal_code:''} fromApi ={addresses.postal_code?true:false}  intClasssName={stepFourPrescriberValidate.zipValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_ZIP}  change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } />
									{/*<Label lblhtmlFor="zipAboutPrescriber" lblclassName={stepFourPrescriberValidate.zipValid || addresses.postal_code || this.props.StepFourPrescriberData.zip? 'active': null} lbldataError={stepFourPrescriberValidate.zipValid ? Constant.ZIP: null} lblname={Constant.LABEL_ZIP+Constant.STAR}/>*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
								<CustomMaskedInput  intId="workPhoneAboutPrescriber" placeholder={Constant.LABEL_WORK_PHONE+Constant.STAR} intType="text" value={addresses.telephone_number} fromApi ={addresses.telephone_number?true:false} intClassName={stepFourPrescriberValidate.workphoneValid? 'validate invalid': 'validate'} required intMask={Constant.REGEX_NUMBER_MASK} intName={Constant.INPUT_WORK_PHONE} onBlur={this.props.workPhone} change={(event)=>{this.props.change(event)}} intStep={Constant.STEP_FOUR_PRESCRIBER} keyPress={this.props.click}  keyEvent='Enter'/>
								{/*<Label lblhtmlFor="workPhoneAboutPrescriber" lblclassName={stepFourPrescriberValidate.workphoneValid ||addresses.telephone_number||this.props.StepFourPrescriberData.workphone? 'active': null} lbldataError={stepFourPrescriberValidate.workphoneValid ?  Constant.WORK_PHONE: null} lblname={Constant.LABEL_WORK_PHONE+Constant.STAR} />*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
							{/* <CustomInput intid="fax1PhoneAboutPrescriber" intType="text" value={addresses.fax_number} fromApi ={addresses.fax_number?true:false} blur={this.props.faxNumber} intClasssName={stepFourPrescriberValidate.zipValid? 'validate invalid allownumericwithoutdecimal': 'validate allownumericwithoutdecimal'}  intName={Constant.INPUT_FAX_1} change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } maxlength="10"/> */}
								<CustomMaskedInput  intId="fax1PhoneAboutPrescriber"  placeholder={Constant.LABEL_FAX_1+Constant.STAR} intMask={Constant.REGEX_NUMBER_MASK} fromApi ={addresses.fax_number?true:false} intType="text" value={addresses.fax_number} intName={Constant.INPUT_FAX_1} onBlur={this.props.faxNumber} change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } keyPress={this.props.faxNumber} keyEvent='Enter'/>
								{/*<Label lblhtmlFor="fax1PhoneAboutPrescriber"  lblclassName={addresses.fax_number? 'active': null} lblname={Constant.LABEL_FAX_1} />*/}
							</div>
						</div>
						{/* <div className="col-lg-3 col-md-3">
							<div className="input-field">
								<CustomMaskedInput  intId="fax2PhoneAboutPrescriber" onBlur={this.props.faxNumber} intMask={Constant.REGEX_NUMBER_MASK}  intType="text"  intName={Constant.INPUT_FAX_2} change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } />
								<Label lblhtmlFor="fax2PhoneAboutPrescriber" lblclassName={addresses.fax_number? 'active': null} lblname={Constant.LABEL_FAX_2}/>
							</div>
						</div> */}
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">

								<CustomInput intid="emailAboutPrescriber" intplaceholder={Constant.LABEL_EMAIL+Constant.STAR} intType="email" intClasssName={stepFourPrescriberValidate.emailValid? 'validate invalid': 'validate'}  value={this.props.email}  required intName={Constant.INPUT_EMAIL}   change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER } keyPress={this.props.click}  keyEvent='Enter'/>
								{/*<Label lblhtmlFor="emailAboutPrescriber" lblclassName={this.props.StepFourPrescriberData.email || stepFourPrescriberValidate.emailValid? 'active': null} lbldataError={stepFourPrescriberValidate.emailValid?Constant.EMAIL: null} lblname={Constant.LABEL_EMAIL+Constant.STAR}/>*/}
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
							<div className="input-field">
								<CustomInput intid="confirmEmailAboutPrescriber" intplaceholder={Constant.LABEL_CONFIRM_EMAIL+Constant.STAR} intType="email" intClasssName={stepFourPrescriberValidate.confEmailValid? 'validate invalid': 'validate'} required   value={this.props.email}  intName={Constant.INPUT_CONFIRM_EMAIL} change={this.props.change} intStep={Constant.STEP_FOUR_PRESCRIBER }  keyPress={this.props.click}  keyEvent='Enter'/>
								{/*<Label lblhtmlFor="confirmEmailAboutPrescriber" lblclassName={this.props.StepFourPrescriberData.confirmEmail || stepFourPrescriberValidate.confEmailValid? 'active': null} lbldataError={stepFourPrescriberValidate.confEmailValid ? Constant.CONFIRM_EMAIL: null} lblname={Constant.LABEL_CONFIRM_EMAIL+Constant.STAR}/>*/}
							</div>
						</div>
					</div>
						<div className="mt20 clearfix">
							<div className="tabSectionTitle clearfix">
								<div className="col-xs-12 col-lg-12 col-md-12 pl0">
									<h3>Taxonomy</h3>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 pl5 pr5">
							<div className="responsive-table max-width-768">
							<BootstrapTable data={taxonomies}  striped bordered={ true }>
								<TableHeaderColumn dataField='type' width="15%">Type</TableHeaderColumn>
								<TableHeaderColumn dataField='amacode'dataFormat={this.amaCodeListner} width="15%">AMA CODE</TableHeaderColumn>
								<TableHeaderColumn dataField='desc'width="16%">Specialty Name</TableHeaderColumn>
								<TableHeaderColumn isKey dataField='code' width="16%">Taxonomy Code</TableHeaderColumn>
								<TableHeaderColumn dataField='state_full_name' width="16%" > State</TableHeaderColumn>
								<TableHeaderColumn dataField='license' width="16%" > License Number</TableHeaderColumn>
							</BootstrapTable>
							</div>
							</div>
				   		</div>
				   <div className="mt20 clearfix">
				   		<div className="tabSectionTitle clearfix">
						   <div className="col-xs-12 col-lg-12 col-md-12 pl0">
								<h3>Practice Locations</h3>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 pl5 pr5">
						<div className="responsive-table max-width-768">
							<BootstrapTable keyField='address'  data={formaattedPracticeLocation} striped bordered={ true }>
								<TableHeaderColumn  dataFormat={this.facilityListner} dataField='facility' width="25%">Practice Name</TableHeaderColumn>
								<TableHeaderColumn  dataField='type' width="25%" >Type</TableHeaderColumn>
								<TableHeaderColumn  dataField='address' tdStyle={ { whiteSpace: 'normal' } } thStyle={ { whiteSpace: 'normal' } } width="50%">Address</TableHeaderColumn>
							</BootstrapTable>
							</div>
						</div>

				   </div>
				   <div className="mt20 clearfix">
				   		<div className="tabSectionTitle clearfix">
						   <div className="col-xs-12 col-lg-12 col-md-12 pl0">
								<h3>State License</h3>
							</div>
						</div>
						<div className="col-lg-12 col-md-12 col-sm-12 pl5 pr5">
						<div className="responsive-table max-width-768">
							<BootstrapTable data={stateLicence} striped bordered={ true }>
								<TableHeaderColumn dataField='desc' width="33.33%">Issuer</TableHeaderColumn>
								<TableHeaderColumn dataField='state_full_name'width="33.33%">State</TableHeaderColumn>
								<TableHeaderColumn isKey dataField='identifier' width="33.33%">Number</TableHeaderColumn>
							</BootstrapTable>
							</div>
						</div>

				   </div>

				</div>

		);
	}
}
export default PrescriberKsUser;