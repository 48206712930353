import React, { Component } from 'react';
import {PhysicanTable} from './AgentsTable';
import Footer from '../../../Component/PageComponent/Footer/Footer'
import Header from '../../../Component/PageComponent/Header/Header';
import axios from 'axios'
import Input from '../../../Component/CustomUI/Input';
import Button from '../../../Component/CustomUI/Button';
import Label from '../../../Component/CustomUI/Label';
import * as Constant from '../../../Component/CustomUI/Constant';
import {TableAgent} from '../../../Component/PageComponent/Table/Table';
import ReactTooltip from 'react-tooltip';
import UserProfile from '../../../UserProfile';
import $ from 'jquery';

let prescriberJson = []
let selectedPrescriberJson = []
let id =''
let prescriberId =''
let queryString = ''
let statusParams = ''
let assUnqiueDataIdParams = ''
let disUnqiueDataIdParams = ''

export default class MyPhysician extends Component {

    state = {
        myPhysicianData : [],
        npiValid:false,
        firstNameValid:false,
        lastNameValid:false,
        update:false,
        isLoading:true

    }


    componentDidMount (){
       this.getMyPhysicianData()

    }

    getMyPhysicianData = ()=>{

        let username = UserProfile.getUserName()
        axios.get('/agentProfile/getMyPhysician?username='+encodeURIComponent(username))
        .then(response => {
            this.setState({myPhysicianData:[response.data]})
            this.setState({isLoading:false})

       })
        .catch(function (error) {
            console.log(error);
        });

    }

    associatedDataListner =(event)=>{

        let targetName = event.target.name;
        let agntFName = document.getElementById("agentFNSearch").value;
        let agntLName = document.getElementById("agentLNSearch").value;
        let agntNPI = document.getElementById("npiAgent").value;
        let username = UserProfile.getUserName()
        let check = "isFromMyPhysician"

        if(!agntFName && !agntLName && !agntNPI){
            if(targetName === "agentAssociatePrescriberSearch" || targetName === undefined){
                window.Materialize.toast(Constant.SELECT_ANY_SEARCH_FIELD, 3000,  'red rounded')
                this.setState({npiValid:true,firstNameValid:true,lastNameValid:true})
           }
        }else if (((agntNPI) &&(agntNPI.length === Constant.TEN) && (agntNPI.match(Constant.REGEX_DIGIT))) || (agntFName || agntLName)){
        let queryString  = '';
        queryString += agntFName ? '?firstName='+ agntFName:''
        queryString += (queryString != "") ? (agntLName ? '&lastName='+ agntLName:'') : (agntLName ? '?lastName='+ agntLName:'')
        queryString += (queryString != "") ? (agntNPI ? '&npiNumber='+ agntNPI:'') : (agntNPI ? '?npiNumber='+ agntNPI:'')
        queryString += (queryString != "") ? (username ? '&username='+ encodeURIComponent(username):'') : ''
        queryString += (queryString != "") ? ('&check='+ check) : ''

        axios.get('/registration/agent/associatedPrescribers'+queryString)
        .then(response => {

                if(response.data.length === 0){
                    window.Materialize.toast(Constant.MD_NOT_FOUND, 3000, 'red rounded')                          
                }
                
                let phyDetail = []
                prescriberJson = []
                phyDetail.push(response.data);
               
                let dataLength = phyDetail[0] ? phyDetail[0].length:0
                for(var i = 0;i < dataLength;i++){
                    let facility = ''
                    let speciality=''
                    for (let k = 0; k < phyDetail[0][i].taxonomies.length; k++) {
                        if ((k) == (phyDetail[0][i].taxonomies.length - 1)) {
                            speciality = speciality + phyDetail[0][i].taxonomies[k].desc
                        } else if(phyDetail[0][i].taxonomies[k].desc !== '') {
                            speciality = speciality + phyDetail[0][i].taxonomies[k].desc + ','
                        }
                    }
                    if (phyDetail[0][i].practiceLocations.length != 0) {
                        for (let k = 0; k < phyDetail[0][i].practiceLocations.length; k++) {
                            if ((k) == (phyDetail[0][i].practiceLocations.length - 1)) {
                                facility = facility + phyDetail[0][i].practiceLocations[k].facility
                            }
                            else if(phyDetail[0][i].practiceLocations[k].facility !== '') {
                                facility = facility + phyDetail[0][i].practiceLocations[k].facility + ','
                            }
                        }
                    }

                    let innerJson = {index:phyDetail[0][i].id,NPI :phyDetail[0][i].npi, PrescriberName : phyDetail[0][i].firstName+" "+phyDetail[0][i].lastName, FacilityName :facility, NPIStatus : phyDetail[0][i].npiStatus, Speciality :speciality, WorkPhone :phyDetail[0][i].contact[0]?phyDetail[0][i].contact[0].contactValue:'', Fax :phyDetail[0][i].contact[2]?phyDetail[0][i].contact[2].contactValue:'' }
                    let findExistingPrescriberIndex = selectedPrescriberJson.findIndex(e=>e.index==phyDetail[0][i].id)
                    if(findExistingPrescriberIndex > -1){
                        innerJson['alreadySelected']= true
                    }else{
                        innerJson['alreadySelected']= false
                    }
                    prescriberJson.push(innerJson)
                }
                prescriberJson = [...new Set(prescriberJson)];
                this.setState({stateTest:false});
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else if(agntNPI != "" && ((agntNPI.length !== Constant.TEN) || !(agntNPI.match(Constant.REGEX_DIGIT)))){
                window.Materialize.toast(Constant.NPI_VALIDATION, 3000,  'red rounded')
                this.setState({
                    stepTwoValidate :{
                        npiValid : true
                    }
                })
            }

    }

    changePrescriberListner =(event,row,isSelected) =>{
            //let removedIndex = -1;
            let alreadySelectedIndex = -1;
            if(!isSelected){
                alreadySelectedIndex = selectedPrescriberJson.findIndex(e=>e.index==row.index);
                if(alreadySelectedIndex ==-1){
                    //removedIndex = prescriberJson.findIndex(e=>e.index==row.index);
                    selectedPrescriberJson.push(row);
                    selectedPrescriberJson.sort(function(a,b){
                        return a.index - b.index;
                        }
                    );
                }
            }
            // else{
            //     removedIndex = selectedPrescriberJson.findIndex(e=>e.index==row.index);
            // }

            this.setState({stateTest:false});
    }

    addPhysician = ()=>{
        let prescriberIds = []
        let userId = this.state.myPhysicianData[0].user.id
        for(var i = 0;i<selectedPrescriberJson.length;i++){
             let id = selectedPrescriberJson[i].index
             prescriberIds.push(id)
            }

        if(userId && prescriberIds.length > 0){
           let agentJson = {
                    id:userId,prescriberIds:prescriberIds

                }

                axios.post('/agentProfile/saveNewAssociatedPhycisian',agentJson)
                .then(response => {
                    window.Materialize.toast(Constant.ASSOCIATE_PRESCRIBER, 3000,  'green rounded')
                    $('#addPrescriberModalCancel').click();
                    this.getMyPhysicianData()
                }).catch(function (error) {
                    console.log(error);
                });
        }else{
            window.Materialize.toast(Constant.ASSOCIATE_ONE_PRESCRIBER, 3000,  'red rounded')
        }
    }

    addPrescriber = ()=>{

        return(
             <div id="addPrescriberModal" className="modal fade" role="dialog" >
                <div className="modal-dialog max1280">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.fromEdit ?'Edit Prescriber Modal':'Add Physician'}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    {this.addMyPhysician()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <section className="buttonWrapper">
                            <Button id="submitPrescriber" btnName="Save" className="btn btn-primary wizard-next btn-darkpurpale" click={this.addPhysician.bind(this)} secondParams={null} />
                                <button type="button" id="addPrescriberModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>

                            </section>
                        </div>
                     </div>
                  </div>
             </div>
        )
    }

    deAssociatePrescriberData = (event,row) => {

         id = row?row.id:''
         prescriberId = row?row.prescriberId:''
         statusParams =  row.status
         assUnqiueDataIdParams = "associate_"+row.prescriberId
         disUnqiueDataIdParams = "disassociate_"+row.prescriberId
         queryString  = '';
         queryString += id ? '?id='+ id:''
         queryString += (queryString != "") ? (prescriberId ? '&prescriberId='+ prescriberId:'') : ''

    }

    associatePrescriber = () =>{

        axios.get('/agentProfile/associatePrescriber'+queryString)
        .then(response => {
          if(response.data){
              $("#AssociatePrescriberModalCancel").click()
              window.Materialize.toast("Prescriber Status Request Is Pending", 3000,  'green rounded')
            if(statusParams === Constant.DISSOCIATED){

                $("#"+assUnqiueDataIdParams).hide();
                $("#"+disUnqiueDataIdParams).show();
                this.getMyPhysicianData()
            }

          }
        }).catch(function (error) {
            console.log(error);
        });
        this.setState({update:true})

    }

    deAssociatePrescriber = ()=>{
        axios.get('/agentProfile/deAssociatePrescriber'+queryString)
        .then(response => {
          if(response.data){
              $("#deAssociatePrescriberModalCancel").click()
              window.Materialize.toast("Prescriber Disassociate Successfully", 3000,  'green rounded')
            if(statusParams !==  Constant.DISSOCIATED){
                $("#"+assUnqiueDataIdParams).show();
                $("#"+disUnqiueDataIdParams).hide();
            }
                this.getMyPhysicianData()
          }
        }).catch(function (error) {
            console.log(error);
        });
        this.setState({update:true})

     }

    deAssociatePrescriberDataModal(){
        return(
            <div id="deAssociatePrescriberModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center max550">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Disassociate Prescriber</h4>
                       </div>
                       <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <p>
                                        Are you sure! You want to disassociate Prescriber ?
                                    </p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="deAssociatePrescriberModalRemove" btnName="Ok" className="btn btn-primary wizard-next btn-darkpurpale " click = {this.deAssociatePrescriber} secondParams={null} />
                       <button type="button" id="deAssociatePrescriberModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

    AssociatePrescriberDataModal(){
        return(
            <div id="AssociatePrescriberModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Associate  Prescriber</h4>
                       </div>
                       <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <p>
                                    Are you sure! You want to associate  Prescriber ?
                                    </p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="AssociatePrescriberModalRemove" btnName="Ok" className="btn btn-primary wizard-next btn-darkpurpale" click = {this.associatePrescriber} secondParams={null} />
                       <button type="button" id="AssociatePrescriberModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

    addPrescriberListener = () =>{
        $("#agentFNSearch").val("");
        $("#agentLNSearch").val("");
        $("#npiAgent").val("");

         prescriberJson = []
         selectedPrescriberJson = []
         this.setState({update:true,
                        npiValid:false,
                        firstNameValid:false,
                        lastNameValid:false,})
    }

    addMyPhysician = ()=>{
        return(<div>
                	<div>
						<div className="agentRole">

                            <div className="tabSectionTitle clearfix">
                                <div className="col-xs-12">
                                    <h3 className="pull-left">Add Physician Details</h3>
                                    <span className="inline pt7 pl15">(Add all Prescribers for whom you will act as agent)</span>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <div className="input-field">
                                         <Label lblhtmlFor="npiAgent" lblname={Constant.LABEL_NPI}/>
                                        <Input intid="npiAgent" intType="text"  intClasssName={this.state.npiValid? 'validate invalid allownumericwithoutdecimal': 'allownumericwithoutdecimal validate'} intName={Constant.INPUT_NPI_AGENT} change={this.props.Change} intStep={Constant.STEP_TWO_AGENT } maxlength="10" keyPress={this.associatedDataListner} keyEvent='Enter'/>

                                    </div>
                                </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="input-field">
                                <Label	lblhtmlFor="agentFNSearch" lblname={ Constant.LABEL_FIRST_NAME }/>
                                <Input intid="agentFNSearch" intType="text"  intName={Constant.INPUT_AGENT_FNSEARCH} intClasssName={this.state.firstNameValid? 'specialCharRestriction  alphanumeric disablednumber validate invalid': 'disablednumber specialCharRestriction  alphanumeric validate'} intStep={Constant.STEP_TWO_AGENT } keyPress={this.associatedDataListner}  keyEvent='Enter' maxLength="30"/>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="input-field">
                                <Label lblhtmlFor="agentLNSearch" lblname={Constant.LABEL_LAST_NAME}/>
                                <Input intid="agentLNSearch" intType="text" intName={Constant.INPUT_AGENT_LNSEARCH} intClasssName={this.state.lastNameValid? 'validate invalid disablednumber specialCharRestriction alphanumeric': 'disablednumber validate alphanumeric specialCharRestriction'} intStep={Constant.STEP_TWO_AGENT } keyPress={this.associatedDataListner} keyEvent='Enter' maxLength="30"/>

                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <div className="input-field">
                                    <span className="actionIconWrapper">
                                    <ReactTooltip />
                                    <a href="#" name="agentAssociatePrescriberSearch" data-tip="Search" data-position="top" onClick={ this.associatedDataListner} ><i className="themify-icons ti-search" name="agentAssociatePrescriberSearch"></i></a>
                                    </span>
                                </div>
                            </div>
                         </div>
                     </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="addPhysicians">
                                    <TableAgent data={prescriberJson}
                                        changePrescriberListner={this.changePrescriberListner}
                                        isSelected={false} className="clearfix" />
                                    </div>
                                </div>
                            </div>


                            <div className="tabSectionTitle clearfix mt20 mb20">
										<div className="col-xs-12">
											<h3 >Selected Physician Details</h3>
										</div>
                            </div>
                                <div className="col-md-12">
                                    <div className="selectPhysicians">
                                        <TableAgent data={selectedPrescriberJson}
                                        changePrescriberListner={this.changePrescriberListner}
                                        isSelected={true}
                                        />
                                    </div>
                                </div>
                            </div>
        </div>)}


    render(){

         UserProfile.setUrlName(window.location.href)

         let userData = this.state.myPhysicianData[0]?this.state.myPhysicianData[0].user:''
         let prescriberData = this.state.myPhysicianData[0]?this.state.myPhysicianData[0].userPrescribers:''
         let userPrivileges = ''
         let prescriberDataLength = prescriberData?prescriberData.length:''
         let data = []

         for(var i = 0;i<prescriberDataLength;i++){
             userPrivileges = prescriberData[i]?prescriberData[i].userPrivileges:''
             let startDateWithTime = prescriberData[i].startDate ? new Date( prescriberData[i].startDate).toLocaleString() : null
             let startDate = startDateWithTime ? startDateWithTime.slice(0,startDateWithTime.indexOf(',')) : null;

             let endDateWithTime =  prescriberData[i].deactivateDate ? new Date( prescriberData[i].deactivateDate).toLocaleString() : null
             let endDate = endDateWithTime ? endDateWithTime.slice(0,endDateWithTime.indexOf(',')) : null

             let  pStatus = prescriberData[i].status
             pStatus = pStatus.toLowerCase()
             let status =   pStatus.charAt(0).toUpperCase() + pStatus.slice(1);

             let privileges = []
             for(let p = 0; p < userPrivileges.length;p++){
                 privileges.push(userPrivileges[p].value);
             }
             let privilegesData = privileges.join(",")
             let prefix = prescriberData[i].prescriber.prefix?prescriberData[i].prescriber.prefix:''
             let name = prescriberData[i].prescriber.fullName? prescriberData[i].prescriber.fullName:''
             let innerData =  {prescriberName :prefix +"  "+name, startDate : startDate?startDate:'', endDate:endDate?endDate:'',privileges : privilegesData,id:userData.id,prescriberId:prescriberData[i].prescriber.id,status:status}
             data.push(innerData)
         }


        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                        <Header h2Tag={"My Physician"}  />
                <main>
                    <div className="inner-page-container">

                        <section className="searchReportContainer boxWithShadow">
                            <div className="pageListContainer">
                            <PhysicanTable data={data}
                            deAssociatePrescriberData ={this.deAssociatePrescriberData}/>
                            <div className="fixed-action-btn">
                                <a href='#addPrescriberModal' id="modalId" onClick={this.addPrescriberListener} data-toggle="modal" data-position='top'  data-target="#addPrescriberModal" className='tooltipped btn-floating btn-large waves-effect waves-light red' data-tooltip='Add Physician'>
                                    <i className="large material-icons">add</i>
                                </a>
                            </div>

                            </div>
                        </section>
                        {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                        {this.addPrescriber()}
                        {this.deAssociatePrescriberDataModal()}
                        {this.AssociatePrescriberDataModal()}
                    </div>
                </main>
                    <Footer />
                </div>
             </div>
        )
    }
}