import React, { Component } from 'react';
import axios from 'axios';
import * as Constant from '../../Component/CustomUI/Constant';
import $ from 'jquery';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import UserProfile from '../../UserProfile';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tooltip from 'rc-tooltip';

var parse = require('html-react-parser');

let file_id = '';
let Path =""
class TrainingVideo extends Component {
    state = {        
        videodata:[],
        document:[],
        Name:'',
        id:'',
        file_type:'',
        documentData:[],
        file:[],
        srcUrl:'',
        index:-1
    }
    componentDidMount(){
       axios.get(Constant.VIDEO_PATH).then(response => {
                if(response.data){
                    this.setState({videodata:response.data})                
				}     
		}).catch(function (error) {
            console.log("Exception occur"+error)
        });

        axios.get('/status/getTrainingDocument').then(response => {
            if(response.data){
                let datatest=[]  

                response.data.map(item=>{
                    let document = {"id":item[0],"Name":item[1],"file_Type":item[2]}
                    datatest.push(document)
                })
                this.setState({document:datatest})  
                
            }                 
              
        }).catch(function (error) {
            console.log("Exception occur"+error)
        });
    }
    setIcon = (cell, row,formatExtraData,rowIdx) => {
        return (
                <div>
                    <a href='#viewPdf' data-toggle="modal" onClick={(event) => this.viewDocument(JSON.stringify(row),rowIdx)} data-toggle="modal" data-target="#viewpdf"  data-tip='View'>
                        <Tooltip placement="top" trigger={['hover']} overlay={<span>{row.Name}</span>}><div>{row.Name?row.Name.length<=28?row.Name:row.Name.substring(0,28-3)+'...':''}</div></Tooltip>
                    </a>
                </div>
            );        
    }
    download = (id) =>{
        window.location = Constant.DOWNLOAD_MD_FORMS+'?id='+ id;
    } 
    viewDocument = (row,index) =>{
        let rows = JSON.parse(row)
        let pathUrl =  Constant.VIEW_MD_FORMS +'?id='+rows.id
        this.setState({srcUrl:pathUrl,Name:rows.Name,id:rows.id,file_Type:rows.file_Type,index:index});   
    }
    remove = (id) => {       
        if(id !== undefined ){
            file_id = id?id:'';           
        }
        return(
            
            <div id="removeDocumentModal"  tabindex= "0"  className="modal fade" role="dialog" data-backdrop="static" data-keyboard={false}>
               <div className="modal-dialog v-center">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete File</h4>
                       </div>
                       <div className="modal-body">
                            <div className="inner-page-container">
                                <div className="col-sm-12">
                                    <p>
                                    Are you sure! You want to delete a file ?
                                    </p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">                           
                            <button type="button"  id="removeFileModalOk" className="btn btn-primary wizard-next btn-darkpurpale"  data-dismiss="modal" onClick = {()=>this.removeFile(id)} >Ok</button>
                            <button type="button" id="removeFileModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal" >Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }
    removeFile = () =>{
        if(file_id !== '' || file_id !== undefined || file_id !== null){          
            axios.get('/status/removeForm?id=' + file_id )
            .then(response => {
                if(response.data) {                                                      
                    this.removeItem(file_id);     
                    let isData = this.state.document ? this.state.document.length : '';                                        
                    $("#removeFileModalCancel").click();                 
                    window.Materialize.toast(Constant.FILE_REMOVE, 5000,  'green rounded') 
                    if(isData == 0){
                        $('#deleteModalCancel').click();
                    }             
                } else {
                    $("#removeFileModalCancel").click();
                    window.Materialize.toast(Constant.NOT_FILE_REMOVE, 5000,  'red rounded')                        
                }            
            })                                   
        } 

    }
    removeItem = (index) => {       
        let trainingDataWithRemoveElement = this.state.document.filter(item=>{
            return item.id!=index?this.state.document:false
        }) 
        let currentIndex = this.state.index >= this.state.document.length - 1?0:this.state.index    
        this.setState({document:trainingDataWithRemoveElement,index:currentIndex})                         
    }
     render(){   
        return (
        <div>
           <div className="v2-back-inner">
              <div className="wrapper slide-nav-toggle">
                  <Header h2Tag="Training Videos"  />
                    <main>
                        <div className="inner-page-container">
                            <div className="training-video-section scrollbar">
                                <div className="row">
                                {
                                    this.state.videodata.length>0?
                                    this.state.videodata.map(video=>
                                    {return(
                                        <div key={video.id}>
                                            <div className="col-sm-6 col-md-3">
                                                <div className="video-box">
                                                    <video className="lg-video-object lg-html5" id="video1" width="500%" controls controlsList="nodownload">
                                                        <source src={Constant.STATUS_VIDEO_PATH+video.id}  type="video/mp4"/>
                                                    </video>
                                                    <h4>{ parse(video.name, 'text/html')}
                                                </h4>
                                                </div>
                                            </div>
                                        </div>
                                    )}):null
                                }
                                </div>
                            </div>
                        </div>
                    </main>
                    <main>
                        {this.state.document.length > 1 ?
                            <div className="max-1500">
                                <div className="table-outer-custom-grid table-outer-custom-ellipse">
                                    <div className="table-cutom-grid">
                                        <BootstrapTable data={this.state.document} striped bordered={ true } remote={true}>
                                            <TableHeaderColumn width="50%" className="table-title" isKey  dataSort={false} dataField='Name' dataFormat={this.setIcon} ><span data-tip="Actions">Training Documents</span> </TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>      
                            </div>  
                        :
                            <div className="max-1500">     
                                    <div className="table-cutom-grid">
                                        <BootstrapTable data={this.state.document} striped bordered={ true }   remote={true}>
                                            <TableHeaderColumn width="50%" className="table-title" isKey  dataSort={false} dataField='Name' dataFormat={this.setIcon} ><span data-tip="Actions">Training Documents</span> </TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                            </div>  
                        }
                        <div id="viewpdf" className="modal fade" role="dialog" >
                            <div className="modal-dialog v-center" >
                                <div className="modal-content">
                                    <div className="modal-header">                                            
                                    </div>
                                    <div className="modal-body">
                                        <Carousel showThumbs={false} showStatus={false} showIndicators={false} selectedItem={this.state.index} useKeyboardArrows emulateTouch={true}>
                                            {
                                                this.state.document.length>0?
                                                this.state.document.map(item=>
                                                {
                                                    return(
                                                    <div key={item.id}>
                                                        <div className="pdf-title">
                                                            <h4>
                                                            <Tooltip placement="top" trigger={['hover']} overlay={<span>{item.Name}</span>}><font color="white">{item.Name?item.Name.length<=53?item.Name:item.Name.substring(0,53-3)+'...':''} </font></Tooltip>
                                                                {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER ?
                                                                <a href="#" className="delete-icon" data-toggle="modal" data-tip='Remove' data-target="#removeDocumentModal" onClick={()=>this.remove(item.id)}>
                                                                    <Tooltip placement="top" trigger={['hover']} overlay={<span>Remove</span>}><i className="fa fa-trash" aria-hidden="true"></i></Tooltip>
                                                                </a> : null}
                                                                <a href="javascript:void(0)" className="floatRight">
                                                                    <Tooltip placement="top" trigger={['hover']} overlay={<span>Download</span>}><i className="fa fa-download" data-tip='Download' aria-hidden="true" onClick={()=>this.download(item.id)}></i></Tooltip>
                                                                </a>    
                                                            </h4>
                                                        </div>
                                                        {item.file_Type==="application/pdf"?<iframe src={Constant.VIEW_MD_FORMS +'?id='+item.id} height="350px"></iframe>:<h4><center><font color="white">Document is not a PDF </font></center></h4>}
                                                        <img/>
                                                    </div>
                                                    )
                                                }
                                                ):null
                                            }
                                        </Carousel>
                                    </div>
                                    <div className="modal-footer">
                                        <section className="buttonWrapper">
                                            <button type="button" id="deleteModalCancel" className="btn btn-primary wizard-next btn-dark " data-dismiss="modal">Close</button>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.remove()}
                    </main>
                <Footer />
            </div>
        </div>
    </div>
           
         );
     }
}

export default TrainingVideo;