import React, { Component } from 'react';
import {ModalTable} from '../../PageComponent/Table/Table';
import axios from 'axios';
import {Input} from 'react-materialize';
import CustomInput from '../../CustomUI/Input';
import Label from '../../CustomUI/Label';
import  * as Constant  from '../../CustomUI/Constant';
import CKeditor from '../../CustomUI/CKeditor';
import Modal from 'react-modal';
import $ from 'jquery';
import jsPDF from 'jspdf';
import UserProfile from '../../../UserProfile';

let mdid=""
let authorizedByName=""
let caseId = ""
let patientName = ""
let DOB = ""
let patientUniqueId =""
let ksPatientId = ""
let tenantId = ""
let patientId = ""
let caseDetailId = ""
let ketuCaseDetailId = ""
let ketuRxId = ""
let phRxId = ""
let mdDocId = ""
let prescriberId = ""
let ckEditorContent = ""
let rowStatusData
var dateFormat = require('dateformat');
let dashBoardDataLength = 0
let dashBoard
let dataCount = 0
let statusGridData = []
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '900px',
      height                 : '600px',
      backgroundColor: '#000'
    }
  };

  Modal.setAppElement(document.getElementById("testModel"));

let files =[]
let tempFiles = []
let description = []
let documentName = []
let documentFiles =[]
let isPaginationAllow = false

export default class UploadDoc extends Component{

    constructor() {
        super();
        this.onBlur = this.onBlur.bind(this);
    }

      state = {
        clicked:false,
        dashBoardData:'',
        followUpStatuses:[],
        therapeuticCategories:[],
        followUpReasones:[],
        hqList:[],
        userType:Constant.PRESCRIBER.toUpperCase(),
        statusData:false,
        historydata:false,
        NPIList:[],
        test:false,
        dashBoardDataCount:0,
        isLoading: false,
        historyData:'',
        historyDataCount:0,
        page:1, sizePerPage:100, sortName:'caseId', sortOrder:'desc',
        historySortName:'uploaded_date',
        content: "",
        isDocumentNameEmpty:false,
        isDocumentDescriptionEmpty:false,

        isDocumentSelected:false,

        srcUrl : "",
        isModalOpen:true,
        documentName:"",
        documentTitle:"",

        // isWQExportClicked : false,
        // isHistoryExportClicked : false
      }

    componentDidMount(){
        $("#Preview").attr("disabled","true");
        $("#AddBtn").attr("disabled","true");
    }

    change = (event) =>{
        //event.preventDefault();
        this.value=event.target.value
        if(event.target.name === "document"){
            let document = event.target.files?event.target.files[0]:''
            tempFiles.push(document)
        }
    }


    click = () => {
        let isValid = this.documentValidation();
        if(isValid) {
             let filesTemp = []
            let descriptionTemp = []
            let documentNameTemp = []

            filesTemp.push(tempFiles[tempFiles.length-1])
            descriptionTemp.push($("#npdescriptioni").val())
            documentNameTemp.push($("#documentName").val())

            files.push(tempFiles[tempFiles.length-1])
            description.push($("#npdescriptioni").val())
            documentName.push($("#documentName").val())
            documentFiles.push({filesTemp,descriptionTemp,documentNameTemp})
            tempFiles = []
            $("#npdescriptioni").val("")
            $('#documentName').prop('selectedIndex',0);
            $("#fileType").val("")
            $("#file").val("")
            this.setState({clicked:true})
        }
    }

    documentValidation = () => {
        let isValid = true;

        if($("#fileType").val() === "") {
            isValid = false;
            this.setState({isDocumentSelected : true});
        } else {
            this.setState({isDocumentSelected : false});
        }

        // if($("#npdescriptioni").val() === "") {
        //     isValid = false;
        //     this.setState({isDocumentDescriptionEmpty : true});
        // } else {
        //     this.setState({isDocumentDescriptionEmpty : false});
        // }

        if($("#documentName").val() ===null || $("#documentName").val() ==="--Select--") {
            isValid = false;
            // this.setState({isDocumentNameEmpty : true});
            window.Materialize.toast("Please select type of document in upload document Section", 5000,  'red rounded')

        } else {
            // this.setState({isDocumentNameEmpty : false});
        }
        return isValid;
    }

    chartNotesValidation = () => {
        let isValid = true;
        if($("#documentTitle").val() === null || $("#documentTitle").val()==="--Select--") {
            isValid = false;
            window.Materialize.toast("Please select type of document in create your own Section", 5000,  'red rounded')
        }
        return isValid;
    }

    elementHandler = {
        '#ignorePDF': function (element, renderer) {
            return true;
        }
    };

    jsPdf = (event) => {
        //event.preventDefault();


            let content =""
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            var date = mm+"/"+dd+"/"+yyyy

            // content +='<table><td width="80%">Patient Name:<b>'+patientName+'</b></td><td width="20%">DOB:<b>'+DOB+'</b></td></table>'

            content += ckEditorContent

            // content +='Authorized by:'
            // content += '<br/>Submitted by:'+UserProfile.getUserName()
            // content +='<br/>Submission date:'+date

            let doc = new jsPDF();
            doc.setFontSize(12);
            doc.text('Patient Name:'+patientName, 10, 10)
            doc.text('DOB:'+DOB, 170, 10)
            doc.fromHTML(
            content,
            10,
            10,
            {
            'width': 180,'elementHandler':this.elementHandler
            });
            doc.setFontSize(12);
            doc.text('Authorized by:'+authorizedByName, 10,285)
            doc.text('Submitted by:'+UserProfile.getName(), 10, 290)
            doc.text('Submission date:'+date, 10, 295)
            window.open(doc.output('bloburl'),'Window','toolbar=0');
    }

    uploadDoc = (event) => {

        //event.preventDefault();
        let isValid = this.chartNotesValidation();
        if(isValid) {
            let docTitle = $("#documentTitle").val();
            let doc = new jsPDF();
            let content =""
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();
            var date = mm+"/"+dd+"/"+yyyy

            //  content +='Patient Name:<b>'+patientName+'</b>'
            // //  content +='Patient Last Name:<b>'+'Last Name'+'</b>'
            //  content +='DOB:<b>'+DOB+'</b>'

            content += ckEditorContent

            // content +='Authorized by:'
            // content += '<br/>Submitted by:'+UserProfile.getUserName()
            // content +='<br/>Submission date:'+date
            // let content = this.state.content
            doc.setFontSize(12);
            doc.text('Patient Name:'+patientName, 10, 10)
            doc.text('DOB:'+DOB, 170, 10)

            doc.fromHTML(
                content,
                15,
                15,
                {
                'width': 180,'elementHandler':this.elementHandler
                });
            doc.setFontSize(12);
            doc.text('Authorized by:'+authorizedByName, 10,285)
            doc.text('Submitted by:'+UserProfile.getName(), 10, 290)
            doc.text('Submission date:'+date, 10, 295)

            let document = doc.output('blob')

            var file = new File([document], docTitle+".pdf", {type: "application/pdf", lastModified: Date.now()});
            let filesTemp = []
            let descriptionTemp = []
            let documentNameTemp = []

            filesTemp.push(file);
            descriptionTemp.push("");
            documentNameTemp.push(docTitle)

            files.push(file);
            description.push("");
            documentName.push(docTitle)
            documentFiles.push({filesTemp,descriptionTemp,documentNameTemp})
            $('#documentTitle').prop('selectedIndex',0);
            // this.setState({content:"",documentTitle:""});
            this.setState({clicked:true})
        }
    }

    onBlur(evt){
        ckEditorContent = evt.editor.getData();
        if(ckEditorContent.trim().length>0){
            $('#Preview').removeAttr("disabled")
            $('#AddBtn').removeAttr("disabled")
        }else{
            $("#Preview").attr("disabled","true");
            $("#AddBtn").attr("disabled","true");
        }


    }

    removeDocument = (event,row) => {
        //event.preventDefault();
        let descriptionIndex = description.indexOf(row.description[0])
        let documentNameIndex = documentName.indexOf(row.fileName[0])
        description.splice(descriptionIndex,1)
        documentName.splice(documentNameIndex,1)
        files.splice(row.id,1)
        documentFiles.splice(row.id,1)
        this.setState({test:true})
    }

    onSubmmite = (event) => {
        event.preventDefault();


		let ketuRxIdData = 0
        let mdDocIdData = 0

        if(mdDocId == 0){
            ketuRxIdData = ketuRxId
        }else{
            ketuRxIdData = ketuRxId
            mdDocIdData = mdDocId
        }

        if($("#file").val()!=="" ||ckEditorContent.trim().length>0){
             $("#validationTest").click()
        }else{

        var doc=$('#documentName').val()
        $("#closeModalTest").click();
        if(documentFiles[0] != null) {
            let formData = new FormData();
            for(var i=0;i<files.length;i++){
                    let fileObject = files[i]
                    formData.append("file", fileObject);
            }
            formData.append("data", new Blob([JSON.stringify({
                    caseId: caseId,
                    patientUniqueId: patientUniqueId,
                    ksPatientId: ksPatientId,
                    tenantId: tenantId,
                    ketuPatientId: patientId,
                    caseDetailId: caseDetailId,
                    doctype:doc,
                    ketuRxId: ketuRxIdData,
                    phRxId: phRxId,
                    prescriberId: prescriberId,
                    //ketuCaseDetailId: ketuCaseDetailId,
                    submissionType:'DOCUMENT',
                    physician:"firstName",
                    mdId:mdid

            })], {
            type: "application/json; charset=utf-8"
            }));
            files = [];
            axios.post('/status/documentData?ketuCaseDetailId='+ketuCaseDetailId+'&description='+description+'&documentName='+documentName+'&mdDocId='+mdDocIdData+'', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                description = []
                documentName = []
                if(response.data) {
                    window.Materialize.toast("Document Uploaded Successfully", 5000,  'green rounded')
                } else {
                    window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                }
                documentFiles = []
                this.setState({test:true})
            })
            .catch(function (error) {
                console.log(error);
            });

        }
        }
       this.clearGlobals();
    }

    clearGlobals=()=>{
        caseId = "";
        patientUniqueId = "";
        ksPatientId = ""
        tenantId = ""
        DOB = ""
        patientName = ""
        patientId = ""
        caseDetailId= ""
        ketuCaseDetailId = ""
        ketuRxId = ""
        phRxId = ""
        prescriberId = ""
        authorizedByName=""
        mdid=""
    }

    validationCheck=()=>{

        if($("#file").val()!=="" || ckEditorContent.trim().length>0){
            $("#validationTest").click()
        } else if(documentFiles.length!==0){
            $("#validationTest").click()
        } else if(documentFiles.length!==0&&ckEditorContent.trim().length>0){
            $("#validationTest").click()
        } else{
            $("#closeModalTest").click()
        }
    }


    copyUploadDocData = () => {

    if($("#uploadDocCaseId").val()==="" || $("#uploadDocCaseId").val()===null ||$("#uploadDocCaseId").val()===undefined){
        $("#uploadDocCaseId").val(caseId)
    }
    if($("#uploadDocPatientUniqueId").val()==="" || $("#uploadDocPatientUniqueId").val()===null ||$("#uploadDocPatientUniqueId").val()===undefined){
        $("#uploadDocPatientUniqueId").val(patientUniqueId)
    }
    if($("#uploadDocKsPatientId").val()==="" || $("#uploadDocKsPatientId").val()===null ||$("#uploadDocKsPatientId").val()===undefined){
        $("#uploadDocKsPatientId").val(ksPatientId)
    }
    if($("#uploadDocTenantId").val()==="" || $("#uploadDocTenantId").val()===null ||$("#uploadDocTenantId").val()===undefined){
        $("#uploadDocTenantId").val(tenantId)
    }
    if($("#uploadDocDOB").val()==="" || $("#uploadDocDOB").val()===null ||$("#uploadDocDOB").val()===undefined){
        $("#uploadDocDOB").val(DOB)
    }
    if($("#uploadDocPatientName").val()==="" || $("#uploadDocPatientName").val()===null ||$("#uploadDocPatientName").val()===undefined){
        $("#uploadDocPatientName").val(patientName)
    }
    if($("#uploadDocPatientId").val()==="" || $("#uploadDocPatientId").val()===null ||$("#uploadDocPatientId").val()===undefined){
        $("#uploadDocPatientId").val(patientId)
    }
    if($("#uploadDocCaseDetailId").val()==="" || $("#uploadDocCaseDetailId").val()===null ||$("#uploadDocCaseDetailId").val()===undefined){
        $("#uploadDocCaseDetailId").val(caseDetailId)
    }
    if($("#uploadDocKetuCaseDetailId").val()==="" || $("#uploadDocKetuCaseDetailId").val()===null ||$("#uploadDocKetuCaseDetailId").val()===undefined){
        $("#uploadDocKetuCaseDetailId").val(ketuCaseDetailId)
    }
    if($("#uploadDocKetuRxId").val()==="" || $("#uploadDocKetuRxId").val()===null ||$("#uploadDocKetuRxId").val()===undefined){
        $("#uploadDocKetuRxId").val(ketuRxId)
    }
    if($("#uploadDocPhRxId").val()==="" || $("#uploadDocPhRxId").val()===null ||$("#uploadDocPhRxId").val()===undefined){
        $("#uploadDocPhRxId").val(phRxId)
    }

    if($("#uploadDocPrescriberId").val()==="" || $("#uploadDocPrescriberId").val()===null ||$("#uploadDocPrescriberId").val()===undefined){
        $("#uploadDocPrescriberId").val(prescriberId)
    }

    if($("#authorizedByName").val()==="" || $("#authorizedByName").val()===null ||$("#authorizedByName").val()===undefined){
        $("#authorizedByName").val(authorizedByName)
    }
    if($("#mdid").val()==="" || $("#mdid").val()===null ||$("#mdid").val()===undefined){
        $("#mdid").val(mdid)
    }

        mdid= $("#mdid").val()
        authorizedByName= $("#authorizedByName").val()
        caseId = $("#uploadDocCaseId").val();
        patientUniqueId = $("#uploadDocPatientUniqueId").val();
        ksPatientId = $("#uploadDocKsPatientId").val();
        tenantId = $("#uploadDocTenantId").val();
        DOB = $("#uploadDocDOB").val();
        patientName = $("#uploadDocPatientName").val();
        patientId = $("#uploadDocPatientId").val();
        caseDetailId= $("#uploadDocCaseDetailId").val();
        if($("#uploadDocKetuCaseDetailId").val() != null){
            ketuCaseDetailId = $("#uploadDocKetuCaseDetailId").val();
        }
        ketuRxId = $("#uploadDocKetuRxId").val();
        phRxId = $("#uploadDocPhRxId").val();
        prescriberId = $("#uploadDocPrescriberId").val();
    }

      updateState() {
        documentFiles=[]
        files = [];
        this.setState({clicked:true})
     }
    clearData=()=>{
        $('#documentTitle').prop('selectedIndex',0);
        $('#documentName').prop('selectedIndex',0);
        documentFiles=[]
        files = [];
        this.setState({clicked:true})
    }
    render () {
        // let styleClass = { width :'5%'}
        this.copyUploadDocData();
        return(
            <div>
            <div className="modal fade" id="topModal" tabIndex="-1" data-backdrop="static" data-keyboard={false} >
                            <div className="modal-dialog max550">
                                <div className="modal-content">
                                {/* <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"></span></button>
                                        <h4 className="modal-title caps"><strong>Demo Modal #2</strong></h4>
                                </div> */}
                                <div className="modal-header">
                                <h4> Confirmation   </h4>
                            </div>
                                <div className="modal-body">
                                    <div className="agengtDetailsAdd inner-page-container">
                                        <div className="col-sm-12">
                                            <p>You have not uploaded the created documents. Do you wish to cancel?</p>
                                         </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <section className="buttonWrapper">
                                    <ul>
                                    <li><button type="button" onClick={this.clearData} className="btn btn-darkpurpale" data-toggle="modal" data-target="#myModal" data-dismiss="modal">Yes</button></li>
                                    <li><button type="button" className="btn btn-dark" data-toggle="modal" data-target="#demo-3" data-dismiss="modal">No</button></li>
                                    </ul>
                                </section>
                            </div>

                        </div>
                        </div>
                    </div>
            <div id="myModal" className="modal fade " role="dialog" data-backdrop="static" data-keyboard={false}  tabIndex="-1">
                <div className="modal-dialog max960">
                    <div className="modal-content max-height-drop">
                        <div className="modal-header">
                        {/* data-dismiss="modal" */}
                        {documentFiles.length===0?<button type="button" className="close" onClick={this.validationCheck}>&times;</button>:<button type="button"  onClick={this.validationCheck} className="close" >&times;</button>}

                            <h4 className="modal-title">
                            <strong id="uploadDocTitlePatientName">{patientName}</strong><span id="uploadDocTitleDateOfBirth"> ({DOB})</span>
                                {/* <span className="pull-right mr10"><strong>DOB: </strong></span> */}
                            </h4>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                            <div className="col-lg-12 col-md-12 col-xs-12 padding-0">
                                <input type="hidden" id="uploadDocPatientName" name="uploadDocPatientName" value="" />
                                <input type="hidden" id="uploadDocDOB" name="uploadDocDOB" value="" />
                                <input type="hidden" id="uploadDocCaseId" name="uploadDocCaseId" value="" />
                                <input type="hidden" id="uploadDocPatientUniqueId" name="uploadDocPatientUniqueId" value="" />
                                <input type="hidden" id="uploadDocKsPatientId" name="uploadDocKsPatientId" value="" />
                                <input type="hidden" id="uploadDocTenantId" name="uploadDocTenantId" value="" />
                                <input type="hidden" id="uploadDocPatientId" name="uploadDocPatientId" value="" />
                                <input type="hidden" id="uploadDocCaseDetailId" name="uploadDocCaseDetailId" value="" />
                                <input type="hidden" id="uploadDocKetuCaseDetailId" name="uploadDocKetuCaseDetailId" value="" />
                                <input type="hidden" id="uploadDocKetuRxId" name="uploadDocKetuRxId" value="" />
                                <input type="hidden" id="uploadDocPhRxId" name="uploadDocPhRxId" value="" />
                                <input type="hidden" id="uploadDocPrescriberId" name="uploadDocPrescriberId" value="" />
                                <input type="hidden" id="isDocumentDescriptionEmpty" name="isDocumentDescriptionEmpty" value="" />
                                <input type="hidden" id="isDocumentNameEmpty" name="isDocumentNameEmpty" value="" />
                                <input type="hidden" id="isDocumentSelected" name="isDocumentSelected" value="" />
                                <input type="hidden" id="authorizedByName" name="authorizedByName" value="" />
                                <input type="hidden" id="mdid" name="mdid" value="" />
                                <div className="title-box">
                                    <h4>Upload Document</h4>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <div className="input-field">
                                        <label className="active">Select Document</label>
                                        <div className="file-field  select-file">
                                            <div className="btn">
                                                <span>Browse Document</span>
                                                <input type="file" id="file" accept="application/pdf" name="document"  onChange={this.change.bind(this)}/>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <CustomInput intType="text" intid="fileType" isDisabled intplaceholder="Select File" intName="document" change={this.change.bind(this)} intClasssName={this.state.isDocumentSelected? 'file-path validate invalid': 'file-path validate'}/>
                                                <Label lblhtmlFor="fileType" lblclassName={this.state.isDocumentSelected? 'active': null} lbldataError={this.state.isDocumentSelected? Constant.SELECT_DOCUMENT:null}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        {/* <CustomInput intid="documentName" intType="text" required intName="documentName" intClasssName={this.state.isDocumentNameEmpty? 'validate invalid':null}/> */}
                                         {/* <Label lblhtmlFor="documentName"  lblname={"Document Name"+Constant.STAR} lblclassName={this.state.isDocumentNameEmpty? 'active': null} lbldataError={this.state.isDocumentNameEmpty? Constant.DOCUMENT_NAME:null}/>
                                        <Label lblhtmlFor="documentName"  lblname={"Document Type"+Constant.STAR} lblclassName={ 'active'} lbldataError={this.state.isDocumentNameEmpty? Constant.DOCUMENT_NAME:null}/>
                                        */}
                                        <div className="col-lg-3 col-md-3 col-xs-12 col-sm-12 pl0 pr0">
                                        <Input  s={12} m={12} l={12} xl={12}  label={"Document Type"+Constant.STAR} type='select'  id="documentName" name="documentName" readOnly>
                                                <option>--Select--</option>
                                                <option>Chart Notes</option>
                                                <option>Enrollment Forms</option>
                                                <option>Prescription</option>
                                                <option>Labs</option>
                                                <option>Consents/AOR</option>
                                                <option>PA Form</option>
                                                <option>PAP Form</option>
                                                <option>Appeal Letter</option>
                                                <option>Assessments</option>
                                                <option> Referral Forms</option>
                                                <option>  Other</option>
                                        </Input>
                                        </div>

                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <div className="input-field suffix">
                                        <Label lblhtmlFor="description"  lblname={"Description"} lblclassName={this.state.isDocumentDescriptionEmpty? 'active': null} lbldataError={this.state.isDocumentDescriptionEmpty? Constant.DOCUMENT_DESCRIPTION:null}/>
                                        <CustomInput intid="npdescriptioni" intType="text" required intName="description" intClasssName={this.state.isDocumentDescriptionEmpty? 'validate invalid': null}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                        <div className="center-block text-center">
                                            <a className='tooltipped btn btn-lg btn-primary wizard-next upload-right' data-tooltip="Upload" data-position="top" onClick={this.click}>Upload</a>
                                        </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12 col-md-12 padding-0">
                                <div className="title-box">
                                    <h4>Create Your Own</h4>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 pl0 pr0">
                                        {/* <CustomInput intid="documentTitle" intType="text" required intName="documentTitle" intClasssName={this.state.isChartNotesTitleEmpty? 'validate invalid':null}/>
                                        <Label lblhtmlFor="documentTitle"  lblname={"Title"+Constant.STAR} lblclassName={this.state.isChartNotesTitleEmpty? 'active': null} lbldataError={this.state.isChartNotesTitleEmpty? Constant.CHARTNOTES_TITLE:null}/>
                                    <Label lblhtmlFor="documentTitle"  lblname={"Document Type"+Constant.STAR} lblclassName={'active'} lbldataError={this.state.isChartNotesTitleEmpty? Constant.CHARTNOTES_TITLE:null}/>*/}
                                <Input s={12} m={12} l={12} xl={12}  type='select' id="documentTitle" label={"Document Type"+Constant.STAR} name="documentTitle" readOnly>
                                    <option>--Select--</option>
                                    <option>Chart Notes</option>
                                    <option>Enrollment Forms</option>
                                    <option>Prescription</option>
                                    <option>Labs</option>
                                    <option>Consents/AOR</option>
                                    <option>PA Form</option>
                                    <option>PAP Form</option>
                                    <option>Appeal Letter</option>
                                    <option>Assessments</option>
                                    <option> Referral Forms</option>
                                    <option>  Other</option>
                                </Input>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <CKeditor jsPdf={this.jsPdf} uploadDoc = {this.uploadDoc} onBlur={this.onBlur} content={this.state.content}></CKeditor>
                            <div className="clearfix mb15"></div>
                            <div className="row">
                            <div className="col-lg-12 col-md-12 col-xs-12 padding-0">
                                <div className="title-box">
                                  <h4>Review and Submit </h4>
                                </div>
                                </div>
                            </div>
                            <ModalTable documentFiles={documentFiles} clicked={this.state.clicked} removeDocument={this.removeDocument}></ModalTable>
                        </div>
                        <div className="modal-footer">
                            <div className="buttonWrapper ">
                                <button className="btn btn-primary wizard-next btn-darkpurpale" disabled={documentFiles.length===0?true:false} onClick={this.onSubmmite}>Submit</button>
                                <input className="btn btn-primary wizard-next btn-darkpurpale" type="hidden" id="closeModalTest" data-dismiss="modal"/>
                                <input className="btn btn-primary wizard-next btn-darkpurpale" type="hidden" id="validationTest" data-target="#topModal" data-toggle="modal"  />
                                <button type="button" onClick={this.validationCheck} id="modalCancel"className="btn btn-primary wizard-next btn-dark">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
