import React from "react";

export default abc => {
  return (
    <div className="v2-back-inner">
      <div className="wrapper slide-nav-toggle">
        {/*<Header />*/}
        <main>
          <div className="inner-page-container">
            <div className="v-center no-recod-found">
            <i className="fa fa-frown-o" aria-hidden="true"></i>
              <h1>404
              <br/>ERROR</h1>
              <h2 className="purpleText text-center whitetext">Oops!,</h2>
              <h2 className="text-center text-bold whitetext"> Page Not Found</h2>
              <p className="text-center whitetext">Looks like the page that you are looking for does not exist. Consider returning to the Homepage. </p>
            </div>
          </div>
        </main>
        {/*<Footer />*/}
      </div>
    </div>
  );
};
