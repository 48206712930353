import React, { Component } from 'react';
import './App.css';
import Login from './Container/Login/Login';
import Register from './Container/Register/Register';
import WorkQueue from './Container/WorkQueue/WorkQueue';
import AuditLog from './Container/WorkQueue/AuditLog';
import MyProfileAgent from './Container/Profile/MyProfile/MyProfileAgent';
import MyProfilePrescriber from './Container/Profile/MyProfile/MyProfilePrescriber';
import MyLocations from './Container/Profile/MyLocations/MyLocations';
import MyPhysicianLocations from './Container/Profile/MyLocations/MyPhysicianLocations';
import MyPhysician from './Container/Profile/MyRepresentative/MyPhysician';
import MyRepresentative from './Container/Profile/MyRepresentative/MyRepresentative';
import OfficeAdminRepresentative from './Container/Profile/MyRepresentative/OfficeAdminRepresentative';
import ChangePassword from './Container/Profile/ChangePassword/ChangePassword'
import SecurityQuestions from './Container/Profile/MyProfile/SecurityQuestions'
import ManageUser from './Container/ManageUser/ManageUser';
import User from './Container/ManageUser/User/User';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ForgotPassword from './Container/ForgotPassword/ForgotPassword';
import ChangePasswordSecuQ from './Component/ForgotPassword/ChangePasswordAndSecurityQuestion';
import * as Constant from './Component/CustomUI/Constant';
import axios from 'axios';
import UserProfile from './UserProfile';
import Accessdenied from '../../portal/src/Component/CustomUI/Accessdenied';
import Pagenofound from '../../portal/src/Component/CustomUI/Pagenofound';
import Forms from '../../portal/src/Component/CustomUI/forms';
import $ from "jquery";
import RequestMap from './RequestMap';
import AgreementPage from './Component/LoginPage/Agreement/Agreement';
import TechAdminRegister from './Container/TechAdminRegister/TechAdminRegister';
import TrainingVideo from './Container/WorkQueue/TrainingVideo';
import Resources from './Container/Resources/Resources';
import UploadForms from './Container/Resources/UploadForms'
import ViewForms from './Container/Resources/ViewForms';

var isDataFetched = false;
class App extends Component {

  state = {isUserLoggedIn : false}

  componentWillMount(){

    axios.post('/auth/isUserLoggedIn?path='+window.location.href)
    .then(response => {
        isDataFetched = true;
        if(response.data.userId != undefined){

        // var CryptoJS = require("crypto-js");

        // var data = response.data;
        // var rawData = CryptoJS.enc.Base64.parse(data);
        // var key = CryptoJS.enc.Latin1.parse("weJiSEvR5yAC5ftB");
        // var iv = CryptoJS.enc.Latin1.parse("ssdkF$HUy2A#D%kd");
        // var plaintextData = CryptoJS.AES.decrypt(
        //     { ciphertext: rawData },
        //     key,
        //     { iv: iv });
        // var plaintext = plaintextData.toString(CryptoJS.enc.Latin1);
        // alert(JSON.parse(plaintext));

          var data = response.data;
          if(data.userFullName){
              UserProfile.setName(data.userFullName);
          }

          if(data.role){
            UserProfile.setRole(data.role);

          }

          if(data.excelExportPassword){
            UserProfile.setExcelExportPassword(data.excelExportPassword)
          }

          if(data.userId){
              UserProfile.setUserId(data.userId);
          }

          if(data.isOfficeAdmin != undefined || data.isOfficeAdmin !== null){
              UserProfile.setOfficeAdmin(data.isOfficeAdmin)
          }

          if(data.status){
              UserProfile.setStatus(data.status);
          }

          if(data.approvalStatus){
              UserProfile.setApprovalStatus(data.approvalStatus)
          }

          if(data.isNeverLogin){
            UserProfile.setIsNeverLogin(data.isNeverLogin)
          }

          this.setState({isUserLoggedIn : true})
        }else{
          UserProfile.clearAll();
          this.setState({isUserLoggedIn : false})
        }
        // else{
        //   this.setState({isUserLoggedIn : false})
        // }
        $("#toggleSideNav").on("click",function(){
          $(".wrapper").toggleClass("slide-nav-toggle");
          $(".fixed-sidebar-left").toggleClass("sidebar-collapsed");
          $("#front-page-logo,#front-page-logo-ks").toggleClass("hidden");

        });
        $(window).scroll(function() {
          var scroll = $(window).scrollTop();

          if (scroll >= 20) {
              $(".herader-v2").addClass("on-scroll-nav");
          } else {
              $(".herader-v2").removeClass("on-scroll-nav");
          }
      });
    })
    .catch(function (error) {
      console.log(error);
      UserProfile.clearAll();
      isDataFetched = true;
    });

  }

  render() {
    // window.console.warn=
    // window.console.log = function () {
		//   return false;
    // }
    window.addEventListener('load', function () {
      // $(".react-bs-container-body").addClass("table-height");
  //     $(".table-height .react-bs-container-body, .max960").mCustomScrollbar({
  //       theme:"dark",
  //       contentTouchScroll: true,
  //       autoDraggerLength:true,
  //       mouseWheel:{ enable: true },
  //       keyboard:{ enable: true}
  //     });
  //     $(".table-max-height-200 .react-bs-container-body").mCustomScrollbar({
  //       theme:"dark",
  //       contentTouchScroll: true,
  //       autoDraggerLength:true,
  //       mouseWheel:{ enable: true },
  //       keyboard:{ enable: true}
  //     });

  //     $(".table-responsive").mCustomScrollbar({
  //       axis:"x",
  //       theme:"dark",
  //       contentTouchScroll: true,
  //       autoDraggerLength:true,
  //       mouseWheel:{ enable: true },
  //       keyboard:{ enable: true},
  //   });
  //   $(".pagination-table .react-bs-table").mCustomScrollbar({
  //     axis:"x",
  //     theme:"dark",
  //     advanced:{
  //       autoExpandHorizontalScroll:2
  //     }
  // });
  //     $(".bodyContent").mCustomScrollbar({
  //       theme:"dark",
  //       contentTouchScroll: true,
  //       autoDraggerLength:true,
  //       mouseWheel:{ enable: true },
  //       keyboard:{ enable: true},
  //       scrollInertia:500,
  //       set_width:false,
	// 			set_height:false,
	// 			horizontalScroll:false,
	// 			scrollEasing:"easeOutCirc",
  //       advanced:{
	// 				updateOnBrowserResize:true,
	// 				updateOnContentResize:true,
	// 				autoExpandHorizontalScroll:false
	// 			},
  //     });
      // $(".wizard-pane .bodyContent-xs").mCustomScrollbar({
      //   theme:"dark",
      //   timeout:0,
      //   scrollInertia:200,
      //   contentTouchScroll: true,
      //   autoDraggerLength:true,
      //   mouseWheel:{ enable: true },
      //   keyboard:{ enable: true},
      // });

      $(".css-1492t68, .css-1492t68").text("--Select--");
      }, false);

      $( document ).on( 'focus', ':input', function(){
        $( this ).attr( 'autocomplete', 'off' );
    });
    $(".scroll-top").click(function () {
       $("html, body").animate({scrollTop: 0}, 1000);
    });
    $(".scroll-down").click(function () {
      $("html, body").animate({scrollBottom: 0}, 1000);
   });

    var isUserLoggedInState = this.state.isUserLoggedIn;
    function PrivateRoute({component: Component, ...rest}) {

      var restJSON ={...rest};
      var isPermitted = false;
      var pathToRedirect = restJSON["path"];
      var loggedInUserRole = UserProfile.getRole();
      var isNeverLogin = UserProfile.getIsNeverLogin();

      if(loggedInUserRole !== "" && pathToRedirect !==""){
          var permissionSet = RequestMap[pathToRedirect];
          console.log(permissionSet)
          if(permissionSet.includes(Constant.PERMIT_ALL)){
            isPermitted = true;
          }else{
            isPermitted = permissionSet.includes(loggedInUserRole);
            if((pathToRedirect === "/myPhysicianLocations" || pathToRedirect === "/officeAdminRepresentative") && !UserProfile.getOfficeAdmin()){
              isPermitted = false;
            }

          }
      }

      if(isDataFetched){
        return (
          ( isUserLoggedInState) ?

          (isNeverLogin ?

            <AgreementPage />

            :

            <Route {...rest} render={() => (
              <div className="App">
                {(isPermitted ? <Component /> : <Accessdenied />)}
              </div>
            )}/>

            )

           :
           <Route {...rest} render={() => (
            <div className="App">
              <Login />
            </div>
          )}/>
        );
      }
      return null;
    }

    const Page404 = ({ location }) => (
      <div className="App">
              {(<Pagenofound />)}
      </div>
   );

    return (

      <BrowserRouter basename={Constant.FRONTEND_CONTEXT_PATH}>
        <div>
            <Switch>
              <Route exact={true} path='/' render={() => (
                  <div className="App">
                    <Login />
                  </div>
                )}/>
              <Route exact={true} path='/forgotPassword' render={() => (
                <div className="App">
                  <ForgotPassword />
                </div>
              )}/>
              <Route exact={true} path='/register' render={() => (
                <div className="App">
                {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN?<TechAdminRegister/>:<Register/>}
                </div>
              )}/>
           
              <PrivateRoute path="/UploadForms" component={UploadForms}/>
              <PrivateRoute path="/ViewForms" component={ViewForms}/>
              <PrivateRoute path="/Resources" component={Resources}/>
              <PrivateRoute path="/changePassSecQue" component={ChangePasswordSecuQ}/>
              <PrivateRoute path='/agreement' component={AgreementPage}/>
              <PrivateRoute path="/workqueue" component={WorkQueue}/>
              <PrivateRoute path="/auditLog" component={AuditLog}/>
              <PrivateRoute path="/myProfileAgent" component={MyProfileAgent} />
              <PrivateRoute path="/myProfilePrescriber" component={MyProfilePrescriber} />
              <PrivateRoute path="/mylocations" component={MyLocations} />
              <PrivateRoute path="/myphysician" component={MyPhysician} />
              <PrivateRoute path="/representative" component={MyRepresentative} />
              <PrivateRoute path="/changepassword" component={ChangePassword}/>
              <PrivateRoute path="/securityquestions" component={SecurityQuestions}/>
              <PrivateRoute path="/manageuser" component={ManageUser} />
              <PrivateRoute path="/user" component={User}/>
              <PrivateRoute path="/myPhysicianLocations" component={MyPhysicianLocations} />
              <PrivateRoute path="/officeAdminRepresentative" component={OfficeAdminRepresentative} />
              <PrivateRoute path="/Accessdenied" component={Accessdenied} />
              <PrivateRoute path="/Pagenofound" component={Pagenofound} />
              <PrivateRoute path="/formsSection" component={Forms} />
              <PrivateRoute path="/trainingVideo" component={TrainingVideo} />
              <Route component={Page404} />
            </Switch>
        </div>
      </BrowserRouter>

    );
  }
}

export default App;
