import React, { Component } from 'react';
import {Input} from 'react-materialize';
import { Prescriber as Prescriber } from '../StepThree/Prescriber';
import Agent from '../StepThree/Agent';
import axios from 'axios';
import Button from '../../CustomUI/Button';
import * as Constant from '../../CustomUI/Constant';
import UserProfile from '../../../UserProfile';

let isKsUser=false
class stepTwo extends Component {

    state = {
        isHiddenMd:false,
        isHiddenAgent:false,
        roleList : [],
        roleTitleList : []
    }

    componentDidMount(){
        axios.get('/registration/renderRole')
        .then(response => {
            // console.log(response.data)
            this.setState({roleList : response.data})
        })
        .catch(function (error) {
            console.log(error);
        });
    }


     prescriberData() {
        if(this.props.isHiddenMd){
            const roleTitleListPrescriberData = this.props.roleTitleList.map( roleTitle =>{
                return <option  value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
            } );

            //  console.log(roleTitleListPrescriberData[0]?roleTitleListPrescriberData[0].props.value:'')
            return (
                <div>

                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5">
                        <Input s={12} m={12} xl={12} l={12} type='select' id="whoIsA" className={this.props.stepTwoValidate.whoIsAValid? 'validate invalid': 'validate'} name="whoIsA"  label="Who is a *" onChange={(event) => this.props.change(event,Constant.STEP_TWO_PRESCRIBER)} >
                                        <option value="">--Select--</option>
                                        {roleTitleListPrescriberData}
                        </Input>
                        </div>

                     <Prescriber
                        addDocumentClicked ={this.props.addDocumentClicked}
                        data={this.props.data}
                        changeFile={this.change}
                        Change={this.props.change}
                        stepTwoValidate={this.props.stepTwoValidate}
                        getPrescriberApiData={this.props.getPrescriberApiData}
                        document={this.props.document}
                        removeDocument={this.props.removeDocument}
                        isLoading={this.props.isLoading}
                     />
            </div>
            );
        }


      }

      agentData() {

        if(this.props.isHiddenAgent){

            const roleTitleListAgentData = this.props.roleTitleList.map(roleTitle =>{
                return <option value={roleTitle.id} key={roleTitle.id} >{roleTitle.name}</option>
             });

            return (
                <div>
                <div className="zero-padding-xs max-height-drop">
                <div className="col-lg-3 col-md-3 col-sm-4 pl5 pr5">
                    <Input m={12} type='select' id="whoIsA"  name="I_Work_At_A" label="I work at a *" className={this.props.stepTwoValidate.whoIsAValid? 'validate invalid': 'validate'} onChange={(event) => this.props.change(event,Constant.STEP_TWO_AGENT)} >
                            <option value="" >--Select--</option>
                            {roleTitleListAgentData}
                    </Input>
                    </div>


                      <Agent
                          isUpdatedData={this.props.isUpdatedData}
                          Change={this.props.change}
                          associatedDataListner={this.props.associatedDataListner}
                          changePrescriberListner={this.props.changePrescriberListner}
                           agentData={this.props.agentData}
                           selectedPrescriberJson={this.props.selectedPrescriberJson}
                           prescriberJson={this.props.prescriberJson}
                           stepTwoValidate={this.props.stepTwoValidate}

                    />
                </div>
                </div>


            );
      }
    }

    render(){

        let href = ''
        const roleListData = this.state.roleList.map(role =>{
               return <option data-id={role.value} key={role.id} value={role.id} >{role.name}</option>
        })

        if(UserProfile.getUrlName() ===  Constant.FRONTEND_SERVER_URL + Constant.FRONTEND_CONTEXT_PATH){
             UserProfile.setRole("")
        }

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            href = UserProfile.getUrlName()
            isKsUser=true
        }else{
            href = Constant.FRONTEND_CONTEXT_PATH
        }



     return (
            <div>

                <h1>{isKsUser?Constant.LABEL_KSROLE:Constant.LABEL_ROLE}</h1>
                <div>
                <div className="bodyContent">
                    <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5">
                                <Input s={12} m={12} xl={12} l={12}   type='select' id="ima" label={isKsUser?"I want to register a *":"I am a *"} name="ima" className={this.props.stepTwoValidate.imaValid? 'validate invalid': 'validate'} onChange={this.props.change}  >
                                         <option value="" >--Select--</option>
                                         {roleListData}
                                </Input>
                                </div>

                        {this.prescriberData()}
                        {this.agentData() }
                        </div>

                </div>
                <div className="wizard-footer">
                    <div className="btn-toolbar pull-right">
                    <a href={href}>
						<Button id="Step2Cancel" btnName="Cancel" className="btn btn-primary btn-white wizard-next scroll-top"  secondParams={Constant.STEP_TWO} />
					</a>
                        <Button id="Step2Next" btnName={Constant.NEXT} className="btn btn-primary wizard-next scroll-top" click={this.props.click} secondParams={Constant.STEP_TWO} />
                    </div>
                </div>
            </div>
            </div>
        );
    }

}

export default stepTwo;