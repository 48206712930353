import React, { Component } from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import $ from 'jquery';
import axios from 'axios';
import UserProfile from '../../../UserProfile';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Input } from 'react-materialize';
import Button from '../../../Component/CustomUI/Button';
import * as Constant from '../../../Component/CustomUI/Constant';
import CustomMaskedInput from '../../../Component/CustomUI/MaskedInput';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

let formaattedPracticeLocation = []
let stateMaster = []
let cityMaster = []
let zipMaster = []
let state = null;
let practiceLocationList = []
let prescriber = []

export default class MyPhysicianLocations extends Component {

    state = {
        practiceLocation : [],
        stateValues: '',
        cityValues: '',
        zipValues: '',
        prescriberNameValue:'',
        values: '',
        editable:'',
        stateChange :false,
        prescriberData : '',
        fromEdit : false,
        addPracticeLocationValidate:{
            addresss1Valid :false,
            stateValid :false,
            cityValid:false,
            ZipValid:false,
            prescriberNameValid:false
        },
        isLoading:true,
        isSuccess:false,
        isSuccess1:false,

    }

    constructor(props) {
        super(props);
    }

    createUI(){

          const stateMasterData = stateMaster.map(state =>{
                 return <option value={state.state} key={state.state} >{state.state_full_name}</option>
           });

           const prescriberData =  prescriber.map(prescriberData =>{
                 return <option value={prescriberData.prescriberId} key={prescriberData.prescriberId} >{prescriberData.prescriberFullName}</option>
            });


        return (

            <div key="key" id="practiceDiv" className='row'>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl0 pr0">
                <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.prescriberNameValid?'validate invalid form-control':'validate form-control'} value={this.state.prescriberNameValues} name="Prescriber Name" id="prescriberName" type="select"   onChange={this.handleChange.bind(this)} label="Prescriber Name*" >
                        <option value="" >--Select--</option>
                        {prescriberData}
                    </Input>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <input name="index" id="index" type="hidden" />
                    {/*<input name="indexOfOutter" id="indexOfOutter" type="hidden" />*/}
                    <label className="practiceNameClass">
                        Practice Name
                    </label>
                    <input s={12} className="form-control"  name=" Practice Name" id="practiceName" type="text" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <label className="addressOneClass">
                        Address1*
                    </label>
                    <input s={12}   name="addresss1" id="addresss1" type="text" className = {this.state.addPracticeLocationValidate.addresss1Valid?'validate invalid form-control': 'validate form-control'}  />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <label className="addressTwoClass" >
                    Address2
                    </label>
                    <input s={12} className="form-control" name="addresss2" id="addresss2" type="text" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl0 pr0">
                    <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.stateValid?'validate invalid form-control':'validate form-control'}  name="state" id="state" type="select"  value={this.state.stateValues} onChange={this.handleChange.bind(this)}   label=" State*" >
                        <option value="" >--Select--</option>
                        {stateMasterData}
                    </Input>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl0 pr0">
                <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.cityValid?'validate invalid form-control':'validate form-control'}  name="city" id="city" type="select" value={this.state.cityValues}  onChange={this.handleChange.bind(this)} label="City*" >
                        <option value="" >--Select--</option>
                        {cityMaster ? cityMaster.map(city =>{
                                return <option value={city} key={city} >{city}</option>
                        }) : <option value="" >--Select--</option>}

                    </Input>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl0 pr0">
                    <div className="max-height-drop">
                        <Input s={12} className={this.state.addPracticeLocationValidate.ZipValid?'validate invalid form-control':'validate form-control'}  value={this.state.zipValues} onChange={this.handleChange.bind(this)}  name="zip" id="zip" type="select" label=" Zip*" >
                            <option value="" >--Select--</option>
                            {zipMaster ? zipMaster.map(zip =>{
                                        return <option value={zip} key={zip} >{zip}</option>
                            }):<option value="" >--Select--</option>}
                        </Input>
                    </div>
                </div>

            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input name="index" id="index" type="hidden" />
                        <label htmlFor={"practicePhone_1"} className={'practicePhoneOneClass'}>
                            Phone 1 *
                        </label>
                        <input type="hidden" name="practicePhoneTempId_1" id="practicePhoneTempId_1"/>
                        <input type="hidden" name="practicePhoneFromApi_1" id="practicePhoneFromApi_1"/>
                        <CustomMaskedInput  intId="practicePhone_1" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}  fromApi ={false} intClassName={this.state.addPracticeLocationValidate.phoneOneValid?'validate invalid form-control':'validate form-control'} required  intName={"practicePhone_1"} />

                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <label className={'practiceFaxOneClass'}>
                            Fax 1 *
                        </label>
                        <input type="hidden" name="practiceFaxTempId_1" id="practiceFaxTempId_1"/>
                        <input type="hidden" name="practiceFaxFromApi_1" id="practiceFaxFromApi_1"/>
                        <CustomMaskedInput   intId="practiceFax_1" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}  fromApi ={false} intClassName={this.state.addPracticeLocationValidate.faxOneValid?'validate invalid form-control':'validate form-control'} required  intName={"practiceFax_1"} />
                        </div>
                    </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input type="hidden" name="practicePhoneTempId_2" id="practicePhoneTempId_2"/>
                        <input type="hidden" name="practicePhoneFromApi_2" id="practicePhoneFromApi_2"/>
                        <label className={'practicePhoneTwoClass'}>
                            Phone 2
                        </label>
                        <CustomMaskedInput    intId="practicePhone_2" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required  intName={"practicePhone_2"} />
                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input type="hidden" name="practiceFaxTempId_2" id="practiceFaxTempId_2"/>
                        <input type="hidden" name="practiceFaxFromApi_2" id="practiceFaxFromApi_2"/>
                        <label className={'practiceFaxTwoClass'}>
                            Fax 2
                        </label>
                        <CustomMaskedInput  intId="practiceFax_2" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required intName={"practiceFax_2"} />
                        </div>
                    </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                     <input type="hidden" name="practicePhoneTempId_3" id="practicePhoneTempId_3"/>
                     <input type="hidden" name="practicePhoneFromApi_3" id="practicePhoneFromApi_3"/>
                        <label className={'practicePhoneThreeClass'}>
                            Phone 3
                        </label>
                        <CustomMaskedInput intId="practicePhone_3" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required intName={"practicePhone_3"} />
                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <label className={'practicePhoneThreeClass'}>
                            Fax 3
                        </label>
                        <input type="hidden" name="practiceFaxFromApi_3" id="practiceFaxFromApi_3"/>
                        <input type="hidden" name="practiceFaxTempId_3" id="practiceFaxTempId_3"/>
                        <CustomMaskedInput intId="practiceFax_3" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}    fromApi ={false} intClassName={'form-control'} required  intName={"practiceFax_3"} />
                        </div>
                    </div>
            </div>
          </div>
        )
    }

    validatePracticeLoction = ()  =>{

        let addresss1 = $("#addresss1").val()
        let   state  = $("#state").val()
        let city = $("#city").val()
        let zip = $("#zip").val()
        let prescriberName = $("#prescriberName").val()
        let phoneOne = $("#practicePhone_1").val()
        let faxOne = $("#practiceFax_1").val()


        let addresss1Valid = false
        let stateValid = false
        let cityValid = false
        let ZipValid = false
        let prescriberNameValid = false
        let phoneOneValid =false
        let faxOneValid =false

        let isValid = true

        if(!addresss1){
            isValid = false
            addresss1Valid = true

        }
        if(!state){
            isValid = false
            stateValid = true

        }
        if(!city){
            isValid = false
            cityValid = true

        }
        if(!zip){
            isValid = false
            ZipValid= true

        }
        if(!prescriberName){
            isValid = false
            prescriberNameValid= true

        }
        if(!phoneOne){
            isValid = false
            phoneOneValid= true
        }
        if(!faxOne){
            isValid = false
            faxOneValid= true
        }

        if(!isValid){
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000,  'red rounded')
        }

    this.setState({addPracticeLocationValidate:{addresss1Valid:addresss1Valid,stateValid:stateValid,ZipValid:ZipValid,cityValid:cityValid,prescriberNameValid:prescriberNameValid,phoneOneValid,faxOneValid}})
        return isValid
    }

    addPracticeLocation = (event) => {

        let msg = ''
        let practiceIndex =  $("#index").val();
//        let indexOfOutter = $("#indexOfOutter").val();


        if(this.validatePracticeLoction()){
            let practiceLocationJson = {}

            if(!this.state.fromEdit){
                this.setState({isSuccess:true})
                 practiceLocationJson = {"fromApi" :false,"facility":$("#practiceName").val(),
                "type":"Other","address_1":$("#addresss1").val(),"address_2":$("#addresss2").val(),"city":$("#city").val(),"state":$("#state").val(),"postal_code":$("#zip").val(),"country_name":"United States"}

                //let prescriberOneData = this.state.prescriberData;
                //let prescriberAddIndex = prescriberOneData.findIndex(e=>e.id == $("#prescriberName").val());
                 practiceLocationList.push(practiceLocationJson)
                 msg = Constant.PRACTICE_LOCATION_ADD

            }else{
                this.setState({isSuccess1:true})
                let id = practiceLocationList[practiceIndex].id
                let fromApi = practiceLocationList[practiceIndex].fromApi

                let type = practiceLocationList[practiceIndex].type
                let address_purpose = practiceLocationList[practiceIndex].address_purpose
                let country_code = practiceLocationList[practiceIndex].country_code

                practiceLocationList.splice(practiceIndex, 1);
                    practiceLocationJson = {"id":id,"fromApi" :fromApi,"facility":$("#practiceName").val(),
                "type":type,"address_purpose":address_purpose,"country_code":country_code,"address_1":$("#addresss1").val(),"address_2":$("#addresss2").val(),"city":$("#city").val(),"state":$("#state").val(),"postal_code":$("#zip").val(),"country_name":"United States"}
                //practiceLocationList.push(practiceLocationJson)
                msg = Constant.PRACTICE_LOCATION_EDIT


            }

            let contact = [];
            for(let i = 1; i<=3;i++){
                let practicePhone = $("#practicePhone_"+i).val();
                let practiceFax = $("#practiceFax_"+i).val();

                let practicePhoneTempId = $("#practicePhoneTempId_"+i).val();
                let practiceFaxTempId = $("#practiceFaxTempId_"+i).val();

                let practicePhoneApi = false
                let practiceFaxApi = false

                if(this.state.fromEdit){
                    practicePhoneApi = $("#practicePhoneFromApi_"+i).val();
                    practiceFaxApi = $("#practiceFaxFromApi_"+i).val();
                }

                contact.push({id:practicePhoneTempId,fromApi:practicePhoneApi,contactType: "PHONE_"+i, contactValue: practicePhone })
                contact.push({id:practiceFaxTempId,fromApi:practiceFaxApi,contactType: "FAX_"+i, contactValue: practiceFax })

            }

            let contactKey = "contact"
            practiceLocationJson[contactKey] = contact;

            //let prescriberData = this.state.prescriberData;

                // if(!this.state.fromEdit){
                //     let prescriberIndex = prescriberData.findIndex(e=>e.id == $("#prescriberName").val());
                //     prescriberData[prescriberIndex].practiceLocations = practiceLocationList[prescriberIndex]
                //     queryParams = prescriberData[prescriberIndex]

                // }else{
                //     prescriberData[indexOfOutter].practiceLocations = practiceLocationList[indexOfOutter]
                //     queryParams = prescriberData[indexOfOutter]
                // }

            // console.log(practiceLocationJson)
            let prescriberId = $("#prescriberName").val()
            // console.log(prescriberId)
            axios.post('/prescriberProfile/savePrescriberPracticeLocation?prescriberId='+prescriberId,practiceLocationJson)
            .then(response => {
                // console.log(response)
                window.Materialize.toast(msg, 3000,  'green rounded')
                this.getPracticeLocationData();
                    $('#locationModalCancel').click();
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

        }
    }

    cityMasterListener = (state) =>{

         axios.get('/prescriberProfile/getCityFromState?state='+state)
            .then(response => {
                cityMaster = response.data;
                this.setState({stateChange : true});
            })
            .catch(function (error) {
               console.log("Exception occur"+error)
            });

    }

    zipMasterListener = (state,city) =>{

        let queryString =  '?state='+ state +'&city='+ encodeURIComponent(city)

         axios.get('/prescriberProfile/getZipFromCity'+queryString)
            .then(response => {
                zipMaster = response.data;
                this.setState({stateChange : true});
            })
            .catch(function (error) {
               console.log("Exception occur"+error)
            });

    }

    handleChange(event) {

        let city = null;

        if(event.target.name  === "state"){
            this.cityMasterListener(event.target.value)
            state = event.target.value
            cityMaster = []
            zipMaster = []
            this.setState({stateValues:event.target.value})
        }

        if(event.target.name  === "city"){
            city = event.target.value
            this.zipMasterListener(this.state.stateValues,city)
            this.setState({cityValues: event.target.value})
        }

        if(event.target.name  === "zip"){
            this.setState({zipValues: event.target.value})
        }

        if(event.target.name  === "Prescriber Name"){
            this.setState({prescriberNameValues: event.target.value})
        }



    }

    addPracticeLocationListener = () =>{

        this.setState({fromEdit:false})
        $("#practiceName").val("");
        $("#addresss1").val("");
        $("#addresss2").val("");
        $("#index").val("");

        $("#practicePhoneTempId_1").val("");
        $("#practicePhone_1").val("");
        $("#practicePhoneTempId_2").val("");
        $("#practicePhone_2").val("");
        $("#practicePhoneTempId_3").val("");
        $("#practicePhone_3").val("");
        $("#practiceFaxTempId_1").val("");
        $("#practiceFax_1").val("");
        $("#practiceFaxTempId_2").val("");
        $("#practiceFax_2").val("");
        $("#practiceFaxTempId_3").val("");
        $("#practiceFax_3").val("");
        this.setState({isSuccess:false})

        cityMaster = []
        zipMaster = []
        this.setState({stateValues:''})
        this.setState({cityValues:''})
        this.setState({zipValues:''})
        this.setState({prescriberNameValues:''})
        $("#addresss1,#addresss2,#state,#city,#zip,#prescriberName,#practicePhone_1,#practicePhone_2,#practicePhone_3,#practiceFax_1,#practiceFax_2,#practiceFax_3").prop("disabled",false);
        this.setState({addPracticeLocationValidate:{addresss1Valid:false,stateValid:false,ZipValid:false,cityValid:false,prescriberNameValid:false,phoneOneValid:false,faxOneValid:false}})

    }

     editPracticeListener = (event,row) =>{
            this.setState({isSuccess1:false})
            let mdLocation = practiceLocationList[row.index];
            this.setState({stateValues:mdLocation.state})
            this.setState({prescriberNameValues:row.prescriberId})
            this.setState({fromEdit:true})
            if(mdLocation.fromApi){
                cityMaster.push(mdLocation.city)
                zipMaster.push(mdLocation.postal_code)
                this.setState({cityValues:mdLocation.city})
                this.setState({zipValues:mdLocation.postal_code})
                $("#addresss1,#addresss2,#state,#city,#zip,#prescriberName").prop("disabled",true);
            }else{
                $("#addresss1,#addresss2,#state,#city,#zip,#prescriberName").prop("disabled",false);

                this.cityMasterListener(mdLocation.state)
                this.setState({cityValues:mdLocation.city})
                this.zipMasterListener(mdLocation.state,mdLocation.city)
                this.setState({zipValues:mdLocation.postal_code})

            }

            $("#practiceName").val(mdLocation.facility);
            $("#addresss1").val(mdLocation.address_1);
            $("#addresss2").val(mdLocation.address_2);
            $("#index").val(row.index);
            //$("#indexOfOutter").val(row.indexOfOutter);
            $(".practiceNameClass").addClass("active");
            $(".addressOneClass").addClass("active");
            $(".addressTwoClass").addClass("active");


            $("#practicePhoneTempId_1").val("");
            $("#practicePhone_1").val("");
            $("#practicePhoneTempId_2").val("");
            $("#practicePhone_2").val("");
            $("#practicePhoneTempId_3").val("");
            $("#practicePhone_3").val("");
            $("#practiceFaxTempId_1").val("");
            $("#practiceFax_1").val("");
            $("#practiceFaxTempId_2").val("");
            $("#practiceFax_2").val("");
            $("#practiceFaxTempId_3").val("");
            $("#practiceFax_3").val("");

            for(let indexValue = 0; indexValue<mdLocation.contact.length;indexValue++){

                switch (mdLocation.contact[indexValue].contactType) {

                    case 'PHONE_1':
                         $("#practicePhoneTempId_1").val(mdLocation.contact[indexValue].id);
                         $("#practicePhone_1").val(mdLocation.contact[indexValue].contactValue);
                         $(".practicePhoneOneClass").addClass("active");
                         $("#practicePhone_1").removeClass("validate invalid");
                         $("#practicePhoneFromApi_1").val(mdLocation.contact[indexValue].fromApi);
                         if(mdLocation.contact[indexValue].fromApi){
                            $("#practicePhone_1").prop("disabled",true);
                         }else{
                            $("#practicePhone_1").prop("disabled",false);
                         }

                      break;
                    case 'PHONE_2':
                        $("#practicePhoneTempId_2").val(mdLocation.contact[indexValue].id);
                        $("#practicePhone_2").val(mdLocation.contact[indexValue].contactValue);
                        $(".practicePhoneTwoClass").addClass("active");
                        $("#practicePhone_2").removeClass("validate invalid");
                        $("#practicePhoneFromApi_2").val(mdLocation.contact[indexValue].fromApi);
                        if(mdLocation.contact[indexValue].fromApi){
                            $("#practicePhone_2").prop("disabled",true);
                         }else{
                            $("#practicePhone_2").prop("disabled",false);
                         }
                      break;
                    case 'PHONE_3':
                        $("#practicePhoneTempId_3").val(mdLocation.contact[indexValue].id);
                        $("#practicePhone_3").val(mdLocation.contact[indexValue].contactValue);
                        $(".practicePhoneThreeClass").addClass("active");
                        $("#practicePhone_3").removeClass("validate invalid");
                        $("#practicePhoneFromApi_3").val(mdLocation.contact[indexValue].fromApi);
                        if(mdLocation.contact[indexValue].fromApi){
                            $("#practicePhone_3").prop("disabled",true);
                         }else{
                            $("#practicePhone_3").prop("disabled",false);
                         }
                      break;
                    case 'FAX_1':
                        $("#practiceFaxTempId_1").val(mdLocation.contact[indexValue].id);
                        $("#practiceFax_1").val(mdLocation.contact[indexValue].contactValue);
                        $(".practiceFaxOneClass").addClass("active");
                        $("#practiceFax_1").removeClass("validate invalid");
                        $("#practiceFaxFromApi_1").val(mdLocation.contact[indexValue].fromApi);
                        if(mdLocation.contact[indexValue].fromApi){
                            $("#practiceFax_1").prop("disabled",true);
                         }else{
                            $("#practiceFax_1").prop("disabled",false);
                         }
                      break;
                    case 'FAX_2':
                        $("#practiceFaxTempId_2").val(mdLocation.contact[indexValue].id);
                        $("#practiceFax_2").val(mdLocation.contact[indexValue].contactValue);
                        $(".practiceFaxTwoClass").addClass("active");
                        $("#practiceFax_2").removeClass("validate invalid");
                        $("#practiceFaxFromApi_2").val(mdLocation.contact[indexValue].fromApi);
                        if(mdLocation.contact[indexValue].fromApi){
                            $("#practiceFax_2").prop("disabled",true);
                         }else{
                            $("#practiceFax_2").prop("disabled",false);
                         }
                      break;
                    case 'FAX_3':
                        $("#practiceFaxTempId_3").val(mdLocation.contact[indexValue].id);
                        $("#practiceFax_3").val(mdLocation.contact[indexValue].contactValue);
                        $(".practiceFaxThreeClass").addClass("active");
                        $("#practiceFax_3").removeClass("validate invalid");
                        $("#practiceFaxFromApi_3").val(mdLocation.contact[indexValue].fromApi);
                        if(mdLocation.contact[indexValue].fromApi){
                            $("#practiceFax_3").prop("disabled",true);
                         }else{
                            $("#practiceFax_3").prop("disabled",false);
                         }
                      break;
                  }
            }
            this.setState({addPracticeLocationValidate:{addresss1Valid:false,stateValid:false,ZipValid:false,cityValid:false,prescriberNameValid:false,phoneOneValid:false,faxOneValid:false}})


     }

     setRemovePracticeLocation = (event,row) =>{
        let id = practiceLocationList[row.index].id
        this.setState({removeLocationId:id});
     }

     removePracticeListener = (event) =>{

        let id = this.state.removeLocationId;
        axios.put('/prescriberProfile/deletePrescriberPracticeLocation?id='+id)
            .then(response => {
                this.getPracticeLocationData();
                $('#deleteModalCancel').click();
            })
            .catch(function (error) {
               console.log("Exception occur"+error)
            });

     }


     getPracticeLocationData = () => {

         axios.get('/officeAdminProfile/getMyPhysicianPracticeLocationData?username='+encodeURIComponent(UserProfile.getUserName()))
            .then(response => {

                this.setState({isLoading:false})
                formaattedPracticeLocation = []
                stateMaster = []
                cityMaster = []
                zipMaster = []
                practiceLocationList = []

               let practiceLocation = response.data.prescriberPracticeLocations
               stateMaster = response.data.stateMaster
               prescriber = response.data.prescriberInfo
               //prescriberId = response.data.prescriberInfo[0].prescriberId
               if(practiceLocation){
                    for(let i =0;i<practiceLocation.length;i++){
                       let innerJson = {"index":i,"prescriberId":practiceLocation[i].prescriberId,"prescriberName":practiceLocation[i].prescriberFullName,"type":practiceLocation[i].type,"fromApi" :practiceLocation[i].fromApi,"facility":practiceLocation[i].facility,"address":practiceLocation[i].address_1+" "+practiceLocation[i].address_2+", "+practiceLocation[i].city+", "+practiceLocation[i].state+" "+practiceLocation[i].postal_code+", "+practiceLocation[i].country_name}
                       formaattedPracticeLocation.push(innerJson);
                    }
                }
                practiceLocationList = practiceLocation;
                //this.setState({practiceLocation:practiceLocation,prescriberData:prescriber})
                this.setState({practiceLocation:practiceLocation})

            })
            .catch(function (error) {

            });
     }

 componentDidMount(){
       this.getPracticeLocationData();
    }

tooltipFormatter(cell, row){
    return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
    };

 locationData(){

        if(formaattedPracticeLocation.length > 0){
            //  console.log("formaattedPracticeLocation------------------------------------------------")
            // console.log(formaattedPracticeLocation)
                return (
                    <div className="responsive-table max-width-768">
                     <BootstrapTable data={formaattedPracticeLocation}  striped bordered={ true }>
                        <TableHeaderColumn dataSort={true} dataFormat={this.setAction} dataField='type' width="10%" ><span>Type </span></TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} isKey dataField='facility' width="10%"><span>Practice Name </span></TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} dataField='prescriberName' width="20%"><span>Prescriber Name </span></TableHeaderColumn>
                        <TableHeaderColumn dataSort={true} tdStyle={ { whiteSpace: 'normal' } } thStyle={ { whiteSpace: 'normal' } } dataField='address' width="50%" ><span>Address </span></TableHeaderColumn>
                        <TableHeaderColumn dataSort={false} dataField='fromApi' dataFormat={this.setActionIcon} width="10%" ><span>Action</span></TableHeaderColumn>
                    </BootstrapTable>
                    </div>
                )
        }
    }

    setAction(cell,row){

        if(row.type=='Primary'){
            return (
               <div>
                    <ReactTooltip place="top" html={true}/>
                    <span className="green pl5 pr5" data-tip={row.type}>
                        {row.type}
                    </span>
               </div>
            )
        }else{
            return (
                <div>
                    <ReactTooltip place="top" html={true}/>
                    <span className="red pl5 pr5" data-tip={row.type}>
                        {row.type}
                    </span>
               </div>
            )
        }
    }
    setActionIcon = (cell,row) => {
        // console.log()

        let styleClass = { display :'none'}
        if(!row.fromApi){
            styleClass.display = 'block inline';
        }


        return (
                <div className='actions-list-div'>
                    <a href='#' onClick={(event) => this.editPracticeListener(event,row)} className='tooltipped' data-toggle="modal" data-position='top'  data-target="#addLocationModal"  data-tooltip='View' data-position='top'>
                        <i className='themify-icons ti-pencil-alt'></i>
                    </a>

                    <a href='#' onClick={(event) => this.setRemovePracticeLocation(event,row)}  className='tooltipped' data-toggle="modal" data-position='top'  data-target="#deleteLocationModal"  style={styleClass} data-tooltip='Delete' data-position='top'>
                        <i className='themify-icons ti-trash '></i>
                    </a>
                </div>
            )
    }

    deletePracticeModel(){
        return(
            <div id="deleteLocationModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center max550">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete Practice Location</h4>
                       </div>
                       <div className="modal-body">
                           <div className="agengtDetailsAdd inner-page-container">
                            <div className="col-sm-12">
                                <p>
                                    Are you sure! You want to Delete Practice Location ?
                                </p>
                            </div>
                           </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="deletePracticeLocation" btnName="Ok" className="btn btn-primary btn-darkpurpale wizard-next " click={this.removePracticeListener.bind(this)} secondParams={null} />
                       <button type="button" id="deleteModalCancel" className="btn btn-primary wizard-next " data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

     practiceModel(){
        //  style={styleClass} let styleClass = { width :'90%'}
        return(
             <div id="addLocationModal" className="modal fade" role="dialog" >
                <div className="modal-dialog max1280">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.fromEdit ?'Edit Practice Location':'Add Practice Location'}</h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    {this.createUI()}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        <section className="buttonWrapper">
                        <button id="submitPracticeLocation" className="btn btn-primary wizard-next btn-darkpurpale"  onClick={this.addPracticeLocation.bind(this)} disabled={this.state.fromEdit?this.state.isSuccess1:this.state.isSuccess} secondParams={null}>{"Save"} </button>
                        {/* <Button id="submitPracticeLocation" btnName="Save" className="btn btn-primary wizard-next btn-darkpurpale"  disabled={this.state.fromEdit?this.state.isSuccess1:this.state.isSuccess} click={this.addPracticeLocation.bind(this)} secondParams={null} /> */}
                            <button type="button" id="locationModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {

        UserProfile.setUrlName(window.location.href)

        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={"My Physician Practice Locations"} />
                    {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                    <main>
                        <div className="inner-page-container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="boxWithShadow boxWithSpace">
                                        {this.locationData()}
                                            <div className="pull-right fixed-action-btn">
                                                <a href='#' id="modalId" onClick={this.addPracticeLocationListener} data-toggle="modal" data-position='top'  data-target="#addLocationModal" className='tooltipped btn-floating btn-large waves-effect waves-light red' data-tooltip='Add' data-position='top'>
                                                    <i className="large material-icons">add</i>
                                                </a>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            {this.practiceModel()}
                            {this.deletePracticeModel()}
                        </div>

                    </main>
                    <Footer/>
                </div>
            </div>
        );
    }
}