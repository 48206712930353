
import React,{Component} from 'react';
import CKEditor from "react-ckeditor-component";


class CKeditor extends Component {
 constructor(props) {
        super(props)
        
    }
    
    
   
    
    onBlur(evt){

    }
    
    afterPaste(evt){
      
    }
 
    render() {
        return (
            <div>
            <CKEditor 
              
              activeClass="p10" 
              content={this.props.content} 
              events={{
                "blur": this.props.onBlur,
                "change": this.props.onBlur,
                "afterPaste":this.props.onBlur,
             }}
             config={{
                removePlugins :'sourcearea',
                 toolbar: [ [ 'Bold' ],[ 'Italic' ],[ 'Cut' ],[ 'Copy' ],[ 'Paste' ],[ 'PasteText' ],[ 'PasteFromWord' ],[ 'Undo' ],[ 'Redo' ],['NumberedList'],['BulletedList'],[ 'Format' ] ]
             }}
             />
                <div className="buttonWrapper">
                    <button className="btn" id="Preview"  onClick={this.props.jsPdf}>Preview</button>       
                    <button className="btn btn-darkpurpale" id="AddBtn"   onClick={this.props.uploadDoc}>Add</button>                 
                </div> 
            </div>
        )
    }

 
}
export default CKeditor;