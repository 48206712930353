import React, { Component } from 'react';
import {Input} from 'react-materialize';
import * as Constant from '../../CustomUI/Constant';
import CustomInput from '../../CustomUI/Input';
import Label from '../../CustomUI/Label';
import * as Datetime from 'react-datetime';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import UserProfile from '../../../UserProfile';
import Select from 'react-select';
import $ from 'jquery';
import PickyDropdown from '../../CustomUI/PickyDropdown';
let followUpReasonData = [];
let selectedOptionAdd=[]
let clientData=[]
const options=[];
export default class searchsection extends Component {
    constructor(props) {
        super(props);
        this.state = {
        value: null,
        selectedOptionAdd:[],};
        this.selectMultipleOption = this.selectMultipleOption.bind(this);}
    state = {
        flag:0,
        // selectedOptionAdd:[],
        selectedReasonAdd:null,
        selectedStatusAdd:null,
        selectedDob:'',
        selectedUploadedFrom: '',
        selectedUploadedTo: '',
        sizePerPage:100,
        data:false        
        }
    componentDidMount(){
        $("#phRxId").focusout(function(){
            $(".picky__input").on("focus",function(){
                $(".picky__input").click();
                });
            });
            $("#followUpStatus").on("focusin keyup",function(event){   
                if(event.keyCode==9){
                    $(".picky__input").click();
                }
              });
        };
    componentWillMount(){  
            $("#pharmacyStore").text("--Select--");    }
            reset = (event) => {
            if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN) {
                document.location.reload(true);} else {
                $('#clearDiv').find('input').val('');
                $('#therapeuticProgram').val('');
                $('#followUpStatus').val('');
                $('#followUpReason').val('');
                $('#subTypeHistory').val('');
                $('#documentType').val('');
                $('#includeDocDone').prop('checked', false);
                $('#includeComplete').prop('checked', false);
                if(this.state.flag){
                    this.props.getFollowUpReasonListener(event);
                    this.props.getSearchHistoryData(event); }
                this.setState({selectedOptionAdd: [],selectedStatusAdd:null,selectedReasonAdd:null,selectedDob: '',selectedUploadedFrom: '',selectedUploadedTo: '',sizePerPage:100})
            }
        }
    onlyInteger = (element) => {
            $(element).keypress(function(event) {
                var keynum
                if(window.event) { // IE8 and earlier
                    keynum = event.keyCode;
                } else if(event.which) { // IE9/Firefox/Chrome/Opera/Safari
                    keynum = event.which;
                } else {
                    keynum = 0;
                }
                if(keynum == 8 || keynum == 0 || keynum == 9 || keynum == 13) {
                    return;
                }
                if(keynum <= 46
                || keynum > 57
                || keynum == 47) {
                    event.preventDefault();
                } // prevent if not number/dot
            });
        }
    resetData = (event) => {
        $('#clearDiv').find('input').val('');
            $('#therapeuticProgram').val('');
            $('#followUpStatus').val('');
            $('#followUpReason').val('');
            $('#subTypeHistory').val('');
            $('#documentType').val('');
            $('#includeDocDone').prop('checked', false);
            $('#includeComplete').prop('checked', false);
            this.setState({selectedOptionAdd: [],selectedStatusAdd:null,selectedReasonAdd:null,selectedDob: '',selectedUploadedFrom: '',selectedUploadedTo: '',sizePerPage:100,page:1})
        if(!this.state.flag){
            this.props.changFlag();
            // this.props.callDisplayData()
        }
        this.props.resetDataCount()}
    handleStatusAdd = (selectedStatusAdd) => {
        this.setState({selectedStatusAdd:selectedStatusAdd,selectedReasonAdd:''})
    }
    handleReasonAdd = (selectedReasonAdd) => {
        this.setState({selectedReasonAdd:selectedReasonAdd})
    }
    handleDateChange = (date) => {
        this.setState({ selectedDob: date });
    }
    handleUploadedFrom = (date) => {
        // if(typeof this.state.selectedUploadedTo==="object"){
        //     var diff = this.state.selectedUploadedTo.diff(date);
        //     if(diff<0){
        //         this.setState({ selectedUploadedTo: "" });
        //     }
        // }
        this.setState({ selectedUploadedFrom: date });
    }
    handleUploadedTo = (date) => {
        this.setState({ selectedUploadedTo: date });
    }
    selectOption(value) {
        this.props.getpharmacyStoreval(value)  
        this.setState({ selectedOptionAdd:value });
    }
    selectMultipleOption=(value,selected)=> {
        console.log(selected)
    this.props.getpharmacyStoreval(value)
    this.setState({ selectedOptionAdd: value});
    }
    render() {
        $(".picky__dropdown").bind("DOMSubtreeModified",function(){
            $(".picky__dropdown span").replaceWith("<label>" + "Select all" + "</label>");
        })
        // $(".picky__dropdown span").replaceWith("<label>" + "Select all" + "</label>");
        this.onlyInteger($(".onlyInteger"));
        const {selectedUploadedFrom,selectedUploadedTo} = this.state;
        var today = Datetime.moment();
        var valid = function( current ) {
            return current.isBefore( today );
        };
        var validTo = function( current ){
            if(selectedUploadedFrom!=='')
                  return current.isAfter(selectedUploadedFrom-1) && current.isBefore( today )
            else
                    return current.isAfter("01/01/2019") && current.isBefore( today )
        };
         var validFrom = function( current ){
            if(selectedUploadedTo!=='')
                return current.isBefore(selectedUploadedTo+1)&& current.isBefore( today )
            else
                return current.isAfter("01/01/2019") && current.isBefore( today )
         };

        let style = {display:'none'};
        if(UserProfile.getRole() !== Constant.LOGGED_IN_ROLE_PRESCRIBER){
            style.display = '';
        }
        let historyHide = false;
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            historyHide = true;
        }
        let therapeuticCategoriesData = [];
        if(this.props.therapeuticCategories !== undefined && this.props.therapeuticCategories.length > 0){
            therapeuticCategoriesData = JSON.parse(this.props.therapeuticCategories).map( tCategory =>{
                return <option  value={tCategory[0]} key={tCategory[0]} >{tCategory[1]}</option>
            } );
        }
        let followUpStatusesData = [];
        followUpStatusesData.push({ value: "", label: "--Select--"})
        if(this.props.followUpStatuses !== undefined && this.props.followUpStatuses.length > 0){
            JSON.parse(this.props.followUpStatuses).map( fuStatus =>{
                followUpStatusesData.push({ value: fuStatus[0], label: fuStatus[1]})
            } );
        }
        const {selectedStatusAdd} = this.state;       
        let  followUpReasonData = [];
        if(this.state.selectedStatusAdd==null){
            followUpReasonData=[]
            followUpReasonData.push({ value:"", label:"--Select--"})
        }
        else if(this.props.followUpReasones !== undefined && this.props.followUpReasones.length > 0){
            followUpReasonData.push({ value:"", label:"--Select--"})
            JSON.parse(this.props.followUpReasones).map( fuReason =>{
                followUpReasonData.push({ value: fuReason[0], label: fuReason[1]})
            } );
        }
        const {selectedReasonAdd} = this.state;      
        let options = []
        let countData=0
        if(this.props.hqList !== undefined && this.props.hqList.length > 0){
            JSON.parse(this.props.hqList).map(hqData =>{    
                options.push({countData:countData, value: hqData[0], label: hqData[1]})
                countData++
            }) 
        }
            console.log(options.length)
        const {selectedOptionAdd} = this.state;
        return (
            <div>
                <section className="searchReportContainerWithNoTopPadding boxWithShadow bootstrap-tab max-height-drop" id="searchField">
                <Tabs onSelect={(index, label) =>{
                            index ? this.reset() : this.resetData()
                            index ? this.props.isHistory(true) : this.props.isHistory(false)
                            this.setState({flag:index});
                            $('#clearDiv').find('input').val('');}}
                                selected={this.state.flag}>
                    <Tab label="Status Queue">
                        <div className="row" id="clearDiv">
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                <div className="input-field">
                                    <Label lblhtmlFor="caseId" lblname={Constant.LABEL_CASE_ID} lblclassName="active"/>
                                    <CustomInput intid="caseId" intType="text" intName="caseId" intClasssName= "onlyInteger"keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="7"/>
                            </div>
                        </div>
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <Label lblhtmlFor="patientFirstName" lblname={Constant.LABEL_PATIENT_FIRST_NAME}/>
                                <CustomInput intid="patientFirstName" intClasssName="onlyAlphaNumeric" intType="text" intName="patientFirstName" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                                </div>
                        </div>
                                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                    <Label lblhtmlFor="patientLastName" lblname={Constant.LABEL_PATIENT_LAST_NAME}/>
                                <CustomInput intid="patientLastName" intClasssName="onlyAlphaNumeric" intType="text" intName="patientLastName" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                            </div>
                        </div>
                            <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                <div className="input-field suffix">
                                    <Label lblclassName="active" lblname={Constant.LABEL_DOB}   />
                                    <Datetime placeholeder={Constant.LABEL_DOB} intType="text" inputProps={{ id: 'dob', name: 'dob'}} intClasssName="datepicker"
                                            keyPress={this.props.getSearchHistoryData} keyEvent='Enter' closeOnSelect={true} strictParsing={true}
                                            timeFormat={false} input = {true}  value={this.state.selectedDob} onChange={this.handleDateChange} isValidDate={valid}/>
                                    <i className="icon-calendar simple-icons" onClick={()=>$("#dob").click()}></i>
                                </div>
                            </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pr0 pl0">
                                <Input xs={12} s={12} l={12} m={12} type='select' id="therapeuticProgram" name="therapeuticProgram"  label="Therapeutic Program"   readOnly>
                                    <option value="">--Select--</option>
                                    {therapeuticCategoriesData}
                                    {/*<option value="ff80808157700a7c0157950ccc4f11d1">Behavioral Health</option>*/}
                            </Input>
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <Label lblhtmlFor="drugName" lblname={Constant.LABEL_DRUG_NAME}/>
                                <CustomInput intid="drugName" intClasssName="onlyAlphaNumeric" intType="text" intName="drugName" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                            </div>
                        </div>
                        {/*<Input s={2} type='select' id="pharmacyStore" name="pharmacyStore"  label="Select Pharmacy Store" >
                            <option value="">--Select--</option>
                            <option value="Patient Care Pharmacy" selected>Patient Care Pharmacy</option>
                            <option value="Good value Inc.">Good value Inc.</option>
                            <option value="Your Care Pharmacy">Your Care Pharmacy</option>
                        </Input>*/}
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <Label lblhtmlFor="phRxId" lblname={Constant.LABEL_PH_RX_ID} lblclassName="active"/>
                                <CustomInput intid="phRxId" intClasssName="onlyAlphaNumeric" intType="text" intName="phRxId" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="50"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">               
                            <div className="input-field suffix">
                                    <label className="active" forhtml="pharmacyStore">Select Pharmacy</label>
                                    <PickyDropdown 
                                    //    hqList={this.props.hqList}
                                        numberDisplayed={2} 
                                        includeSelectAll={options.length!=0?true:false}
                                        includeFilter={true}
                                        open={false}
                                        multiple={true}
                                        value={selectedOptionAdd}
                                    //    options={bigList}
                                        // includeSelectAll={true}
                                        options={ options}
                                        onChange={this.selectMultipleOption}
                                        placeholder={"--Select--"}
                                        allSelectedPlaceholder={"All pharmacy Store selected"}
                                        manySelectedPlaceholder={"Selected %s pharmacy Store"}
                                        dropdownHeight={200}  />
                                </div>
                        </div>{/* <Picky
                                    numberDisplayed={2}
                                value={selectedOptionAdd}
                                options={options}
                                onChange={this.selectMultipleOption}
                                open={false}
                                    valueKey="value"
                                labelKey="label"
                                multiple={true}
                                includeSelectAll={true}
                                includeFilter={true}
                                placeholder={"--Select--"}
                                allSelectedPlaceholder={"All pharmacy Store selected"}
                                manySelectedPlaceholder={"Selected %s pharmacy Store"}
                                    dropdownHeight={200}
                                render={({
                                        filtered,
                                        index,
                                        style,
                                        item,
                                        isSelected,
                                        selectValue,
                                        labelKey,
                                        valueKey,
                                        multiple
                                        }) => {
                                        return (
                                            <li
                                            style={{ ...style }} // required
                                            className= "col-sm-4" // required to indicate is selected
                                            key={item[valueKey]} // required
                                            >
                                            <div className={isSelected ? "selected form-group" : "form-group"} onClick={() => selectValue(item)}>
                                                <input type="checkbox"
                                                checked={isSelected}
                                                style={{height: "auto", width: "auto" }} />
                                            <label
                                                style={{
                                                fontWeight: isSelected ? "normal" : "normal"
                                                }}>
                                                {item[labelKey]}
                                            </label>
                                            </div>
                                            </li>
                                        );
                                        }}
                                        /> */}
                                
                                    {/* <Select
                                        id = "pharmacyStore"
                                        name="pharmacyStore"
                                        className="input-field"
                                        isMulti={true}
                                        isSearchable={false}
                                        closeMenuOnSelect = {true}
                                        value={selectedOptionAdd}
                                        onChange={this.handleChangeAdd}
                                        options={options}
                                        placeholder="--Select--"
                                    /> */}                         
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field suffix">
                                    <label className="active" forhtml="followUpStatus">Select Referral Status</label>
                                        <Select
                                        id = "followUpStatus"
                                        name="followUpStatus"
                                        isMulti = {false}
                                        isSearchable={true}
                                        closeMenuOnSelect = {true}
                                        value={selectedStatusAdd}
                                        placeholder="--Select--"
                                        onChange={(event)=>{this.props.getFollowUpReasonListener(event);this.handleStatusAdd(event)}}
                                        options={followUpStatusesData}/>
                            </div>
                        </div>
                        {/* <Input x={12} s={12} m={4} l={2} type='select' id="followUpStatus" name="followUpStatus"  label="Select Referral Status" onChange={this.props.getFollowUpReasonListener} readOnly>
                                    <option value="">--Select--</option>
                                    {followUpStatusesData}
                        </Input> */}
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field suffix">
                                    <label className="active" forhtml="followUpReason">Select Referral Reason</label>
                                        <Select
                                        id = "followUpReason"
                                        name="followUpReason"
                                        isMulti = {false}
                                        isSearchable={true}
                                        closeMenuOnSelect = {true}
                                        value={selectedReasonAdd}
                                        onChange={this.handleReasonAdd}
                                        placeholder="--Select--"
                                        options={followUpReasonData}/>
                            </div>
                        </div>
                        {/* <Input x={12} s={12} m={4} l={2} type='select' id="followUpReason" name="followUpReason"  label="Select Referral Reason" readOnly>
                                    <option value="">--Select--</option>
                                    {followUpReasonData}
                        </Input> */}
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2" style={style}>
                            <div className="input-field">
                                <label htmlFor="prescriberFirstName">Prescriber First Name</label>
                                <CustomInput intid="prescriberFirstName" intClasssName="onlyAlphaNumeric" intType="text" intName="prescriberFirstName" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2" style={style}>
                            <div className="input-field">
                            <label htmlFor="prescriberLastName">Prescriber Last Name</label>
                            <CustomInput intid="prescriberLastName" intClasssName="onlyAlphaNumeric" intType="text" intName="prescriberLastName" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                            </div>
                        </div>
                    {historyHide === false ?
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className = "form-group pt20">
                                <input type="checkbox" name="includeDocDone" id="includeDocDone" className="form-control preferredAddress pull-left"/>
                                <label htmlFor="includeDocDone" className="show pull-left">Include Marked As Done</label>
                            </div>
                        </div>: null }
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className = "form-group pt20">
                                <input type="checkbox" name="includeComplete" id="includeComplete" className="form-control pull-left"/>
                                <label htmlFor="includeComplete" className="show pull-left">Include Completed Cases</label>
                            </div>
                        </div>
                    
                    </div>
                    <section className="buttonWrapper">
                        <button className="btn btn-darkpurpale" onClick={this.props.getSearchHistoryData}>Search</button>
                        {/* <button className="btn" onClick={this.props.exportWQ}>Export</button> */}
                        <button className="btn  btn-white"  name = "reset" onClick={this.resetData}>Reset</button>
                            {/*<a className="btn" onClick={this.createExportQueryString} href={Constant.EXPORT_WORKQUEUE_URL + queryString}>Export</a>*/}
                            <button className="btn btn-darkpurpale" onClick={this.props.createExportQueryString}>Export</button>
                    </section></Tab>
                    {historyHide === false ? (

                    <Tab label="Uploaded documents" >
                    <div className="row" id="clearDiv">
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                            <div className="input-field">
                                <Label lblhtmlFor="caseIdHistory" lblname={Constant.LABEL_CASE_ID}/>
                                <CustomInput intid="caseIdHistory" intType="text" intClasssName= "onlyInteger" intName="caseIdHistory" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="7"/>
                            </div>
                        </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field">
                            <Label lblhtmlFor="fNameHistory" lblname={Constant.LABEL_PATIENT_FIRST_NAME}/>
                            <CustomInput intid="fNameHistory" intType="text" intName="fNameHistory" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field">
                            <Label lblhtmlFor="lNameHistory" lblname={Constant.LABEL_PATIENT_LAST_NAME}/>
                            <CustomInput intid="lNameHistory" intType="text" intName="lNameHistory" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field suffix">
                            {/* <CustomInput intid="dobHistory" intType="text" intName="dobHistory" intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter'/> */}
                            <Label lblhtmlFor="icon_prefix" lblclassName="active" lblname={Constant.LABEL_DOB}/>
                            <Datetime intType="text" inputProps={{ id: 'dobHistory', name: 'dobHistory'}} intClasssName="datepicker"
                            keyPress={this.props.getSearchHistoryData} keyEvent='Enter' closeOnSelect={true} strictParsing={true}
                            timeFormat={false} input = {true} value={this.state.selectedDob} onChange={this.handleDateChange} isValidDate={valid}/>
                            <i className="icon-calendar simple-icons" onClick={()=>$("#dobHistory").click()}></i>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field">
                            <Label lblhtmlFor="userfNameHistory" lblname={Constant.LABEL_USER_FIRST_NAME}/>
                            <CustomInput intid="userfNameHistory" intType="text" intClasssName="onlyAlphaNumeric" intName="userfNameHistory" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field">
                            <Label lblhtmlFor="userlNameHistory" lblname={Constant.LABEL_USER_LAST_NAME}/>
                            <CustomInput intid="userlNameHistory" intType="text" intClasssName="onlyAlphaNumeric" intName="userlNameHistory" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' maxlength="30"/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field suffix">
                            {/* <CustomInput intid="subDateFromHistory" intType="text" intName="subDateFromHistory" intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter'/> */}
                            <Label lblhtmlFor="icon_prefix" lblclassName="active" lblname={Constant.LABEL_SUBMISSION_DATE_FROM}/>
                            <Datetime  isValidDate={validFrom} intType="text" inputProps={{ id: 'subDateFromHistory', name: 'subDateFromHistory'}}
                            intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' closeOnSelect={true}
                            strictParsing={true} timeFormat={true} input = {true} value={this.state.selectedUploadedFrom} onChange={this.handleUploadedFrom}/>
                            <i className="icon-calendar simple-icons" onClick={()=>$("#subDateFromHistory").click()}></i>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                        <div className="input-field suffix">
                            {/* <CustomInput intid="subDateToHistory" intType="text" intName="subDateToHistory" intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter'/> */}
                            <Label lblhtmlFor="icon_prefix" lblclassName="active" lblname={Constant.LABEL_SUBMISSION_DATE_TO}/>
                            <Datetime isValidDate={validTo} intType="text" inputProps={{ id: 'subDateToHistory', name: 'subDateToHistory'}}
                            intClasssName="datepicker" keyPress={this.props.getSearchHistoryData} keyEvent='Enter' closeOnSelect={true}
                            strictParsing={true} timeFormat={true} input = {true} value={this.state.selectedUploadedTo} onChange={this.handleUploadedTo}/>
                            <i className="icon-calendar simple-icons" onClick={()=>$("#subDateToHistory").click()}></i>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pr0 pl0">
                        <Input x={12} s={12} m={12} l={12}  type='select' id="subTypeHistory" name="subTypeHistory"  label="Submission Type" readOnly>
                                <option value="" >--Select--</option>
                                <option value="">Document</option>
                        </Input>
                    </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2 pr0 pl0">
                            <Input x={12} s={12} m={12} l={12} type='select' id="documentType" name="documentType"  label="Document Type" readOnly>
                                    <option value="" >--Select--</option>
                                    <option value="Chart Notes">Chart Notes</option>
                                    <option value="Enrollment Forms">Enrollment Forms</option>
                                    <option value="Prescription">Prescription</option>
                                    <option value="Labs">Labs</option>
                                    <option value="Consents/AOR">Consents/AOR</option>
                                    <option value=" PA Form"> PA Form</option>
                                    <option value="Appeal Letter">Appeal Letter</option>
                                    <option value="Assessments">Assessments</option>
                                    <option value="Referral Forms">Referral Forms</option>
                                    <option value="Other"> Other</option>
                            </Input>
                        </div>                       
                    </div>                    
                <section className="buttonWrapper">
                        <button className="btn scroll-down btn-darkpurpale" onClick={this.props.getSearchHistoryData}>Search</button>
                        {/* <button className="btn" onClick={this.props.exportHistory}>Export</button> */}
                        <button className="btn btn-white" name = "reset" onClick={this.reset}>Reset</button>
                </section>
                    </Tab>):<Tab label=""></Tab>}
                </Tabs>
                    </section>
            </div>
        );
    }
    }