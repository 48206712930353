import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import $ from 'jquery';
import * as Constant from '../../../Component/CustomUI/Constant';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip'

class AgentsTable extends Component {

setSpan = (cell,row) => {

    let classNameValue = "badge complete";

    if(row.status==Constant.DISSOCIATED){
       classNameValue = "red badge complete";
    }else if(row.status==Constant.PENDING){
       classNameValue = "yellow badge complete";
    }

     return (
         <div>
               <ReactTooltip place="top" html={true}/>
                <span className={classNameValue} data-tip={row.status}>
                    {row.status}
                </span>
         </div>

     )

}

setActionIcon = (cell,row) => {

        let styleClass = { display :'none'}
        if(!row.fromApi){
            styleClass.display = 'block inline';
        }

        return (
                <div className='actions-list-div'>
                  <ReactTooltip place="top" html={true}/>
                    <a href='#' onClick={(event) => this.props.editMyAgentListener(event,row)} data-tip="Edit"  data-toggle="modal"   data-target="#editMyAgentModal" >
                        <i className='themify-icons ti-pencil-alt'></i>
                    </a>
                </div>
            )
}

 setAction = (cell,row) =>{
        let unqiueData = "changeAgent_"+row.userId
        return(
             <div className="actions-list-div">
                <input type="checkbox" onClick={(event) => this.props.selectedPrivilegesListener(event,row)} id={unqiueData} name={unqiueData} className=""/>
                <label htmlFor={unqiueData}></label>
            </div>
        )
    }

    getFormattedDate = (date) => {

        if(date){
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            var day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;

            return month + '/' + day + '/' + year;
        }

        return null;

    }

    tooltipFormatter(cell, row){

        return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
        };


  render() {
      let displayData = []
      for(let i =0;i<this.props.userPrescribers.length;i++){
         let user = this.props.userPrescribers[i].user
         let userPrivileges = this.props.userPrescribers[i].userPrivileges
         let privileges = []
          for(let p = 0; p < userPrivileges.length;p++){
              privileges.push(userPrivileges[p].value);
          }

         let prescriber = this.props.userPrescribers[i].prescriber

         let startDateWithTime = this.props.userPrescribers[i].startDate ? new Date(this.props.userPrescribers[i].startDate).toLocaleString() : null
         let startDate = startDateWithTime ? startDateWithTime.slice(0,startDateWithTime.indexOf(',')) : null;

         let endDateWithTime = this.props.userPrescribers[i].deactivateDate ? new Date(this.props.userPrescribers[i].deactivateDate).toLocaleString() : null
         let endDate = endDateWithTime ? endDateWithTime.slice(0,endDateWithTime.indexOf(',')) : null

        let pStatus = this.props.userPrescribers[i].status
        pStatus = pStatus.toLowerCase()
        let status = pStatus.toLowerCase().charAt(0).toUpperCase() + pStatus.slice(1);


         let json = {"userId":user.id,"agentName":user.firstName +" "+user.lastName,"prescriberName":prescriber.firstName +" "+prescriber.lastName,
          "fName":user.firstName,"mName":user.middleName,"lName":user.lastName,"email":user.email,"userPrivileges":userPrivileges,"prefix":user.prefix,"privileges":privileges.join(","),
          "status":status,"startDate":startDate,"endDate":endDate,"uName":user.username,"roleTitleName":(user.roleTitle)?user.roleTitle.name:''
      }
         displayData.push(json)
      }



    return (
      <div className="responsive-table max-width-768">
        <BootstrapTable data={displayData} striped bordered={ true }>
            <TableHeaderColumn  dataFormat={this.setAction} width="12.5%"><span>Select </span></TableHeaderColumn>
            <TableHeaderColumn  dataFormat={this.setSpan} width="12.5%"><span>Status </span></TableHeaderColumn>
            <TableHeaderColumn  isKey dataField='agentName' width="12.5%"><span>Agent Name </span></TableHeaderColumn>
            <TableHeaderColumn  dataField='prescriberName' width="12.5%"><span>Prescriber Name </span></TableHeaderColumn>
            <TableHeaderColumn  dataField='startDate' width="12.5%"><span>Start Date </span></TableHeaderColumn>
            <TableHeaderColumn  dataField='endDate' width="12.5%"><span>End Date </span></TableHeaderColumn>
            <TableHeaderColumn  tdStyle={ { whiteSpace: 'normal' } } thStyle={ { whiteSpace: 'normal' } } dataField='privileges' width="12.5%"><span >Privileges </span></TableHeaderColumn>
            <TableHeaderColumn  dataFormat={this.setActionIcon} width="12.5%"><span>Actions </span></TableHeaderColumn>
        </BootstrapTable>
        </div>

    );
  }
}



class PhysicanTable extends Component {

    setActionIconData = (cell,row) =>   {
         let assUnqiueDataId = "associate_"+row.prescriberId
         let disUnqiueDataId = "disassociate_"+row.prescriberId
         let  isShow = false

         if(row.status === Constant.DISSOCIATED){
              isShow = true

         }

        return (


            <div className='actions-list-div'>
            <ReactTooltip place="top" html={true}/>
               {isShow? <button  className='tooltipped' data-tip="Associate" id = {assUnqiueDataId} data-toggle="modal" data-position='top'  data-target="#AssociatePrescriberModal" onClick={(event)=> this.props.deAssociatePrescriberData(event,row)} >
               <i className="fa fa-thumb-tack" aria-hidden="true"></i>
               </button>: <a href='#'  className='tooltipped' id = {disUnqiueDataId} data-tip="Disassociate"  data-toggle="modal"   data-target="#deAssociatePrescriberModal" onClick={(event)=> this.props.deAssociatePrescriberData(event,row)}  ><i className='fa fa-ban'></i></a> }
            </div>
            // <i class="fa fa-tasks" aria-hidden="true"></i>

       );

    }

  setSpanData(cell,row){

    let classNameValue = "badge complete";

    if(row.status==Constant.DISSOCIATED){
       classNameValue = "red badge complete";
    }else if(row.status==Constant.PENDING){
       classNameValue = "yellow badge complete";
    }

     return (
         <div>
            <ReactTooltip place="top" html={true}/>
            <span className={classNameValue} data-tip={row.status}>
                {row.status}
            </span>
         </div>

     )
  }

  tooltipFormatter(cell, row){
    return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
    };

    render() {

        // console.log(this.props.data)

      return (
        <div>
        <div className="responsive-table max-width-768">
          <BootstrapTable data={this.props.data} striped bordered={ true } >
              <TableHeaderColumn dataSort={false} dataFormat={this.setSpanData} dataField='status' width="10%"> <span>Status </span></TableHeaderColumn>
              <TableHeaderColumn isKey={true} dataSort={false} dataField='prescriberName' width="30%"><span>Prescriber Name </span></TableHeaderColumn>
              <TableHeaderColumn dataSort={false} dataField='startDate' width="15%"><span>Start Date </span></TableHeaderColumn>
              <TableHeaderColumn dataSort={false} dataField='endDate' width="15%"><span>End Date </span></TableHeaderColumn>
              <TableHeaderColumn dataSort={false} dataField='privileges' width="15%"><span>Privileges </span></TableHeaderColumn>
              <TableHeaderColumn dataFormat={this.setActionIconData} width="15%"><span>Actions </span></TableHeaderColumn>
          </BootstrapTable>
          </div>
        </div>
      );
    }
  }

export {PhysicanTable, AgentsTable};