import React, { Component } from 'react';
import { Input } from 'react-materialize';
import Button from '../../../Component/CustomUI/Button';
import * as Constant from '../../../Component/CustomUI/Constant';
import $ from 'jquery';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactTooltip from 'react-tooltip';
import CustomMaskedInput from '../../../Component/CustomUI/MaskedInput';

let stateMaster = []
let cityMaster = []
let zipMaster = []
let state = null;
let formaattedPracticeLocation = []
let locationList = []

export default class agentContact extends Component {

    state = {
       randomPassword:'',
       practiceLocation : [],
       stateValues: '',
       cityValues: '',
       zipValues: '',
       prescriberNameValue:'',
       values: '',
       editable:'',
       stateChange :false,
       prescriberData : '',
       fromEdit : false,
       addPracticeLocationValidate:{
           addresss1Valid :false,
           stateValid :false,
           cityValid:false,
           ZipValid:false,
           prescriberNameValid:false
       },
       isLoading:true,
       isSuccess:false,
       isSuccess1:false,
    }

     addPracticeLocationListener = () =>{

        this.setState({fromEdit:false})
        $("#practiceName").val("");
        $("#addresss1").val("");
        $("#addresss2").val("");
        $("#index").val("");

        $("#practicePhoneTempId_1").val("");
        $("#practicePhone_1").val("");
        $("#practicePhoneTempId_2").val("");
        $("#practicePhone_2").val("");
        $("#practicePhoneTempId_3").val("");
        $("#practicePhone_3").val("");
        $("#practiceFaxTempId_1").val("");
        $("#practiceFax_1").val("");
        $("#practiceFaxTempId_2").val("");
        $("#practiceFax_2").val("");
        $("#practiceFaxTempId_3").val("");
        $("#practiceFax_3").val("");
        this.setState({isSuccess:false})

        cityMaster = []
        zipMaster = []
        this.setState({stateValues:''})
        this.setState({cityValues:''})
        this.setState({zipValues:''})
        this.setState({prescriberNameValues:''})
        $("#addresss1,#addresss2,#state,#city,#zip,#prescriberName,#practicePhone_1,#practicePhone_2,#practicePhone_3,#practiceFax_1,#practiceFax_2,#practiceFax_3").prop("disabled",false);
        this.setState({addPracticeLocationValidate:{addresss1Valid:false,stateValid:false,ZipValid:false,cityValid:false,prescriberNameValid:false,phoneOneValid:false,faxOneValid:false}})

    }
      handleChange(event) {

        let city = null;

        if(event.target.name  === "state"){
            this.cityMasterListener(event.target.value)
            state = event.target.value
            cityMaster = []
            zipMaster = []
            this.setState({stateValues:event.target.value})
        }

        if(event.target.name  === "city"){
            city = event.target.value
            this.zipMasterListener(this.state.stateValues,city)
            this.setState({cityValues: event.target.value})
        }

        if(event.target.name  === "zip"){
            this.setState({zipValues: event.target.value})
        }

        if(event.target.name  === "Prescriber Name"){
            this.setState({prescriberNameValues: event.target.value})
        }
    }

      cityMasterListener = (state) =>{

         axios.get('/prescriberProfile/getCityFromState?state='+state)
            .then(response => {
                cityMaster = response.data;
                this.setState({stateChange : true});
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

    }

    zipMasterListener = (state,city) =>{

        let queryString =  '?state='+ state +'&city='+ encodeURIComponent(city)

         axios.get('/prescriberProfile/getZipFromCity'+queryString)
            .then(response => {
                zipMaster = response.data;
                this.setState({stateChange : true});
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

    }

     getPracticeLocationData = () => {


     }

    validatePracticeLoction = ()  =>{

        let addresss1 = $("#addresss1").val()
        let  state  = $("#state").val()
        let city = $("#city").val()
        let zip = $("#zip").val()
        let prescriberName = $("#prescriberName").val()
        let phoneOne = $("#practicePhone_1").val()
        let faxOne = $("#practiceFax_1").val()


        let addresss1Valid = false
        let stateValid = false
        let cityValid = false
        let ZipValid = false
        let prescriberNameValid = false
        let phoneOneValid =false
        let faxOneValid =false

        let isValid = true

        if(!addresss1){
            isValid = false
            addresss1Valid = true

        }
        if(!state){
            isValid = false
            stateValid = true

        }
        if(!city){
            isValid = false
            cityValid = true

        }
        if(!zip){
            isValid = false
            ZipValid= true

        }
        if(!prescriberName){
            isValid = false
            prescriberNameValid= true

        }
        if(!phoneOne){
            isValid = false
            phoneOneValid= true
        }
        if(!faxOne){
            isValid = false
            faxOneValid= true
        }

        if(!isValid){
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000,  'red rounded')
        }

    this.setState({addPracticeLocationValidate:{addresss1Valid:addresss1Valid,stateValid:stateValid,ZipValid:ZipValid,cityValid:cityValid,prescriberNameValid:prescriberNameValid,phoneOneValid,faxOneValid}})
        return isValid
    }

    addPracticeLocation = (event) => {

        let msg = ''
        if(this.validatePracticeLoction()){

            let practiceLocationJson = {}

            if(!this.state.fromEdit){
                this.setState({isSuccess:true})
                 practiceLocationJson = {"facility":$("#practiceName").val(),
                "type":"Other","address_1":$("#addresss1").val(),"address_2":$("#addresss2").val(),"city":$("#city").val(),
                "state":$("#state").val(),"postal_code":$("#zip").val(),"country_name":"United States",
                "phone_1":$("#practicePhone_1").val(),"phone_2":$("#practicePhone_2").val(),"phone_3":$("#practicePhone_3").val(),
                "fax_1":$("#practiceFax_1").val(),"fax_2":$("#practiceFax_2").val(),"fax_3":$("#practiceFax_3").val(),
                "state_full_name":$('#state').find(":selected").text()}
                msg = Constant.PRACTICE_LOCATION_ADD

            }else{
                // this.setState({isSuccess1:true})
                // let id = practiceLocationList[practiceIndex].id
                // let fromApi = practiceLocationList[practiceIndex].fromApi

                // let type = practiceLocationList[practiceIndex].type
                // let address_purpose = practiceLocationList[practiceIndex].address_purpose
                // let country_code = practiceLocationList[practiceIndex].country_code

                // practiceLocationList.splice(practiceIndex, 1);
                //     practiceLocationJson = {"id":id,"fromApi" :fromApi,"facility":$("#practiceName").val(),
                // "type":type,"address_purpose":address_purpose,"country_code":country_code,"address_1":$("#addresss1").val(),"address_2":$("#addresss2").val(),"city":$("#city").val(),"state":$("#state").val(),"postal_code":$("#zip").val(),"country_name":"United States"}
                // //practiceLocationList.push(practiceLocationJson)
                // msg = Constant.PRACTICE_LOCATION_EDIT


            }

            // let contact = [];
            // for(let i = 1; i<=3;i++){
            //     let practicePhone = $("#practicePhone_"+i).val();
            //     let practiceFax = $("#practiceFax_"+i).val();

            //     let practicePhoneTempId = $("#practicePhoneTempId_"+i).val();
            //     let practiceFaxTempId = $("#practiceFaxTempId_"+i).val();

            //     contact.push({id:practicePhoneTempId,fromApi:practicePhoneApi,contactType: "PHONE_"+i, contactValue: practicePhone })
            //     contact.push({id:practiceFaxTempId,fromApi:practiceFaxApi,contactType: "FAX_"+i, contactValue: practiceFax })

            // }

            // let contactKey = "contact"
            // practiceLocationJson[contactKey] = contact;

            // let queryParams = ''
             var prescriberId  = $("#prescriberName").val();

            axios.post('/agentProfile/saveAgentPracticeLocation?agentId='+this.props.agentId+"&prescriberId="+prescriberId,practiceLocationJson)
            .then(response => {
                window.Materialize.toast(msg, 3000,  'green rounded')
                // this.getPracticeLocationData();
                //     $('#locationModalCancel').click();
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

        }
    }

    getCopyLocationList = (event) => {
         axios.get('/agentProfile/getCopyPracticeLocationList?agentId='+this.props.agentId)
            .then(response => {
                locationList = []
                formaattedPracticeLocation = []
                let practiceLocation = response.data
                  if(practiceLocation){
                    for(let i =0;i<practiceLocation.length;i++){
                       let innerJson = {"locationId":practiceLocation[i].id, "index":i,"prescriberId":practiceLocation[i].prescriberId,"prescriberName":practiceLocation[i].prescriberFullName,"facility":practiceLocation[i].facility,"address":practiceLocation[i].address_1+" "+practiceLocation[i].address_2+", "+practiceLocation[i].city+", "+practiceLocation[i].state+" "+practiceLocation[i].postal_code+", "+practiceLocation[i].country_name}
                       formaattedPracticeLocation.push(innerJson);
                    }
                }
                this.setState({isUpdate:true})
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

    }

   setAction = (cell,row) =>{
        let unqiueData = "changeAgent_"+row.locationId
        return(
             <div className="actions-list-div">
                <input type="checkbox" onClick={(event) => this.selectedPrivilegesListener(event,row)} id={unqiueData} name={unqiueData} className="form-control" />
                <label htmlFor={unqiueData}></label>
            </div>
        )
    }
    selectedPrivilegesListener = (event,row) =>{

        if(event.target.checked){
            locationList.push(row.locationId)
        }else{
            let removedIndex = locationList.findIndex(e=>e == row.locationId);
            if (removedIndex > -1){
                locationList.splice(removedIndex,1);
            }
        }
    }

    saveCopyAgentLocation = () =>{

        if (locationList.length > 0){
            axios.post('/agentProfile/saveCopyAgentPracticeLocation?locationId='+locationList)
            .then(response => {
                this.setState({isUpdate:true})
                $('#cancelCopyLoaction').click();
                 window.Materialize.toast(Constant.COPIED_PRESCRIBER_MESSAGE, 3000,  'green rounded')
                this.props.getAgentProfileData();

            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });
        }else{
            window.Materialize.toast(Constant.MANDATORY_LOCATION_MESSAGE, 3000,  'red rounded')
        }

    }

    locationData(){

            return (
                    <BootstrapTable className="responsive-table max-768" data={formaattedPracticeLocation}  striped bordered={ false }>
                    <TableHeaderColumn dataSort={false} dataFormat={this.setAction} dataField='locationId' ><span>Select </span></TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} isKey dataField='facility'><span>Practice Name </span></TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} dataField='prescriberName'><span>Prescriber Name </span></TableHeaderColumn>
                    <TableHeaderColumn dataSort={true} tdStyle={ { whiteSpace: 'normal' } } thStyle={ { whiteSpace: 'normal' } } dataField='address' ><span>Address </span></TableHeaderColumn>
                </BootstrapTable>

            )

    }

    copyPracticeModel(){
        return(
                <div id="copyLocationModal" className="modal fade" role="dialog" >
                    <div className="modal-dialog max1280">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{'Copy Practice Location'}</h4>
                            </div>
                            <div className="modal-body">

                                {this.locationData()}
                            </div>
                            <div className="modal-footer">
                                <button id="copyPracticeLocation" className="btn btn-primary wizard-next btn-darkpurpale"  onClick={this.saveCopyAgentLocation.bind(this)} >{"Save"} </button>
                                <button type="button" id="cancelCopyLoaction" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>

                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    deleteLocationModel(){
        return(
            <div id="deleteLocationModal" className="modal fade" role="dialog" >
               <div className="modal-dialog v-center max550">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete Prescriber Practice Location</h4>
                       </div>
                       <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    <p>Are you sure! You want to Delete Prescriber Practice Location ?</p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">

                       <Button id="deletePracticeLoc" btnName="Ok" className="btn btn-primary wizard-next btn-purpale" click={this.removeLocationListener.bind(this)} secondParams={null} />
                       <button type="button"  id="deleteLocationModalCancel" className="btn btn-primary wizard-next  btn-dark" data-dismiss="modal">Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }

    setActionIconData = (cell,row) => {
        let styleClass = { display :'none'}
        styleClass.display = 'block inline';


        return (
                <div className='actions-list-div'>
                 <ReactTooltip place="top" html={true}/>
                    <a href='#'  onClick={(event) => this.setRemoveLocation(event,row)}  data-toggle="modal"  data-target="#deleteLocationModal"  style={styleClass}  data-tip="Delete"  >
                    <i className="fa fa-trash"></i>
                    </a>
                </div>
            )
    }

    setRemoveLocation = (event,row) =>{
       this.setState({removeLocId:row.id});
    }

     removeLocationListener = (event) =>{

        let id = this.state.removeLocId;
        axios.delete('/agentProfile/deletePracticeLocation?id='+id)
            .then(response => {
                 this.setState({
                    isLoading:true
                })
                $("#deleteLocationModalCancel").click();
                this.props.getAgentProfileData();
            })
            .catch(function (error) {
                console.log("Exception occur"+error)
            });

     }

     practiceModel(){
        //  style={styleClass} let styleClass = { width :'90%'}
        return(
             <div id="addLocationModal" className="modal fade" role="dialog" >
                <div className="modal-dialog max960">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{this.state.fromEdit ?'Edit Practice Location':'Add Practice Location'}</h4>
                        </div>
                        <div className="modal-body">

                            {this.createUI()}
                        </div>
                        <div className="modal-footer">
                        <section className="buttonWrapper">
                        <button id="submitPracticeLocation" className="btn btn-primary wizard-next btn-darkpurpale"  onClick={this.addPracticeLocation.bind(this)}>{"Save"} </button>
                        {/* <Button id="submitPracticeLocation" btnName="Save" className="btn btn-primary wizard-next btn-darkpurpale"  disabled={this.state.fromEdit?this.state.isSuccess1:this.state.isSuccess} click={this.addPracticeLocation.bind(this)} secondParams={null} /> */}
                            <button type="button" id="locationModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal">Cancel</button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createUI(){

        let stateMasterData = []
        if(this.props.stateMaster){
           stateMasterData = this.props.stateMaster.map(state =>{
                 return <option value={state.state} key={state.state} >{state.state_full_name}</option>
           });
        }

        let prescriberData = []
         if(this.props.prescriber){
             prescriberData =  this.props.prescriber.map(prescriberData =>{
                 return <option value={prescriberData.prescriberId} key={prescriberData.prescriberId} >{prescriberData.prescriberFullName}</option>
            });
         }



        return (

            <div key="key" id="practiceDiv" className='row'>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.prescriberNameValid?'validate invalid form-control':'validate form-control'} value={this.state.prescriberNameValues} name="Prescriber Name" id="prescriberName" type="select"   onChange={this.handleChange.bind(this)} label="Prescriber Name*" >
                        <option value="" >--Select--</option>
                        {prescriberData}
                    </Input>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <input name="index" id="index" type="hidden" />
                    {/*<input name="indexOfOutter" id="indexOfOutter" type="hidden" />*/}
                    <label className="practiceNameClass">
                        Practice Name
                    </label>
                    <input s={12} className="form-control"  name=" Practice Name" id="practiceName" type="text" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <label className="addressOneClass">
                        Address1*
                    </label>
                    <input s={12}   name="addresss1" id="addresss1" type="text" className = {this.state.addPracticeLocationValidate.addresss1Valid?'validate invalid form-control': 'validate form-control'}  />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="input-field">
                    <label className="addressTwoClass" >
                    Address2
                    </label>
                    <input s={12} className="form-control" name="addresss2" id="addresss2" type="text" />
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.stateValid?'validate invalid form-control':'validate form-control'}  name="state" id="state" type="select"  value={this.state.stateValues} onChange={this.handleChange.bind(this)}   label=" State*" >
                        <option value="" >--Select--</option>
                        {stateMasterData}
                    </Input>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                <div className="max-height-drop">
                    <Input s={12} className={this.state.addPracticeLocationValidate.cityValid?'validate invalid form-control':'validate form-control'}  name="city" id="city" type="select" value={this.state.cityValues}  onChange={this.handleChange.bind(this)} label="City*" >
                        <option value="" >--Select--</option>
                        {cityMaster ? cityMaster.map(city =>{
                                return <option value={city} key={city} >{city}</option>
                        }) : <option value="" >--Select--</option>}

                    </Input>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="max-height-drop">
                        <Input s={12} className={this.state.addPracticeLocationValidate.ZipValid?'validate invalid form-control':'validate form-control'}  value={this.state.zipValues} onChange={this.handleChange.bind(this)}  name="zip" id="zip" type="select" label=" Zip*" >
                            <option value="" >--Select--</option>
                            {zipMaster ? zipMaster.map(zip =>{
                                        return <option value={zip} key={zip} >{zip}</option>
                            }):<option value="" >--Select--</option>}
                        </Input>
                    </div>
                </div>

            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input name="index" id="index" type="hidden" />
                        <label htmlFor={"practicePhone_1"} className={'practicePhoneOneClass'}>
                            Phone 1 *
                        </label>
                        <input type="hidden" name="practicePhoneTempId_1" id="practicePhoneTempId_1"/>
                        <input type="hidden" name="practicePhoneFromApi_1" id="practicePhoneFromApi_1"/>
                        <CustomMaskedInput  intId="practicePhone_1" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}  fromApi ={false} intClassName={this.state.addPracticeLocationValidate.phoneOneValid?'validate invalid form-control':'validate form-control'} required  intName={"practicePhone_1"} />

                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <label className={'practiceFaxOneClass'}>
                            Fax 1 *
                        </label>
                        <input type="hidden" name="practiceFaxTempId_1" id="practiceFaxTempId_1"/>
                        <input type="hidden" name="practiceFaxFromApi_1" id="practiceFaxFromApi_1"/>
                        <CustomMaskedInput   intId="practiceFax_1" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}  fromApi ={false} intClassName={this.state.addPracticeLocationValidate.faxOneValid?'validate invalid form-control':'validate form-control'} required  intName={"practiceFax_1"} />
                        </div>
                    </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input type="hidden" name="practicePhoneTempId_2" id="practicePhoneTempId_2"/>
                        <input type="hidden" name="practicePhoneFromApi_2" id="practicePhoneFromApi_2"/>
                        <label className={'practicePhoneTwoClass'}>
                            Phone 2
                        </label>
                        <CustomMaskedInput    intId="practicePhone_2" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required  intName={"practicePhone_2"} />
                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <input type="hidden" name="practiceFaxTempId_2" id="practiceFaxTempId_2"/>
                        <input type="hidden" name="practiceFaxFromApi_2" id="practiceFaxFromApi_2"/>
                        <label className={'practiceFaxTwoClass'}>
                            Fax 2
                        </label>
                        <CustomMaskedInput  intId="practiceFax_2" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required intName={"practiceFax_2"} />
                        </div>
                    </div>

                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                     <input type="hidden" name="practicePhoneTempId_3" id="practicePhoneTempId_3"/>
                     <input type="hidden" name="practicePhoneFromApi_3" id="practicePhoneFromApi_3"/>
                        <label className={'practicePhoneThreeClass'}>
                            Phone 3
                        </label>
                        <CustomMaskedInput intId="practicePhone_3" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}   fromApi ={false} intClassName={'form-control'} required intName={"practicePhone_3"} />
                    </div>
                    </div>
                <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3">
                    <div className="input-field">
                        <label className={'practicePhoneThreeClass'}>
                            Fax 3
                        </label>
                        <input type="hidden" name="practiceFaxFromApi_3" id="practiceFaxFromApi_3"/>
                        <input type="hidden" name="practiceFaxTempId_3" id="practiceFaxTempId_3"/>
                        <CustomMaskedInput intId="practiceFax_3" intType="text"  intMask={Constant.REGEX_NUMBER_MASK}    fromApi ={false} intClassName={'form-control'} required  intName={"practiceFax_3"} />
                        </div>
                    </div>
            </div>
          </div>
        )
    }


    render() {
        return(
            <div>
                <div className="mt20">
                        <div className="tabSectionTitle clearfix">
                            <div className="col-xs-12">
                                <h3 className="pull-left">Location Information</h3>
                                {/*<div className="pull-right">
                                    <a href='#' id="addModalId" onClick={this.addPracticeLocationListener} data-toggle="modal" data-target="#addLocationModal" data-tip="Add Location"  data-place="left">
                                        <button className="btn btn-primary wizard-next green" type="button">Add Location</button>
                                    </a>
                                    <ReactTooltip/>
                                </div>*/}
                                <div className="pull-right">
                                    <a href='#' id="copyodalId" onClick={this.getCopyLocationList} data-toggle="modal" data-target="#copyLocationModal" >
                                        <button className="btn btn-primary wizard-next green" type="button">Copy Practice Location</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable data={this.props.agentPracticeLocation} striped bordered={false}>
                            <TableHeaderColumn dataField='facility'>Practice Name</TableHeaderColumn>
                            <TableHeaderColumn isKey dataField='prescriberFullName'>Prescriber</TableHeaderColumn>
                            <TableHeaderColumn dataField='address' >Address</TableHeaderColumn>
                            <TableHeaderColumn dataField='contact' >Contact</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataFormat={this.setActionIconData} width="15%"><span>Actions </span></TableHeaderColumn>


                        </BootstrapTable>

                    </div>
                   {this.practiceModel()}
                   {this.copyPracticeModel()}
                   {this.deleteLocationModel()}

              </div>
        )
    }
}