import React, { Component } from 'react';
import axios from 'axios';
import * as Constant from '../../Component/CustomUI/Constant';
import $ from 'jquery';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import UserProfile from '../../UserProfile';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

let Path ="";
let file_id = '';
let mainType = '';
let tag = '';
let flag = 0;
let dropdownData=[{ value: "EnrollmentForms", label: "Manufacturer Enrollment Forms", description:"We understand the challenges of navigating through utilization management imposed by PBMs or health plans for medications your patient needs. KloudScript will work with manufacturers to ensure all patients receive access to affordable medications."},
{ value: "priorAuthorization", label: "Prior Authorization Guidelines", description:"We understand the challenges of navigating through utilization management imposed by the PBMs or health plans for medications your patients need. The guidelines below for each disease state will help ensure all necessary clinical information is provided along with the referral to expedite the decision on prior authorizations. Send your referrals with outlined statement of medical necessity evidence and feel confident that your patients care will be coordinated with minimal administrative burden on your office staff!"},
{ value: "EnrollmentForms_KS", label: "KloudScript Enrollment Forms", description:"Our referral forms make it easy for you to send prescriptions to any of the KloudScript’s network of pharmacy partners. Simply download the form, fill-in appropriate referral information and fax it in."}];

class ViewForms extends Component {
    
    state = {
        isAsthma:false,
        data:[],
        filedata:[],
        srcUrl:'',
        isOpenModel: false,        
    }
    data = (event,row) =>{    
          let rows = JSON.parse(row)               
          axios.get('/status/getFileData?mainType='+ mainType +"&subType="+rows.type)
          .then(response => {
              if(response.data){
                    let datatest=[]
                    response.data.map(item=>{
                    let filedata = {"id":item[0],"file_name":item[1],"original_file_name":item[2],"file_type":item[3]}
                    datatest.push(filedata)
                })
                this.setState({filedata:datatest})                
              }                             
          })
          .catch(function (error) {
              console.log(error);
          });

    }
    download = (id) =>{
        window.location = Constant.DOWNLOAD_MD_FORMS+'?id='+ id;
    } 
   
    removeItem = (index) => {       
       let dataWithRemoveElement = this.state.filedata.filter(item=>{
           return item.id!=index?this.state.filedata:false
       })           
       this.setState({filedata:dataWithRemoveElement})              
       $("#removeFileModal").removeClass("in");
       $("#removeFileModal").addClass("hidden");  
       $(".modal-backdrop").addClass("hidden");               
      }
      remove = (id) => {         
        if(id !== undefined ){
            file_id = id?id:'';           
        }
        let msg = '';
        let dataLength = this.state.filedata ? this.state.filedata.length : '';
        if(dataLength === 1){
            msg = "   If you delete this last file then category will also remove."           
        }     
        $("#removeFileModal").removeClass("hidden");
        return(
            <div id="removeFileModal" className="modal fade" role="dialog" data-backdrop="static" data-keyboard={false}>
               <div className="modal-dialog v-center">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">Delete File</h4>
                       </div>
                       <div className="modal-body">
                            <div className="inner-page-container">
                                <div className="col-sm-12">
                                    <p>
                                    Are you sure! You want to delete a file ? {msg}
                                    </p>
                                </div>
                            </div>
                       </div>
                       <div className="modal-footer">
                       <section className="buttonWrapper">                           
                            <button type="button" id="removeFileModalOk" className="btn btn-primary wizard-next btn-darkpurpale"  data-dismiss="modal" onClick = {()=>this.removeFile()} >Ok</button>
                            <button type="button" id="removeFileModalCancel" className="btn btn-primary wizard-next btn-dark" data-dismiss="modal" >Cancel</button>
                       </section>
                        </div>
                   </div>
               </div>
           </div>
       )
    }  
    removeFile = () =>{
        
        if(file_id !== '' || file_id !== undefined || file_id !== null){          
            axios.get('/status/removeForm?id=' + file_id )
            .then(response => {
                if(response.data){                                                      
                    this.getPriorAuthorization();
                    this.removeItem(file_id);
                    file_id = "";
                    let isCategory = this.state.filedata ? this.state.filedata.length : '';                                              
                    $("#removeFileModalCancel").click();               
                    window.Materialize.toast(Constant.FILE_REMOVE, 5000,  'green rounded')        
                    if(isCategory === 0){
                        this.state.data.map((item1,index)=>{                     
                            $('#'+index).removeClass('active');      
                            $('#deleteModalCancel').click();    
                        })          
                        window.Materialize.toast(Constant.CATEGORY_REMOVE, 5000,  'green rounded');             
                    }          
                }else{
                    $("#removeFileModalCancel").click();
                    window.Materialize.toast(Constant.NOT_FILE_REMOVE, 5000,  'red rounded')                        
                }            
            })                                   
        } 

    }           
    componentDidMount(){        
        let url_string = window.location.href;
        let url = new URL(url_string);        
        mainType =url.searchParams.get("maintype")                    
            $(document).on("keydown",function(event){
                if(event.which===13 && $('#removeFileModal').hasClass('in')){                                            
                    $("#removeFileModalOk").click();               
                    $("#removeFileModalCancel").click();                                                   
                }          
            })            
            $('.pdf-title-box a').click(function() {
                $(this).toggleClass('active');
            });
            this.getPriorAuthorization();            
    }
    getPriorAuthorization(){        
        axios.get('/status/getFormData?mainType='+ mainType)
            .then(response => {
                if(response.data){   
                    let datatest=[]

                    response.data.map(item=>{
                    let data = {"type":item}
                    datatest.push(data)
                    })                 
                    this.setState({data:datatest});
                }                
            })
            .catch(function (error) {
                console.log(error);
            });            
    }
    setIcon = (cell, row) => {
        return (
                <div>
                    <a href='#viewPdf' data-toggle="modal" onClick={(event) => this.data(event,JSON.stringify(row))} data-toggle="modal" data-target="#viewpdf"  data-tip='View'>
                    <Tooltip placement="top" trigger={['hover']} overlay={<span>{row.type}</span>}><div>{row.type?row.type.length<=28?row.type:row.type.substring(0,28-3)+'...':''}</div></Tooltip>
                    </a>
                </div>
            );
    }
     render(){
        $("#removeFileModal").removeClass("in");            
        return (            
             <div>                
                <div className="v2-back-inner">
                    {this.remove()}
                 <div className="wrapper slide-nav-toggle">
                    { dropdownData.map(item =>{
                        if(item.value === mainType){
                            tag = item.label;
                        }
                    })}
                    <Header h2Tag={tag}/>
                    <main>
                        <div className="max-1500">
                            <div className="row">
                                <div className="res-title">
                                    <h3>                                        
                                        { dropdownData.map(item =>{
                                            return item.value === mainType?item.label:null
                                        })}
                                    </h3>
                                </div>
                                <div className="res-des">
                                    <p>
                                    { dropdownData.map(item =>{
                                            return item.value === mainType?item.description:null
                                        })}                                    
                                    </p>
                                </div>
                                
                            {this.state.data.length > 1 ?
                                <div className="table-outer-custom-grid table-outer-custom-ellipse">  
                                  <div className="table-cutom-grid">
                                    <BootstrapTable data={this.state.data} striped bordered={ true }  remote={true} >
			                            <TableHeaderColumn width="50%" className="col-hidden" isKey  dataSort={false} dataField="type" dataFormat={this.setIcon} > <span>Type</span></TableHeaderColumn>
                                    </BootstrapTable>
                                   </div> 
                                </div>:  
                                <div className="table-cutom-grid">
                                  <BootstrapTable data={this.state.data} striped bordered={ true }  remote={true} >
                                      <TableHeaderColumn width="50%" className="col-hidden" isKey  dataSort={false} dataField="type" dataFormat={this.setIcon} > <span>Type</span></TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                            }      
                                
                              
                            <div id="viewpdf" className="modal fade" role="dialog" >
                                    <div className="modal-dialog v-center" >
                                        <div className="modal-content">
                                            <div className="modal-header">                                            
                                            </div>
                                            <div className="modal-body">
                                                <Carousel showThumbs={false} showStatus={false} showIndicators={false} useKeyboardArrows>
                                                    {
                                                        this.state.filedata.length>0?
                                                        this.state.filedata.map(item=>
                                                        {return(
                                                        <div key={item.id}>
                                                            <div className="pdf-title">
                                                                <h4>
                                                                    <Tooltip placement="top" trigger={['hover']} overlay={<span>{item.original_file_name}</span>}><font color="white">{item.original_file_name?item.original_file_name.length<=53?item.original_file_name:item.original_file_name.substring(0,53-3)+'...':''} </font></Tooltip>
                                                                    {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER ?
                                                                    <a href="#" className="delete-icon" data-toggle="modal" data-tip='Remove' data-target="#removeFileModal" onClick={()=>this.remove(item.id)}>
                                                                        <Tooltip placement="top" trigger={['hover']} overlay={<span>Remove</span>}><i className="fa fa-trash" aria-hidden="true"></i></Tooltip>
                                                                    </a> : null}
                                                                    <a href="javascript:void(0)" className="floatRight">
                                                                        <Tooltip placement="top" trigger={['hover']} overlay={<span>Download</span>}><i className="fa fa-download" data-tip='Download' aria-hidden="true" onClick={()=>this.download(item.id)}></i></Tooltip>
                                                                    </a>   
                                                                </h4>
                                                            </div>
                                                            {item.file_type==="application/pdf"?<iframe src={Constant.VIEW_MD_FORMS +'?id='+item.id} height="350px"></iframe>:<h4><center><font color="white">Document is not a PDF </font></center></h4>}
                                                            <img/>
                                                        </div>
                                                        )}):null
                                                    }
                                                </Carousel>
                                            </div>
                                            <div className="modal-footer">
                                            <section className="buttonWrapper">

                                            <button type="button" id="deleteModalCancel" className="btn btn-primary wizard-next btn-dark " data-dismiss="modal">Close</button>
                                            </section>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>                      
                        </div>
                </main>
                <Footer />
            </div>
        </div>
    </div>
         );
     }
}

export default ViewForms;