import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn,ExportCSVButton } from 'react-bootstrap-table';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip'

let fileURL;

class ModalTable extends Component {

  setIcon = (cell,row) => {
		return (
			<div className="actions-list-div">
      <ReactTooltip />
				<a href="#" className='tooltipped' data-tip="View" data-position="top" onClick={(event)=>this.viewDocument(event,row)}>
				<i className="themify-icons ti-eye"></i></a>
				<a href="#" className="tooltipped" data-tip="Delete" data-position="top" onClick={(event)=>this.props.removeDocument(event,row)}>
				<i className="themify-icons ti-trash"></i></a>
			</div>
		);
	}

  viewDocument = (event,row) => {
    let documentFiles = this.props.documentFiles;
    let innerFile;
    for(let i = 0; i < documentFiles.length; i++) {
      if(row.fileName === documentFiles[i].documentNameTemp) {
        innerFile = documentFiles[i].filesTemp[0];
        break;
      }
    }
    fileURL = URL.createObjectURL(innerFile);
    window.open(fileURL,'View Document','toolbar=0')
  }


  render() {

    let documentFiles = this.props.documentFiles?this.props.documentFiles:''
    const data=[]
    if(this.props.documentFiles[0] != null){
      for(var i = 0;i<documentFiles.length;i++){
          let innerdata= {id:i,fileName:documentFiles[i].documentNameTemp,description:documentFiles[i].descriptionTemp}
          data.push(innerdata)
       }
    }

    return (
      <div className="table-max-height-200">
        <BootstrapTable data={this.props.clicked?data:[]} dataField='id1'  striped bordered={ true }>
            <TableHeaderColumn dataSort={true} isKey dataField='fileName'>Document Type</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='description'>Description</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.setIcon}>Actions</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}


class Table extends Component {

  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    // console.log('This is my custom function for ExportCSVButton click event');
    onClick();
  }
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        btnText='Export'
        onClick={ () => this.handleExportCSVButtonClick(onClick) }/>
    );
  }

  setStatusReason = (fieldValue) => {
    return (
      <div>
          <span className='badge complete'>{fieldValue}</span>
      </div>
          );
  }

  setStatusDrug = (fieldValue) => {
    // console.log('This is my custom function for ExportCSVButton click event');
    return (

      <div>
          <span className='badge complete'>{fieldValue}</span>
      </div>
          );

  }

  render() {
    return (
      <div>
      <div className="table-responsive table-height">
        <BootstrapTable  data={this.props.data} striped bordered={ true }>
            <TableHeaderColumn dataFormat={this.props.setIcon}>Select</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} isKey dataField='caseId'>Case Id</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='patientName'>Patient Name</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='dateOfBirth'>Date of Birth</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='therapeuticCategoryName'>Therapeutic Program</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='drug'>Drug</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='organizationName'>Pharmacy</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='prescriber'>Prescriber</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='insurance'>Insurance</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataFormat={this.setStatusReason} dataField='followUpStatus'>Referral Staus &amp; Reason</TableHeaderColumn>
        </BootstrapTable>
        </div>

      </div>
    );
  }
}

class HistoryTable extends Component {

  constructor(props) {
    super(props);

    this.options = {
      onPageChange: this.props.onPageChange,
      onSortChange: this.props.onSortChange,
      sizePerPage: 100,
      sizePerPageList: [ 100,50,25 ]
    };

    this.state = {
        data: [],
    }
  }

   render(){
    //  console.log(this.props.historyDashBoardData)
     let dashBoardData = this.props.historyDashBoardData?this.props.historyDashBoardData:''
     let data = []
     for(var i=0;i<dashBoardData.length;i++){
       let innerData = {firstName:dashBoardData[i].firstName,lastName:dashBoardData[i].lastName,dateOfBirth:dashBoardData[i].dateOfBirth,physicianHistory:dashBoardData[i].Physician,subTypeHistory:dashBoardData[i].submissionType}
       data.push(innerData);

     }

     return (
       <div>
       <div className="table-responsive max-width-768">
         <BootstrapTable  data={data} striped bordered={ true }>
            <TableHeaderColumn  dataFormat={this.props.setIcon}>Select</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} isKey dataField='caseid'>Case Id</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='patient'>Patient</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='dob'>Date of Birth</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='uploadedby'>Uploaded By</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='uploadeddate'>Uploaded Date</TableHeaderColumn>
        </BootstrapTable>
        </div>
       </div>
     );
   }
}

class TableAgent extends Component {

   checkBoxListner = (cell,row) =>{
    return(
      <div className="actions-list-div">

        <button name="npiSelection"  onClick={(event) => this.props.changePrescriberListner(event,row,this.props.isSelected)}>
        {/* {this.props.isSelected?<i className="themify-icons ti-close" aria-hidden="true" data-tip="Delete"></i>
        :<i className="themify-icons ti-plus" aria-hidden="true" data-tip="Add"></i>
        } */}
   <OverlayTrigger placement="top" overlay={<Tooltip id={String(row.id)}>{this.props.isSelected?"Delete":"Add"}</Tooltip>}><span>{this.props.isSelected?<i className="themify-icons ti-close" aria-hidden="true" data-tip="Delete"></i>:<i className="themify-icons ti-plus" aria-hidden="true" data-tip="Add"></i>}</span></OverlayTrigger>
        </button>
      </div>
    )
  }

  tooltipFormatter(cell, row){
      return (<OverlayTrigger placement="left" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
  };


  render() {
    return (
      <div>
      <div className="responsive-table">
        <BootstrapTable data={this.props.data} striped bordered={ true }>
            <TableHeaderColumn dataField='index'  dataFormat={this.checkBoxListner} width="10%"> <span>Select </span></TableHeaderColumn>
            <TableHeaderColumn  isKey dataField='NPI' width="10%"><span>NPI </span></TableHeaderColumn>
            <TableHeaderColumn dataField='NPIStatus' width="10%"><span>NPI Status </span></TableHeaderColumn>
            <TableHeaderColumn dataField='PrescriberName' width="15%"><span>Prescriber Name </span></TableHeaderColumn>
            <TableHeaderColumn dataField='FacilityName' width="15%"><span>Facility Name </span></TableHeaderColumn>
            <TableHeaderColumn dataField='Speciality' dataFormat={this.tooltipFormatter} width="15%"><span>Specialty</span></TableHeaderColumn>
            <TableHeaderColumn dataField='WorkPhone' width="10%"><span>Work Phone </span></TableHeaderColumn>
            <TableHeaderColumn dataField='Fax' width="10%"><span>Fax </span></TableHeaderColumn>
        </BootstrapTable>
        </div>
      </div>
    );
  }
}

// dataFormat={this.tooltipFormatter}
// <span data-tip="Type">Type </span> <ReactTooltip place="top" html={true}/>
export {Table,TableAgent,ModalTable,HistoryTable};