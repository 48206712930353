var EncryptDecrypt = (function() {

  var CryptoJS = require("crypto-js");
  var keySize = 256;
  var iterations = 100;

  var encrypt = function(dataToEncrypt) {
    var encryptedText = "";
    if(dataToEncrypt != null){
      var salt = CryptoJS.lib.WordArray.random(128/8);
  
      var key = CryptoJS.PBKDF2(process.env.REACT_APP_SECRET_KEY, salt, {
          keySize: keySize/32,
          iterations: iterations
        });

      var iv = CryptoJS.lib.WordArray.random(128/8);
      
      var encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, { 
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });
      
      // salt, iv will be hex 32 in length
      // append them to the ciphertext for use  in decryption
      encryptedText = salt.toString()+ iv.toString() + encrypted.toString();
    }
    return encryptedText;
  };

  var decrypt = function(dataToDecrypt) {
    var decryptedText = "";
    if(dataToDecrypt != null){

      var salt = CryptoJS.enc.Hex.parse(dataToDecrypt.substr(0, 32));
      var iv = CryptoJS.enc.Hex.parse(dataToDecrypt.substr(32, 32))
      var encrypted = dataToDecrypt.substring(64);
      
      var key = CryptoJS.PBKDF2(process.env.REACT_APP_SECRET_KEY, salt, {
          keySize: keySize/32,
          iterations: iterations
        });

      var bytes = CryptoJS.AES.decrypt(encrypted, key, { 
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
        
      })

      decryptedText = bytes.toString(CryptoJS.enc.Utf8); 
    }
    return decryptedText;
  };

  return {
    encrypt: encrypt,
    decrypt: decrypt,
  }

})();

export default EncryptDecrypt;