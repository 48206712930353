import React, { Component } from 'react';
 import {Input} from 'react-materialize';
import CustomInput from '../../CustomUI/Input';
import * as Constant from '../../CustomUI/Constant';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import ReactTooltip from 'react-tooltip'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import UserProfile from '../../../../src/UserProfile';
import $ from 'jquery';

class Agent extends Component {

    tooltipFormatter(cell, row){
        return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
    };
    render() {
        $(".specialCharRestriction").on('keypress keyup blur', function (event) {
            // var regex = new RegExp("^[#%^&{}[\\]]+$");
            var regex = new RegExp("^[#%^&{}[\\]]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (regex.test(key)) {
                event.preventDefault();
                return false;
            }
         });
         //specific characters allowed ,alphabets allowed,digit allowed
         $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._']+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
        let stepFourAgentValidate ={...this.props.stepFourAgentValidate}
        return (
                <div className="agengtDetails max-height-drop">
                  {this.props.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                    <div className="row">
                    {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER ||  UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN?
                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                            <div className="input-field">


									<CustomInput intplaceholder={Constant.LABEL_USER_NAME+Constant.STAR} intid="userNameloginstep4" intType="text" intClasssName={stepFourAgentValidate.userNameValid || stepFourAgentValidate.duplicateUserNameValid? 'validate invalid specialCharRestriction alphanumeric noSpace': 'validate specialCharRestriction alphanumeric noSpace'}  fromApi={false} intName={Constant.INPUT_USER_NAME}  change={this.props.change} intStep={Constant.STEP_FOUR_AGENT } required  />
									{/*<Label  lblhtmlFor="userNamelogin" lblclassName={'active'} lbldataError={stepFourAgentValidate.userNameValid ? "Please Enter UserName": stepFourAgentValidate.duplicateUserNameValid?"UserName is Already Exist": null}   lblname={Constant.LABEL_USER_NAME+Constant.STAR}/>*/}
                            </div>
                        </div>:''}

                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                    <Input s={12} m={12} l={12} xl={12} type='select' required id="prefix" label={Constant.LABEL_PREFIX} name={Constant.INPUT_PREFIX} onChange={(event)=>this.props.change(event,Constant.STEP_FOUR_AGENT)}>
                            <option value="" >--Select--</option>
                                <option value={Constant.PREFIX_VALUE_DR}>{Constant.PREFIX_NAME_DR}</option>
								<option value={Constant.PREFIX_VALUE_MISS}>{Constant.PREFIX_NAME_MISS}</option>
								<option value={Constant.PREFIX_VALUE_MR}>{Constant.PREFIX_NAME_MR}</option>
								<option value={Constant.PREFIX_VALUE_MRS}>{Constant.PREFIX_NAME_MRS}</option>
								<option value={Constant.PREFIX_VALUE_MS}>{Constant.PREFIX_NAME_MS}</option>
								<option value={Constant.PREFIX_VALUE_PROF}>{Constant.PREFIX_NAME_PROF}</option>
                    </Input>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                      <div className="input-field">
                               <CustomInput intid="fNameAboutAgent" intplaceholder={Constant.LABEL_FIRST_NAME + Constant.STAR} intType="text" intClasssName={stepFourAgentValidate.firstNameValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" change={this.props.change} intStep={Constant.STEP_FOUR_AGENT } maxlength="50"/>
                            {/*<Label lblhtmlFor="fNameAboutAgent" lblclassName = {(this.props.StepFourAgentData.firstName || stepFourAgentValidate.firstNameValid)? 'active' : null} lbldataError={stepFourAgentValidate.firstNameValid ? Constant.FIRST_NAME : null} lblname={Constant.LABEL_FIRST_NAME + Constant.STAR}/>*/}
						</div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                        <div className="input-field">
                            <CustomInput intid="mNameAboutAgent" intplaceholder={Constant.LABEL_MIDDLE_NAME} intType="text"  intName={Constant.INPUT_MIDDLE_NAME}  change={this.props.change} intStep={Constant.STEP_FOUR_AGENT } maxlength="50"/>
                          {/*  <Label lblhtmlFor="mNameAboutAgent" lblname={Constant.LABEL_MIDDLE_NAME}/>*/}
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5" >
                    <div className="input-field">
						<CustomInput intid="lNameAboutAgent" intplaceholder={Constant.LABEL_LAST_NAME + Constant.STAR} intType="text" intClasssName={stepFourAgentValidate.lastNameValid? 'validate invalid': 'validate'}required  intName={Constant.INPUT_LAST_NAME } change={this.props.change} intStep={Constant.STEP_FOUR_AGENT } maxlength="50"/>
                        {/*<Label lblhtmlFor="lNameAboutAgent" lblclassName={this.props.StepFourAgentData.lastName || stepFourAgentValidate.lastNameValid? 'active': null} lbldataError={stepFourAgentValidate.lastNameValid ? Constant.LAST_NAME: null} lblname={Constant.LABEL_LAST_NAME + Constant.STAR}/>*/}
						</div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                         <div className="input-field">
                            <CustomInput intid="emailAboutAgent" intplaceholder={Constant.LABEL_EMAIL + Constant.STAR} intType="text" intClasssName={stepFourAgentValidate.emailValid? 'validate invalid': 'validate'}  required  intName={Constant.INPUT_EMAIL } change={this.props.change} intStep={Constant.STEP_FOUR_AGENT }/>
                             {/*<Label lblhtmlFor="emailAboutAgent" lblclassName={(this.props.StepFourAgentData.email || stepFourAgentValidate.emailValid)? 'active': null} lbldataError={stepFourAgentValidate.emailValid ?Constant.EMAIL: null} lblname={Constant.LABEL_EMAIL + Constant.STAR}/>*/}

						</div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3 col-lg-3 pl5 pr5">
                        <div className="input-field">
                            <CustomInput intid="confirmEmailAboutAgent" intplaceholder={Constant.LABEL_CONFIRM_EMAIL + Constant.STAR} intType="text" intClasssName={stepFourAgentValidate.confEmailValid? 'validate invalid': 'validate'} required  intName={Constant.INPUT_CONFIRM_EMAIL } change={this.props.change} intStep={Constant.STEP_FOUR_AGENT }/>
                            {/*<Label lblhtmlFor="confirmEmailAboutAgent" lblclassName={this.props.StepFourAgentData.confirmEmail || stepFourAgentValidate.confEmailValid? 'active': null} lbldataError={stepFourAgentValidate.confEmailValid?Constant.CONFIRM_EMAIL: null} lblname={Constant.LABEL_CONFIRM_EMAIL + Constant.STAR}/>*/}
						</div>
                    </div>
                    </div>
                    <div className="col-sm-12 pl5 pr5">
                    <BootstrapTable data={this.props.selectedPrescriberJson} striped bordered={ true } className="tabel-height">
						<TableHeaderColumn dataSort={true} dataField='PrescriberName' dataFormat={this.tooltipFormatter}><span data-tip="Prescriber">Prescriber</span><ReactTooltip place="top" html={true}/></TableHeaderColumn>
						<TableHeaderColumn dataSort={true} isKey dataField='NPI' dataFormat={this.tooltipFormatter}> <span data-tip="NPI">NPI</span><ReactTooltip place="top" html={true}/></TableHeaderColumn>
                    </BootstrapTable>
                    </div>
                </div>
        );
    }
}
export default Agent;