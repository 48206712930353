import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip'
import UserProfile from '../../../UserProfile';
import * as Constant from '../../CustomUI/Constant';

class DynamicTable extends Component {

  constructor(props) {
    super(props);

    this.options = {
      onPageChange: this.props.onPageChange,
      onSortChange: this.props.onSortChange,
      sizePerPage: this.props.sizePerPage,
      sizePerPageList: [ 100,50,25 ],
      paginationPosition: 'bottom',
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last',
      page:this.props.page
    };

    this.state = {
        data: [],
        defaultDataCount:0,
        totalDataCount:0
    }
  }

  setStatusReason = (fieldValue) => {
    // var followUpStatus = (fieldValue != null && fieldValue.toString().indexOf("<br>") > -1) ? fieldValue.toString().split("<br>")[0].toString().trim() : "";
    // var followUpReason = (fieldValue != null && fieldValue.toString().indexOf("<br>") > -1) ? fieldValue.toString().split("<br>")[1].toString().trim() : "";

    // return (
    //   <div>
    //        {followUpStatus != "" ?
    //         <div>
    //           <span className='badge statusgrid' data-tip={followUpStatus}>{followUpStatus}</span><ReactTooltip place="top" html={true}/>
    //         </div>
    //       : null}
    //       {followUpReason != "" ?
    //         <div>
    //           <br/>
    //           <span className='badge statusgrid' data-tip={followUpReason}>{followUpReason}</span><ReactTooltip place="top" html={true}/>
    //         </div>
    //       : null}
    //   </div>
    // );
    var followUp = [];
    if(fieldValue != null && fieldValue.toString().indexOf("<br>") > -1){
      followUp = fieldValue.split("<br>");
    }else if(fieldValue != null && fieldValue.toString().indexOf("<br>") === -1){
      followUp.push(fieldValue.trim());
    }

    function Item(props) {
      var classAssign = 'badge statusgrid';
      return (
      <div>
        {props.message != "" && props.message != null ?
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(fieldValue)}>{props.message}</Tooltip>}>
          <span className={classAssign} data-tip={props.message}>{props.message}</span></OverlayTrigger>
        : null }
        <br/>
      </div>)
      ;
    }

    return (
      <div>
          {fieldValue!=null ?
              followUp.map((message,index) => <Item key={index} message={message} />)
            : null }
      </div>
    );

  }

  setDrug = (cell, row) => {
    var drugs = row.drug;
    var drugRow = [];
    if(drugs != null && drugs.toString().indexOf(",") > -1){
      drugRow = drugs.split(",");
    }else if(drugs != null && drugs.toString().indexOf(",") === -1){
      drugRow.push(drugs.trim());
    }

    function Item(props) {
      var classAssign = 'badge statusgrid';
      var noClassAssign = 'badge';
      var drugName = "";
      if(props.message != null && props.message.toString().indexOf("---") > -1){
        drugName = props.message.split("---")[1].toString().trim()
      }else if(props.message != null && props.message.toString().indexOf("---") === -1){
        drugName = props.message
      }
      return (
      <div>
        {drugName.split("--")[1].toString().trim() != 0 ?
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{drugName.split("--")[0].toString().trim()}</Tooltip>}>
        <span className={classAssign} data-tip={drugName.split("--")[0].toString().trim()}>{drugName.split("--")[0].toString().trim()}</span></OverlayTrigger>
        :
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{drugName.split("--")[0].toString().trim()}</Tooltip>}>
        <span className={noClassAssign} data-tip={drugName.split("--")[0].toString().trim()}>{drugName.split("--")[0].toString().trim()}</span></OverlayTrigger>
        }
        <br/>
      </div>)
      ;
    }

    return (
      <div>
          {drugs!=null ?
              drugRow.map((message,index) => <Item key={index} message={message} />)
            : null }
      </div>
    );

  }

  setCase = (cell, row) => {
    var cases = row.rxCaseTc;
    var caseRow = [];
    if(cases != null && cases.toString().indexOf(",") > -1){
      caseRow = cases.split(",");
    }else if(cases != null && cases.toString().indexOf(",") === -1){
      caseRow.push(cases.trim());
    }

    function Item(props) {
      var classAssign = 'badge statusgrid';
      var noClassAssign = 'badge';
      var caseName = "";
      if(props.message != null && props.message.toString().indexOf("---") > -1){
        caseName = props.message.split("---")[1].toString().trim()
      }else if(props.message != null && props.message.toString().indexOf("---") === -1){
        caseName = props.message
      }
      return (
      <div>
        {caseName.split("--")[1].toString().trim() != "" ?
        <span className={classAssign} data-tip={caseName.split("--")[0].toString().trim()}>{caseName.split("--")[0].toString().trim()}</span>:
        <span className={noClassAssign} data-tip="Stand Alone Rx">Stand Alone Rx</span>
        }
        <br/>
      </div>)
      ;
    }

    return (
      <div>
          {cases!=null ?
              caseRow.map((message,index) => <Item key={index} message={message} />)
            : null }
      </div>
    );

  }

  setTc = (cell, row) => {
    var tcs = row.rxCaseTc;
    var tcRow = [];
    if(tcs != null && tcs.toString().indexOf(",") > -1){
      tcRow = tcs.split(",");
    }else if(tcs != null && tcs.toString().indexOf(",") === -1){
      tcRow.push(tcs.trim());
    }

    function Item(props) {
      var classAssign = 'badge statusgrid';
      var noClassAssign = 'badge';
      var tcName = "";
      if(props.message != null && props.message.toString().indexOf("---") > -1){
        tcName = props.message.split("---")[1].toString().trim()
      }else if(props.message != null && props.message.toString().indexOf("---") === -1){
        tcName = props.message
      }
      return (<div>
        {tcName.split("--")[1].toString().trim() != "" ?
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{tcName.split("--")[1].toString().trim()}</Tooltip>}>
        <span className={classAssign} data-tip={tcName.split("--")[1].toString().trim()}>{tcName.split("--")[1].toString().trim()}</span></OverlayTrigger>
        :<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>Stand Alone Rx</Tooltip>}>
        <span className={noClassAssign} data-tip="Stand Alone Rx">Stand Alone Rx</span></OverlayTrigger>}
        <br/>
             </div>);
    }

    return (
      <div>
          {tcs!=null ?
              tcRow.map((message,index) => <Item key={index} message={message} />)
            : null }
      </div>
    );

  }

  setPrescriberNPI = (cell, row) => {
    var prescribers = row.prescriber;
    var prescriberRow = [];
    if(prescribers != null && prescribers.toString().indexOf(",") > -1){
      prescriberRow = prescribers.split(",");
    }else if(prescribers != null && prescribers.toString().indexOf(",") === -1){
      prescriberRow.push(prescribers.trim());
    }

    function Item(props) {
      var classAssign = 'badge statusgrid';
      var noClassAssign = 'badge';
      var prescriberName = "";
      var npi = "";
      if(props.message != null && props.message.toString().indexOf("---") > -1){
        prescriberName = props.message.split("---")[1].toString().trim()
      }else if(props.message != null && props.message.toString().indexOf("---") === -1){
        prescriberName = props.message
      }
      prescriberName = (prescriberName != null && prescriberName.toString().indexOf("<br>") > -1) ? prescriberName.toString().split("<br>")[0].toString().trim() : "";
      npi = (prescriberName != null && prescriberName.toString().indexOf("<br>") > -1) ? prescriberName.toString().split("<br>")[1].toString().trim() : "";

      return (
      <div>
        {prescriberName.split("--")[1].toString().trim() != 0 ?
        <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{prescriberName.split("--")[0].toString().trim()}</Tooltip>}>
       <span className={classAssign} data-tip={prescriberName.split("--")[0].toString().trim()}>{prescriberName.split("--")[0].toString().trim()}</span>
       </OverlayTrigger>:
       <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{prescriberName.split("--")[0].toString().trim()}</Tooltip>}>
        <span className={noClassAssign} data-tip={prescriberName.split("--")[0].toString().trim()}>{prescriberName.split("--")[0].toString().trim()}</span>
        </OverlayTrigger>}
        {(UserProfile.getRole() !== Constant.LOGGED_IN_ROLE_PRESCRIBER && npi !== "") ?
            <div>
              <span className='badge statusgrid' data-tip={npi}>{npi}</span><ReactTooltip place="top" html={true}/>
            </div>
          : null}
        <br/>
      </div>)
      ;
    }
    return (
      <div>
          {prescribers!=null ?
              prescriberRow.map((message,index) => <Item key={index} message={message} />)
            : null }
      </div>
    );

  }
  setInsurance = (cell, row) => {
    var classAssign = 'badge statusgrid';
    var noClassAssign = 'badge';
    var insurance = (row.insurance != null && row.insurance.toString().indexOf(",") > -1) ? row.insurance.toString().split(",")[0].toString().trim() : row.insurance;
    insurance = (insurance != null && insurance.toString().indexOf("---") > -1) ? insurance.toString().split("---")[1].toString().trim() : "";
    var insuranceM = (row.insurance != null && row.insurance.toString().indexOf(",") > -1) ? row.insurance.toString().split(",")[1].toString().trim() : "";
    insuranceM = (insuranceM != null && insuranceM.toString().indexOf("---") > -1) ? insuranceM.toString().split("---")[1].toString().trim() : "";
    return (
      <div>
           {insurance !== "" && insurance !== null ?
            <div>
             <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{insurance.split("--")[0].toString().trim()}</Tooltip>}>
              <span className={insurance.split("--")[1].toString().trim() == 0 ? noClassAssign : classAssign} data-tip={insurance.split("--")[0].toString().trim()}>{insurance.split("--")[0].toString().trim()}</span>
             </OverlayTrigger>
            </div>
          : null}
          {insuranceM !== "" && insuranceM !== null ?
            <div>
              <hr/>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{insuranceM.split("--")[0].toString().trim()}</Tooltip>}>
              <span className={insuranceM.split("--")[1].toString().trim() == 0 ? noClassAssign : classAssign} data-tip={insuranceM.split("--")[0].toString().trim()}>{insuranceM.split("--")[0].toString().trim()}</span>
              </OverlayTrigger>
            </div>
          : null}
      </div>
    );


  }

  tooltipFormatter(cell, row){
    return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
};

  canUploadDocumentStatus() {

    if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
      return (<TableHeaderColumn width="10%" dataFormat={this.props.setIcon}><span data-tip="Actions">Actions </span> <ReactTooltip place="top" html={true}/></TableHeaderColumn>)
    }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
      return (<TableHeaderColumn width="10%" dataFormat={this.props.setIcon}><span data-tip="Actions">Actions </span> <ReactTooltip place="top" html={true}/></TableHeaderColumn>)
    }else {
      return (null)
    }

  }

  setReviewIcon = (cell, row) => {
    return (
            <div>
                <div className="actions-list-div">
                    <ReactTooltip place="top" html={true}/>
                    <a href='#' data-toggle="modal" data-tip='View'>
                        <i className="fa fa-eye"></i>
                    </a>
                </div>
            </div>
        );
  }

  render() {

    let isKETUUser = false;

    if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
      isKETUUser = true;
    }

    this.options = {onPageChange: this.props.onPageChange,onSortChange: this.props.onSortChange,sizePerPage: this.props.sizePerPage,sizePerPageList: [ 100,50,25 ],
      paginationPosition: 'bottom',
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last',
      page: this.props.page
    };

    var isSort = parseInt(this.props.dataCount) > 1 ? true : false;

    return (
      <div>
         {/* {this.props.isWQExportClicked?this.export():""} */}
         <div className="row">
          <div className="pull-left">
            <h4 className="count-number"><strong>Total:</strong> {parseInt(this.props.dataCount)}</h4>
            </div>

            {isKETUUser == false ?

              <div className="pull-right mb10">
                <button className="btn btn-darkpurpale " onClick={()=>this.props.setDashbordCount(1)}>Action Required {this.props.defaultDataCount===null?"":":  "+this.props.defaultDataCount}</button>
                <button className="btn btn-dark ml5"onClick={()=>this.props.setDashbordCount(0)}> All Patients    {this.props.totalDataCount===null?"":":  "+this.props.totalDataCount}</button>
              </div>

            : null}

         </div>
         <div className="responsive-table">
         <BootstrapTable data={this.props.data} striped bordered={ true }  pagination={this.props.isPaginationAllow}  options={this.options} remote={true} fetchInfo={{ dataTotalSize: parseInt(this.props.dataCount) }}>
			      <TableHeaderColumn width="10%" dataSort={isSort} dataField='caseId' dataFormat={this.setCase} > <span>Case<br/>Id</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={isSort} dataField='caseStatus' dataFormat={this.tooltipFormatter}> <span>Case<br/>Status</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={isSort} dataField='patientName' dataFormat={this.tooltipFormatter}> <span>Patient<br/>Name</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={isSort} dataField='dateOfBirth' dataFormat={this.tooltipFormatter}> <span>Date of<br/>Birth</span></TableHeaderColumn>
            <TableHeaderColumn width="12%" dataSort={isSort} dataField='therapeuticCategoryName' dataFormat={this.setTc}> <span>Therapeutic<br/>Program </span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataSort={false} isKey dataField='drug' dataFormat={this.setDrug}><span>PH Rx ID<br/>& Drug</span></TableHeaderColumn>
            <TableHeaderColumn width="12%" dataSort={isSort} dataField='organizationName' dataFormat={this.tooltipFormatter}> <span>Pharmacy</span> </TableHeaderColumn>
            <TableHeaderColumn width="12%" dataSort={isSort} dataFormat={this.setPrescriberNPI} dataField='prescriber'> <span>Prescriber</span></TableHeaderColumn>
            <TableHeaderColumn width="12%" dataSort={isSort} dataField='insurance' dataFormat={this.setInsurance}> <span>Insurance</span></TableHeaderColumn>
            <TableHeaderColumn width="12%" dataSort={isSort} dataFormat={this.setStatusReason} dataField='followUpStatus'><span>Referral Status<br/>& Reason</span></TableHeaderColumn>                        
            {this.canUploadDocumentStatus()}
        </BootstrapTable>
        </div>
      </div>
    );
  }
}

class DynamicHistoryTable extends Component {

  constructor(props) {
    super(props);

    this.options = {
      onPageChange: this.props.onPageChange,
      onSortChange: this.props.onSortChange,
      sizePerPage: this.props.sizePerPage,
      sizePerPageList: [ 100,50,25 ],
      paginationPosition: 'bottom',
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last',
      page:this.props.page
    };

  }

  viewDocument = (event,row) => {
    // console.log(row)
    let dashBoardData = this.props.historyDashBoardData?this.props.historyDashBoardData:''
    let innerFile;
    for(let i = 0; i < dashBoardData.length; i++) {
        innerFile = dashBoardData[i].mergerdocumentpath;
    }
   let fileURL = URL.createObjectURL(innerFile);
    window.open(fileURL,'View Document','toolbar=0')
  }


  tooltipFormatter(cell, row){
    return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
  };

   render(){
    var dateFormat = require('dateformat');
    this.options = {onPageChange: this.props.onPageChange,onSortChange: this.props.onSortChange,sizePerPage: this.props.sizePerPage,sizePerPageList: [ 100,50,25 ],
      paginationPosition: 'bottom',
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last',
      page: this.props.page
    };
     let dashBoardData = this.props.historyDashBoardData?this.props.historyDashBoardData:''
     let data = []
     for(var i=0;i<dashBoardData.length;i++){
       let innerData = {id:dashBoardData[i].id,docpath:dashBoardData[i].mergerdocumentpath,caseid:dashBoardData[i].caseid,patient:dashBoardData[i].patient,dob:dateFormat(dashBoardData[i].dob, "mm/dd/yyyy"),uploadedby:dashBoardData[i].uploadedby,uploadeddate:dateFormat(dashBoardData[i].uploadeddate, "mm/dd/yyyy h:MM:ss TT"),documentType:dashBoardData[i].documentType}
       data.push(innerData);
     }

     var isSort = parseInt(this.props.dataCount) > 1 ? true : false;

     return (
       <div >
         <div className="row">
          {/* <div className="col-md-12"> */}
            <h4 className="count-number"><strong> Total Count:</strong> {parseInt(this.props.dataCount)}</h4>
          {/* </div> */}
         </div>
         <div className="responsive-table">
         {/* {this.props.isHistoryExportClicked?this.export():""} */}
         <BootstrapTable  data={data} striped bordered={ true } pagination={this.props.isPaginationAllow }  options={this.options} remote={true} fetchInfo={{ dataTotalSize: parseInt(this.props.dataCount) }}>
            <TableHeaderColumn width="10%" dataSort={isSort} isKey dataField='caseid'><span>Case ID</span></TableHeaderColumn>
            <TableHeaderColumn width="15%" dataSort={isSort} dataField='patient'><span>Patient Name</span></TableHeaderColumn>
            <TableHeaderColumn width="15%" dataSort={isSort} dataField='uploadedby'><span>Uploaded By</span></TableHeaderColumn>
            <TableHeaderColumn width="20%" dataSort={isSort} dataField='uploadeddate'><span>Uploaded Date & Time</span></TableHeaderColumn>
            <TableHeaderColumn width="30%" dataField='documentType'><span>Document Type</span></TableHeaderColumn>
            <TableHeaderColumn width="10%" dataFormat={this.props.setHistoryIcon}><span>Actions</span></TableHeaderColumn>
        </BootstrapTable>
        </div>
       </div>
     );
   }
}

export {DynamicTable,DynamicHistoryTable};