import React from "react";
import Footer from "../../../src/Component/PageComponent/Footer/Footer";
import Header from "../../../src/Component/PageComponent/Header/Header";
export default abc => {
  return (
    <div className="v2-back-inner">
      <div className="wrapper slide-nav-toggle">
        <Header />
        <main>
          <div className="inner-page-container">
            <div className="v-center no-recod-found">
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              <h1>Access Denied </h1>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};
