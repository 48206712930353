import React, { Component } from 'react';
import { Input } from 'react-materialize';
import * as Constant from '../../../Component/CustomUI/Constant';

export default class LoginInfo extends Component {
    render(){
        return(
            <div>
                <Input xl={2} l={3} m={6} s={12} value="Test" label={Constant.LABEL_USER_NAME+Constant.STAR} intid="userNamelogin" intType="text" intClasssName={this.props.userNameValid? 'validate invalid':'validate '} intName={Constant.INPUT_USER_NAME} required />
                <Input xl={2} l={3} m={6} s={12} type='select' required id="userRole" label="User Role">
                    <option value="">--Select--</option>
                    <option value="">KS Business Super User</option>                    
                    <option value="">KS Technical Admin</option>                    
                </Input>
                <Input xl={2} l={3} m={6} s={12} type='select' required id="resetMethod" label="Reset Password Method">
                    <option value="">--Select--</option>
                    <option value="CALLBACK">Callback</option>
                    <option value="EMAIL">Email</option>
                    <option value="SMS">SMS</option>                    
                </Input>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2">
                    <div className="input-field suffix">
                        <input id="creatPass" type="text" disabled />
                        <label htmlFor="creatPass">Create &amp; show password</label>
                        <i className="fa fa-lock"></i>
                    </div>
                </div>                
            </div>
        )
    }
}