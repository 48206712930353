import FixSideBar from '../FixSideBar/FixSideBar';
import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'
import UserProfile from '../../../UserProfile';
import $ from 'jquery';
import * as Constant from '../../CustomUI/Constant';
import IdleTimerComponent  from '../IdlePage/idlePage';
import axios from 'axios';

let href = ""
class header extends Component {


    state = {
        login: false,
        changePassword: false,
        MyProfileAgent : false,
        pendingAgentCount : 0,
        isCopy:false,
        isSuccess:true

    }


    componentWillMount = () =>{
            if(UserProfile.getOfficeAdmin() || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
                axios.get('/prescriberProfile/getAgentPendingRequestCount/')
                .then(response => {
                    this.setState({ pendingAgentCount: response.data })

                })
                .catch(function (error) {
                    console.log(error);
                });

            }
    }

    copyToClipboard = (event) =>{
		var $temp = $("<input>");
	    $("body").append($temp);
	    $temp.val($("#userKeyText").text().trim()).select();
	    document.execCommand("copy");
	    $temp.remove();
	}



    logoutSubmit = (event) => {

        var form = $("#logoutForm");
        var url = form.attr('action');

        event.preventDefault();
        $.ajax({
        type: "POST",
        url: url,
        xhrFields: {
            withCredentials: true
        },
        data: form.serialize(), // serializes the form's elements.
            success: function(data)
            {
                UserProfile.clearAll();
                window.open(Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH, '_self')
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                               //alert("Status: " + textStatus); alert("Error: " + errorThrown);
  }
        });
        event.preventDefault();

    }

    getRoleBasedRights = () => {
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
            return(
               <li><a href={Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"myProfilePrescriber"}><i className="fa fa-user-circle-o"></i> My Profile</a></li>
           );
        }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            //Office Admin
            return(
                <li><a href={Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"myProfileAgent"}><i className="fa fa-user-circle-o"></i> My Profile</a></li>
            );
        }
    }

    helpBoxModal(){


        return(
            <div id="helpBoxModal" className="modal fade"  >
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-header "  >
                            <h4 className="modal-title">Contact Information</h4>
                        </div>
                        <div className="modal-body " >
                        <div className="agengtDetailsAdd inner-page-container">
                        <div className="col-sm-12">
                            <p>
                                <span>
                                    <b>Please do not exchange HIPAA protected information via e-mail.</b>
                                </span>
                                <br/><br/>
                                <b>ProntoRx Support Email: </b>
                                <a href="mailto:support@theprontorx.com">support@theprontorx.com</a>
                                <br/>
                                Please use this email for general inquiries and non-urgent technical support issues related to ProntoRx.
                                <br/>
                                <br/>
                                <b>ProntoRx Support Phone: </b>
                                877-265-1231
                                <br/>
                                Please call during business hours for urgent technical support related to ProntoRx.
                                <br/>
                                <br/>
                                <b> Clinical Support: </b>
                                <a href="mailto: clinicalservices@theprontorx.com">clinicalservices@theprontorx.com</a>
                                <br/>
                                Please use this e-mail if you have inquiries related to clinical programs and products.<br/>
                                <br/>
                                <span><b>ProntoRx Support Hours of Operations: </b></span>
                                <br/>
                                <br/>
                                <b>Days: </b>
                            Monday through Friday
                            <br/>
                            <br/>
                            <b>Hours: </b>
                            9am to 5pm Central Time
                            <br/>
                            <br/>
                            <b>Holidays Observed: </b> New Year’s Day, Memorial Day, Independence Day, Labor Day, Thanksgiving Day, Day After Thanksgiving Day and Christmas.
                        </p>
                        </div>
                        </div>
                        </div>
                        <div className="modal-footer">

                                <button  type="button " id="helpBoxModalCancel" name = "helpBoxModalEdit" className="btn btn-primary wizard-next btn-dark right" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render(){
        // $("#toggleSideNav").on("click",
        // function()
        // {
        //     $(".wrapper").toggleClass("slide-nav-toggle");
        //     $(".fixed-sidebar-left").toggleClass("sidebar-collapsed");
        //     $("#front-page-logo,#front-page-logo-ks").toggleClass("hidden")

        // });
        let isHide = false
        if(this.props.isSuccess && this.state.isSuccess){
            this.setState({isSuccess:false})
           this.componentWillMount()
        }
        let role_name = ''
        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER){
            role_name = "Ks Business Super User"
        }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            role_name = "Ks Tech Admin"
        }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            role_name = "Agent"
        }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
            role_name = "Prescriber"
        }

        if (this.state.login === true) {
            return <Redirect to='/' />
        }

        if(UserProfile.getOfficeAdmin()){
            href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"officeAdminRepresentative"
        }else{
            href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"representative"
        }

        if(UserProfile.getIsNeverLogin() || UserProfile.getInactiveDueToIncorrectPass() || UserProfile.getIsInactiveDueToIncorrectSecQue() || UserProfile.getIsPasswordExpire() || UserProfile.getShowChangePassword()){
            isHide = true
       }

        return(


            <div>
                <header className="herader-v2">

                    <div className="navbar-fixed">
                        <nav id="top-nav">
                        {/*<a className="navbar-brand" href="#">Navbar</a>
                        <button className="navbar-toggler pull-right visible-xs" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        */}
                        <div className="navbar-collapse nav-wrapper" id="navbarNav">
                            {!isHide?<a id="toggleSideNav"><span className="material-icons">menu</span></a>:null}
                            <IdleTimerComponent />
                             {!isHide?
                             <div className="right">
                             <ul className="navbar-nav navbar-link visible-md visible-lg">
                                    <li className={window.location.href.indexOf(Constant.FRONTEND_CONTEXT_PATH+"trainingVideo") > -1 ? "nav-item active" : "nav-item"}><a href={Constant.FRONTEND_CONTEXT_PATH+"Resources"} className="nav-link">Resources <span className="sr-only">(current)</span></a></li>
                                    <li className={window.location.href.indexOf(Constant.FRONTEND_CONTEXT_PATH+"workqueue") > -1 ? "nav-item active" : "nav-item"}>
                                        <a className="nav-link" href={Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"workqueue"}>Status Queue<span className="sr-only">(current)</span></a>
                                    </li>
                                </ul>
                                <ul className="navbar-nav navbar-user-link ">
                                    <div className="navbar-icons-box inline">
                                        {UserProfile.getOfficeAdmin() || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER?
                                    <li className="dropdown inline notification">
                                            <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#">
                                            {this.state.pendingAgentCount > 0 ?
                                                <i className="material-icons white-text fo">notifications_active</i>
                                                :
                                                <i className="material-icons white-text fo">notifications</i>
                                            }
                                            {this.state.pendingAgentCount > 0 ?
                                                <div className="notification-count">
                                                    <div data-test-selector="NotificationIndicator"><span>{this.state.pendingAgentCount>9?"9+":this.state.pendingAgentCount }</span>
                                                    </div>
                                                </div>
                                                :
                                               null
                                            }
                                            </a>
                                            <ul className="dropdown-menu dropdown-user animated flipInY">
                                                <li>
                                                    <div className="user-detail-box">
                                                        <div className="user-text">
                                                            <h4>Notifications</h4>

                                                        </div>
                                                    </div>
                                                </li>


                                              <li>
                                              <div className="user-detail-box"><div className="user-text"><h5>Pending Agent Approvals: <a href={href} className="text-darkpurpale fw700 font20">{this.state.pendingAgentCount}</a></h5></div></div>

                                             </li>

                                            </ul>
                                    </li>:''}
                                     <li className="dropdown inline keydrop">
                                        <a className="dropdown-toggle profile-pic " data-toggle="dropdown" href="#">
                                                <i className="fa fa-key"></i>
                                        <span className="material-icons vMiddle">arrow_drop_down</span> </a>
                                    <ul className="dropdown-menu dropdown-user animated flipInY">
                                        <li>
                                            <div className="user-detail-box">
                                                <div className="user-text">
                                                    {this.state.isCopy ? <div className="alert alert-success copied-success" id="copiedSuccessfully">
                                                        <strong>Copied successfully!</strong>
                                                    </div>:""}
                                                     <h4>User Key</h4>
                                                </div>
                                            </div>
                                        </li>
                                          <li>
                                                <div className="user-detail-box">
                                                <div className="user-text">
                                                     <h5 id="userKeyText">{UserProfile.getExcelExportPassword()}
                                                          <a href="#" onClick={(event) => {this.copyToClipboard(event);}}><i className="fa fa-clipboard" aria-hidden="true"></i></a>
                                                     </h5>
                                                </div>
                                            </div>
                                          </li>

                                            <li>
                                                <p>*Note* Above password will be valid only for today till 12 AM CST. A new password will be autogenerated each day.</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="dropdown inline helpicon">
                                            <a className="dropdown-toggle profile-pic helpicon" data-toggle="modal"   data-target="#helpBoxModal"  href="#">
                                            <i className="fa fa-question-circle"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-user animated flipInY ">


                                              <li><a  className="font16"><i className="fa fa-commenting font16 "></i> Your agent pending status: <span className="text-darkpurpale fw700 font20"></span></a>

                                             </li>

                                            </ul>
                                    </li>
                                        {/*
                                        <div className="navbar-icon" id="clientNameDivOld">Good Health Pharmacy</div>
                                        <div className="navbar-icon notification"> <a href="#"> <i className="simple-icons icon-bell"></i>
                                            <div className="notify"> <span className="heartbit"></span> <span className="top-nav-icon-badge">5</span> </div>
                                        </a> </div>
                                        <div className="navbar-icon"><a href="#"><i className="fa fa-fax"></i></a></div>
                                        <div className="navbar-icon"><a href="#"><i className="simple-icons icon-info"></i></a></div>*/}

                                    <li className="dropdown inline">
                                        <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#">
                                        <i className="fa fa-user-circle" aria-hidden="true"></i>
                                        {/*<span className="material-icons vMiddle account-icon">account_circle</span>*/}
                                        <span className="hidden-xs vMiddle">{UserProfile.getName()}</span><span className="material-icons vMiddle">arrow_drop_down</span> </a>
                                    <ul className="dropdown-menu dropdown-user animated flipInY">
                                        <li>
                                            <div className="user-detail-box">
                                                <div className="user-text">
                                                     <h4>{UserProfile.getName()}</h4>
                                                     <h6>{role_name}</h6>
                                                </div>
                                            </div>
                                        </li>
                                            {this.getRoleBasedRights()}
                                            {(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN && UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ?
                                                ( <li><a href={Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"changepassword"}><i className="fa fa-unlock-alt"></i> Change Password</a></li>)
                                                : null}
                                            {(UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN && UserProfile.getRole() != Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER) ?
                                                ( <li><a href={Constant.FRONTEND_CONTEXT_PATH+"securityquestions"}><i className="fa fa-question" aria-hidden="true"></i> Security Questions</a></li>)
                                                : null}
                                        <li>
                                            <form method="post" action={Constant.SIGNOUT_CONTEXT_PATH} id="logoutForm" onSubmit={this.logoutSubmit}>
                                                <button className="btn btn-darkpurpale" type="submit" value="Logout">Logout</button>
                                            </form>
                                        </li>
                                        </ul>
                                    </li>
                                    </div>
                                </ul>
                                </div>
                                :''}
                            </div>
                        </nav>
                        <div className="bottom-nav">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="nav-left clearfix">
                                        <div className="col-xs-12 col-sm-6 col-md-6">
                                            {/* <h2>{this.props.h2Tag}</h2> */}
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6">
                                            <ol className="breadcrumb right">
                                                <li className="breadcrumb-item active">{this.props.h2Tag}</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isHide? <FixSideBar  data ={this.props.data} MyProfileAgent = {this.state.MyProfileAgent}/>:" "}
                    </div>
                    {this.helpBoxModal()}
                </header>

            </div>

        );
    }
}

export default header;