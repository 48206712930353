import React, {Component} from 'react';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';
import CustomInput from '../../../Component/CustomUI/Input'
import Label from '../../../Component/CustomUI/Label'
import * as Constant from '../../../Component/CustomUI/Constant';
import $ from 'jquery';
import axios from 'axios';
import UserProfile from '../../../UserProfile';

export default class ChangePassword extends Component {

        state = {
               changePasswordValid : {
                    oldPasswordValid : false,
                    passwordValid : false,
                    confirmPasswordValid : false,
               } ,
              hasError : false,
              errorList : [],
              oldPasswordNotMatch:true,
              password:true,
              confirmPassword:false,
              oldPasswordandPassword:false,
              blankPassword:false


        }

        oldPasswordMatch=()=>{
                let oldPassword =$("#oldPasswordlogin").val();
                if(oldPassword ){
                            let queryString  = '';
                            queryString +=  '?oldPass='+ encodeURIComponent(oldPassword) +'&userId='+UserProfile.getUserId()
                            axios.get('/user/oldPassword'+queryString).then(response => {
                                if(response.data){
                                    this.setState({oldPasswordNotMatch:response.data})
                                }else{
                                    this.setState({oldPasswordNotMatch:response.data})
                                    window.Materialize.toast(Constant.PASSWORD_DOES_NOT_MATCH, 3000,  'red rounded')
                                }
                    });
                }
        }
        submitChangePasswordListner = () =>{
            let oldPassword = $("#oldPasswordlogin").val();
            let password = $("#passwordlogin").val();
            let confirmPassword = $("#confirmPasswordlogin").val();
            let passwordValid = false
            let confirmPasswordValid = false

              if((!oldPassword||oldPassword.trim().length==0)||(!password||password.trim().length==0)||(!confirmPassword||confirmPassword.trim().length==0)){
                 window.Materialize.toast(Constant.MANDATORY_MESSAGE, 3000,  'red rounded')
              }else if(oldPassword != "" && !this.state.oldPasswordNotMatch){
                  window.Materialize.toast(Constant.PASSWORD_DOES_NOT_MATCH, 3000, 'red rounded')
              }else if(password.match(Constant.WRONG_PASSWORD_FORMATE) == null){
                  passwordValid=true
                  window.Materialize.toast(Constant.INVALID_PASSWORD_FORMATE, 3000, 'red rounded')
              }else if(password.match(Constant.WRONG_PASSWORD_FORMATE) == null&&oldPassword==password){
                  passwordValid=true
                  window.Materialize.toast(Constant.SAME_OLD_NEW_PASSWORD, 3000, 'red rounded')
              }else if(password != confirmPassword){
                  confirmPasswordValid=true
                  window.Materialize.toast(Constant.CONFIRM_PASSWORD_INVALID, 3000, 'red rounded')
              }




                  let queryString = '';
                  queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
                  queryString += '&password='+encodeURIComponent(password)
                  this.securityQuestion = []
                  if(oldPassword && password && confirmPassword && this.state.oldPasswordNotMatch &&  !passwordValid && !confirmPasswordValid){
                   axios.put(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/user/updateCredentials'+queryString,this.securityQuestion)
                    .then(response => {
                    let data = response.data
                    let matchData = "successfully"
                    if(data === matchData){
                       // window.location.href = UserProfile.getUrlName();
                        window.Materialize.toast(Constant.CHANGE_PASSWORD_UPDATED_SUCESSFULLY, 3000, 'green rounded')
                        $("#oldPasswordlogin").val("")
                        $("#passwordlogin").val("")
                        $("#confirmPasswordlogin").val("")


                  }else{
                    window.Materialize.toast(response.data, 4000, 'red rounded')
                  }
                })
                .catch(function (error) {
                    console.log(error);
                });


            }

    }


       render (){


        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                   <Header h2Tag={"Change Password"} />
                    <main>
                        <div className="inner-page-container">
                            <div className="row">
                            <div className="boxWithShadow boxWithSpace clearfix">
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                            <div className="col-sm-12">
                                                <div className="input-field">
                                                    <Label lblhtmlFor="oldPasswordlogin"  lblclassName={this.state.blankPassword? 'active': null}   lblname={Constant.LABEL_OLDPASSWORD+Constant.STAR}/>
                                                    <CustomInput intid="oldPasswordlogin" intName="password" intType="password" blur={this.oldPasswordMatch} intClasssName={this.state.blankPassword? 'validate invalid': null} maxlength="16" required />
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="input-field">
                                                    <Label lblhtmlFor="passwordlogin" lblclassName={this.state.blankPassword? 'active': null}  lblname={Constant.LABEL_PASSWORD+Constant.STAR}/>
                                                    <CustomInput intid="passwordlogin" intName="password" intType="password" blur={this.password}intClasssName={this.state.blankPassword? 'validate invalid': null} maxlength="16" required />
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                            <div className="input-field">
                                                <Label lblhtmlFor="confirmPasswordlogin" lblclassName={this.state.blankPassword? 'active': null}  lblname={Constant.LABEL_CONFIRM_PASSWORD+Constant.STAR}/>
                                                <CustomInput intid="confirmPasswordlogin" intType="password" intName={Constant.INPUT_CONFIRM_PASSWORD} intClasssName={this.state.blankPassword? 'validate invalid': null} maxlength="16" required />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-md-offset-1">
                                             <div className="mt-15">
                                                 <p className="purpleText font14">{Constant.INVALID_PASSWORD_FORMATE}</p>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <section className="buttonWrapper mb20">
                                            <button className="btn waves-effect waves-light btn-darkpurpale " onClick={this.submitChangePasswordListner}>Save</button>
                                            <a href={UserProfile.getUrlName()} className="btnn btn btn-primary waves-effect waves-light btn-dark">Cancel</a>
                                        </section>
                                </div>

                            </div>
                       </div>
                    </main>
                    <Footer />
                 </div>
            </div>
        )
    }
}