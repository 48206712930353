import React, {Component} from 'react';
import Input1 from '../../../Component/CustomUI/Input';
import Label from '../../../Component/CustomUI/Label';
import $ from 'jquery';
import {Input} from 'react-materialize';
import axios from 'axios';
import UserProfile from '../../../UserProfile';
import * as Constant from '../../../Component/CustomUI/Constant';
import Footer from '../../../Component/PageComponent/Footer/Footer';
import Header from '../../../Component/PageComponent/Header/Header';


let securityQuestionsData=[]
let securityQuestionAnswerData =[]


export default class SecurityQuestions extends Component {

    state = {
        ProfileValidate :{
            securityAnswer1Valid:false,
            securityAnswer2Valid:false,
            securityAnswer3Valid:false,
            securityQuestion1Valid:false,
            securityQuestion2Valid:false,
            securityQuestion3Valid:false,
            isDuplicateAnswer:false,
            isDuplicateQuestion:false
        },
        isAgent:false,
        isPrescriber:false,
        isSuccess:false

    }

    componentDidMount = () =>{

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT){
            this.getAgentSecurityQuestionList()
         }else if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER){
             this.getPrescriberSecurityQuestionList()
         }
    }

    getAgentSecurityQuestionList = () =>{

        let queryString = '';
        let isProfile = false
        queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
        queryString += '&isProfile='+isProfile

        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/agentProfile/getAgentProfileData'+queryString)
            .then(response => {
                // console.log(response.data)
                securityQuestionsData.push(response.data.securityQuestions)
                securityQuestionAnswerData.push(response.data.securityQuestionAnswer)
                this.setState({isAgent:true});


            })
            .catch(function (error) {
                console.log(error);
        });

    }

    getPrescriberSecurityQuestionList = () =>{

        let queryString = '';
        let isProfile = false
        queryString += '?username='+encodeURIComponent(UserProfile.getUserName())
        queryString += '&isProfile='+isProfile

        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/prescriberProfile/getPrescriberProfileData'+queryString)
            .then(response => {
                // console.log(response.data)
                securityQuestionsData.push(response.data.securityQuestions)
                securityQuestionAnswerData.push(response.data.securityQuestionAnswer)
                this.setState({isPrescriber:true});

        })
            .catch(function (error) {
                console.log(error);
        });
    }

    saveAgentProfileListner = ()=>{

        let securityQuestion1Valid = false;
        let securityQuestion2Valid = false;
        let securityQuestion3Valid = false;
        let securityAnswer1Valid = false;
        let securityAnswer2Valid = false;
        let securityAnswer3Valid = false;
        let isDuplicateAnswer12= false;
        let isDuplicateAnswer23= false;
        let isDuplicateAnswer31= false;
        let isDuplicateAnswer = false
        let isDuplicateQuestion =false
        let isValid = true

        let securityQuestion1 = $("#securityQuestion1").val();
        let securityQuestion2 = $("#securityQuestion2").val();
        let securityQuestion3 = $("#securityQuestion3").val();
        let securityAnswer1 = $("#securityAnswer1").val();
        let securityAnswer2 = $("#securityAnswer2").val();
        let securityAnswer3 = $("#securityAnswer3").val();
        let answerId1 = $("#answerId1").val();
        let answerId2 = $("#answerId2").val();
        let answerId3 = $("#answerId3").val();

        console.log(securityAnswer1.trim())

        if(!securityAnswer1||securityAnswer1.trim().length==0 ){
            securityAnswer1Valid = true
            isValid = false
        }
        if(!securityAnswer2&&securityAnswer2.trim().length==0){
            securityAnswer2Valid = true
            isValid = false
        }

        if(!securityAnswer3&&securityAnswer3.trim().length==0 ){
            securityAnswer3Valid = true
            isValid = false
        }

        if(securityAnswer1Valid || securityAnswer2Valid || securityAnswer3Valid){
            window.Materialize.toast(Constant.MANDATORY_MESSAGE, 5000,  'red rounded')
        }

        if(securityAnswer1 && securityAnswer1.trim().length!=0 && securityAnswer2.trim().length!=0&&securityAnswer1.toLowerCase().trim() === securityAnswer2.toLowerCase().trim()){
            isDuplicateAnswer12 = true
            isValid = false
            isDuplicateAnswer = true
        }
        if(securityQuestion1 === securityQuestion2 ){
            securityQuestion1Valid = true
            securityQuestion2Valid = true
            isDuplicateQuestion = true
            isValid = false
        }
        if(securityAnswer2 && securityAnswer2.trim().length!=0 && securityAnswer3.trim().length!=0&&securityAnswer2.toLowerCase().trim() === securityAnswer3.toLowerCase().trim()){
            isDuplicateAnswer23 = true
            isDuplicateAnswer = true
            isValid = false
        }
        if(securityQuestion2 === securityQuestion3){
            securityQuestion2Valid = true
            securityQuestion3Valid = true
            isDuplicateQuestion = true
            isValid = false
        }
        if(securityAnswer3 && securityAnswer3.trim().length!=0 && securityAnswer1.trim().length!=0 && securityAnswer3.toLowerCase().trim() === securityAnswer1.toLowerCase().trim()){
            isDuplicateAnswer31 = true
            isDuplicateAnswer = true
            isValid = false
        }
        if(securityQuestion3 === securityQuestion1){
            securityQuestion3Valid = true
            securityQuestion1Valid = true
            isDuplicateQuestion = true
            isValid = false
        }

        if(isDuplicateQuestion){

            window.Materialize.toast(Constant.QUESTION_IS_DUPLICATE, 5000,  'red rounded')
        }
        if(isDuplicateAnswer){

            window.Materialize.toast(Constant.ANSWER_IS_DUPLICATE, 5000,  'red rounded')
        }

         this.securityQuestion = []

         this.securityQuestion.push({id:answerId1,question_id:securityQuestion1,answer:securityAnswer1.trim()});
         this.securityQuestion.push({id:answerId2,question_id:securityQuestion2,answer:securityAnswer2.trim()});
         this.securityQuestion.push({id:answerId3,question_id:securityQuestion3,answer:securityAnswer3.trim()});

        this.setState({
                ProfileValidate :{
                securityAnswer1Valid:securityAnswer1Valid,securityAnswer2Valid:securityAnswer2Valid,securityAnswer3Valid:securityAnswer3Valid,
                securityQuestion1Valid:securityQuestion1Valid,securityQuestion2Valid:securityQuestion2Valid,securityQuestion3Valid:securityQuestion3Valid,
                isDuplicateAnswer12:isDuplicateAnswer12,isDuplicateAnswer23:isDuplicateAnswer23,isDuplicateAnswer31:isDuplicateAnswer31
            }
        })

        if(isValid){
            //  console.log(this.securityQuestion)
            let queryString = '';
            queryString += '?username='+encodeURIComponent(UserProfile.getUserName())

            axios.put(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+'/user/updateCredentials'+queryString,this.securityQuestion)
            .then(response => {
                   let data = response.data
                    let matchData = "successfully"

                if(data === matchData){
                  // window.location.href = UserProfile.getUrlName();
                    window.Materialize.toast(Constant.SECURITY_QUE_UPDATE, 4000, 'green rounded')
                }else{
                    window.Materialize.toast(Constant.FAILED_ERROR_MSG, 4000, 'red rounded')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        this.setState({isSuccess:true})
    }

    render(){

        let secQueAns =securityQuestionAnswerData[0] ? securityQuestionAnswerData[0] : [];
        let secQue = securityQuestionsData[0] ? securityQuestionsData[0] : [];
        let questionIdList = []
        secQueAns.map(questionData =>{
           questionIdList.push(questionData.questionId);
           return questionData
        });

        const questionListData1 = secQue.map(questionData =>{
             return <option data-id={questionData.id} selected={questionIdList[0] === questionData.id} value={questionData.id} key={questionData.id} >{questionData.question}</option>
        });

        const questionListData2 = secQue.map(questionData =>{
             return <option data-id={questionData.id} selected={questionIdList[1] === questionData.id} value={questionData.id} key={questionData.id} >{questionData.question}</option>
        });

       const questionListData3 = secQue.map(questionData =>{
             return <option data-id={questionData.id} selected={questionIdList[2] === questionData.id} value={questionData.id} key={questionData.id} >{questionData.question}</option>
        });

        let answerOne = null;
        let answerTwo = null;
        let answerThree = null;

        let answerOneId = null;
        let answerTwoId = null;
        let answerThreeId = null;

        if(secQueAns && secQueAns[0]){
            answerOne = secQueAns[0].answer
            answerTwo = secQueAns[1].answer
            answerThree = secQueAns[2].answer

            answerOneId = secQueAns[0].answerId
            answerTwoId = secQueAns[1].answerId
            answerThreeId = secQueAns[2].answerId
        }

        let ProfileValidate = {...this.state.ProfileValidate}

        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                     <Header h2Tag={"Security Questions"} />

                    <main>
                        <div className="inner-page-container">
                            <div className="row clearfix">
                                <div className="col-sm-12">
                                    <div className="boxWithShadow boxWithSpace max-height-drop">
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12 pl0 pr0">
                                            <input type="hidden" value={answerOneId} id="answerId1" name="answerId1"/>
                                            <input type="hidden" value={answerTwoId} id="answerId2" name="answerId2"/>
                                            <input type="hidden" value={answerThreeId} id="answerId3" name="answerId3"/>

                                            <Input s={12} m={12} l={12} xl={12}  id='securityQuestion1'  type='select'  label='Security Question 1' name='securityQuestion1' required className={ProfileValidate.securityQuestion1Valid ? 'validate invalid': 'validate'}  >
                                                    {questionListData1}
                                            </Input>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12">
                                            <div className="input-field">                                
                                                <Label lblhtmlFor="securityAnswer1" lblclassName={ProfileValidate.securityAnswer1Valid || this.state.isUpdate||answerOne ?'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                <Input1 intid="securityAnswer1" intName={Constant.INPUT_SECURITY_ANSWER_1} intType="text" value={answerOne} required intClasssName={ProfileValidate.securityAnswer1Valid || ProfileValidate.isDuplicateAnswer12 || ProfileValidate.isDuplicateAnswer31? 'validate invalid noSpace': 'validate noSpace'} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12 pl0 pr0">
                                            <Input s={12} m={12} l={12} xl={12}  id='securityQuestion2'  type='select'  label='Security Question 2' name='securityQuestion2' required className={ProfileValidate.securityQuestion2Valid ? 'validate invalid': 'validate'}   >
                                                {questionListData2}
                                            </Input>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12">
                                            <div className="input-field">
                                                <Label lblhtmlFor="securityAnswer2" lblclassName={ProfileValidate.securityAnswer2Valid || this.state.isUpdate||answerTwo? 'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                <Input1 intid="securityAnswer2" intName={Constant.INPUT_SECURITY_ANSWER_2} intType="text" value={answerTwo} required intClasssName={ProfileValidate.securityAnswer2Valid  || ProfileValidate.isDuplicateAnswer12 || ProfileValidate.isDuplicateAnswer23? 'validate invalid noSpace': 'validate noSpace'} />
                                            </div>
                                        </div>                   
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12 pl0 pr0">
                                                <Input s={12} m={12} l={12} xl={12}  id='securityQuestion3'  type='select'  label='Security Question 3' name='securityQuestion3' required className={ProfileValidate.securityQuestion3Valid? 'validate invalid': 'validate'}  >
                                                        {questionListData3}
                                                </Input>
                                                </div>
                                        <div className="col-lg-6 col-md-6 col-xm-12 col-xs-12">
                                            <div className="input-field">
                                                <Label lblhtmlFor="securityAnswer3" lblclassName={ProfileValidate.securityAnswer3Valid || this.state.isUpdate ||answerThree? 'active null':'null active'} lblname={Constant.LABEL_ANSWER+Constant.STAR}/>
                                                <Input1 intid="securityAnswer3" intName={Constant.INPUT_SECURITY_ANSWER_3} intType="text" required value={answerThree} intClasssName={ProfileValidate.securityAnswer3Valid || ProfileValidate.isDuplicateAnswer31 || ProfileValidate.isDuplicateAnswer23? 'validate invalid noSpace': 'validate noSpace'} />
                                            </div>
                                        </div>
                                      
                    {this.props.isInactive?"":
                        <div className="clearfix">
                                <section className="buttonWrapper mb20">
                                    <button className="btn waves-effect waves-light btn-darkpurpale" onClick={this.saveAgentProfileListner}>Save</button>
                                    <a href={UserProfile.getUrlName()} className="btnn btn btn-primary waves-effect waves-light btn-dark">Cancel</a>
                                </section>
                        </div>
                    }                     </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                <Footer />
            </div>
        )
    }
}