import React, { Component } from 'react';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import CustomInput from '../../Component/CustomUI/Input';
import Label from '../../Component/CustomUI/Label';
import  * as Constant  from '../../Component/CustomUI/Constant';
import * as Datetime  from 'react-datetime';
import Select from 'react-select';
import $ from 'jquery';

let isDefaultData=1
let actionGridData = []
let isPaginationAllow = false
var dateFormat = require('dateformat');
let valid=true

export default class AuditLog extends Component {
    state = {
        page:1,
        sizePerPage:100,
        serchFrom:'',
        serchTo:'',
        hideoption:true,
        Username:'',
        actionGridData:[],
        actionType:[],
        actiontypeoption:'',
        selectedUname:'',
        sortName:'dateTime',
        sortOrder:'desc',
        selectedactionAdd:null,
        actionTypeList:[],
        pageCount:0
       }
      constructor(props) {
            super(props);
            this.options = {
                onPageChange: this.onPageChange,
                onSortChange: this.onSortChange,
                sizePerPage: this.state.sizePerPage,
                sizePerPageList: [ 100,50,25 ],
                paginationPosition: 'top',
                prePage: 'Prev', // Previous page button text
                nextPage: 'Next', // Next page button text
                firstPage: 'First', // First page button text
                lastPage: 'Last',
                page:this.state.page
            };
    }
        componentDidMount(){
            $(".ipaddressnumeric").on('keypress keyup blur', function (event) {
                var regex = new RegExp("^[0-9.:]+$");
               var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
               if(event.key=='Enter'||event.key==13){
                return true;
                }else if (!regex.test(key)){
                    event.preventDefault();
                    return false;
                }
            });

            $(".datenumeric").on('keypress keyup blur', function (event) {
                var regex = new RegExp("^[0-9/:AM PM]+$");
               var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
               if(event.key=='Enter'||event.key==13){
                return true;
                }else if (!regex.test(key)){
                    event.preventDefault();
                    return false;
                }
            });

            $(".alphanumeric").on('keypress keyup blur', function (event) {
                var regex = new RegExp("^[a-zA-Z0-9@._-]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);				
                if(event.key=='Enter'||event.key==13){
                    return true;
                    }
                else if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            });
            $(".serverUrl").on('keypress keyup blur', function (event) {
                var regex = new RegExp("^[a-zA-Z0-9:/]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);				
                if(event.key=='Enter'||event.key==13){
                    return true;
                    }
                else  if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            });
          
            $('.noSpace').keyup(function() {
                this.value = this.value.replace(/\s/g,'');
               });
          axios.get('/audit/actionType')
            .then(response => {
             this.setState({actionTypeList:JSON.parse(JSON.stringify(response.data[0].actiontype))})
            })
            .catch(function (error) {
            });
          }
        actionTypeAdd = (selectedactionAdd) => {
        if(selectedactionAdd.value==""){
            this.setState({selectedactionAdd:""})
        }else{
            this.setState({selectedactionAdd:selectedactionAdd})
            }
        }
        usernameAdd = (selectedUname) => {
            this.setState({selectedUname:selectedUname})
        }
        onPageChange = (page, sizePerPage) => {
            this.setState({page:page,sizePerPage:sizePerPage,isLoading: true})
            this.getSearchData1(page, sizePerPage, this.state.sortName, this.state.sortOrder);
        }
        onSortChange = (sortName, sortOrder) => {
            this.setState({sortName:sortName,sortOrder:sortOrder,isLoading: true})
            this.getSearchData1(this.state.page, this.state.sizePerPage, sortName, sortOrder);
        }
        handleDateChange = (date) => {
            this.setState({ selectedDob: date });
        }
        getserchFrom=(date)=>{
            this.setState({ serchFrom: date });
        }
        getserchTo=(date)=>{
            this.setState({ serchTo: date });
        }
        getSearchData = () => {           
                 valid=true 
                if($("#Auditusername").val().charAt(0)=='_'||$("#Auditusername").val().indexOf('#')!==-1 ||$("#Auditusername").val().indexOf('%')!==-1||$("#Auditusername").val().indexOf('^')!==-1 ||$("#Auditusername").val().indexOf('&')!==-1 ||$("#Auditusername").val().indexOf(']')!==-1 ||$("#Auditusername").val().indexOf('[')!==-1 ||$("#Auditusername").val().indexOf('{')!==-1 ||$("#Auditusername").val().indexOf('}')!==-1 ||$("#Auditusername").val().indexOf('^')!==-1 ){
                    valid=false    
                    window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_USERNAME, 3000, 'red rounded')
                 }			
                 if($("#Auditserverurl").val().charAt(0)=='_'||$("#Auditserverurl").val().indexOf('#')!==-1 ||$("#Auditserverurl").val().indexOf('%')!==-1||$("#Auditserverurl").val().indexOf('^')!==-1 ||$("#Auditserverurl").val().indexOf('&')!==-1 ||$("#Auditserverurl").val().indexOf(']')!==-1 ||$("#Auditserverurl").val().indexOf('[')!==-1 ||$("#Auditserverurl").val().indexOf('{')!==-1 ||$("#Auditserverurl").val().indexOf('}')!==-1 ||$("#Auditserverurl").val().indexOf('^')!==-1 ){
                    valid=false   
                    window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_SERVER_URL, 3000, 'red rounded')
                         
                }	
                if($("#Auditlocalipaddress").val().charAt(0)=='_'||$("#Auditlocalipaddress").val().indexOf('#')!==-1 ||$("#Auditlocalipaddress").val().indexOf('%')!==-1||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ||$("#Auditlocalipaddress").val().indexOf('&')!==-1 ||$("#Auditlocalipaddress").val().indexOf(']')!==-1 ||$("#Auditlocalipaddress").val().indexOf('[')!==-1 ||$("#Auditlocalipaddress").val().indexOf('{')!==-1 ||$("#Auditlocalipaddress").val().indexOf('}')!==-1 ||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ){
                    valid=false  
                    window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_LOCALIPADDRESS, 3000, 'red rounded')
              }            
            
              if($("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != ""){

                var AuditserchFrom = document.getElementById("AuditserchFrom").value;
                var AuditserchTo = document.getElementById("AuditserchTo").value; 
                if((!Constant.Datetime.test(document.getElementById("AuditserchFrom").value)) || (!Constant.Datetime.test(document.getElementById("AuditserchTo").value))){
                    valid=false
                    window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')
                }
               else if (AuditserchFrom > AuditserchTo) {                   
                if(Datetime.moment(AuditserchFrom).isAfter(AuditserchTo)){
                        valid=false
                        window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')         
                    }            
                        }
            }else if(valid){
                window.Materialize.toast(Constant.AUDIT_SEARCH_VALIDATIONE, 3000, 'red rounded')
            }  
              if(valid && $("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != "" ){
                isDefaultData=0;
                let limit = 100;
                let offset = 0;
                var actiontypeObj = $("input[name='Auditactiontype']");
                var actiontype = '';
                var actiontypeLen = actiontypeObj.length;
                for(var i=0;i<actiontypeLen;i++){
                    actiontype = actiontype + (actiontype !="" ? "," : "") +actiontypeObj[i].value;
                }
                this.setState({
                    isLoading: true,
                })
                actionGridData=[]
                let queryString = '';
                let sortName = 'dateTime';
                let sortOrder = 'desc';
                queryString +='?loggedInUserName='+($("#Auditusername").val().trim() != undefined ? $("#Auditusername").val().trim() : "")
                queryString +='&serverUrl='+($("#Auditserverurl").val().trim() != undefined ? $("#Auditserverurl").val().trim() : "")
                queryString += '&limit='+limit
                queryString += '&offset='+offset
                queryString += '&sortName='+sortName
                queryString += '&sortOrder='+sortOrder
                queryString +='&ipAddress='+($("#Auditlocalipaddress").val().trim() != undefined ? $("#Auditlocalipaddress").val().trim() : "")
                queryString +='&serchFrom='+($("#AuditserchFrom").val().trim() != undefined ? $("#AuditserchFrom").val().trim(): "")
                queryString +='&serchTo='+($("#AuditserchTo").val().trim() != undefined ? $("#AuditserchTo").val().trim(): "")
                queryString += '&userAction='+actiontype
                axios.get('/audit/searchData'+queryString)
                    .then(response => {
                           isPaginationAllow = true
                        for(let i=0;i<response.data[0].length;i++){
                            let innerData= {dateTime :dateFormat(response.data[0][i].timeStamp,"mm/dd/yyyy") +" "+Datetime.moment(response.data[0][i].timeStamp, 'yyyy-mm-dd hh:mm:ss A').format('hh:mm:ss A'),loggedInUserName :response.data[0][i].loggedInUserName,serverUrl : response.data[0][i].serverUrl,userAction :response.data[0][i].userAction,ipAddress : response.data[0][i].ipAddress}
                            actionGridData.push(innerData)
                            }
                        this.setState({isUpdate:true,isLoading: false,page:1,sizePerPage:100,pageCount:response.data[1].pageCount,sortName:sortName,sortOrder:sortOrder})
                    })
                    .catch(function (error) {
                    });
                }             
            }
        getSearchData1 = (page, sizePerPage, sortName, sortOrder) => {
            valid=true 
            if($("#Auditusername").val().charAt(0)=='_'||$("#Auditusername").val().indexOf('#')!==-1 ||$("#Auditusername").val().indexOf('%')!==-1||$("#Auditusername").val().indexOf('^')!==-1 ||$("#Auditusername").val().indexOf('&')!==-1 ||$("#Auditusername").val().indexOf(']')!==-1 ||$("#Auditusername").val().indexOf('[')!==-1 ||$("#Auditusername").val().indexOf('{')!==-1 ||$("#Auditusername").val().indexOf('}')!==-1 ||$("#Auditusername").val().indexOf('^')!==-1 ){
                valid=false    
                window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_USERNAME, 3000, 'red rounded')
             }			
             if($("#Auditserverurl").val().charAt(0)=='_'||$("#Auditserverurl").val().indexOf('#')!==-1 ||$("#Auditserverurl").val().indexOf('%')!==-1||$("#Auditserverurl").val().indexOf('^')!==-1 ||$("#Auditserverurl").val().indexOf('&')!==-1 ||$("#Auditserverurl").val().indexOf(']')!==-1 ||$("#Auditserverurl").val().indexOf('[')!==-1 ||$("#Auditserverurl").val().indexOf('{')!==-1 ||$("#Auditserverurl").val().indexOf('}')!==-1 ||$("#Auditserverurl").val().indexOf('^')!==-1 ){
                valid=false   
                window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_SERVER_URL, 3000, 'red rounded')                     
            }	
            if($("#Auditlocalipaddress").val().charAt(0)=='_'||$("#Auditlocalipaddress").val().indexOf('#')!==-1 ||$("#Auditlocalipaddress").val().indexOf('%')!==-1||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ||$("#Auditlocalipaddress").val().indexOf('&')!==-1 ||$("#Auditlocalipaddress").val().indexOf(']')!==-1 ||$("#Auditlocalipaddress").val().indexOf('[')!==-1 ||$("#Auditlocalipaddress").val().indexOf('{')!==-1 ||$("#Auditlocalipaddress").val().indexOf('}')!==-1 ||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ){
                valid=false  
                window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_LOCALIPADDRESS, 3000, 'red rounded')
          }
          if($("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != "" ){
            var AuditserchFrom = document.getElementById("AuditserchFrom").value;
            var AuditserchTo = document.getElementById("AuditserchTo").value;   
            if((!Constant.Datetime.test(document.getElementById("AuditserchFrom").value)) || (!Constant.Datetime.test(document.getElementById("AuditserchTo").value))){
                valid=false
                window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')
            }
           else if (AuditserchFrom > AuditserchTo) {
                // if(Moment(AuditserchFrom, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')
                // > Moment(AuditserchTo, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')){
                    if(Datetime.moment(AuditserchFrom).isAfter(AuditserchTo)){
                    valid=false
                    window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')         
                }            
                // if((AuditserchTo.includes("10"))  || (AuditserchTo.includes("11"))){
                //     if(Moment(AuditserchFrom, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')
                //     > Moment(AuditserchTo, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')){
                //         valid=false
                //         window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')         
                //     }            
                // }else if(!(AuditserchTo.includes("10")) ||(AuditserchTo.includes("11") )){
                //          valid=false
                //         window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')                  
                //     }
                    }
        }else if(valid){
            valid=false
            window.Materialize.toast(Constant.AUDIT_SEARCH_VALIDATIONE, 3000, 'red rounded')
        }       
            if(valid && $("#AuditserchFrom").val().trim()!= ""&&$("#AuditserchTo").val().trim()!= "" ){
            let limit = sizePerPage;
            let offset = (page-1)*sizePerPage;
            var actiontypeObj = $("input[name='Auditactiontype']");
            var actiontype = '';
            var actiontypeLen = actiontypeObj.length;
            for(var i=0;i<actiontypeLen;i++){
                actiontype = actiontype + (actiontype !="" ? "," : "") +actiontypeObj[i].value;        }
            this.setState({
                isLoading: true,
            })
            if($("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != "" ){
            actionGridData=[]
            let queryString = '';
            queryString +='?loggedInUserName='+($("#Auditusername").val().trim() != undefined ? $("#Auditusername").val().trim() : "")
            queryString +='&serverUrl='+($("#Auditserverurl").val() != undefined ? $("#Auditserverurl").val() : "")
            queryString += '&limit='+limit
            queryString += '&offset='+offset
            queryString += '&sortName='+sortName
            queryString += '&sortOrder='+sortOrder
            queryString +='&ipAddress='+($("#Auditlocalipaddress").val().trim() != undefined ? $("#Auditlocalipaddress").val().trim() : "")
            queryString +='&serchFrom='+($("#AuditserchFrom").val().trim() != undefined ? $("#AuditserchFrom").val().trim(): "")
            queryString +='&serchTo='+($("#AuditserchTo").val().trim() != undefined ? $("#AuditserchTo").val().trim(): "")
            queryString += '&userAction='+actiontype.trim()
            axios.get('/audit/searchData'+queryString)
                .then(response => {
                    isPaginationAllow = true
                    for(let i=0;i<response.data[0].length;i++){
                        let innerData= {dateTime :dateFormat(response.data[0][i].timeStamp,"mm/dd/yyyy") +" "+Datetime.moment(response.data[0][i].timeStamp, 'yyyy-dd-mm hh:mm:ss A').format('hh:mm:ss A'),loggedInUserName :response.data[0][i].loggedInUserName,serverUrl : response.data[0][i].serverUrl,userAction :response.data[0][i].userAction,ipAddress : response.data[0][i].ipAddress}
                        actionGridData.push(innerData)}
                    this.setState({isUpdate:true,isLoading: false, page:page,sizePerPage:sizePerPage,
                        sortName:sortName,sortOrder:sortOrder})
                })
                .catch(function (error) {
                });
            }
        }else{
            this.setState({isLoading:false})
        }
    }
    createExportQueryString=(event)=>{
        valid=true 
        if($("#Auditusername").val().charAt(0)=='_'||$("#Auditusername").val().indexOf('#')!==-1 ||$("#Auditusername").val().indexOf('%')!==-1||$("#Auditusername").val().indexOf('^')!==-1 ||$("#Auditusername").val().indexOf('&')!==-1 ||$("#Auditusername").val().indexOf(']')!==-1 ||$("#Auditusername").val().indexOf('[')!==-1 ||$("#Auditusername").val().indexOf('{')!==-1 ||$("#Auditusername").val().indexOf('}')!==-1 ||$("#Auditusername").val().indexOf('^')!==-1 ){
            valid=false    
            window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_USERNAME, 3000, 'red rounded')
         }			
         if($("#Auditserverurl").val().charAt(0)=='_'||$("#Auditserverurl").val().indexOf('#')!==-1 ||$("#Auditserverurl").val().indexOf('%')!==-1||$("#Auditserverurl").val().indexOf('^')!==-1 ||$("#Auditserverurl").val().indexOf('&')!==-1 ||$("#Auditserverurl").val().indexOf(']')!==-1 ||$("#Auditserverurl").val().indexOf('[')!==-1 ||$("#Auditserverurl").val().indexOf('{')!==-1 ||$("#Auditserverurl").val().indexOf('}')!==-1 ||$("#Auditserverurl").val().indexOf('^')!==-1 ){
            valid=false   
            window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_SERVER_URL, 3000, 'red rounded')
                 
        }	
        if($("#Auditlocalipaddress").val().charAt(0)=='_'||$("#Auditlocalipaddress").val().indexOf('#')!==-1 ||$("#Auditlocalipaddress").val().indexOf('%')!==-1||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ||$("#Auditlocalipaddress").val().indexOf('&')!==-1 ||$("#Auditlocalipaddress").val().indexOf(']')!==-1 ||$("#Auditlocalipaddress").val().indexOf('[')!==-1 ||$("#Auditlocalipaddress").val().indexOf('{')!==-1 ||$("#Auditlocalipaddress").val().indexOf('}')!==-1 ||$("#Auditlocalipaddress").val().indexOf('^')!==-1 ){
            valid=false  
            window.Materialize.toast(Constant.AUDIT_SEARCH_AUDIT_LOCALIPADDRESS, 3000, 'red rounded')
      }
      if($("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != ""){
    var AuditserchFrom = document.getElementById("AuditserchFrom").value;
        var AuditserchTo = document.getElementById("AuditserchTo").value;  
        if((!Constant.Datetime.test(document.getElementById("AuditserchFrom").value)) || (!Constant.Datetime.test(document.getElementById("AuditserchTo").value))){
            valid=false
            window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')
        }      
        else if (AuditserchFrom > AuditserchTo) {
            // if(Moment(AuditserchFrom, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')
            // > Moment(AuditserchTo, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')){
                if(Datetime.moment(AuditserchFrom).isAfter(AuditserchTo)){
                valid=false
                window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')         
            }            
            // if((AuditserchTo.includes("10"))  || (AuditserchTo.includes("11"))){
            //     if(Moment(AuditserchFrom, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')
            //     > Moment(AuditserchTo, 'MM/DD/YYYY hh:mm A').format('MM/DD/YYYY HH:mm')){
            //         valid=false
            //         window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')         
            //     }            
            // }else if(!(AuditserchTo.includes("10")) ||(AuditserchTo.includes("11") )){
            //          valid=false
            //         window.Materialize.toast(Constant.AUDIT_SEARCH_DATE_VALIDATIONE, 3000, 'red rounded')                  
            //     }
                }
    }else if(valid){
       if( $("#AuditserchFrom").val().trim() == "" && $("#AuditserchTo").val().trim() == "" ){
        valid=false
        window.Materialize.toast(Constant.AUDIT_SEARCH_VALIDATIONE, 3000, 'red rounded')
       }    
    }    
        if(valid && $("#AuditserchFrom").val().trim() != "" && $("#AuditserchTo").val().trim() != "" ){
            let sortName = this.state.sortName?this.state.sortName:'dateTime';
            let sortOrder =  this.state.sortOrder?this.state.sortOrder:'desc';
            let queryString = ''
            var actiontypeObj = $("input[name='Auditactiontype']");
            var actiontype = '';
            var actiontypeLen = actiontypeObj.length;
            for(var i=0;i<actiontypeLen;i++){
                actiontype = actiontype + (actiontype !="" ? "," : "") +actiontypeObj[i].value;
            }
            queryString +='?loggedInUserName='+($("#Auditusername").val().trim() != undefined ? $("#Auditusername").val().trim() : "")
            queryString += '&userAction='+actiontype.trim()
            queryString +='&serverUrl='+($("#Auditserverurl").val() != undefined ? $("#Auditserverurl").val() : "")
            queryString +='&serchFrom='+($("#AuditserchFrom").val().trim() != undefined ? $("#AuditserchFrom").val().trim(): "")
            queryString +='&serchTo='+($("#AuditserchTo").val().trim() != undefined ? $("#AuditserchTo").val().trim(): "")
            queryString += '&ipAddress='+($("#Auditlocalipaddress").val().trim() != undefined ? $("#Auditlocalipaddress").val().trim() : "")
            queryString += '&sortName='+sortName
            queryString += '&sortOrder='+sortOrder
            queryString += '&isDefaultData='+isDefaultData;           
            console.log(queryString)
             window.location = Constant.EXPORT_Audit_URL + queryString;
            }
            else if(valid) {
                window.Materialize.toast(Constant.AUDIT_SEARCH_VALIDATIONE, 3000, 'red rounded')
            }                
             
        }
    resetData = (event) => {
        isPaginationAllow=false
        $('#clearDiv').find('input').val('');
         $('#Auditserverurl').val('');
         $('#AuditserchFrom').val('');
         $('#AuditserchTo').val('');
         $('#Auditlocalipaddress').val('');
         $('#Auditactiontype').val('');
         $('#Auditusername').val('');
         actionGridData=[];
         this.setState({pageCount:0,selectedactionAdd:null,selectedUname:null,serchFrom: '',serchTo:'',sizePerPage:100})
         window.location.reload();
     }
    render() {
        this.options = {
        onPageChange: this.onPageChange,
        onSortChange: this.onSortChange,
        sizePerPage: this.state.sizePerPage,
        sizePerPageList: [ 100,50,25 ],
        paginationPosition: 'bottom',
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last',
        page: this.state.page
      };
        const {serchFrom,serchTo} = this.state;
        var today = Datetime.moment();
        var validTo = function( current ){
          if(serchFrom!=='')   
                return current.isAfter(serchFrom-86400000) && current.isBefore( today )   &&current.isAfter("01/01/2019")             
            else              
                return current.isAfter("01/01/2019")  && current.isBefore( today )
        };
        var validFrom = function( current ){
            if(serchTo!==''){
                return current.isBefore(serchTo+1)&& current.isBefore( today )&& current.isAfter("01/01/2019")
            }                
            else{
                return current.isAfter("01/01/2019")  && current.isBefore( today )
            }
        };

        let ActionTypedata = [];
        ActionTypedata.push({ value: "", label: "--Select--"})
        if(this.state.actionTypeList !== undefined && this.state.actionTypeList.length > 0){
            JSON.parse(this.state.actionTypeList).map( actionTp =>{
                ActionTypedata.push({ value: actionTp, label: actionTp.replace('_', ' ')})
            } );
        }
        const {selectedactionAdd} = this.state;
        return (
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                    <Header   h2Tag={"Audit Log"} />
                    <main>
                        <div className="inner-page-container">
                            <div className="col-sm-12">
                                <section className="boxWithShadow boxWithSpace clearfix max-height-drop" >
                                <div className="col-xs-12">
                                    <h4>Audit Log</h4>
                                </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field  hide-arrow">
                                            <Label lblhtmlFor="Auditusername" lblname="User Name" lblclassName="active" />
                                                <CustomInput intid="Auditusername" intType="text" intName="Auditusername"  keyPress={this.getSearchData} keyEvent='Enter'  intClasssName="alphanumeric " autocomplete="on" />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field suffix">
                                                <label className="active" forhtml="Auditactiontype">Action Type</label>
                                                <Select
                                                    id="Auditactiontype"
                                                    name="Auditactiontype"
                                                    isMulti={false}
                                                    value={selectedactionAdd}
                                                    options={ActionTypedata}
                                                    isSearchable={true}
                                                    onChange={this.actionTypeAdd}
                                                    closeMenuOnSelect={true}
                                                    placeholder="--Select--" />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field">
                                                <Label lblhtmlFor="Auditserverurl" lblname="Server URL" />
                                                <CustomInput intid="Auditserverurl" intClasssName="serverUrl noSpace"keyPress={this.getSearchData} keyEvent='Enter' intType="text" intName="serverurl" maxlength="100" />
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field suffix">
                                                <Label lblhtmlFor="icon_prefix" lblclassName="active" lblname="Search From*" />
                                                <Datetime  
                                                isValidDate={validFrom}
                                                intType="text" inputProps={{ id: 'AuditserchFrom', name: 'AuditserchFrom' ,className:'datenumeric'}}
                                                intClasssName="datepicker datenumeric"
                                                keyPress={this.getserchFrom}
                                                keyEvent='Enter'
                                                closeOnSelect={true}
                                                strictParsing={true}
                                                timeFormat={true}
                                                input={true}
                                                value={this.state.serchFrom}
                                                onChange={this.getserchFrom} />
                                                <i className="icon-calendar simple-icons" onClick={()=>$("#AuditserchFrom").click()}></i>
                                            </div></div>
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field suffix">
                                                <Label lblhtmlFor="icon_prefix" lblclassName="active" lblname="Search To*" />
                                                <Datetime
                                                    isValidDate={validTo}
                                                    intType="text" inputProps={{ id: 'AuditserchTo',name: 'AuditserchTo',className:'datenumeric' }} intClasssName="datepicker datenumeric"
                                                    keyPress={this.getserchTo}
                                                    keyEvent='Enter'
                                                    closeOnSelect={true}
                                                    value={this.state.serchTo}
                                                    strictParsing={true}
                                                    input={true}
                                                    timeFormat={true}
                                                    onChange={this.getserchTo} />
                                                    <i className="icon-calendar simple-icons"onClick={()=>$("#AuditserchTo").click()}></i>
                                                     </div></div>
                                        <div className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                                            <div className="input-field">
                                                <Label lblhtmlFor="Auditlocalipaddress" lblname="Local IP Address" lblclassName="active" />
                                                <CustomInput intid="Auditlocalipaddress" intName="Auditlocalipaddress" intClasssName="ipaddressnumeric noSpace" keyPress={this.getSearchData} keyEvent='Enter' intType="text"  maxlength="200" />

                                            </div>
                                        </div>
                                    </div>
                                    <section className="buttonWrapper">
                                        <button className="btn btn-darkpurpale" onClick={this.getSearchData}>Search</button>
                                        <button className="btn  btn-white" name="reset" onClick={this.resetData}>Reset</button>
                                        <button className="btn btn-darkpurpale" onClick={this.createExportQueryString}>Export</button>
                                        {/* <button className="btn" >Export</button> */}
                                    </section>
                                </section>
                            </div>
                        </div>
                        <div>
                            <div className="row">

                                <div className="col-sm-12">
                                    <section className="boxWithShadow boxWithSpace clearfix" >
                                    <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="count-number"><strong> Total Count:</strong> {this.state.pageCount}</h4>
                                    </div>
                                    </div>
                                        <div className="responsive-table">
                                        <BootstrapTable pagination ={isPaginationAllow} sizePerPage={this.state.sizePerPage} striped bordered={ true } data={actionGridData} options={this.options} remote={true} fetchInfo={{ dataTotalSize: parseInt(this.state.pageCount) }}>
                                            <TableHeaderColumn dataField='loggedInUserName' dataSort={true} width="15%" ><span>User Name</span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='serverUrl' width="45%"><span>Server URL</span></TableHeaderColumn>
                                            <TableHeaderColumn dataField='userAction' width="15%"><span>Action Type</span></TableHeaderColumn>
                                            <TableHeaderColumn isKey dataField='ipAddress' width="10%"><span>Local IP Address</span></TableHeaderColumn>
                                            <TableHeaderColumn dataSort={true} dataField='dateTime'width="15%"><span>Time Stamp</span></TableHeaderColumn>

                                            
                                        </BootstrapTable>
                                        </div>
                                        { this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                                      </section>
                                </div>

                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
                </div>


        );
    }
}