import React, { Component } from 'react';
import{AgentsTable as PhysicansAgentTable} from './AgentsTable';
import Button from '../../../Component/CustomUI/Button';
import CustomInput from '../../../Component/CustomUI/Input';
import Label from '../../../Component/CustomUI/Label';
import * as Constant from '../../../Component/CustomUI/Constant';
import $ from 'jquery';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';


let data =  []
export default class MyPhysiciansAgents extends Component {

    state = {
        selectedRow : {},
        displayData : [],
        isUpdated : false,
        isSearchLoading : false,
        isSetSearchLoading : false


    }

    searchMyAgentListener = () =>{
        if($("#searchMyAgentFname").val().trim().length === 0 ||$("#searchMyAgentFname").val().indexOf('#')!==-1||$("#searchMyAgentFname").val().indexOf('#')!==-1 ||$("#searchMyAgentFname").val().indexOf('%')!==-1 ||$("#searchMyAgentFname").val().indexOf('^')!==-1 ||$("#searchMyAgentFname").val().indexOf('&')!==-1){
            $("#searchMyAgentFname").val("")
		}
		if($("#searchMyAgentLname").val().trim().length === 0 ||$("#searchMyAgentLname").val().indexOf('#')!==-1 ||$("#searchMyAgentLname").val().indexOf('%')!==-1 ||$("#searchMyAgentLname").val().indexOf('^')!==-1 || $("#searchMyAgentLname").val().indexOf('&')!==-1 ){
             $("#searchMyAgentLname").val("")
        }
        if( $("#searchMyAgentEmail").val().trim().length === 0 ){
            $("#searchMyAgentEmail").val("")

        }
        // if ($("#searchMyAgentFname").val().trim().length === 0 ) {
        //     $("#searchMyAgentFname").val("")

        // }
        // if ($("#searchMyAgentLname").val().trim().length === 0 ) {
        //     $("#searchMyAgentFname").val("")

        // }
           if($("#searchMyAgentFname").val() || $("#searchMyAgentLname").val() || $("#searchMyAgentEmail").val()){
                this.setState({isSearchLoading : true})
                let queryParams = ''
                queryParams += '?fName='+$("#searchMyAgentFname").val().trim();
                queryParams += '&lName='+$("#searchMyAgentLname").val().trim();
                queryParams += '&email='+$("#searchMyAgentEmail").val().trim();

                axios.get('/prescriberProfile/searchMyAgentData'+queryParams)
                .then(response => {
                    this.setState({displayData:response.data,isUpdated:false,isSearchLoading : false})
                    // console.log(response.data);
                })
                .catch( (error) =>{
                    this.setState({
                        isSearchLoading: false
                    })
                });

           }
        else{
               window.Materialize.toast(Constant.PLEASE_SEARCH_CRITERIA, 3000,  'red rounded')
           }


    }

    setSelectedMyAgentListner = (event,row) =>{
        let userId = row.id
        let prescriberId = this.props.prescriberId
        let queryParams = ''
        queryParams += '?userId='+userId;
        queryParams += '&prescriberId='+prescriberId

        this.setState({isSetSearchLoading : true})
         axios.post('/prescriberProfile/setMyAgentAssoction'+queryParams)
           .then(response => {
                // console.log(response.data);
                this.setState({isSetSearchLoading : false})
                window.Materialize.toast(Constant.AGENT_ASSOCIATED_SUCCESS, 3000,  'green rounded')
                this.props.getMyAgentData();
                $("#searchMyAgentModelCancel").click();
           })
           .catch(function (error) {
           });
    }
    setAction = (cell,row) =>{
        let unqiueData = "searchedAgent_"+row.id
        return(
             <div className="actions-list-div">
                <button name={unqiueData} id={unqiueData} checked={true} onClick={(event) => this.setSelectedMyAgentListner(event,row)}>
                    <i className="themify-icons ti-plus" aria-hidden="true"></i>
                </button>
            </div>
        )
    }
    myAgentGrid(){
        let displayGridData = []
        for(let i =0;i<this.state.displayData.length;i++){
            let innerJson = {"id":this.state.displayData[i].id,"agentName":this.state.displayData[i].firstName +" "+this.state.displayData[i].lastName,"agentEmail":this.state.displayData[i].email}
            displayGridData.push(innerJson);
        }
        if(this.state.isUpdated){
            displayGridData = []
        }
        return(
            <div>
                <BootstrapTable data={displayGridData} striped bordered={ true } className="table-height" >
                    <TableHeaderColumn  dataSort={false} dataFormat={this.setAction}>Action</TableHeaderColumn>
                    <TableHeaderColumn isKey dataField='agentName' dataSort={false}>Agent Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='agentEmail' dataSort={false}>Agent Email</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
    myAgentSearch(){
        return(
            <div className="myAgentSearch">
                    <div className="row">

                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="input-field">
                                <Label lblhtmlFor="searchMyAgentFname" lblclassName = {'active'}  lblname={Constant.LABEL_FIRST_NAME}/>
                               <CustomInput intid="searchMyAgentFname"  intClasssName=" alphanumeric disablednumber" intType="text"   intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" maxLength="30" keyPress={this.searchMyAgentListener} keyEvent='Enter'/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="input-field">
                                <Label lblhtmlFor="searchMyAgentLname" lblclassName = {'active'}  lblname={Constant.LABEL_LAST_NAME}/>
                               <CustomInput intid="searchMyAgentLname" intClasssName=" alphanumeric disablednumber" intType="text"   intName={Constant.INPUT_LAST_NAME} intPattern="^[a-zA-Z]+$" maxLength="30" keyPress={this.searchMyAgentListener} keyEvent='Enter'/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <div className="input-field">
                            <Label lblhtmlFor="searchMyAgentEmail" lblclassName = {'active'}  lblname={Constant.LABEL_EMAIL}/>
                             <CustomInput intid="searchMyAgentEmail"  intType="text"   intName={Constant.INPUT_EMAIL} keyPress={this.searchMyAgentListener} keyEvent='Enter'/>
                        </div>
                    </div>
                   </div>
                </div>
        )
    }
    clearSearchMyAgentListener = () =>{
        $("#searchMyAgentFname").val("");
        $("#searchMyAgentLname").val("");
        $("#searchMyAgentEmail").val("");
        this.setState({isUpdated:true})
    }
     searchMyAgentModel(){
        return(
             <div id="searchMyAgentModal" className="modal fade " role="dialog" >
                <div className="modal-dialog max-width-1024">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Search for an Agent </h4>
                        </div>
                        <div className="modal-body">
                            <div className="agengtDetailsAdd inner-page-container">
                                <div className="col-sm-12">
                                    {this.myAgentSearch()}
                                </div>
                                <div className="col-sm-12">
                                    {this.myAgentGrid()}
                                </div>
                            </div>
                        </div>
                       {this.state.isSearchLoading || this.state.isSetSearchLoading?<div className="overlay"><div className="loader v-center"></div></div>:
                        <div className="modal-footer">
                            <section className="buttonWrapper">
                                <Button id="searchMyAgent" click={this.searchMyAgentListener.bind(this)} secondParams={null} btnName="Search" className="btn btn-primary btn-darkpurpale wizard-next"  />
                                <button type="button" id="searchMyAgentModelCancel" className="btn btn-primary wizard-next" data-dismiss="modal">Cancel</button>
                            </section>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount(){
        $(".disablednumber").on('keypress keyup blur', function (event) {
			var regex = new RegExp("[0-9]");
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
			if (regex.test(key)) {
				event.preventDefault();
				return false;
			}
        });
        $(".alphanumeric").on('keypress keyup blur', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9@._-]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if(event.key=='Enter'||event.key==13){
                return true;
                }else  if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
    }
    render(){
        return(
            <div>
                <PhysicansAgentTable data={data}
                    userPrescribers={this.props.userPrescribers}
                    user = {this.props.user}
                    editMyAgentListener = {this.props.editMyAgentListener}
                    selectedPrivilegesListener = {this.props.selectedPrivilegesListener}
                    addMyAgentListener = {this.props.addMyAgentListener}
                />
                <div className="pull-right fixed-action-btn">
                    <div className="row">
                        <div className="col-md-6">
                            <a href='#' id="searchMyAgentId" onClick={this.clearSearchMyAgentListener} data-toggle="modal" data-position='top'  data-target="#searchMyAgentModal" className='tooltipped btn-floating btn-large waves-effect waves-light green' data-tooltip='Search for an Agent' data-position='top'>
                                <i className="large material-icons">search</i>
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href='#' id="addMyAgentId" onClick={this.props.addMyAgentListener} data-toggle="modal" data-position='top'  data-target="#addMyAgentModal" className='tooltipped btn-floating btn-large waves-effect waves-light red' data-tooltip='Add MyAgent' data-position='top'>
                                <i className="large material-icons">add</i>
                            </a>
                        </div>
                    </div>
                </div>
                {this.searchMyAgentModel()}
            </div>
        )
    }
}