import React, { Component } from 'react';
import { Input } from 'react-materialize';
import Input1 from '../../../Component/CustomUI/Input';
import Label from '../../../Component/CustomUI/Label';
import * as Constant from '../../../Component/CustomUI/Constant';
import $ from 'jquery';
import axios from 'axios';
import UserProfile from '../../../UserProfile';
import AgentContact from './AgentContact';


export default class agentProfile extends Component {

    state = {
        randomPassword:''
    }
    componentDidMount(){

        //restrict specific character
	  $(".specialCharRestriction").on('keypress keyup blur', function (event) {
		// var regex = new RegExp("^[#%^&{}[\\]]+$");
		var regex = new RegExp("^[#%^&{}[\\]]+$");
		var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regex.test(key)) {
			event.preventDefault();
			return false;
		}
	 });
	 //specific characters allowed ,alphabets allowed,digit allowed
	 $(".alphanumeric").on('keypress keyup blur', function (event) {
		var regex = new RegExp("^[a-zA-Z0-9@._']+$");
		var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (!regex.test(key)) {
			event.preventDefault();
			return false;
		}
    });

    $('.noSpace').keyup(function() {
		this.value = this.value.replace(/\s/g,'');
	   });
       }

       render() {


        let userDataObject = this.props.userData[0] ? this.props.userData[0] : ''
        let ProfileValidate = { ...this.props.ProfileValidate }
        let resetPasswordMethod = userDataObject.resetPasswordMethod ? userDataObject.resetPasswordMethod : ''
      // let className = userDataObject.username !== ' ' ? 'active' : ''
        let valid = $("#mNameAboutAgent").val()?true:false





        return (
            <div>
                <div className="AgentProfile">
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="uNameAboutAgent" lblclassName={'active'}  lblname={Constant.LABEL_USER_NAME + Constant.STAR} />
                            <input type="hidden" value={userDataObject.password} id="password" name="password" />
                            <input type="hidden" value={userDataObject.status} id="userStatus" name="userStatus" />
                            <input type="hidden" value={userDataObject.approvalStatus} id="userApprovalStatus" name="userApprovalStatus" />
                            <input type="hidden" value={userDataObject.userKey} id="userKey" name="userKey" />
                            {/*<Input1 intid="uNameAboutAgent" intType="text" value={userDataObject.username} intClasssName={ProfileValidate.userNameValid ? 'validate invalid' : 'validate'} required intName="username" intPattern="^[a-zA-Z]+$" change={this.props.change} intStep={Constant.STEP_FOUR_AGENT} />
                            <Label lblhtmlFor="uNameAboutAgent" lblclassName={(ProfileValidate.userNameValid) ? 'active' : null} lbldataError={ProfileValidate.userNameValid ? "Please Enter username" : null}  lblname={Constant.LABEL_USER_NAME + Constant.STAR} />*/}

                            <Input1 intid="uNameAboutAgent" intClasssName={"specialCharRestriction alphanumeric noSpace"} intType="text" value={userDataObject.username} required intName="username" intPattern="^[a-zA-Z]+$" change={this.props.change} intStep={Constant.STEP_FOUR_AGENT}/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pl0 pr0">
                    <Input xl={12} l={12} m={12} s={12} type='select' required id="prefix" label={Constant.LABEL_PREFIX} name={Constant.INPUT_PREFIX} >
                        <option  value="" >--Select--</option>
                        <option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_DR} value={userDataObject.prefix === Constant.PREFIX_VALUE_DR ? userDataObject.prefix : Constant.PREFIX_VALUE_DR}> { Constant.PREFIX_NAME_DR}</option>
                        <option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_MISS} value={userDataObject.prefix === Constant.PREFIX_VALUE_MISS ? userDataObject.prefix : Constant.PREFIX_VALUE_MISS}>{ Constant.PREFIX_NAME_MISS}</option>
						<option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_MR} value={userDataObject.prefix === Constant.PREFIX_VALUE_MR ? userDataObject.prefix : Constant.PREFIX_VALUE_MR}>{ Constant.PREFIX_NAME_MR}</option>
						<option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_MRS} value={userDataObject.prefix === Constant.PREFIX_VALUE_MRS ? userDataObject.prefix : Constant.PREFIX_VALUE_MRS}>{Constant.PREFIX_NAME_MRS}</option>
						<option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_MS} value={userDataObject.prefix === Constant.PREFIX_VALUE_MS ? userDataObject.prefix : Constant.PREFIX_VALUE_MS}>{ Constant.PREFIX_NAME_MS}</option>
                        <option selected = {userDataObject.prefix === Constant.PREFIX_VALUE_PROF} value={userDataObject.prefix === Constant.PREFIX_VALUE_PROF ? userDataObject.prefix :Constant.PREFIX_VALUE_PROF}>{Constant.PREFIX_NAME_PROF}</option>

                    </Input>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="fNameAboutAgent" lblclassName={ProfileValidate.firstNameValid ? 'active null' : 'null active'} lblname={Constant.LABEL_FIRST_NAME + Constant.STAR} />
                            <Input1 intid="fNameAboutAgent" intType="text" value={userDataObject.firstName} intClasssName={ProfileValidate.firstNameValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_FIRST_NAME} intPattern="^[a-zA-Z]+$" change={this.props.change} intStep={Constant.STEP_FOUR_AGENT} maxlength="50"/>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="mNameAboutAgent" lblclassName={valid ? 'null active' :  'active'} lblname={Constant.LABEL_MIDDLE_NAME} />
                            <Input1 intid="mNameAboutAgent" intType="text" value={userDataObject.middleName} maxlength="50" />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="lNameAboutAgent" lblclassName={(ProfileValidate.lastNameValid) ? 'active null' : 'null active'} lblname={Constant.LABEL_LAST_NAME + Constant.STAR} />
                            <Input1 intid="lNameAboutAgent" intType="text" value={userDataObject.lastName} intClasssName={ProfileValidate.lastNameValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_LAST_NAME} intPattern="^[a-zA-Z]+$" change={this.props.change} intStep={Constant.STEP_FOUR_AGENT} maxlength="50" />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                            <Label lblhtmlFor="emailAboutAgent" lblclassName={ProfileValidate.emailValid ? 'active null' : 'null active'} lblname={Constant.LABEL_EMAIL + Constant.STAR} />
                            <Input1 intid="emailAboutAgent" intType="text" value={userDataObject.email} intClasssName={ProfileValidate.emailValid ? 'validate invalid' : 'validate'} required intName={Constant.INPUT_EMAIL} change={this.props.change} intStep={Constant.STEP_FOUR_AGENT} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pl0 pr0">
                        <Input xl={12} l={12} m={12} s={12} disabled id='resetpasswordmethod' className="hide-icon" type='select' label='Reset Password Method *' name='resetpassword'>
                         <option disabled value=""></option>
                        <option value="EMAIL"  selected={resetPasswordMethod === "EMAIL" ? resetPasswordMethod : false}>Email </option>
                     </Input>
                     </div>
                    {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_PRESCRIBER?"":<div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                        <div className="input-field">
                                <Label lblhtmlFor={Constant.LABEL_CREATE_AND_SHOW_PASSWORD} lblclassName='active ' lblname= {Constant.LABEL_CREATE_AND_SHOW_PASSWORD}/>
                                <input type="text" className="form-control" id="displayTempPassword" name="displayTempPassword" value={this.state.randomPassword} autocomplete="off" disabled="disabled" />
                                {/* <input type="hidden" name="tempPassword" id="tempPassword" value="u3YFk2rE" className="form-control" autocomplete="off" /> */}

                                <button className="btn-link"
                                     disabled = {userDataObject.status === "INACTIVE"?"disabled":''}
                                     onClick={
                                        () => {
                                            axios.get('agentProfile/generatePassword')
                                                .then(response => {
                                                    this.setState({randomPassword:response.data})
                                                  //  $("#displayTempPassword").val(response.data)
                                                    // console.log(response)
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        }
                                    }>

                                    <span className="fa-passwd-reset fa-stack generatePasswordIcon" title="Generate Password" >
                                        <i className="fa fa-undo fa-stack-2x"></i>
                                        <i className="fa fa-lock fa-stack-1x"></i>
                                    </span>
                                </button>

                        </div>
                    </div> }
                    
                    {UserProfile.getRole() === Constant.LOGGED_IN_ROLE_AGENT?
                    <AgentContact
                    stateMaster = {this.props.stateMaster}
                    prescriber={this.props.prescriber}
                    agentId={userDataObject.id}
                    agentPracticeLocation = {this.props.agentPracticeLocation}
                    getAgentProfileData = {this.props.getAgentProfileData}/> : null}
                    

                    <div className="clearfix"></div>
                </div>
            </div>
        )

    }
}