import React from 'react';

export default (props) => {
    return (
        
        <input id={props.intid} 
        pattern={props.intPattern} 
        placeholder={props.intplaceholder} 
        type={props.intType} 
        className={props.intClasssName} 
        name={props.intName} 
        required={props.required} 
        maxLength={props.maxlength}
        onChange={props.change ? ((event) => props.change(event,props.intStep)) : function(){}}
        onKeyPress={props.keyPress ? ((event) => {if (event.key === props.keyEvent) {props.keyPress(event)}}) : function(){}}
        onBlur={props.blur?((event)=>props.blur(event,props.intStep)):function(){}}
        // defaultValue={props.value}
        // value={props.value}
        value={props.value?props.value:null}
        disabled={((props.fromApi && props.value) || props.isDisabled)}  />          

        
    );
} 