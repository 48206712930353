import React, { Component } from 'react';
import LoginBox from '../../Component/LoginPage/LoginBox/LoginBox';
import UserProfile from '../../UserProfile';
import * as Constant from '../../Component/CustomUI/Constant';
import Footer from '../../Component/PageComponent/Footer/Footer';
import $ from 'jquery';

class Login extends Component {

    state = {
        username: "",
        password: "",
        login: false,
        isUserNameEmpty: false,
        isPasswordEmpty: false,
        inactiveDueToIncorrectPass:false
    }

    loginSubmit = (event) => {

       event.preventDefault();
       let isValid = this.loginValidation();
       if(isValid) {
            var form = $("#loginForm");
            var url = form.attr('action');

            $.ajax({
            type: "POST",
            url: url,
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            data: form.serialize(), // serializes the form's elements.

                success: function(data)
                {
                    if(data.validation ) {
                        if(data.validation.search("ProntoRx") !== -1) {
                            data.validation = data.validation.replace("ProntoRx","ProntoRx\u2122");
                        }
                        window.Materialize.toast(data.validation, 7000, 'red rounded')

                        if(data.remainingLoginAttemptMsg) {
                            if(data.remainingLoginAttemptMsg.search("ProntoRx") !== -1) {
                                data.remainingLoginAttemptMsg = data.remainingLoginAttemptMsg.replace("ProntoRx","ProntoRx\u2122");
                            }
                            window.Materialize.toast(data.remainingLoginAttemptMsg, 7000, 'red rounded')
                        }

                    } else {

                        UserProfile.clearAll();   

                        if(data.userName){
                            UserProfile.setUserName(data.userName);
                        }

                        if(data.userFullName){
                            UserProfile.setName(data.userFullName);
                        }

                        if(data.userId){
                            UserProfile.setUserId(data.userId);
                        }
                        if(data.role){
                            UserProfile.setRole(data.role)

                        }
                        if(data.isNeverLogin){
                            UserProfile.setIsNeverLogin(data.isNeverLogin)
                            UserProfile.setShowChangePassword(data.showChangePassword?data.showChangePassword:'')
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"agreement";
                        }else if(data.isInactiveDueToIncorrectSecQue) {
                            UserProfile.setIsInactiveDueToIncorrectSecQue(data.isInactiveDueToIncorrectSecQue)
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"changePassSecQue";
                        }else if(data.inactiveDueToIncorrectPass) {
                            UserProfile.setInactiveDueToIncorrectPass(data.inactiveDueToIncorrectPass)
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"changePassSecQue";
                        }else if(data.showChangePassword){
                            UserProfile.setShowChangePassword(data.showChangePassword)
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"changePassSecQue";
                        }else if(data.isPasswordExpire){
                            UserProfile.setIsPasswordExpire(data.isPasswordExpire)
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"changePassSecQue";
                        }
                        else{
                            window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH+"workqueue";
                        }

                    }
                },
                fail : function(data) {
                    window.location.href = Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH;
                },
            })
       }
    }

    loginValidation = () => {
        let isValid = true;
        let username = $("#username").val();
        let password = $("#password").val();
        if(username === "") {
            isValid = false;
        } else {
            this.setState({username : username})
            this.setState({isUserNameEmpty : false});
        }

        if(password === "") {
            isValid = false;
        } else {
            this.setState({password : password})
            this.setState({isPasswordEmpty : false});
        }

        if(!isValid){
            window.Materialize.toast("Invalid username and/or password", 7000, 'red rounded')
        }

        // if($("#username").val() !== "") {
        //     fetch(Constant.SIGNIN_CONTEXT_PATH).then(function(response) {
        //         console.log("-------->>>>>>>>>")
        //         console.log(response)
        //         //this.setState({statusCode:response.status})
        //         let statusCode = response.status
        //       if(statusCode === 404) {
        //         window.Materialize.toast(Constant.LOGIN_FAILED_USER_INACTIVE, 3000,  'red rounded')
        //       } else if(statusCode === "") {
        //         window.Materialize.toast(Constant.LOGIN_FAILED_USER_MAX_FAILED_ATTEMPTS, 3000,  'red rounded')
        //       } else if(statusCode === "") {
        //         window.Materialize.toast(Constant.LOGIN_FAILED_USER_APPROVAL_STATUS_PENDING, 3000,  'red rounded')
        //       } else if(statusCode === "") {
        //         window.Materialize.toast(Constant.LOGIN_FAILED_USER_APPROVAL_STATUS__REJECTED, 3000,  'red rounded')
        //       } else if(statusCode === "") {
        //         window.Materialize.toast(Constant.LOGIN_FAILED_USER_INACTIVE_TECHADMIN, 3000,  'red rounded')
        //       }
        //       })

        // }
        return isValid;
    }

    changeLoginDetail = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({[name]: value});
    }

    render() {

        UserProfile.setUrlName(window.location.href)

        return (
            <div className="loginPage v2-back ">
		        <div className="wrapper">
                   {/* <LoginTop />*/}
                    <LoginBox loginSubmit={this.loginSubmit.bind(this)}
                                username={this.state.username}
                                password={this.state.password}
                                changeLoginDetail={this.changeLoginDetail}
                                isUserNameEmpty={this.state.isUserNameEmpty}
                                isPasswordEmpty={this.state.isPasswordEmpty}/>
                                {/*   <KeyPointSection />*/}

                    <Footer />
                </div>
            </div>
        );
    }
}

export default Login;