import React, { Component } from 'react';
import Input from '../../CustomUI/Input'
import * as Constant from '../../CustomUI/Constant';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import ReactTooltip from 'react-tooltip';
 import $ from 'jquery';
 import {OverlayTrigger, Tooltip} from 'react-bootstrap';
let fileURL;
let documentJson = [];
let npiList = [];

class Prescriber extends Component {
	tooltipFormatter(cell, row){
        return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={String(row.id)}>{cell}</Tooltip>}><span>{cell}</span></OverlayTrigger>);
    };
	setIcon = (cell,row) => {
		return (
			<div className="actionIconWrapper">
			<ReactTooltip />
				<a href='#' className='tooltipped' data-tip='View' data-position='top' onClick={(event)=>this.viewDocument(event,row)}>
				<i className="themify-icons ti-eye"></i></a>
				<a href="#" className="tooltipped" data-tip="Delete" data-position="top" onClick={(event)=>{this.props.removeDocument(event,row);this.removeDocument(event,row)}}>
				<i className="themify-icons ti-trash"></i></a>
			</div>
		);
	}
	removeDocument = (event, row) => {
		documentJson=[]
		let file = this.props.document
		for(var i =0;i <file.length;i++)
		{
			// console.log("remove from local"+i)
			var innerJson = {"id":i,"file":file[i].name}
			documentJson.push(innerJson);
		}
		// console.log("remove from documentJson"+JSON.stringify( documentJson))
    }

	viewDocument = (event,row) => {
		let file = this.props.document;
		let innerFile;
		for(let i = 0; i < file.length; i++) {
			if(row.file === file[i].name) {
				innerFile = file[i];
				break;
			}
		}
		fileURL = URL.createObjectURL(innerFile);
		window.open(fileURL,'Window Name','toolbar=0')
	}

	render() {



		$(".allownumericwithoutdecimal").on("keypress keyup blur",function (event) {
			$(this).val($(this).val().replace(/[^-\d].+/, ""));
			 	 if ((event.which < 48 || event.which > 57)) {
					 event.preventDefault();
			 }
			  });
        let stepTwoValidate = {...this.props.stepTwoValidate}
		let file = this.props.document;
		for(var i =0;i <file.length;i++){
			let isDuplicatefile=false
			let innerJson = {"id":i,"file":file[i].name}
			//for restrict duplicate file
			for(let j=0;j<documentJson.length;j++){
				if(file[i].name===documentJson[j].file){
					isDuplicatefile=true
				}
			}	if(isDuplicatefile===false)	{
				documentJson.push(innerJson);

			}
			// documentJson.push(innerJson);
		}
		// console.log("documentJson"+JSON.stringify( documentJson))
	//	console.log($("#npi").val())
		return (
			<div>
			<div className="prescriberRole">
			<div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5">
					<div className="input-field">
						<Input s={12} m={12} xl={12} l={12} intplaceholder={Constant.LABEL_NPI+Constant.STAR} intid="npi" intType="text" intClasssName={stepTwoValidate.npiValid? 'validate invalid allownumericwithoutdecimal': 'allownumericwithoutdecimal validate'} required intName={Constant.INPUT_NPI} change={this.props.Change} intStep={Constant.STEP_TWO_PRESCRIBER } onKeyUp={(event)=>this.props.getPrescriberApiData(event,"keyup")} onpaste={(event)=>this.props.getPrescriberApiData(event,"paste")} maxlength="10"/>
						{/*<Label lblhtmlFor="npi" lblclassName={this.props.data.npi || stepTwoValidate.npiValid ?  'active': null} lbldataError={stepTwoValidate.npiValid? Constant.NPI:null} lblname={Constant.LABEL_NPI+Constant.STAR}/>*/}
					</div>
				</div>
				<div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl5 pr5">
					<div className="input-field">
						{/*<label className="selectDoc">Upload License(Optional)</label>*/}
						<div className="file-field  select-file">
							<div className="btn btn-link">
								<i className="fa fa-upload" aria-hidden="true"></i>
								<input type="file" id="file" title="" data-tip="" data-for='test' accept="application/pdf" name="document" onChange={(event)=>{this.props.Change(event,'step2Prescriber');$("#file").val("")}}/>
							</div>
							<div className="file-path-wrapper">
								<Input s={12} m={12} xl={12} l={12} intClasssName="file-path validate" title="" intType="text" isDisabled={true} intplaceholder="Upload License(Optional)" intName="document" change={this.props.Change} intStep={Constant.STEP_TWO_PRESCRIBER }/>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="pl5 pr5">
			<BootstrapTable data={documentJson} striped bordered={ true } className="break-word  table-height">
				<TableHeaderColumn width={'70%'} isKey dataField='file' ><span data-tip="Document Name">Document Name</span><ReactTooltip place="top" html={true}/></TableHeaderColumn>
				<TableHeaderColumn width={'30%'} dataField='id' dataFormat={this.setIcon}><span data-tip="Action">Action</span><ReactTooltip place="top" html={true}/></TableHeaderColumn>
        	</BootstrapTable>
			</div>
			{this.props.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
        </div>
		);
	}
}

export  {Prescriber};