import React, {Component} from 'react';
import Footer from '../../Component/PageComponent/Footer/Footer';
import Header from '../../Component/PageComponent/Header/Header';
import UserSearch from './UserSearch';
import {UserHistoryTable,UserTable} from './UserTable';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import * as Constant from '../../Component/CustomUI/Constant';
import UserProfile from '../../UserProfile';

let queryString  = '';
let statusMsg = "";
let statusType = "";
let rowId = "";
let iconFlag = "";
let msg = "";
let Change = false
let isPaginationAllow = false

export default class ManageUser extends Component {
    state = {
        user: [],
        userCount: 0,
        isHistoryModalOpen: false,
        isConfirmationModalOpen: false,
        userHistory: [],
        status: "",
        isStatusChanged: false,
        isAuthChanged: false,
        page:1, sizePerPage:100, sortName:'userName', sortOrder:'asc',
        isLoading: false
    }

    componentDidMount() {
        this.onlyInteger($("#usernpi"));
        this.searchUser(this.state.page, this.state.sizePerPage, this.state.sortName, this.state.sortOrder, true);
    }

    onOpenHistoryModal = (row) => {
        if(this.state.isAuthChanged) {
            if(row.approvalStatus === Constant.APPROVED.toUpperCase()) {
                row.approvalStatus = Constant.REJECTED.toUpperCase()
            } else if(row.approvalStatus === Constant.REJECTED.toUpperCase()) {
                row.approvalStatus = Constant.APPROVED.toUpperCase()
            }
            this.setState({isAuthChanged : false})
        }
        this.setState({ isHistoryModalOpen: true });
    }

    onOpenConfirmationModal = () => {
        this.setState({ isConfirmationModalOpen: true });
    }

    onCloseHistoryModal = () => {
        this.setState({ isHistoryModalOpen: false });
    }

    onCloseConfirmationModal = () => {
        this.setState({ isConfirmationModalOpen: false });
    }

    onlyInteger = (element) => {
        $(element).keypress(function(event) {
            var keynum
            if(window.event) { // IE8 and earlier
                keynum = event.keyCode;
            } else if(event.which) { // IE9/Firefox/Chrome/Opera/Safari
                keynum = event.which;
            } else {
                keynum = 0;
            }

            if(keynum == 8 || keynum == 0 || keynum == 9 || keynum == 13) {
                return;
            }
            if(keynum <= 46
            || keynum > 57
            || keynum == 47) {
                event.preventDefault();
            } // prevent if not number/dot

        });

    }

    history = (event,row) => {
        // event.preventDefault();
        this.setState({
            isLoading: true
        })
        let options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true};
        axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/history/id?id='+row.id)
        .then(response => {
            let userHistory=[]
            if(response.data.length>0){
                userHistory = response.data.map(userHistoryData=>{
                           return {"user":userHistoryData.user_name,"description":userHistoryData.description,"date":Intl.DateTimeFormat('en-US', options).format(userHistoryData.updatedDate)}
                 })
                }
            this.setState({userHistory : userHistory, isLoading: false})
        })
        .catch(function (error) {
            console.log(error);
        });
        this.onOpenHistoryModal(row);
    }

    openStatusConfirmation = (event,row,flag) => {
        // event.preventDefault();
        msg = ""
        iconFlag = flag;
        if(flag==="Status") {
            if(this.state.isStatusChanged) {
                if(row.status === Constant.ACTIVE.toUpperCase()) {
                    row.status = Constant.INACTIVE.toUpperCase()
                } else {
                    row.status = Constant.ACTIVE.toUpperCase()
                }
            }
            if(row.status === Constant.ACTIVE.toUpperCase()) {
                msg = Constant.INACTIVE;
            } else {
                msg = Constant.ACTIVE;
            }
        } else if(flag==="Auth") {

            if(this.state.isAuthChanged) {
                if(row.approvalStatus === Constant.APPROVED.toUpperCase()) {
                    row.approvalStatus = Constant.REJECTED.toUpperCase()
                } else if(row.approvalStatus === Constant.REJECTED.toUpperCase()) {
                    row.approvalStatus = Constant.APPROVED.toUpperCase()
                }
            }
            msg = $(".radio-wrapper input[name=userAuthCheckbox"+row.id+"]:checked").val();
        }
        console.log(msg)
        statusMsg = "Are you sure you want to "+msg+" this User?";
        statusType = row.status
        rowId = row.id
        this.onOpenConfirmationModal();
    }

    updateStatus = (event,status,rowId) => {
       
        // event.preventDefault();
            let statusFlag = ""
            let addCSSFlag = ""
            let removeCSSFlag = ""
            let datatip = ""
            let addIcon = ""
            let removeIcon = ""
            if(status.statusType===Constant.INACTIVE.toUpperCase()) {
                statusFlag = Constant.ACTIVE
                addCSSFlag = 'badge complete status_'+rowId.rowId
                removeCSSFlag = 'badge incomplete status_'+rowId.rowId
                datatip = "Make Inactive"
                addIcon = "fa fa-ban "
                removeIcon = "fa fa-check-circle "
            } else {
                statusFlag = Constant.INACTIVE
                addCSSFlag = 'badge incomplete status_'+rowId.rowId
                removeCSSFlag = 'badge complete status_'+rowId.rowId
                addIcon = "fa fa-check-circle"
                removeIcon = "fa fa-ban "
                datatip = "Make Active"
            }
            axios.put(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/userStatus?userId='+rowId.rowId+'&userStatus='+statusFlag.toUpperCase())
            .then(response => {
                if(response.data) {
                    if(queryString === "") {
                        document.location.reload(true)
                    } else {
                        this.onCloseConfirmationModal();
                        this.setState({isStatusChanged:true});
                        $(".status_"+rowId.rowId).html(statusFlag);
                        $(".status_"+rowId.rowId).removeClass(removeCSSFlag).addClass(addCSSFlag);
                        $(".userStatusCheckboxIcon"+rowId.rowId).removeClass(removeIcon).addClass(addIcon);
                        $("#userStatusCheckbox"+rowId.rowId+" span").text(datatip)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    updateAuth = (event,rowId,msg) => {
        // event.preventDefault();
        let addCSSFlag = ""
        let removeCSSFlag = ""
        let approvalStatusType = ""
        let id = ""
        let removeId = ""
        if(msg.msg==="Reject") {
            approvalStatusType = Constant.REJECTED
            addCSSFlag = 'badge incomplete approvalStatus_'+rowId.rowId
            removeCSSFlag = 'badge complete approvalStatus_'+rowId.rowId
            id = 'userAuthCheckboxNo'+rowId.rowId
            removeId = 'userAuthCheckboxYes'+rowId.rowId
        } else if(msg.msg==="Approve") {
            approvalStatusType = Constant.APPROVED
            addCSSFlag = 'badge complete approvalStatus_'+rowId.rowId
            removeCSSFlag = 'badge incomplete approvalStatus_'+rowId.rowId
            id = 'userAuthCheckboxYes'+rowId.rowId
            removeId = 'userAuthCheckboxNo'+rowId.rowId
        }
        axios.put(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/userApproval?userId='+rowId.rowId+'&userStatus='+approvalStatusType.toUpperCase())
            .then(response => {
                if(response.data) {
                    window.Materialize.toast("User Approval Status Updated Successfully", 5000,  'green rounded')
                    if(queryString === "") {
                        document.location.reload(true)
                    } else {

                        this.onCloseConfirmationModal();
                        this.setState({isAuthChanged:true});
                        this.searchData(event)
                        Change = true
                        $(".approvalStatus_"+rowId.rowId).html(approvalStatusType);
                        $(".approvalStatus_"+rowId.rowId).removeClass(removeCSSFlag).addClass(addCSSFlag);
                        $('#userAuthCheckboxNA'+rowId.rowId).removeAttr('checked');
                        $('#'+id).prop('checked',true);
                        $('#'+id).prop('disabled',true);
                        $('#'+removeId).removeAttr('checked');
                        $('#'+removeId).removeAttr('disabled');
                    }
                } else {
                    window.Materialize.toast("Something went wrong. Please try again later", 5000,  'red rounded')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    searchData = (event) => {
        // event.preventDefault();
        // this.setState({isAuthChanged:false})
        if(this.state.isAuthChanged){
            this.setState({isAuthChanged:false})
        }
        if(this.state.isStatusChanged) {
            this.setState({isStatusChanged:false})
        }
        let limit = 100;
        let offset = 0;
        let sortName = 'userName';
        let sortOrder = 'asc';
        let page = 1;

        let firstName = document.getElementById("firstName").value;
        let lastName = document.getElementById("lastName").value;
        let email = document.getElementById("email").value;
		let userRole = document.getElementById("userRole").value;
        let userName = document.getElementById("userName").value;
        let userTitle = document.getElementById("userTitle").value;
        let authorization = document.getElementById("authorization").value;
        let status = document.getElementById("status").value;
        let npi = document.getElementById("usernpi").value;

        if($("#firstName").val().trim().length === 0||$("#firstName").val().indexOf('#')!==-1 ||$("#firstName").val().indexOf('%')!==-1||$("#firstName").val().indexOf('^')!==-1 ||$("#firstName").val().indexOf('&')!==-1 ||$("#firstName").val().indexOf(']')!==-1 ||$("#firstName").val().indexOf('[')!==-1 ||$("#firstName").val().indexOf('{')!==-1 ||$("#firstName").val().indexOf('}')!==-1 ||$("#firstName").val().indexOf('^')!==-1 ){
            $("#firstName").val("")
            firstName=""
          }
        if($("#lastName").val().trim().length === 0||$("#lastName").val().indexOf('#')!==-1 ||$("#lastName").val().indexOf('%')!==-1||$("#lastName").val().indexOf('^')!==-1 ||$("#lastName").val().indexOf('&')!==-1 ||$("#lastName").val().indexOf(']')!==-1 ||$("#lastName").val().indexOf('[')!==-1 ||$("#lastName").val().indexOf('{')!==-1 ||$("#lastName").val().indexOf('}')!==-1 ||$("#lastName").val().indexOf('^')!==-1 ){
            $("#lastName").val("")
            lastName=""
          }
        if($("#userName").val().trim().length === 0||$("#userName").val().indexOf('#')!==-1 ||$("#userName").val().indexOf('%')!==-1||$("#userName").val().indexOf('^')!==-1 ||$("#userName").val().indexOf('&')!==-1 ||$("#userName").val().indexOf(']')!==-1 ||$("#userName").val().indexOf('[')!==-1 ||$("#userName").val().indexOf('{')!==-1 ||$("#userName").val().indexOf('}')!==-1 ||$("#userName").val().indexOf('^')!==-1 ){
            $("#userName").val("")
            userName= ""
          }
        queryString = "";
        queryString += '?firstName='+ firstName.trim()
        queryString += '&lastName='+ lastName.trim()
        queryString += '&email='+ email.trim()
        queryString += '&userRole='+ userRole
        queryString += '&userName='+ userName.trim()
        queryString += '&userTitle='+ userTitle
        queryString += '&auth='+ authorization
        queryString += '&status='+ status
        queryString += '&npi='+npi
        queryString += '&limit='+limit
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        // console.log(queryString)
        if(queryString) {
            this.setState({
                isLoading: true,
                sizePerPage:limit,
                page:page,
                sortName:sortName,
                sortOrder:sortOrder
            })
            axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/search'+queryString)
            .then(response => {
                let userData=[]
                let userDataCount=0
                this.setState({isLoading: false})
                if(response.data[0].result.length!==0){
                    userData = JSON.parse(response.data[0].result).map(item=>{
                        return item
                    })
                    userDataCount = JSON.parse(response.data[1].resultCount)[0]
                    if(userData.length < 1 || userDataCount === 0) {
                        this.setState({user : [], userCount : 0});
                    }
                    else {
                          isPaginationAllow = true
                         this.setState({ user:userData,userCount:userDataCount});
                    }
                }else{
                    isPaginationAllow = false
                    this.setState({ user:userData,userCount:userDataCount});
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    // isLoading: false
                })
            });


        }
    }

    searchUser = (page, sizePerPage, sortName, sortOrder, defaultData) => {
        
        let limit = sizePerPage ? sizePerPage: 100;
        let offset = (page-1)*sizePerPage ? (page-1)*sizePerPage : 0;

        if(defaultData === true){
            sortName = 'userName';
            sortOrder = 'asc';
            page = 1;
            sizePerPage = 100;
        }

        let firstName = document.getElementById("firstName").value;
        let lastName = document.getElementById("lastName").value;
        let email = document.getElementById("email").value;
        let userRole = document.getElementById("userRole").value;
        let userName = document.getElementById("userName").value;
        let userTitle = document.getElementById("userTitle").value;
        let authorization = document.getElementById("authorization").value;
        let status = document.getElementById("status").value;
        let npi = document.getElementById("usernpi").value;
        if($("#firstName").val().trim().length === 0||$("#firstName").val().indexOf('#')!==-1 ||$("#firstName").val().indexOf('%')!==-1||$("#firstName").val().indexOf('^')!==-1 ||$("#firstName").val().indexOf('&')!==-1 ||$("#firstName").val().indexOf(']')!==-1 ||$("#firstName").val().indexOf('[')!==-1 ||$("#firstName").val().indexOf('{')!==-1 ||$("#firstName").val().indexOf('}')!==-1 ||$("#firstName").val().indexOf('^')!==-1 ){
            $("#firstName").val("")
            firstName=""
          }
        if($("#lastName").val().trim().length === 0||$("#lastName").val().indexOf('#')!==-1 ||$("#lastName").val().indexOf('%')!==-1||$("#lastName").val().indexOf('^')!==-1 ||$("#lastName").val().indexOf('&')!==-1 ||$("#lastName").val().indexOf(']')!==-1 ||$("#lastName").val().indexOf('[')!==-1 ||$("#lastName").val().indexOf('{')!==-1 ||$("#lastName").val().indexOf('}')!==-1 ||$("#lastName").val().indexOf('^')!==-1 ){
            $("#lastName").val("")
            lastName=""
          }
        if($("#userName").val().trim().length === 0||$("#userName").val().indexOf('#')!==-1 ||$("#userName").val().indexOf('%')!==-1||$("#userName").val().indexOf('^')!==-1 ||$("#userName").val().indexOf('&')!==-1 ||$("#userName").val().indexOf(']')!==-1 ||$("#userName").val().indexOf('[')!==-1 ||$("#userName").val().indexOf('{')!==-1 ||$("#userName").val().indexOf('}')!==-1 ||$("#userName").val().indexOf('^')!==-1 ){
            $("#userName").val("")
            userName=""
          }
        queryString = "";
        queryString += '?firstName='+ firstName.trim()
        queryString += '&lastName='+ lastName.trim()
        queryString += '&email='+ email.trim()
        queryString += '&userRole='+ userRole
        queryString += '&userName='+ userName.trim()
        queryString += '&userTitle='+ userTitle
        if(defaultData === true){
            queryString += '&auth=PENDING'
        }else{
            queryString += '&auth='+ authorization
        }
        queryString += '&status='+ status
        queryString += '&npi='+npi
        queryString += '&limit='+limit
        queryString += '&offset='+offset
        queryString += '&sortBy='+sortName
        queryString += '&sortOrder='+sortOrder
        // console.log(queryString)
        if(queryString) {
            this.setState({
                isLoading: true,
                sortName:sortName,
                sortOrder:sortOrder,
                page: page,
                sizePerPage: sizePerPage
            })
            axios.get(Constant.BACKEND_SERVER_URL+Constant.BACKEND_CONTEXT_PATH+Constant.USER_MANAGEMENT_URL+'/search'+queryString)
            .then(response => {
                let userData=[]
                let userDataCount=0
                this.setState({isLoading: false})
                if(response.data[0].result.length!==0){
                    userData = JSON.parse(response.data[0].result).map(item=>{
                        return item
                    })
                    userDataCount = JSON.parse(response.data[1].resultCount)[0]
                    if(userData.length < 1 || userDataCount === 0) {
                        this.setState({user : [], userCount : 0});
                    }
                    else {
                         isPaginationAllow = true
                         this.setState({ user:userData,userCount:userDataCount});
                    }
                }else{
                    isPaginationAllow = false
                    this.setState({ user:userData,userCount:userDataCount});
                }
            })
            .catch( (error) =>{
                console.log(error)
                //  this.setState({isLoading: false})
            });
        }
    }
    onPageChange = (page, sizePerPage) => {
        this.setState({page:page,sizePerPage:sizePerPage})
        this.searchUser(page, sizePerPage, this.state.sortName, this.state.sortOrder, false);
    }
    onSortChange = (sortName, sortOrder) => {
        this.setState({sortName:sortName,sortOrder:sortOrder})
        this.searchUser(this.state.page, this.state.sizePerPage, sortName, sortOrder, false);
    }
    render() {

        if(UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER || UserProfile.getRole() === Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN){
            UserProfile.setUrlName(window.location.href)
        }


        return(
            <div className="v2-back-inner">
                <div className="wrapper slide-nav-toggle">
                    <Header h2Tag={"User Management"}/>
                    <main>
                        <div className="inner-page-container">
                            <UserSearch searchData={this.searchData} searchUser={this.searchUser}/>
                            <section className="searchReportContainer boxWithShadow">
                                <div className="pageListContainer">

                                    <div>
                                      <UserTable isPaginationAllow = {isPaginationAllow} data={this.state.user} toggleStatus={this.openStatusConfirmation} toggleAuth={this.openStatusConfirmation} history={this.history} onPageChange={this.onPageChange} onSortChange={this.onSortChange} sizePerPage={this.state.sizePerPage} dataCount={this.state.userCount} page={this.state.page}/>
                                    </div>
                                </div>
                            </section>
                            <div>
                                <Modal open={this.state.isHistoryModalOpen} onClose={this.onCloseHistoryModal} center classNames={{modal:"max960"}}>
                                   <div className="modal-header">
                                        <h4 className="modal-title">User History</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="agengtDetailsAdd inner-page-container">
                                            <div className="col-sm-12">
                                            <UserHistoryTable historyData={this.state.userHistory}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                    <section className="buttonWrapper">
                                        <button type="button" id="button" className="btn btn-darkpurpale " onClick={this.onCloseHistoryModal}>Close</button>
                                        </section>
                                    </div>
                                </Modal>
                            </div>
                            <div>
                                <Modal open={this.state.isConfirmationModalOpen} onClose={this.onCloseConfirmationModal} classNames={{modal:"max550"}}>
                                    <div className="modal-header">
                                        <h4 className="modal-title">Change User status </h4>
                                    </div>
                                    <div className="modal-body">
                                    <div className="agengtDetailsAdd inner-page-container">
                                            <div className="col-sm-12">
                                                <p>
                                                {statusMsg}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                    <section className="buttonWrapper">
                                        <button type="button" id="button" className="btn btn-darkpurpale " onClick={iconFlag==="Status"?(event)=>this.updateStatus(event,{statusType},{rowId})
                                            :(event)=>this.updateAuth(event,{rowId},{msg})}>Yes</button>
                                        <button type="button" id="button" className="btn" onClick={this.onCloseConfirmationModal}>No</button>
                                        </section>
                                    </div>
                                </Modal>
                            </div>
                            {this.state.isLoading?<div className="overlay"><div className="loader v-center"></div></div>:null}
                        </div>
                    </main>
                    <Footer />
                 </div>
            </div>
        )
    }
}