import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import * as Constant from '../../../Component/CustomUI/Constant';
import ReactTooltip from 'react-tooltip';

class OfficeAdminAgentsTable extends Component {

setSpan = (cell,row) => {

    let classNameValue = "badge complete";

    if(row.status==Constant.DISSOCIATED){
       classNameValue = "red badge complete";
    }else if(row.status==Constant.PENDING){
       classNameValue = "yellow badge complete";
    }

     return (
        <div>
               <ReactTooltip place="top" html={true}/>
                <span className={classNameValue} data-tip={row.status}>
                    {row.status}
                </span>
         </div>
     )
}

setActionIcon = (cell,row) => {

        let styleClass = { display :'none'}
        if(!row.fromApi){
            styleClass.display = 'block inline';
        }

        return (
                <div className='actions-list-div'>
                 <ReactTooltip place="top" html={true}/>
                    <a href='#' onClick={(event) => this.props.editMyAgentListener(event,row)} data-tip="Edit" className='tooltipped' data-toggle="modal" data-position='top'  data-target="#editMyAgentModal"  data-tooltip='Edit' data-position='top'>
                        <i className='themify-icons ti-pencil-alt'></i>
                    </a>
                </div>
            )
}

 setAction = (cell,row) =>{
        let unqiueData = "changeAgent_"+row.userId+row.prescriberId
        return(
             <div className="actions-list-div form-group ">
                <input type="checkbox" onClick={(event) => this.props.selectedPrivilegesListener(event,row)}  id={unqiueData} name={unqiueData} className=" form-control preferredAddress"/>
                <label for={unqiueData}></label>
            </div>
        )
    }


  render() {

      let displayData = []

       for(let i =0;i<this.props.userPrescribers.length;i++){

          let userPrescribers = this.props.userPrescribers[i].userPrescribers;
          let prescriber = this.props.userPrescribers[i].prescriber;

          for(let j = 0;j<userPrescribers.length;j++){

                let user = userPrescribers[j].user;

                let startDateWithTime = userPrescribers[j].startDate ? new Date(userPrescribers[j].startDate).toLocaleString():null
                let startDate = startDateWithTime ? startDateWithTime.slice(0,startDateWithTime.indexOf(',')) : null;


                let endDateWithTime = userPrescribers[j].deactivateDate ? new Date(userPrescribers[j].deactivateDate).toLocaleString() : null
                let endDate = endDateWithTime ? endDateWithTime.slice(0,endDateWithTime.indexOf(',')) : null



                let pStatus = userPrescribers[j].status
                pStatus = pStatus.toLowerCase()
                let status = pStatus.toLowerCase().charAt(0).toUpperCase() + pStatus.slice(1);

                let userPrivileges = userPrescribers[j].userPrivileges
                let privileges = []
                for(let p = 0; p < userPrivileges.length;p++){
                    privileges.push(userPrivileges[p].value);
                }

                //if(UserProfile.getUserName() !== user.username){

                    let json = {"userId":user.id,"agentName":user.firstName +" "+user.lastName,"prescriberName":prescriber.firstName +" "+prescriber.lastName,
                        "fName":user.firstName,"mName":user.middleName,"lName":user.lastName,"email":user.email,"userPrivileges":userPrivileges,"prefix":user.prefix,"privileges":privileges.join(","),
                        "status":status,"startDate":startDate,"endDate":endDate,"prescriberId":prescriber.id,"uName":user.username,"roleTitleName":(user.roleTitle)?user.roleTitle.name:''
                    }
                    displayData.push(json);
                //}
          }

       }

    return (
      <div>
      <div className="pageListContainer">
      <div className="responsive-table max-width-768">
        <BootstrapTable data={displayData} striped bordered={ true }>
            <TableHeaderColumn  dataFormat={this.setAction} width="8%" ><span>Select</span></TableHeaderColumn>
            <TableHeaderColumn  dataFormat={this.setSpan} width="10%"><span>Status</span></TableHeaderColumn>
            <TableHeaderColumn  dataField='prescriberName' width="15%"><span>Prescriber Name</span></TableHeaderColumn>
            <TableHeaderColumn  dataSort={true} isKey dataField='agentName' width="15%"><span>Prescriber's Agent Name</span></TableHeaderColumn>
            <TableHeaderColumn  dataSort={true} dataField='startDate' width="13%"><span>Start Date</span></TableHeaderColumn>
            <TableHeaderColumn  dataSort={true} dataField='endDate' width="10%"><span>End Date</span></TableHeaderColumn>
            <TableHeaderColumn  dataSort={true} tdStyle={ { whiteSpace: 'normal' } } thStyle={ { whiteSpace: 'normal' } } dataField='privileges' width="23%"><span>Privileges</span></TableHeaderColumn>
            <TableHeaderColumn  dataFormat={this.setActionIcon} width="8%"><span>Action</span></TableHeaderColumn>
        </BootstrapTable>
        </div>
        </div>
      </div>

    );
  }
}
export default OfficeAdminAgentsTable;