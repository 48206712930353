import * as Constant from './Component/CustomUI/Constant';
const RequestMap = {
    // "/auditLog":[Constant.PERMIT_ALL],

    "/UploadForms":[Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER,Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN],
    "/ViewForms":[Constant.PERMIT_ALL],
    "/Resources":[Constant.PERMIT_ALL],
    "/auditLog":[Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN],
    "/":[Constant.PERMIT_ALL],
    "/workqueue":[Constant.PERMIT_ALL],
    "/myProfileAgent":[Constant.LOGGED_IN_ROLE_AGENT,Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER,Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN],
    "/myProfilePrescriber":[Constant.LOGGED_IN_ROLE_PRESCRIBER,Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER,Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN],
    "/mylocations":[Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/myphysician":[Constant.LOGGED_IN_ROLE_AGENT],
    "/representative":[Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/changepassword":[Constant.LOGGED_IN_ROLE_AGENT,Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/securityquestions":[Constant.LOGGED_IN_ROLE_AGENT,Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/manageuser":[Constant.LOGGED_IN_ROLE_KS_BUSINESS_SUPER_USER,Constant.LOGGED_IN_ROLE_KS_TECH_ADMIN],
    "/user":[Constant.LOGGED_IN_ROLE_AGENT,Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/changePassSecQue":[Constant.LOGGED_IN_ROLE_AGENT,Constant.LOGGED_IN_ROLE_PRESCRIBER],
    "/myPhysicianLocations":[Constant.LOGGED_IN_ROLE_AGENT],
    "/officeAdminRepresentative":[Constant.LOGGED_IN_ROLE_AGENT],
    "/Accessdenied":[Constant.PERMIT_ALL],
    "/Pagenofound":[Constant.PERMIT_ALL],
    "/agreement":[Constant.PERMIT_ALL],
    "/formsSection":[Constant.PERMIT_ALL],
    "/trainingVideo":[Constant.PERMIT_ALL]
}

export default RequestMap;