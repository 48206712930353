import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import $ from 'jquery';
import Modal from 'react-responsive-modal';
import * as Constant from '../../CustomUI/Constant';
import UserProfile from '../../../../src/UserProfile';
import axios from 'axios';

class IdlePage extends Component {

        constructor(props) {
            super(props)
            this.idleTimer = null
            this.onAction = this._onAction.bind(this)
            this.onActive = this._onActive.bind(this)
            this.onIdle = this._onIdle.bind(this)
            this.state ={ timer:{
                count: 0,
                running: false,
                startTime:300
            },
            hideTimer:true,
            isConfirmationModalOpen:false
            }


        }

        startTimer = () => {

            this.setState({disable:false})
                let  value = this.state.timer.startTime?this.state.timer.startTime:""
                    const strSeconds=strSeconds?'':value.toString();
                    if(strSeconds.match(/[0-9]/)) {
                        this.setState({disable:false})
                        this.setState({hideTimer:true})
                        this.handleCountdown(parseInt(strSeconds, 10));

                }

        }

        handleCountdown = (seconds) => {
            this.setState({timer:{
                count: seconds,
                running: true}
            })
        }

        format= (time) =>{
            let seconds = time % 60;
            let minutes = Math.floor(time / 60);
            minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
            seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
            return minutes + ':' + seconds;
        }


        componentDidUpdate(prevProps, prevState) {
                if(this.state.timer.running){
                    this.handleStart();
                }
        }

        handleStart =() => {

                this.timer = setInterval(() => {
                    const newCount = this.state.timer.count - 1;
                    this.setState({timer: {count: newCount >= 0 ? newCount : 0}});
                    if(newCount === 1){
                        this.handleStop()
                    }
                    if(newCount === 0){
                        this.handleStop()
                      }
                    }, 1000);


        }

        handleStop() {

                if(this.timer) {
                clearInterval(this.timer);
                this.setState({timer:{
                    running: false}
                })
                    this.setState({hideTimer:false})
            }
        }

        handleStopForStayLogged = () =>{
            if(this.timer) {
                clearInterval(this.timer);
                this.setState({timer:{
                    running: false}
                })
                this.setState({hideTimer:true})
            }
        }

        _onAction(e) {
        }

        _onActive(e) {
        }

        _onIdle(e) {
            this.startTimer()
            this.setState({isConfirmationModalOpen:true})
        }

        logOutScreen = (event) => {

                var form = $("#logoutForm");
                var url = form.attr('action');

                event.preventDefault();
                $.ajax({
                type: "POST",
                url: url,
                xhrFields: {
                    withCredentials: true
                },
                data: form.serialize(), // serializes the form's elements.
                    success: function(data)
                    {
                        UserProfile.clearAll();
                        window.open(Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH, '_self')
                    },
                    error: function(XMLHttpRequest, textStatus, errorThrown) {
                        }
                  });


        }

        stayLoggedInScreen = () =>{
            this.isUserLoggedIn()
            this.setState({isConfirmationModalOpen:false})
            this.handleStopForStayLogged()
            this.setState ({ timer:{
                count: 0,
                running: false,
                startTime:300
            }})

        }

        isUserLoggedIn = () => {
            axios.post('/auth/isUserLoggedIn?path='+window.location.href)
            .then(response => {
                console.log("isUserLoggedIn:::::::::::::"+true);
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        onCloseConfirmationModal = () =>{

        }

    render() {
        let logOut = false
        if(!this.state.hideTimer){
             logOut = true
        }

        if(logOut){

            var form = $("#logoutForm");
            var url = form.attr('action');


            $.ajax({
            type: "POST",
            url: url,
            xhrFields: {
                withCredentials: true
            },
            data: form.serialize(), // serializes the form's elements.
                success: function(data)
                {
                    UserProfile.clearAll();
                    window.open(Constant.FRONTEND_SERVER_URL+Constant.FRONTEND_CONTEXT_PATH, '_self')
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    }
              });


        }

        return (
        <div>
            <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1500000}
            />
            <Modal open={this.state.isConfirmationModalOpen} onClose={this.onCloseConfirmationModal} classNames={{modal:"max550"}}>
                                        <div className="modal-header">
                                            <h4 className="modal-title">Are You Still There? </h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="agengtDetailsAdd inner-page-container">
                                                <div className="col-sm-12">
                                                    <p>
                                                        Due to inactivity. you will automatically be logged out of Physician Portal in <b className="font18 text-darkpurpale">{this.state.hideTimer?this.format(this.state.timer.count):""}</b> minutes
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <section className="buttonWrapper">
                                            <ul>
                                                <li><button type="button" id="button" className="btn btn-darkpurpale" onClick={this.stayLoggedInScreen}>Stay Logged In</button></li>
                                                <li><form method="post" action={Constant.SIGNOUT_CONTEXT_PATH} id="logoutForm" onSubmit={this.logOutScreen}><button className="btn btn-dark"  id="button" type="submit"  value="Logout">Log Out</button> </form></li>
                                            </ul>
                                            </section>
                                        </div>
            </Modal>
        </div>
        )
    }


}

export default IdlePage;